import { useEffect, useState } from 'react';
import { FormInputText } from './FormInputText';
import { FormInputSelectWithSearchDropdown, SearchWithDropdownContainer } from './FormInputSelectWithSearch.styles';

export function FormInputSelectWithSearch(props: any) {
  const {
    options, onChange, returnType, disabled, value, error, valid,
  } = props;
  const [searchFraze, setSearchFraze] = useState('');
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [dropdownActive, setDropdownActive] = useState(false);

  const handleChange = (opt: any, dontCallChange?: boolean) => {
    let fraze = opt.name;
    const code = opt.communal_territory ? `(${opt.communal_territory.car_plate_symbol})` : '';
    fraze += code;
    setSearchFraze(fraze);
    setDropdownActive(false);
    if (returnType === 'object') {
      if (dontCallChange !== true) {
        onChange(opt);
      }
    } else if (returnType && returnType !== 'object') {
      if (dontCallChange !== true) {
        onChange(opt[returnType]);
      }
    } else if (dontCallChange !== true) {
      onChange(opt.id);
    }
  };

  const handleSearch = (value: any) => {
    if (value === '') {
      setSearchFraze(value);
      setFilteredOptions([]);
      setDropdownActive(false);
    } else {
      const filtered = options.filter((item: any) => item.name.toLowerCase().startsWith(value.toLowerCase()));
      setSearchFraze(value);
      setFilteredOptions(filtered);
      if (filtered.length) {
        setDropdownActive(true);
      } else {
        setDropdownActive(false);
      }
    }
  };

  useEffect(() => {
    if (disabled) {
      setSearchFraze('');
    }
  }, [disabled]);

  useEffect(() => {
    if (typeof value === 'object' && value.value === undefined) {
      if (value.residence_commune_code !== null || value.residence_commune_code !== '') {
        const selected = options.filter((item: any) => item.cadastral_code === value.residence_commune_code);
        if (selected.length) handleChange(selected[0], true);
      }
    } if (typeof value === 'object' && value.value !== undefined) {
      if (value.value !== null || value.value !== '') {
        const selected = options.filter((item: any) => item.cadastral_code === value.value);
        if (selected.length) handleChange(selected[0], true);
      }
    } else if (value !== null || value !== '') {
      const selected = options.filter((item: any) => item.id === value);
      if (selected.length) handleChange(selected[0], true);
    }
  }, [value]);

  return (
    <SearchWithDropdownContainer>
      <FormInputText label={props.label} value={searchFraze} type="text" disabled={disabled} onChange={handleSearch} error={error} valid={valid} placeholder={props.placeholder} />
      {dropdownActive
        && (
          <FormInputSelectWithSearchDropdown>
            {filteredOptions.length > 0 && filteredOptions.map((opt: any) => (
              <span key={opt.id} onClick={() => handleChange(opt)}>
                {opt.name}
                {' '}
                {opt.communal_territory ? `(${opt.communal_territory.car_plate_symbol})` : ''}
                {' '}
              </span>
            ))}
          </FormInputSelectWithSearchDropdown>
        )}
    </SearchWithDropdownContainer>
  );
}
