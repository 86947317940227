import styled from 'styled-components';

export const InputTextAreaStyles = styled.textarea`
        width: 375px;
        height: 164px;
        border-radius: 10px;
        background: var(--Lightest-Grey, #F6F6F6);
        border: 1px solid grey;
        margin-top: 12px;
        padding: 15px;
        width: -webkit-fill-available;
        
        &:focus-visible{
                outline: none;
        }

`;
