import { FormInputGroup } from './FormInputGroup';
import { FormInputLabel } from './FormInputLabel';
import { FormInputNumberStyled } from './FormInputNumber.styles';
import { FormInputTextStyled } from './FormInputText.styles';

interface FormInputAddressProps {
  label: string;
  valueAddress: string;
  valueHouse: string;
  addressError: any;
  houseNumberError: any;
  validAddress: boolean;
  validHouseNumber: boolean;
  onAddressChange: Function;
  onHouseNumberChange: Function;
}

export function FormInputAddress({
  label, valueAddress, valueHouse, addressError, houseNumberError,
  validAddress, validHouseNumber, onAddressChange, onHouseNumberChange,
}: FormInputAddressProps) {
  const handleAddressChange = ({ target: { value } }: any) => {
    onAddressChange(value);
  };

  const handleHouseNumberChange = ({ target: { value } }: any) => {
    onHouseNumberChange(value);
  };

  return (
    <>
      <FormInputGroup>
        <FormInputLabel>{label}</FormInputLabel>
        <FormInputTextStyled
          type="text"
          className={`form-control text-center ${addressError ? 'is-invalid' : ''} ${validAddress ? 'is-valid' : ''}`}
          onChange={handleAddressChange}
          value={valueAddress}
        />
        <div className="invalid-feedback">{addressError}</div>
      </FormInputGroup>
      <FormInputGroup>
        <FormInputLabel>N.</FormInputLabel>
        <FormInputNumberStyled
          type="number"
          className={`form-control text-center ${houseNumberError ? 'is-invalid' : ''} ${validHouseNumber ? 'is-valid' : ''}`}
          onChange={handleHouseNumberChange}
          value={valueHouse}
        />
        <div className="invalid-feedback">{houseNumberError}</div>
      </FormInputGroup>
    </>
  );
}
