import { generalAlertError } from '../../helpers/alerts';
import { backAxe } from '../../services/axios-client/backAxeClient';
import { quotationsAxe } from '../../services/axios-client/quotationsAxeClient';
import caller from './formsCaller';

const getFormData = () => quotationsAxe.get('/form-data/service-models');

const getMunicipalities = () => quotationsAxe.get('/form-data/communes');

const getPrivacyTerms = () => quotationsAxe.get('/form-data/data-privacy');

const getVehicleBrands = () => quotationsAxe.get('/form-data/vehicle-makes');

const getStates = () => quotationsAxe.get('/form-data/states');

/**
 * Auto Form
 */

export const autoSubmitAnswers = async (answersData, navigate) => {
  caller.autoFormDataUpdated({
    isSubmitting: true,
    loadingQuotes: true,
  });

  const res = await quotationsAxe.post(
    '/quotations/vehicles/quote',
    answersData,
  );

  caller.autoFormDataUpdated({
    isSubmitting: false,
    loadingQuotes: false,
  });

  if (!res.data.request_token) {
    generalAlertError(
      "Qualcosa e' andato storto. Chiamare l'amministratore del Sistema",
    );
    return;
  }

  navigate(`/results/auto/${res.data.request_token}`);
};

export const autoChangeIndex = async (newIndex) => {
  caller.autoFormDataUpdated({ index: newIndex });
};

export const autoGetVehicleInformation = async (vehiclePlate) => {
  caller.autoFormDataUpdated({
    isLoadingFastQuote: true,
  });

  try {
    const response = await backAxe.get(
      `/fast-quote/get-vehicle-info/${vehiclePlate}`,
      {
        timeout: 6 * 60 * 1000,
      },
    );

    caller.autoFormDataUpdated({
      isLoadingFastQuote: false,
    });

    if (response.status == 400) {
      if (response.data.error === 'could_not_log_in') {
        generalAlertError(
          "Ops, c'è un problema. Rivolgiti al tuo consulente Greenia",
        );
      } else if (response.data.error === 'ania_locked') {
        generalAlertError(
          'Impossibile ottenere informazioni Ania. Riprovare più tardi.',
        );
      } else {
        generalAlertError(
          'La targa del veicolo non è stata trovata. Per favore compila manualmente!',
        );
      }
      return;
    }

    if (response.status !== 200) {
      generalAlertError(
        "Si è verificato un problema nell'ottenere le informazioni sul veicolo",
      );
      return;
    }

    caller.autoFormDataUpdated({
      fastQuoteVehicleInfo: response.data,
    });
  } catch (err) {
    caller.autoFormDataUpdated({
      isLoadingFastQuote: false,
    });
    generalAlertError(
      "Si è verificato un problema nell'ottenere le informazioni sul veicolo",
    );
  }
};

export const autoCancelFastQuote = () => {
  caller.autoFormDataUpdated({
    fastQuoteVehicleInfo: null,
  });
};

export const autoGetInitialData = async () => {
  const [statesRes, brandsRes, formDataRes, dataPrivacyRes] = await Promise.all(
    [getStates(), getVehicleBrands(), getFormData(), getPrivacyTerms()],
  );

  caller.autoFormDataUpdated({
    loading: false,
    states: statesRes.data.data,
    brands: brandsRes.data.data,
    formData: formDataRes.data.data,
    dataPrivacy: dataPrivacyRes.data.data,
  });

  const municipalitiesRes = await getMunicipalities();

  caller.autoFormDataUpdated({
    municipalities: municipalitiesRes.data.data,
  });
};

export const autoUpdateFormData = (data) => {
  caller.autoFormDataUpdated(data);
};

export const autoGetVehicleModels = async (make_id, imm_vehicle_year) => {
  caller.autoFormDataUpdated({
    isLoadingVehicleModels: true,
  });
  const res = await quotationsAxe.get('/form-data/vehicle-models', {
    params: {
      make_id,
      imm_vehicle_year,
    },
  });
  caller.autoFormDataUpdated({
    isLoadingVehicleModels: false,
    vehicleModels: res.data.data,
  });
};

export const autoGetVehicleVersions = async (model_id, imm_vehicle_year) => {
  caller.autoFormDataUpdated({
    isLoadingVehicleVersions: true,
  });
  const res = await quotationsAxe.get('/form-data/vehicle-versions', {
    params: {
      model_id,
      imm_vehicle_year,
    },
  });
  caller.autoFormDataUpdated({
    isLoadingVehicleVersions: false,
  });
  if (res.status === 200) {
    caller.autoFormDataUpdated({
      vehicleVersions: res.data.data,
    });
  }
};

export const autoGetQuickInfo = async (vehiclePlate, updateFormData) => {
  caller.autoFormDataUpdated({
    isLoadingFastQuote: true,
  });

  try {
    const response = await quotationsAxe.get(`/form-data/quick-info/auto/${vehiclePlate}`);

    caller.autoFormDataUpdated({
      isLoadingFastQuote: false,
    });

    if (response.status === 200) {
      updateFormData(response.data.data, true);
    }
  } catch (err) {
    caller.autoFormDataUpdated({
      isLoadingFastQuote: false,
    });
  }
};

export const resetAutoForm = () => {
  caller.resetAutoForm();
};
