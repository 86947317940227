import React from 'react';
import moment from 'moment';
import Header from '../../components/utilities/Header';
import documentiPolizza from '../../assets/icons/documenti-polizza.svg';
import {
  DocumentiPolizza, DocumentiPolizzaBadge, DocumentiPolizzaIcon, DocumentiPolizzaText, DownloadIcon, PreventiviSection, PreventiviStyles,
} from './CompletedQuoteScreen.styles';
import download from '../../assets/icons/download.svg';
import { apiDownloadDocument } from '../../services/axios-client/axeCommons';

function CompletedQuoteScreen({
  quote, rc_quotation, insuranceRequest,
}: any) {
  const downloadDocument = async (documentId: number) => {
    const response = await apiDownloadDocument(documentId);
    const alink = document.createElement('a');
    alink.target = '_blank';
    alink.href = response.data.data.contents;
    alink.download = response.data.data.filename;
    alink.click();
  };

  let initialQuotationDocumentRequests: any[] = [];

  if (quote.initial_quotation !== undefined) {
    initialQuotationDocumentRequests = [
      ...quote.initial_quotation.insurance_request.document_requests.filter((dr: any) => dr.document !== null),
      ...quote.initial_quotation.document_requests.filter((dr: any) => dr.document !== null),
    ];
  }

  return (
    <>
      <Header headerTextBold="Preventivi" headerText="Assicurazione" />

      <PreventiviSection>
        <PreventiviStyles>
          <h2>Anagrafica</h2>
          <h3>
            Email:
            {' '}
            <span>{insuranceRequest.customer.email}</span>
          </h3>
          <h3>
            Telefono:
            {' '}
            <span>{insuranceRequest.customer.phone}</span>
          </h3>
          <h3>
            Indirizo:
            {' '}
            <span>
              {insuranceRequest.customer.address}
              ,
              {' '}
              {insuranceRequest.customer.house_number}
              ,
              {' '}
              {insuranceRequest.customer.postal_code}
            </span>
          </h3>
          <h3>
            Residenza:
            {' '}
            <span>{insuranceRequest.customer.city_of_residence.name}</span>
          </h3>
        </PreventiviStyles>
        <PreventiviStyles>
          <h2>Dati Polizza</h2>
          <h3>
            Premio:
            <span>
              {' '}
              {rc_quotation.amount.toLocaleString()}
              {' '}
              &euro;
            </span>
          </h3>
          {rc_quotation.company !== null ? (
            <h3>
              Compagnia:
              {' '}
              <span>{rc_quotation.company.name}</span>
            </h3>
          ) : (
            <h3>
              Compagnia:
              {' '}
              <span>{rc_quotation.preventivas_company.company_name}</span>
            </h3>
          )}
          <h3>
            Prodotto:
            {' '}
            <span>{rc_quotation.product.name}</span>
          </h3>
          <h3>
            Stato:
            {' '}
            <span>{rc_quotation.quotation_status.name}</span>
          </h3>
        </PreventiviStyles>
        <PreventiviStyles>
          <h2>Dati Veicolo</h2>
          <h3>
            Marca Veicolo:
            {' '}
            <span>{insuranceRequest.vehicle.brand.name}</span>
          </h3>
          <h3>
            Modello Veicolo:
            {' '}
            <span>{insuranceRequest.vehicle.model.name}</span>
          </h3>
          <h3>
            Targa:
            {' '}
            <span>{insuranceRequest.vehicle.vehicle_plate}</span>
          </h3>
          <h3>
            Altro Alimentazione:
            {' '}
            <span>{insuranceRequest.vehicle.fuel.name}</span>
          </h3>
          <h3>
            Antifurto:
            {' '}
            <span>{insuranceRequest.vehicle.theft_protection.name}</span>
          </h3>
          <h3>
            Prima Immatricolazione:
            {' '}
            <span>
              {insuranceRequest.vehicle.vehicle_month}
              /
              {insuranceRequest.vehicle.vehicle_year}
            </span>
          </h3>
          <h3>
            Inizio Copertura:
            {' '}
            <span>
              {moment(insuranceRequest.policy_effective_date)
                .toDate()
                .toLocaleDateString('it-IT')}
            </span>
          </h3>
        </PreventiviStyles>
        <PreventiviStyles>
          <h2>Documenti Polizza</h2>
          {insuranceRequest.documents.map((document: any, index: number) => (
            <React.Fragment key={index}>
              <h3>{document.document_type.name}</h3>
              <DocumentiPolizza>
                <DocumentiPolizzaIcon>
                  <img src={documentiPolizza} alt="" />
                </DocumentiPolizzaIcon>
                <DocumentiPolizzaText>
                  <h3>{document.original_filename}</h3>
                </DocumentiPolizzaText>

                {(document.signable_document === 1 && document.is_signed_valid === 1)
                  && (
                    <DocumentiPolizzaBadge background="#00A651">
                      <h6>Firmato</h6>
                    </DocumentiPolizzaBadge>
                  )}
                <DownloadIcon>
                  <img src={download} alt="" onClick={() => downloadDocument(document.id)} />
                </DownloadIcon>
              </DocumentiPolizza>
            </React.Fragment>
          ))}

          {rc_quotation.insurance_request.documents.map((document: any, index: any) => (
            <React.Fragment key={index}>
              <h3>{document.document_type.name}</h3>
              <DocumentiPolizza>
                <DocumentiPolizzaIcon>
                  <img src={documentiPolizza} alt="" />
                </DocumentiPolizzaIcon>
                <DocumentiPolizzaText>
                  <h3>{document.original_filename}</h3>
                </DocumentiPolizzaText>

                {(document.signable_document === 1 && document.is_signed_valid === 1)
                  && (
                    <DocumentiPolizzaBadge background="#00A651">
                      <h6>Firmato</h6>
                    </DocumentiPolizzaBadge>
                  )}
                <DownloadIcon>
                  <img src={download} alt="" onClick={() => downloadDocument(document.id)} />
                </DownloadIcon>
              </DocumentiPolizza>
            </React.Fragment>
          ))}

          {rc_quotation.initial_quotation?.documents.map((document: any, index: number) => (
            <React.Fragment key={index}>
              <h3>{document.document_type.name}</h3>
              <DocumentiPolizza>
                <DocumentiPolizzaIcon>
                  <img src={documentiPolizza} alt="" />
                </DocumentiPolizzaIcon>
                <DocumentiPolizzaText>
                  <h3>{document.original_filename}</h3>
                </DocumentiPolizzaText>

                {(document.signable_document === 1 && document.is_signed_valid === 1)
                  && (
                    <DocumentiPolizzaBadge background="#00A651">
                      <h6>Firmato</h6>
                    </DocumentiPolizzaBadge>
                  )}
                <DownloadIcon>
                  <img src={download} alt="" onClick={() => downloadDocument(document.id)} />
                </DownloadIcon>
              </DocumentiPolizza>
            </React.Fragment>
          ))}

          {rc_quotation.initial_quotation?.insurance_request.documents.map((document: any, index: any) => (
            <React.Fragment key={index}>
              <h3>{document.document_type.name}</h3>
              <DocumentiPolizza>
                <DocumentiPolizzaIcon>
                  <img src={documentiPolizza} alt="" />
                </DocumentiPolizzaIcon>
                <DocumentiPolizzaText>
                  <h3>{document.original_filename}</h3>
                </DocumentiPolizzaText>

                {(document.signable_document === 1 && document.is_signed_valid === 1)
                  && (
                    <DocumentiPolizzaBadge background="#00A651">
                      <h6>Firmato</h6>
                    </DocumentiPolizzaBadge>
                  )}
                <DownloadIcon>
                  <img src={download} alt="" onClick={() => downloadDocument(document.id)} />
                </DownloadIcon>
              </DocumentiPolizza>
            </React.Fragment>
          ))}

          {initialQuotationDocumentRequests.map((document: any, index: any) => (
            <React.Fragment key={index}>
              <h3>{document.document_type.name}</h3>
              <DocumentiPolizza>
                <DocumentiPolizzaIcon>
                  <img src={documentiPolizza} alt="" />
                </DocumentiPolizzaIcon>
                <DocumentiPolizzaText>
                  <h3>{document.original_filename}</h3>
                </DocumentiPolizzaText>

                {(document.signable_document === 1 && document.is_signed_valid === 1)
                  && (
                    <DocumentiPolizzaBadge background="#00A651">
                      <h6>Firmato</h6>
                    </DocumentiPolizzaBadge>
                  )}
                <DownloadIcon>
                  <img src={download} alt="" onClick={() => downloadDocument(document.id)} />
                </DownloadIcon>
              </DocumentiPolizza>
            </React.Fragment>
          ))}

        </PreventiviStyles>
      </PreventiviSection>

    </>
  );
}

export default CompletedQuoteScreen;
