import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from 'react-router-dom';
import Header from "../../components/utilities/Header"
import { Container, CreateClaimButtom, ServiceLink } from "./EmergencyScreen.styles";
import { faFire, faPersonMilitaryPointing, faTruckMedical } from "@fortawesome/free-solid-svg-icons";
import { getEmergencyPhoneNumbers } from "../../utils/helpers";

export const EmergencyScreen = () => {
    const navigate = useNavigate();
    return <>
        <Header headerText="In caso di" headerTextBold="emergenza" />

        <Container>
            <p>Seleziona quale servizio vuoi chiamare</p>

            <div className="services">
                <ServiceLink href={`tel:${getEmergencyPhoneNumbers().ambulance}`}>
                    <FontAwesomeIcon className="img" icon={faTruckMedical} />
                    <div className="text">Ambulanza</div>
                </ServiceLink>
                <ServiceLink href={`tel:${getEmergencyPhoneNumbers().firefighter}`}>
                    <FontAwesomeIcon className="img" icon={faFire} />
                    <div className="text">Vigile del fuoco</div>
                </ServiceLink>
                <ServiceLink href={`tel:${getEmergencyPhoneNumbers().police}`}>
                    <FontAwesomeIcon className="img" icon={faPersonMilitaryPointing} />
                    <div className="text">Polizia Stradale</div>
                </ServiceLink>
            </div>

            <CreateClaimButtom onClick={() => navigate('/after-sales/accident') }>Compila i dettagli dell'incidente</CreateClaimButtom>
        </Container>
    </>
};