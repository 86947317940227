import styled from 'styled-components';
import { themeColors } from '../../../components/colors/theme';
import { formColors } from '../../../components/colors/forms';

export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 20000;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ModalBackdrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
`;

export const ModalCloseButton = styled.button`
  display: table;
  padding: 10px 20px;
  border: ${formColors.primary};
  border: 1px solid ${formColors.primary};
  border-radius: 10px;
  background: ${formColors.primary};
  color: white;
  transition: all 300ms linear;
  margin-left: auto;
  cursor: pointer;

  &:hover {
    color: ${formColors.primary};
    background: white;
  }
`;

export const ModalContent = styled.div`
  position: relative;
  width: 100%;
  max-width: 800px;
  max-height: 90%;
  background-color: white;
  display: flex;
  flex-direction: column;
`;

export const ModalActionButton = styled.div`
  font-size: 15px;
  background-color: lightgrey;
  border: 2px solid lightgrey;
  color: black;
  margin: 4px;
  padding: 5px 10px;
  border-radius: 8px;
  cursor: pointer;

  &.primary {
    color: white;
    background-color: ${themeColors.primary};
    border: 2px solid ${themeColors.primary};
  }

  &.secondary {
    color: ${themeColors.primary};
    border: 2px solid white;
    background-color: ${themeColors.primary};
  }
`;

export const ModalHeader = styled.div`
  width: calc(100% - 40px);
  font-size: 20px;
  color: white;
  font-weight: bold;
  padding: 10px 20px;
  background-color: ${themeColors.primary};
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  .title {
  }

  .actions {
    display: flex;

    ${ModalActionButton}.primary {
      color: ${themeColors.primary};
      border: 2px solid white;
      background-color: white;
    }

    ${ModalActionButton}.secondary {
      color: white;
      background-color: ${themeColors.primary};
      border: 2px solid white;
    }
  }
`;

export const ModalBody = styled.div`
  padding: 20px;
  overflow-y: auto;

  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }

  .row > * {
    width: 100%;
  }

  @media (max-width: 600px) {
    .row {
      flex-direction: column;
    }
  }
`;
