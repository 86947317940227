import { BACKEND_BASE_URL } from '../../../../config/urls';
import {
  CardCompanyLogoResult, CardContainer, CardFlex, CardInformation, InformationContainer,
} from '../cards/card.styles';

const paymentFrequencyNames: {
  [key: number]: string;
} = {
  1: 'Annuo',
  2: 'Semestrale',
};

export function AragCardSelected({
  quotation, type,
}: any) {
  return (
    <CardContainer>
      <CardFlex>
        <CardInformation>
          <div className="price-result">
            <div className="title-result">Prezzo annuo</div>
            <div className="amount-result">
              &euro;
              {Number(quotation.amount).toLocaleString('it-IT', {
                minimumFractionDigits: 2,
              })}
            </div>
          </div>

        </CardInformation>
        <CardCompanyLogoResult src={BACKEND_BASE_URL + quotation.company.logo} alt={`${quotation.company.name} Logo`} />
      </CardFlex>

      <InformationContainer>
        <div className="record-result">
          <div className="record-title-result">Compagnia:</div>
          <div className="record-value-result">{quotation.company.name}</div>
        </div>
        <div className="record-result">
          <div className="record-title-result">Frequenza pagamento:</div>
          <div className="record-value-result">{paymentFrequencyNames[quotation.payment_frequency]}</div>
        </div>
        <div className="record-result">
          <div className="record-title-result">Prodotto:</div>
          <div className="record-value-result">{type.name}</div>
        </div>
        <div className="record-result">
          <div className="record-title-result">Emissione:</div>
          <div className="record-value-result">Tradizionale</div>
        </div>
      </InformationContainer>
    </CardContainer>
  );
}
