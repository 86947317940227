import styled from 'styled-components';

export const InputGroupStyled = styled.input`
    padding: 12px;
    width: calc(100% - 30px);
    background-color: white;
    border: 1px solid rgb(219, 221, 230);
    border-radius: 10px;

    &:active{
            border: 2px solid rgb(153, 219, 185);
            outline: none;
            box-shadow: rgb(153, 219, 185) 1px 1px 10px;

    }

    &:focus-visible{
            border: 2px solid rgb(153, 219, 185);
            outline: none;
            box-shadow: rgb(153, 219, 185) 1px 1px 10px;
    }
`;
