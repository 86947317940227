import { backAxe } from './backAxeClient';
import { downloadFile } from './axiosHelpers';

export const modifyRequestData = (requestToken: string) => backAxe.get(`/insurance-request/${requestToken}/modify-data`);

export const greeniaPrivacyPolicy = async () => {
  const response = await backAxe.get('/greenia-privacy-policy', {
    responseType: 'blob',
  });

  if (response.status !== 200) {
    return;
  }

  downloadFile(response.data);
};

// export const downloadResourceDocs = async (id, professionId) => {
//   const response = await backAxe.get(
//     `/download-resource-document/${id}/${professionId}`,
//     {
//       responseType: 'blob',
//     },
//   );

//   if (response.status !== 200) {
//     return;
//   }

//   downloadFile(response.data);
// };

export const deleteDocument = (documentId: number) => backAxe.delete(`/documents/${documentId}`, {});

export const apiDownloadDocument = (documentId: number) => backAxe.get(`/documents/${documentId}/download`);

// export const apiDownloadBrokerDocument = (documentId) => backAxe.get(`/download-broker-document/${documentId}`);

// export const apiDownloadBrokerReport = (documentId) => backAxe.get(`/download-broker-report/${documentId}`);

// export const apiDownloadBrokerBill = (compensation_bill_id) => backAxe.get(`/download-broker-bill/${compensation_bill_id}`);

// export const apiDownloadBrokerCompensationProof = (compensation_proof_id) => backAxe.get(`/download-broker-compensation-proof/${compensation_proof_id}`);

export const uploadDocument = (data: any) => backAxe.post('/upload-document', data);
export const uploadPurchaseDocument = (data: any) => backAxe.post('/documents/upload', data);

export const documentMethodManualUpload = (quotationId: number, documentId: number, data: any) => backAxe.post(
  `/quotations/${quotationId}/manually/sign/documents/${documentId}/upload-signed`,
  data,
);

export const apiUploadOptionalDocument = (data: any) => backAxe.post('/optional-documents/upload', data);

export const apiDeleteOptionalDocument = (documentId: number) => backAxe.post(`/optional-documents/delete/${documentId}`, {});

export const apiDownloadOptionalDocument = (documentId: number) => backAxe.get(`/optional-documents/${documentId}/download`);

export const apiOptionalDocumentMethodManualUpload = (quotationId: number, documentId: number, data: any) => backAxe.post(
  `/quotations/${quotationId}/manually/sign/optional-documents/${documentId}/upload-signed`,
  data,
);
