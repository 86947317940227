import { FormInputGroup } from './FormInputGroup';
import { FormInputLabel } from './FormInputLabel';
import { PaymentTypePeriodStyles } from './PaymentTypePeriod.styles';

interface PaymentTypePeriodProps {
  selectedType: number;
  updateAnswers: Function;
}

function PaymentTypePeriod({ selectedType, updateAnswers }: PaymentTypePeriodProps) {
  const handleChangeType = (type: number) => {
    updateAnswers({
      payment_frequency: type,
    });
  };

  return (
    <FormInputGroup>
      <FormInputLabel>Periodicità dei pagamenti</FormInputLabel>
      <PaymentTypePeriodStyles>
        <button className={`${selectedType === 1 ? 'active' : ''}`} onClick={() => handleChangeType(1)}>Annuale</button>
        <button className={`${selectedType === 2 ? 'active' : ''}`} onClick={() => handleChangeType(2)}>Semestrale</button>
      </PaymentTypePeriodStyles>
    </FormInputGroup>
  );
}

export default PaymentTypePeriod;
