import React, { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Loader } from '@googlemaps/js-api-loader';
import { useNavigate, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import axios from 'axios';
import Header from '../../components/utilities/Header';
import InputTextarea from '../../components/forms/InputTextarea';
import {
  AccidentDocuments, AfterSaleAccidentSection, AfterSaleButton, GetCurrentLocationButton, MapContainerStyles, MapInput,
} from './AfterSaleReplacement.styles';
import { ActionIcons, AfterSaleIcon, AfterSaleText } from './ShowAccident.styles';
import xCircle from '../../assets/images/accidents/x-circle.svg';
import { generalAlertError } from '../../helpers/alerts';
import AfterSaleBulkUploader from '../../components/uploader/AfterSaleBulkUploader';
import PageLoader from '../../components/utilities/PageLoader';
import { createNewAccident } from '../../feature/afterSales/afterSaleActions';
import imageUploaded from '../../assets/icons/image-uploaded.svg';

// interface FormData {
//     note: string
//     files: any | null
//     latitude: number
//     longitude: number
//     address: string
// }

const schema = yup.object({
  files: yup.array().min(1, 'Devi caricare almeno 1 file'),
  note: yup.string().required('Il campo è obbligatorio'),
  address: yup.string().required('Il campo è obbligatorio').max(191),
  latitude: yup.number().required(),
  longitude: yup.number().required(),
});

const asInputOptions = {
  shouldDirty: true,
  shouldTouch: true,
  shouldValidate: true,
};

function AfterSaleAccident() {
  const { quoteId } = useParams();
  const { isSubmiting } = useSelector((store: any) => store.afterSales);
  const googleRef = useRef(null);
  const navigate = useNavigate();
  const {
    register,
    formState: { errors },
    setValue,
    getValues,
    watch,
    handleSubmit,
  } = useForm<any>({
    mode: 'all',
    resolver: yupResolver(schema),
    shouldFocusError: true,
    defaultValues: {
      files: [],
      note: '',
      address: '',
      latitude: 41.902782,
      longitude: 12.496366,
    },
  });

  const formData = watch();

  const fileInputHandler = (files: any) => {
    const formData = getValues();

    if (files.length > 10 - formData.files.length) {
      generalAlertError('Non puoi caricare piu di 10 documenti');
      return;
    }

    const filesUpdated = [...formData.files];

    let files_counter = 0;

    Object.values(files).forEach((file: any) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        filesUpdated.push({
          file_type: `${(reader.result as string).split(',')[0]},`,
          original_filename: file.name,
          content: (reader.result as string).replace(`data:${file.type};base64,`, ''),
        });
        files_counter++;
        if (files_counter === files.length) {
          setValue('files', filesUpdated, asInputOptions);
        }
      };
    });
  };

  useEffect(() => {
    const loader = new Loader({
      apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY!,
      version: 'weekly',
      libraries: ['places'],
    });

    loader.load()
      .then((google: any) => {
        googleRef.current = google;

        const myLatLng = {
          lat: formData.latitude,
          lng: formData.longitude,
        };

        const map = new google.maps.Map(document.getElementById('map'), {
          center: myLatLng,
          zoom: 15,
        });

        google.maps.Marker({
          position: myLatLng,
          map,
          title: 'Hello Greenia!',
        });

        const autocomplete = new google.maps.places.Autocomplete(document.getElementById('autocomplete'), {
          fields: ['formatted_address', 'geometry', 'name'],
          types: ['address'],
          componentRestrictions: { country: 'it' },
        });

        autocomplete.addListener('place_changed', () => {
          const place = autocomplete.getPlace();

          if (!place.geometry) {
            setValue('address', '');
          } else {
            const map = new google.maps.Map(document.getElementById('map'), {
              zoom: 15,
              center: place.geometry.location,
            });

            google.maps.Marker({
              position: place.geometry.location,
              map,
            });

            setValue('address', place.formatted_address);
            setValue('latitude', place.geometry.location.lat());
            setValue('longitude', place.geometry.location.lng());
          }
        });
      });

    // return () => {
    //     if (loader) {
    //         loader.reset();
    //         delete window.google;
    //         Loader.instance = undefined;
    //     }
    // }
  }, []);

  const removeUpload = (filename: string) => {
    const { files } = formData;

    const filteredFiles = files.filter((file: any) => file.original_filename !== filename);

    setValue('files', filteredFiles);
  };

  const submitData = () => {
    createNewAccident(quoteId!, formData, navigate);
  };

  const getCurrentLocationButton = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(async ({ coords: { latitude, longitude } }: any) => {
        if (latitude === undefined) {
          generalAlertError('Non siamo riusciti a ottenere la tua posizione');
          return;
        }

        const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`);

        if (response.status !== 200 || response.data.results.length === 0) {
          generalAlertError('Non siamo riusciti a ottenere la tua posizione');
          return;
        }

        const result = response.data.results[0];

        const map = new (googleRef.current as any).maps.Map(document.getElementById('map'), {
          zoom: 15,
          center: result.geometry.location,
        });

        (googleRef.current as any).maps.Marker({
          position: result.geometry.location,
          map,
        });

        setValue('address', result.formatted_address);
        setValue('latitude', result.geometry.location.lat);
        setValue('longitude', result.geometry.location.lng);
      }, () => generalAlertError('Devi consentirci di ottenere la tua posizione'));
    } else {
      generalAlertError('La geolocalizzazione non è supportata da questo browser');
    }
  };

  if (isSubmiting) {
    return <PageLoader />;
  }

  return (
    <>

      <Header headerText="Nuovo" headerTextBold="Caso" />

      <AfterSaleAccidentSection>
        <h2>Apri Nuovo Caso Per Denuncia Sinistro</h2>
        <p>Fill or Update your information and we'll get your housee covered in no time.</p>

        <form onSubmit={handleSubmit(submitData)}>
          {formData.files.length !== 0 && formData.files.map((file: any) => (
            <AccidentDocuments>
              <AfterSaleIcon>
                <img src={imageUploaded} alt="" />
              </AfterSaleIcon>
              <AfterSaleText>
                <h3>{file.original_filename}</h3>
              </AfterSaleText>
              <ActionIcons onClick={() => removeUpload(file.original_filename)}>
                <img src={xCircle} alt="" />

              </ActionIcons>
            </AccidentDocuments>
          ))}

          {formData.files.length < 10 && <AfterSaleBulkUploader note="Upload Your Document. Max 10 File" maxFile={10} onFileUpload={(files: any) => fileInputHandler(files)} />}

          <InputTextarea label="Note" className="after-sales-styles" error={errors.note?.message} registration={register('note')} />
          <h3>Inserisci indirizzo del sinistro</h3>
          <GetCurrentLocationButton onClick={() => getCurrentLocationButton()}>Ottieni la posizione attuale</GetCurrentLocationButton>
          <MapInput id="autocomplete" placeholder="Inserisci Indirizzo..." {...register('address')} />
          <MapContainerStyles>
            <div id="map" />
          </MapContainerStyles>
          <AfterSaleButton>Continue</AfterSaleButton>

        </form>

      </AfterSaleAccidentSection>
    </>
  );
}

export default AfterSaleAccident;
