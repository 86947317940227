import axios from 'axios';
import { BACKEND_API_URL } from '../../config/urls';
import { axeRequestInterceptor, axeResponseInterceptor } from './axiosHelpers';

export const backAxe = axios.create();

backAxe.defaults.validateStatus = (status) => status < 500;

backAxe.interceptors.request.use(axeRequestInterceptor);

backAxe.interceptors.response.use((response) => response, axeResponseInterceptor);

backAxe.defaults.baseURL = BACKEND_API_URL;
