import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import Header from '../../components/utilities/Header';
import { InsurancesStyles, QuotationsList } from './Insurances.styles';
import InsurancesButtons from '../../components/utilities/InsurancesButtons';
import PageLoader from '../../components/utilities/PageLoader';
import { getCustomerInsurances } from '../../feature/quotations/quotationsActions';
import { InsuranceCard } from '../../components/utilities/InsuranceCard';

function Insurnaces() {
  const { loading, insurances } = useSelector((store: any) => store.quotations.insurances);
  const navigate = useNavigate();

  useEffect(() => {
    getCustomerInsurances();
  }, []);

  const goToInsurance = (requestToken: string, productId: string) => {
    if (productId === '1') {
      navigate(`/results/auto/${requestToken}`);
    }
  };

  if (loading) {
    return <PageLoader />;
  }

  return (
    <>
      <Header headerText="Le tue" headerTextBold="Assicurazioni" />
      <InsurancesStyles>
        <InsurancesButtons />
        <QuotationsList>
          {insurances.map((insurance: any) => (
            <InsuranceCard insurance={insurance} key={insurance.request_token} onClick={() => goToInsurance(insurance.request_token, insurance.product_id)} />
          ))}
          {insurances.length === 0 && <p>Nessun preventivo trovato!</p>}

        </QuotationsList>
      </InsurancesStyles>
    </>
  );
}

export default Insurnaces;
