import { Step, Steps } from './AutoStepper.styles';

interface AutoStepperProps {
  currentStep: number;
}

export function AutoStepper({ currentStep }: AutoStepperProps) {
  return (
    <div>
      <Steps>
        <Step className={currentStep >= 1 ? 'completed' : ''}>
          <div className="line">
            <div className="thin-line" />
            <div className="number">1</div>
          </div>
          <div className="text">Dati del Veicolo</div>
        </Step>
        <Step className={currentStep >= 2 ? 'completed' : ''}>
          <div className="line">
            <div className="thin-line" />
            <div className="number">2</div>
          </div>
          <div className="text">Dati Personali</div>
        </Step>
        <Step className={currentStep >= 3 ? 'completed' : ''}>
          <div className="line">
            <div className="thin-line" />
            <div className="number">3</div>
          </div>
          <div className="text">Dati Assicurativi</div>
        </Step>
        <Step className={currentStep >= 4 ? 'completed' : ''}>
          <div className="line">
            <div className="thin-line" />
            <div className="number">4</div>
          </div>
          <div className="text">Calcolare</div>
        </Step>
        <Step className="last">
          <div className="line">
            <div className="thin-line" />
          </div>
        </Step>
      </Steps>
    </div>
  );
}
