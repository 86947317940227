import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import moment from 'moment';
import carAccident from '../../assets/images/accidents/car-accident.svg';
import circleArrow from '../../assets/images/accidents/circle-arrow.svg';
import Header from '../../components/utilities/Header';
import {
  ActionIcons, AfterSaleIcon, AfterSaleNote, AfterSaleText, SignableDocumentBadge, SignableDocumentIcons, SignableDocumentImg, SignableDocumentText, SignableDocuments, SingleAfterSaleDocuments, SingleAfterSaleIcon, SingleAfterSaleImageSection, SingleAfterSaleInfo, SingleAfterSaleTitleSection, SingleSignableDocument,
} from './ShowAccident.styles';
import { getCancellation } from '../../feature/afterSales/afterSaleActions';
import PageLoader from '../../components/utilities/PageLoader';
import { backAxe } from '../../services/axios-client/backAxeClient';
import imageUploaded from '../../assets/icons/image-uploaded.svg';
import download from '../../assets/icons/download.svg';
import { generalAlertSuccessToast } from '../../helpers/alerts';

function ShowCancellation() {
  const { cancellationId } = useParams();
  const {
    loading, cancellation, cancellation_files, signable_documents,
  } = useSelector((store: any) => store.afterSales.cancellation);
  const uploadDocumentRef = useRef<any>(null);

  const downloadDocument = async (cancellationRequestDocumentId: number | string) => {
    const response = await backAxe.get(`/after-sale/cancellations/documents/${cancellationRequestDocumentId}/download`);
    const alink = document.createElement('a');
    alink.target = '_blank';
    alink.href = response.data.data.contents;
    alink.download = response.data.data.filename;
    alink.click();
  };

  const downloadSignableDocument = async (documentId: number | string) => {
    const response = await backAxe.get(`/after-sale-optional-documents/${documentId}/download`);
    const alink = document.createElement('a');
    alink.target = '_blank';
    alink.href = response.data.data.contents;
    alink.download = response.data.data.filename;
    alink.click();
  };

  const fireSignedDocUpload = (documentId: number) => {
    document.getElementById(`document_${documentId}`)?.click();
  };

  const uploadDocument = (file: any, documentId: number | string) => {
    const fileInfo = file[0];
    let baseURL: any = '';
    // Make new FileReader
    const reader = new FileReader();
    // Convert the file to base64 text
    reader.readAsDataURL(fileInfo);
    // on reader load pdf...
    reader.onload = async () => {
      const fileType = fileInfo.type;
      baseURL = reader.result;
      const data = {
        content: baseURL.replace(`data:${fileType};base64,`, ''),
      };
      await backAxe.post(`/manually/sign/after-sale-optional-documents/${documentId}/upload-signed`, { document: data.content });

      generalAlertSuccessToast('File caricato');
      uploadDocumentRef.current = null;
      getCancellation(cancellationId!);
    };
  };

  useEffect(() => {
    getCancellation(cancellationId!);
  }, [cancellationId]);

  if (loading) {
    return <PageLoader />;
  }

  return (
    <>
      <Header headerText="Post" headerTextBold="Vendita" />

      <SingleAfterSaleTitleSection>
        <SingleAfterSaleIcon>
          <img src={carAccident} alt="" />
        </SingleAfterSaleIcon>
        <SingleAfterSaleInfo>
          <h2>
            Assicurazioni
            <span>{cancellation.product.name}</span>
          </h2>
          <h4>
            Case ID:
            <span>
              #
              {cancellation.id}
            </span>
          </h4>
          <h4>
            Opened in:
            <span>
              {moment(cancellation.created_at)
                .toDate()
                .toLocaleDateString('it-IT')}
            </span>
          </h4>
        </SingleAfterSaleInfo>
      </SingleAfterSaleTitleSection>

      <SingleAfterSaleImageSection>
        <AfterSaleNote>
          <h3>Note</h3>
          <p>{cancellation.note}</p>
        </AfterSaleNote>
        <h2>Documenti</h2>
        {cancellation_files.map((file: any, index: number) => (
          <SingleAfterSaleDocuments key={index}>
            <AfterSaleIcon>
              <img src={imageUploaded} alt="" />
            </AfterSaleIcon>
            <AfterSaleText>
              <h3>{file.original_filename}</h3>
              {/* <p>img123456432.jpg</p>
                            <p>16:30 PM - 12.03.2023</p> */}
            </AfterSaleText>
            <ActionIcons>
              <img src={download} alt="" onClick={() => downloadDocument(file.id)} />
            </ActionIcons>
          </SingleAfterSaleDocuments>
        ))}
        <SignableDocuments>
          {signable_documents.length > 0 && <h2>Documenti Da Firmare</h2>}
          {signable_documents.map((document: any, index: number) => (
            <SingleSignableDocument key={index}>
              <SignableDocumentImg>
                <img src={imageUploaded} alt="" />
              </SignableDocumentImg>
              <SignableDocumentText>
                <h4>{document.original_filename}</h4>
                {/* <p>img123456432.jpg</p>
                                <p>16:30 PM - 12.03.2023</p> */}
              </SignableDocumentText>
              {document.signable_document === 1 && document.is_signed_valid === null && document.signed_document_filename === null ? (
                <SignableDocumentBadge background="rgba(255, 165, 0, 0.6)" textColor="white">
                  In Attesa Firma
                </SignableDocumentBadge>
              ) : ('')}
              {document.signable_document === 1 && document.is_signed_valid === null && document.signed_document_filename !== null ? (
                <SignableDocumentBadge background="rgba(255, 165, 0, 0.6)" textColor="white">
                  In Attesa Verifica
                </SignableDocumentBadge>
              ) : ('')}
              {document.signable_document === 1 && document.is_signed_valid === 1 && document.signed_document_filename !== null ? (
                <SignableDocumentBadge background="rgba(78, 209, 139, 0.4)" textColor="#00A651">
                  Firmato
                </SignableDocumentBadge>
              ) : ('')}
              {document.signable_document === 1 && document.is_signed_valid === 0 ? (
                <SignableDocumentBadge background="#FFC1C1" textColor="#FF5050">
                  Non Valida
                </SignableDocumentBadge>
              ) : ('')}
              <SignableDocumentIcons>
                <img src={download} alt="" onClick={() => downloadSignableDocument(document.id)} />
                <img src={circleArrow} alt="" onClick={document.signable_document === 1 && document.is_uploaded_valid === 1 && ((document.is_signed_valid === 0 && document.signed_document_filename !== null) || (document.is_signed_valid === null && document.signed_document_filename === null)) ? () => fireSignedDocUpload(document.id) : () => { }} />
                <input type="file" name="" id={`document_${document.id}`} style={{ display: 'none' }} onChange={(e: any) => uploadDocument(e.target.files, document.id)} />
              </SignableDocumentIcons>

            </SingleSignableDocument>
          ))}
        </SignableDocuments>

      </SingleAfterSaleImageSection>
    </>
  );
}

export default ShowCancellation;
