import { backAxe } from '../../services/axios-client/backAxeClient';
import caller from './profileCaller';

export const getReusableDocuments = async () => {
  caller.reusableDocumentsPending();

  try {
    const response = await backAxe.get('/documents/reusable');

    const { data, status } = response;

    if (status !== 200) {
      return caller.reusableDocumentsRejected('Errore interno');
    }

    return caller.reusableDocumentsFulfilled(data.data.documents);
  } catch (err) {
    return caller.reusableDocumentsRejected('Errore interno');
  }
};

export const resetReusableDocuments = () => caller.reusableDocumentsReset();
