import { useLocation, useNavigate } from 'react-router';
import { NavigationContainer, NavigationItem, NavigationSection } from './Navigation.styles';

import HomeActiveImage from '../../assets/images/navigation/home-active.svg';
import HomeInactiveImage from '../../assets/images/navigation/home-inactive.svg';
import InsurancesActiveImage from '../../assets/images/navigation/insurances-active.svg';
import InsurancesInactiveImage from '../../assets/images/navigation/insurances-inactive.svg';
import SOSActiveImage from '../../assets/images/navigation/sos-active.svg';
import SOSInactiveImage from '../../assets/images/navigation/sos-inactive.svg';
import ProfileInactiveImage from '../../assets/images/navigation/profile-inactive.svg';
import ProfileActiveImage from '../../assets/images/navigation/profile-active.svg';
import PostVenditaImage from '../../assets/images/navigation/post-vendita.svg';

export function Navigation() {
  const location = useLocation();
  const navigate = useNavigate();

  const getActiveRoute = () => {
    const route = location.pathname;

    const routesMap: any = {
      '/': 'home',
      '/insurances': 'insurances',
      '/products': 'products',
      '/forms/auto': 'forms-auto',
      '/profile': 'profile',
      '/emergency': 'emergency',
    };

    return routesMap[route] ?? 'not-defined';
  };

  const navigateToRoute = (routeName: string) => {
    const routesMap: any = {
      home: '/',
      insurances: '/insurances',
      products: '/products',
      'forms-auto': '/forms/auto',
      'after-sales': '/after-sales',
      profile: '/profile',
      emergency: '/emergency',
    };

    navigate(routesMap[routeName] ?? '/');
  };

  const routeName = getActiveRoute();

  return (
    <NavigationSection>
      <NavigationContainer>
        <NavigationItem className={routeName === 'home' ? 'active' : ''} onClick={() => navigateToRoute('home')}>
          <div className="icon">
            <img src={routeName === 'home' ? HomeActiveImage : HomeInactiveImage} />
          </div>
          <div className="text">Home</div>
        </NavigationItem>
        <NavigationItem className={routeName === 'insurances' || routeName === 'products' || routeName === 'forms-auto' ? 'active' : ''} onClick={() => navigateToRoute('insurances')}>
          <div className="icon">
            <img src={routeName === 'insurances' || routeName === 'products' || routeName === 'forms-auto' ? InsurancesActiveImage : InsurancesInactiveImage} />
          </div>
          <div className="text">Insurances</div>
        </NavigationItem>
        <NavigationItem className={`primary ${routeName === 'after-sales' ? 'active' : ''}`} onClick={() => navigateToRoute('after-sales')}>
          <div className="icon">
            <img src={PostVenditaImage} />
          </div>
          <div className="text">Post Vendita</div>
        </NavigationItem>
        <NavigationItem className={routeName === 'emergency' ? 'active' : ''} onClick={() => navigateToRoute('emergency')}>
          <div className="icon">
            <img height="24" src={routeName === 'emergency' ? SOSActiveImage : SOSInactiveImage} />
          </div>
          <div className="text">SOS</div>
        </NavigationItem>
        <NavigationItem className={routeName === 'profile' ? 'active' : ''} onClick={() => navigateToRoute('profile')}>
          <div className="icon">
            <img src={routeName === 'profile' ? ProfileActiveImage : ProfileInactiveImage} />
          </div>
          <div className="text">Profile</div>
        </NavigationItem>
      </NavigationContainer>
    </NavigationSection>
  );
}
