import { generalAlertError } from '../../helpers/alerts';
import { backAxe } from '../../services/axios-client/backAxeClient';
import { quotationsAxe } from '../../services/axios-client/quotationsAxeClient';
import caller from './resultsCaller';

export const loadAutoQuotes = async (requestToken: string) => {
  const res = await quotationsAxe.get(`/quotation-results/vehicles/${requestToken}`);
  if (res.status === 200) {
    caller.autoRequestInfoUpdated(res.data.data);
  }
};

export const updateGuaranteesAutoAction = async (
  requestToken: string,
  coverages: string[],
  quoteId?: number,
) => {
  if (quoteId !== undefined) {
    await quotationsAxe.put(
      `/guarantees/vehicles/${requestToken}/update`,
      {
        coverages,
      },
      {
        params: {
          quoteId,
        },
      },
    );
  } else {
    await quotationsAxe.put(`/guarantees/vehicles/${requestToken}/update`, {
      coverages,
    });
  }

  loadAutoQuotes(requestToken);
};

export const addQuoteAutoToCart = async (quoteId: number, callback: any) => {
  caller.autoDataUpdated({
    addingQuote: true,
  });
  try {
    const response = await backAxe.get(`/quotations/save/${quoteId}`);

    if (response.status !== 200) {
      generalAlertError('La quotazione non e stata aggiunta! Riprova.');
      caller.autoDataUpdated({
        addingQuote: false,
      });
      return;
    }
    caller.autoDataUpdated({
      addingQuote: false,
    });

    callback();
  } catch (error) {
    generalAlertError('Errore Interno.');
    caller.autoDataUpdated({
      addingQuote: false,
    });
  }
};

export const resetAutoQuotes = () => {
  caller.autoReset();
};
