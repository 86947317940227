import { configureStore } from '@reduxjs/toolkit';
import { authSlice } from './auth/authSlice';
import { formsSlice } from './forms/formsSlice';
import { insurancesSlice } from './insurances/insurancesSlice';
import { quotationsSlice } from './quotations/quotationsSlice';
import { afterSaleSlice } from './afterSales/afterSaleSlice';
import { profileSlice } from './profile/profileSlice';
import { resultsSlice } from './results/resultsSlice';
import { purchaseSlice } from './purchase/purchaseSlice';

export const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    forms: formsSlice.reducer,
    results: resultsSlice.reducer,
    insurances: insurancesSlice.reducer,
    quotations: quotationsSlice.reducer,
    afterSales: afterSaleSlice.reducer,
    profile: profileSlice.reducer,
    purchase: purchaseSlice.reducer,
  },
});
