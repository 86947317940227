import React from 'react';
import moment from 'moment';
import {
  LogoContainer, QuotationCardStyles, QuoteInfo, QuoteInfoContainer, Status, StatusCircle,
} from './QuotationCard.styles';
import { BACKEND_BASE_URL } from '../../config/urls';

function QuotationCard({ quote, customClass, onClick }: any) {
  return (
    <QuotationCardStyles className={customClass} onClick={onClick}>
      <Status>
        <StatusCircle className={quote.status === 1 ? 'new-quote' : quote.status === 7 ? 'completed' : 'progress'} />
        <p>{quote.quotation_status.name}</p>
      </Status>
      <QuoteInfoContainer>
        <QuoteInfo>
          <h2>
            Assicurazione
            <span>{quote.product.name}</span>
          </h2>
          {quote.company_id !== null ? (
            <p>
              <span>Compagnia:</span>
              {' '}
              {quote.company.name}
            </p>
          ) : (
            <p>
              <span>Compagnia:</span>
              {' '}
              {quote.preventivas_company.company_name}
            </p>
          )}
          {quote.insurance_request && quote.insurance_request.policy_effective_date !== null && (
          <p>
            Scadenza:
            {moment(quote.insurance_request.policy_effective_date)
              .add(
                365,
                'days',
              )
              .toDate()
              .toLocaleDateString('it-IT')}
          </p>
          )}

        </QuoteInfo>
        <LogoContainer>
          {quote.company_id !== null ? <img src={BACKEND_BASE_URL + quote.company.logo} alt="" /> : <img src={`${BACKEND_BASE_URL}/preventivass/companies/${quote.preventivas_company.logo_filename}`} alt="" />}
        </LogoContainer>
      </QuoteInfoContainer>
    </QuotationCardStyles>

  );
}

export default QuotationCard;
