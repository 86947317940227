import { FormInputRadioGroupStyled, Paragraph } from './FormInputRadio.styles';
import { FormInputGroup } from './FormInputGroup';
import { FormInputLabel } from './FormInputLabel';

interface FormInputRadioProps {
  label?: string;
  name: string;
  paragraph?: string;
  options: any[];
  registration?: any;
  error?: any;
  value?: string;
  onChange?: Function;
}

export function FormInputRadio({
  label, name, paragraph, options, registration, error, value, onChange,
}: FormInputRadioProps) {
  if (registration === undefined) {
    return (
      <FormInputGroup>
        {label !== undefined
                    && <FormInputLabel>{label}</FormInputLabel>}
        {paragraph !== undefined
                    && <Paragraph>{paragraph}</Paragraph>}
        <FormInputRadioGroupStyled>
          {options.map((opt: any, index: number) => (
            <div key={index.toString()}>
              <label className="radio-button">
                <input name={name} type="radio" value={opt.value} checked={value == opt.value} onChange={() => onChange!(opt.value)} />
                {opt.label}
                <span />
              </label>
            </div>
          ))}
        </FormInputRadioGroupStyled>
        {error !== undefined
                    && (
                    <div className="general-input-error text-center">
                      {error}
                    </div>
                    )}
      </FormInputGroup>
    );
  }

  return (
    <FormInputGroup>
      {label !== undefined
                && <FormInputLabel>{label}</FormInputLabel>}
      {paragraph !== undefined
                && <Paragraph>{paragraph}</Paragraph>}
      <FormInputRadioGroupStyled>
        {options.map((opt: any, index: number) => (
          <div key={index.toString()}>
            <label className="radio-button">
              <input type="radio" value={opt.value} {...registration} />
              {opt.label}
              <span />
            </label>
          </div>
        ))}
      </FormInputRadioGroupStyled>
      {error !== undefined
                && (
                <div className="general-input-error text-center">
                  {error}
                </div>
                )}
    </FormInputGroup>
  );
}
