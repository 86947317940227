import styled from 'styled-components';

export const AfterSaleCategoriesSection = styled.div`
    p{
        color: #828282;
        font-family: Poppins;
        font-size: 14px;
        font-weight: 400;
        margin: 0 10px;
    }

    @media screen and (min-width:768px){
        p{
            text-align: center;
        }
    }
`;

export const AfterSaleCategoriesGroup = styled.div`

    display: flex;
    align-items: stretch;
    justify-content: center;
    flex-wrap: wrap;
    gap: 15px;
    margin-top: 40px;
    
    `;

export const AfterSaleCategory = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 22px;
    border: 3px solid rgba(0, 166, 81, 0.2);
    padding: 20px;
    width: 130px;
    cursor: pointer;
    
    `;

export const AfterSaleCategoryItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    h4{
    margin-top: 5px;
    margin-bottom: 0;
    color: #343434;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    }

    .afterSaleIcon{
        font-size: 50px;
    }

`;
