import styled from 'styled-components';
import { formColors } from '../colors/forms';

export const SearchWithDropdownContainer = styled.div`
    position: relative;
`;

export const FormInputSelectWithSearchDropdown = styled.div`
    position: absolute;
    top: 70px;
    left: 0px;
    display: flex;
    flex-direction: column;
    margin: 5px;
    margin-top: 0px;
    width: calc(100% - 10px);
    border: 2px solid lightgrey;
    border-radius: 5px;
    background-color: ${formColors.background};
    z-index: 200;
    max-height: 200px;
    overflow: auto;

    span {
        padding: 5px 10px;
        border-bottom: 1px solid lightgrey;
        cursor: pointer;
        transition: .3s;
    }

    span:hover {
        background-color: rgba(200,200,200,.3);
    }

    span:last-child {
        border-bottom: unset;
    }
`;
