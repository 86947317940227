import auto from '../assets/images/products/auto.svg';
// import moto from '../assets/images/products/moto.svg';
// import camion from '../assets/images/products/camion.svg';
// import casa from '../assets/images/products/casa.svg';
// import proffesionista from '../assets/images/products/proffesionista.svg';
// import viaggio from '../assets/images/products/viaggio.svg';
// import infortuni from '../assets/images/products/infortuni.svg';
import { StorageService } from '../services/storage';

export const products = [
  {
    name: 'Auto',
    icon: auto,
    path: '/forms/auto',
    clear: () => {
      StorageService.clearAutoFormAnswers();
      StorageService.clearAutoFormOwner();
      StorageService.clearAutoFormDriver();
      StorageService.clearAutoFormBersani();
    },
  },
  // {
  //   name: "Autocarro",
  //   icon: camion,
  //   path: "/forms/auto",
  //   clear: () => {},
  // },
  // {
  //   name: "Professionale",
  //   icon: proffesionista,
  //   path: "/forms/auto",
  //   clear: () => {},
  // },
  // {
  //   name: "Infortuni",
  //   icon: infortuni,
  //   path: "/forms/auto",
  //   clear: () => {},
  // },
  // {
  //   name: "Viaggi",
  //   icon: viaggio,
  //   path: "/forms/auto",
  //   clear: () => {},
  // },
];
