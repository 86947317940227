import { store } from './store';

export const callerCreator = (actions: any) => {
  const caller: any = {};

  const actionKeys: any = Object.keys(actions);
  const actionValues: any = Object.values(actions);

  actionKeys.forEach((actionKey: string, index: number) => {
    caller[actionKey] = (payload: any) => store.dispatch(actionValues[index](payload));
  });

  return caller;
};
