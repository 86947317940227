import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import {
  addQuoteAutoToCart, loadAutoQuotes, resetAutoQuotes, updateGuaranteesAutoAction,
} from '../../feature/results/resultsActions';
import Header from '../../components/utilities/Header';
import {
  FailedCompanyLogo, GeneralExtrasCard, InformationCard, QuotationsTypeButtons, QuoteLoader, QuoteLoaderContainer, ModifyDateButton,
  RiskContainer,
  RiskCard,
  RiskTitle,
  SimpleButton,
  ButtonDiv,
  SimpleModal,
  LoaderContainer,
  ListItemsAquista,
  UnstyledListAquista,
  AlertInfo,

} from './common/styles';
import { AdriaticCard } from './common/cards/AdriaticCard';
import PageLoader from '../../components/utilities/PageLoader';
import { PrimaCard } from './common/cards/PrimaCard';
import { BACKEND_BASE_URL } from '../../config/urls';
import { PreventivassCard } from './common/cards/PreventivassCard';
import editIcon from '../../assets/icons/edit-icon.svg';
import { PreventivassCardLinearAndDallbogg } from './common/cards/PreventivassCardLinearAndDallbogg';
import { quotationTypes } from '../../constants';
import { confirmAlert, generalAlertError } from '../../helpers/alerts';
import SmallLoader from '../../components/utilities/SmallLoader';
import { AragCard } from './common/cards/AragCard';
import { RiskCardCompany } from './common/cards/RiskCardCompany';
import { AragCardSelected } from './common/selectedCards/AragCardSelected';
import RiskCardSelected from './common/selectedCards/RiskCardSelected';
import ProcessingQuoteAuto from './common/ProcessingQuoteAuto';

const coverageName: { [key: string]: string } = {
  incendio: 'Incendio',
  furto: 'Furto',
  eventi_naturali: 'Eventi Naturali',
  assistenza_stradale: 'Assistenza stradale',
  eventi_sociopolitici: 'Atti vandalici',
  cristalli: 'Cristalli',
  rivalsa: 'Rivalsa',
  infortuni_conducente: 'Infortuni conducente',
  tutela_legale: 'Tutela Legale',
  collisione: 'Kasco collisione',
  kasko: 'Kasko completa',
};

const autoGuranteesSlugs = [
  'assistenza_stradale',
  'infortuni_conducente',
  'tutela_legale',
  'collisione',
  'cristalli',
  'rivalsa',
  'kasko',
  'eventi_naturali',
  'eventi_sociopolitici',
];

type CurrentQuote = 'diretta' | 'indiretta';

export function ResultsAuto() {
  const navigate = useNavigate();
  const { requestToken } = useParams();
  const [handleModal, setHandleModal] = useState<string | null>(null);

  const intervalRef = useRef(null);
  const requestInfoRef = useRef(null);
  const state = useSelector((store: any) => store.results.auto);
  const {
    loading, requestInfo, quotations, addingQuote, processingQuote, tutelaLegaleQuote, assistenzaQuote, infortuniQuote, rivalsaQuote, cristalliQuote,
  } = state;
  const [counter, setCounter] = useState(180);
  const [currentQuotes, setCurrentQuotes] = useState<CurrentQuote>('diretta');
  const [openConveragesSection, setOpenCoveragesSection] = useState(false);
  const [temporaryCoverages, setTemporaryCoverages] = useState<string[]>([]);

  const toggleCoveragesSection = (value: boolean) => {
    if (value) {
      setTemporaryCoverages(requestInfo.coverages);
    } else {
      setTemporaryCoverages([]);
    }
    setOpenCoveragesSection(value);
  };

  const includesCoverage = (slug: string) => temporaryCoverages.includes(slug);

  const toggleCoverage = (slug: string) => {
    if (includesCoverage(slug)) {
      setTemporaryCoverages(temporaryCoverages.filter((v: string) => v !== slug));
    } else {
      setTemporaryCoverages([...temporaryCoverages, slug]);
    }
  };

  const submitCoverages = () => {
    updateGuaranteesAutoAction(requestToken!, temporaryCoverages);
  };

  useEffect(() => {
    loadAutoQuotes(requestToken!);

    return () => {
      resetAutoQuotes();
    };
  }, []);

  const fetchQuotes = () => {
    loadAutoQuotes(requestToken!);
  };

  const selectQuotation = (quoteId: number) => {
    confirmAlert('Sei Sicuro di aggiungere questa quotazione?', () => addQuoteAutoToCart(quoteId, fetchQuotes));
  };

  const clearQuotesInterval = () => {
    if (intervalRef.current !== null) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };

  requestInfoRef.current = requestInfo;

  const getTotal = () => {
    let quoteInProccessAmount = 0;
    let tutelaInProccessAmount = 0;
    let assistenzaInProccessAmount = 0;
    let rivalsaInProccessAmount = 0;
    let cristalliInProccessAmount = 0;
    let infortuniConducenteInProccessAmount = 0;

    if (processingQuote !== null) {
      quoteInProccessAmount = parseFloat(processingQuote.amount);
    }
    if (tutelaLegaleQuote !== null) {
      tutelaInProccessAmount = parseFloat(tutelaLegaleQuote.amount);
    }

    if (assistenzaQuote !== null) {
      assistenzaInProccessAmount = parseFloat(assistenzaQuote.amount);
    }

    if (rivalsaQuote !== null) {
      rivalsaInProccessAmount = parseFloat(rivalsaQuote.amount);
    }

    if (cristalliQuote !== null) {
      cristalliInProccessAmount = parseFloat(cristalliQuote.amount);
    }

    if (infortuniQuote !== null) {
      infortuniConducenteInProccessAmount = parseFloat(infortuniQuote.amount);
    }

    return quoteInProccessAmount + tutelaInProccessAmount + assistenzaInProccessAmount + rivalsaInProccessAmount + cristalliInProccessAmount + infortuniConducenteInProccessAmount;
  };

  useEffect(() => {
    const iq: any = setInterval(() => {
      const requestInfo: any = requestInfoRef.current;
      if (requestInfo !== null && requestInfo.product_id == 1) {
        if (requestInfo.with_preventivas === null) {
          loadAutoQuotes(requestToken!);
        }

        if (requestInfo.with_preventivas !== null) {
          clearQuotesInterval();
        }
      }
    }, 10000);

    intervalRef.current = iq;

    return () => {
      clearQuotesInterval();
    };
  }, []);

  useEffect(() => {
    const timer: any = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  const modifyData = () => {
    navigate('/forms/auto', {
      state: { requestToken },
    });
  };

  const handleRequestSave = () => {
    if (processingQuote === null) {
      generalAlertError('Seleziona Prima La Responsabilita Civile', 'Errore');
      return;
    }

    navigate(`/quotes/auto/purchase/${requestToken}`);
  };

  if (loading) {
    return <PageLoader />;
  }

  return (
    <div>
      <Header headerText="Risultati" headerTextBold="Preventivi" />

      <InformationCard>
        <div className="title">
          Informazioni&nbsp;
          <div className="bold">sull'auto</div>
        </div>

        <div className="row">
          <div className="bold">Contraente:</div>
          &nbsp;
          {requestInfo.customer.gender === 'G' ? requestInfo.customer.business_name : `${requestInfo.customer.name} ${requestInfo.customer.surname}`}
        </div>
        <div className="row">
          <div className="bold">Marca:</div>
          &nbsp;
          {requestInfo.vehicle.brand.name}
        </div>
        <div className="row">
          <div className="bold">Modello:</div>
          &nbsp;
          {requestInfo.vehicle.version.description}
        </div>
        <div className="row">
          <div className="bold">Targa:</div>
          &nbsp;
          {requestInfo.vehicle.vehicle_plate}
        </div>
        <div className="row">
          <div className="bold">Inizio Copertura:</div>
          &nbsp;
          {moment(requestInfo.policy_effective_date).format('DD.MM.YYYY')}
        </div>
        <ModifyDateButton onClick={modifyData}>
          <p>Modifica Dati</p>
          <img src={editIcon} alt="Modifica Dati" />
        </ModifyDateButton>
      </InformationCard>

      <RiskCard>
        <div>
          {processingQuote === null && tutelaLegaleQuote === null
            ? <h5>Seleziona Le Quotazioni Che Vuoi Aquistare</h5>
            : (
              <>
                <UnstyledListAquista>
                  {processingQuote !== null && (
                    <li>
                      <ListItemsAquista>
                        <h5>
                          {processingQuote.company.name}
                          {' '}
                          <sub>
                            (
                            {quotationTypes.find((item) => item.id === processingQuote.quote_type)?.name}
                            )
                          </sub>
                        </h5>
                        <h5>
                          {parseFloat(processingQuote.amount)}
                          {' '}
                          €
                        </h5>
                      </ListItemsAquista>
                    </li>
                  )}
                  {tutelaLegaleQuote !== null && (
                    <li>
                      <ListItemsAquista>
                        <h5>
                          {tutelaLegaleQuote.company.name}
                          {' '}
                          <sub>
                            (
                            {quotationTypes.find((item) => item.id === tutelaLegaleQuote.quote_type)?.name}
                            )
                          </sub>
                        </h5>
                        <h5>
                          {parseFloat(tutelaLegaleQuote.amount)}
                          {' '}
                          €
                        </h5>
                      </ListItemsAquista>
                    </li>
                  )}
                  {assistenzaQuote !== null && (
                    <li>
                      <ListItemsAquista>
                        <h5>
                          {assistenzaQuote.company.name}
                          {' '}
                          <sub>
                            (
                            {quotationTypes.find((item) => item.id === assistenzaQuote.quote_type)?.name}
                            )
                          </sub>
                        </h5>
                        <h5>
                          {parseFloat(assistenzaQuote.amount)}
                          {' '}
                          €
                        </h5>
                      </ListItemsAquista>
                    </li>
                  )}
                  {infortuniQuote !== null && (
                    <li>
                      <ListItemsAquista>
                        <h5>
                          {infortuniQuote.company.name}
                          {' '}
                          <sub>
                            (
                            {quotationTypes.find((item) => item.id === infortuniQuote.quote_type)?.name}
                            )
                          </sub>
                        </h5>
                        <h5>
                          {parseFloat(infortuniQuote.amount)}
                          {' '}
                          €
                        </h5>
                      </ListItemsAquista>
                    </li>
                  )}
                  {rivalsaQuote !== null && (
                    <li>
                      <ListItemsAquista>
                        <h5>
                          {rivalsaQuote.company.name}
                          {' '}
                          <sub>
                            (
                            {quotationTypes.find((item) => item.id === rivalsaQuote.quote_type)?.name}
                            )
                          </sub>
                        </h5>
                        <h5>
                          {parseFloat(rivalsaQuote.amount)}
                          {' '}
                          €
                        </h5>
                      </ListItemsAquista>
                    </li>
                  )}
                  {cristalliQuote !== null && (
                    <li>
                      <ListItemsAquista>
                        <h5>
                          {cristalliQuote.company.name}
                          {' '}
                          <sub>
                            (
                            {quotationTypes.find((item) => item.id === cristalliQuote.quote_type)?.name}
                            )
                          </sub>
                        </h5>
                        <h5>
                          {parseFloat(cristalliQuote.amount)}
                          {' '}
                          €
                        </h5>
                      </ListItemsAquista>
                    </li>
                  )}
                  <hr />
                  <li>
                    <ListItemsAquista>
                      <h5>
                        Totale:
                      </h5>
                      <h5>
                        {' '}
                        {getTotal()}
                        {' '}
                        €
                      </h5>
                    </ListItemsAquista>
                  </li>
                </UnstyledListAquista>
                <div className="d-flex justify-content-end mt-3">
                  <ButtonDiv>
                    <SimpleButton onClick={() => handleRequestSave()}>
                      Aquista
                    </SimpleButton>
                  </ButtonDiv>
                </div>
              </>
            )}
        </div>
      </RiskCard>

      {currentQuotes === 'diretta'
        && (
          <GeneralExtrasCard>
            <div className="header">
              <div className="title">
                Aggiungi&nbsp;
                <div className="bold">garanzie</div>
              </div>
              <div className="icon" onClick={() => toggleCoveragesSection(!openConveragesSection)}>
                <FontAwesomeIcon icon={openConveragesSection ? faMinus : faPlus} />
              </div>
            </div>

            <div className={`extras ${openConveragesSection ? 'show' : ''}`}>
              {autoGuranteesSlugs.map((covSlug) => (
                <div key={covSlug} className={`extra ${includesCoverage(covSlug) ? 'selected' : ''}`}>
                  <div className="icon" onClick={() => toggleCoverage(covSlug)}>
                    <FontAwesomeIcon icon={includesCoverage(covSlug) ? faMinus : faPlus} />
                  </div>
                  <div className="name">{coverageName[covSlug]}</div>
                </div>
              ))}

              <div className="submit-button" onClick={() => submitCoverages()}>Aggiungi</div>
            </div>
          </GeneralExtrasCard>
        )}

      <RiskContainer>
        {quotationTypes.map((type, index) => (
          <>
            {quotations && quotations[type.id]
              && (
                <RiskCard key={index} className={`${requestInfo.processing_quotation_id === null && type.id !== 'res_civile' ? 'bg-secondary bg-opacity-25' : ''} ${requestInfo.processing_quotation_id !== null && type.id === 'res_civile' ? 'bg-success bg-opacity-25' : ''} ${requestInfo.tutela_legale_quote_id !== null && type.id === 'tutela_legale' ? 'bg-success bg-opacity-25' : ''} ${requestInfo.assistenza_stradale_quote_id !== null && type.id === 'assistenza_stradale' ? 'bg-success bg-opacity-25' : ''} ${requestInfo.cristalli_quote_id !== null && type.id === 'cristalli' ? 'bg-success bg-opacity-25' : ''} ${requestInfo.infortuni_conducente_quote_id !== null && type.id === 'infortuni_conducente' ? 'bg-success bg-opacity-25' : ''} ${requestInfo.rivalsa_quote_id !== null && type.id === 'rivalsa' ? 'bg-success bg-opacity-25' : ''}`}>
                  <RiskTitle>
                    <h4>{type.name}</h4>
                  </RiskTitle>
                  {(processingQuote !== null && type.id === 'res_civile') && <ProcessingQuoteAuto quotation={processingQuote} />}

                  {(tutelaLegaleQuote !== null && type.id === 'tutela_legale') ? <AragCardSelected quotation={tutelaLegaleQuote} type={type} /> : (tutelaLegaleQuote === null && type.id === 'tutela_legale') ? <AlertInfo>Seleziona Quotazione</AlertInfo> : ''}

                  {(assistenzaQuote !== null && type.id === 'assistenza_stradale') ? <RiskCardSelected quotation={assistenzaQuote} type={type} /> : (assistenzaQuote === null && type.id === 'assistenza_stradale') ? <AlertInfo>Seleziona Quotazione</AlertInfo> : ''}

                  {(infortuniQuote !== null && type.id === 'infortuni_conducente') ? <RiskCardSelected quotation={infortuniQuote} type={type} /> : (infortuniQuote === null && type.id === 'infortuni_conducente') ? <AlertInfo>Seleziona Quotazione</AlertInfo> : ''}

                  {(cristalliQuote !== null && type.id === 'cristalli') ? <RiskCardSelected quotation={cristalliQuote} type={type} /> : (cristalliQuote === null && type.id === 'cristalli') ? <AlertInfo>Seleziona Quotazione</AlertInfo> : ''}

                  {(rivalsaQuote !== null && type.id === 'rivalsa') ? <RiskCardSelected quotation={rivalsaQuote} type={type} /> : (rivalsaQuote === null && type.id === 'rivalsa') ? <AlertInfo>Seleziona Quotazione</AlertInfo> : ''}

                  <ButtonDiv>
                    <SimpleButton onClick={() => setHandleModal(type.id)}>
                      Aggiungi
                    </SimpleButton>
                  </ButtonDiv>
                </RiskCard>
              )}
          </>
        ))}
      </RiskContainer>

      {quotationTypes.map((type, index) => (
        <React.Fragment key={index}>
          {handleModal === type.id && (
            <SimpleModal className="modal-overlay">
              <div className="modal-content">
                <button className="modal-close" onClick={() => setHandleModal(null)}>
                  &times;
                </button>
                <h2>{type.name}</h2>
                {addingQuote && (
                  <LoaderContainer>
                    <SmallLoader />
                    <div style={{ marginLeft: '10px' }}>Aggiungendo...</div>
                  </LoaderContainer>
                )}

                {addingQuote === false
                  && (
                    <>
                      {type.id === 'res_civile' && (
                        <QuotationsTypeButtons>
                          <div className={`button ${currentQuotes === 'diretta' ? 'selected' : ''}`} onClick={() => setCurrentQuotes('diretta')}>Diretta</div>
                          <div className={`button ${currentQuotes === 'indiretta' ? 'selected' : ''}`} onClick={() => setCurrentQuotes('indiretta')}>Indiretta</div>
                        </QuotationsTypeButtons>
                      )}

                      {(requestInfo.product_id == 1 && requestInfo.with_preventivas === null && type.id === 'res_civile') && (
                        <QuoteLoaderContainer>
                          <QuoteLoader>{counter}</QuoteLoader>
                          {'  '}
                          Recuperando più preventivi...
                        </QuoteLoaderContainer>
                      )}

                      {addingQuote === false && (quotations && quotations[type.id] && type.id === 'res_civile') && (currentQuotes === 'diretta' || currentQuotes === 'indiretta') ? quotations[type.id].map((quote: any, index: number) => (
                        currentQuotes === 'diretta' ? (
                          <React.Fragment key={index}>
                            {quote.company_id === 1
                              && (
                                <div className="col-12 mb-2">
                                  <AdriaticCard
                                    quotation={quote}
                                    selectQuotation={selectQuotation}
                                  />
                                </div>
                              )}
                            {quote.company_id === 2
                              && (
                                <div className="col-12 mb-2">
                                  <PrimaCard
                                    key={quote.id}
                                    quotation={quote}
                                    requestToken={requestToken}
                                    selectQuotation={selectQuotation}
                                  />
                                </div>
                              )}

                            {(quote.company_id === 9 || quote.company_id === 10)
                              && (
                                <div className="col-12 mb-2">
                                  <PreventivassCardLinearAndDallbogg
                                    key={quote.id}
                                    quotation={quote}
                                    selectQuotation={selectQuotation}
                                  />
                                </div>
                              )}
                            {quote.added_manually === 1
                              && (
                                <div className="col-12 mb-2">
                                  <PreventivassCard requestInfo={requestInfo} quotation={quote} selectQuotation={selectQuotation} />
                                </div>
                              )}
                          </React.Fragment>
                        ) : currentQuotes === 'indiretta' ? (
                          <React.Fragment key={index}>
                            {quote.company_id === null && quote.added_manually == 0
                              && (
                                <div className="col-12 mb-2">
                                  <PreventivassCard quotation={quote} selectQuotation={selectQuotation} />
                                </div>
                              )}
                          </React.Fragment>
                        ) : ''
                      )) : ''}

                      {type.id === 'assistenza_stradale' && (quotations && quotations[type.id]) ? quotations[type.id].map((quote: any, index: number) => (
                        <React.Fragment key={index}>
                          {(processingQuote !== null && processingQuote.company_id === 1 && quote.company_id === 1) ? (
                            <RiskCardCompany
                              quotation={quote}
                              type={type}
                              selectQuotation={selectQuotation}
                            />
                          ) : <h5 className="fw-bold">Nessuna Quotazione Trovata</h5>}
                        </React.Fragment>
                      )) : ''}

                      {type.id === 'tutela_legale' && (quotations && quotations[type.id]) ? quotations[type.id].map((quote: any, index: number) => (
                        <React.Fragment key={index}>
                          {quote.company_id === 15 ? (
                            <AragCard
                              quotation={quote}
                              selectQuotation={selectQuotation}
                              requestInfo={requestInfo}
                              type={type}
                            />
                          ) : (
                            <RiskCardCompany
                              quotation={quote}
                              type={type}
                              selectQuotation={selectQuotation}
                              requestInfo={requestInfo}
                            />
                          )}
                        </React.Fragment>

                      )) : ''}

                      {type.id === 'infortuni_conducente' && (quotations && quotations[type.id]) ? quotations[type.id].map((quote: any, index: number) => (
                        <React.Fragment key={index}>
                          {(processingQuote !== null && processingQuote.company_id === 1 && quote.company_id === 1) ? (
                            <RiskCardCompany
                              quotation={quote}
                              type={type}
                              selectQuotation={selectQuotation}
                              requestInfo={requestInfo}
                            />
                          ) : <h5 className="fw-bold">Nessuna Quotazione Trovata</h5>}
                        </React.Fragment>
                      )) : ''}

                      {type.id === 'rivalsa' && (quotations && quotations[type.id]) ? quotations[type.id].map((quote: any, index: number) => (
                        <React.Fragment key={index}>
                          {(processingQuote !== null && processingQuote.company_id === 1 && quote.company_id === 1) ? (
                            <RiskCardCompany
                              quotation={quote}
                              type={type}
                              selectQuotation={selectQuotation}
                              requestInfo={requestInfo}
                            />
                          ) : <h5 className="fw-bold">Nessuna Quotazione Trovata</h5>}
                        </React.Fragment>
                      )) : ''}

                      {type.id === 'cristalli' && (quotations && quotations[type.id]) ? quotations[type.id].map((quote: any, index: number) => (
                        <React.Fragment key={index}>
                          {(processingQuote !== null && processingQuote.company_id === 1 && quote.company_id === 1) ? (
                            <RiskCardCompany
                              quotation={quote}
                              type={type}
                              selectQuotation={selectQuotation}
                              requestInfo={requestInfo}
                            />
                          ) : <h5 className="fw-bold">Nessuna Quotazione Trovata</h5>}
                        </React.Fragment>
                      )) : ''}
                    </>
                  )}

                {(quotations && quotations[type.id] && quotations[type.id].length === 0) && <h5 className="fw-bold">Nessuna Quotazione Trovata</h5>}

                <div className="button-div">
                  <button className="small-button" onClick={() => setHandleModal(null)}>
                    Close
                  </button>
                </div>
              </div>
            </SimpleModal>
          )}
        </React.Fragment>
      ))}

      {currentQuotes === 'diretta' && requestInfo.failed_quotations.length !== 0
        && (
          <>
            <h3>Queste compagnie non hanno risposto con una quotazione.</h3>

            {requestInfo.failed_quotations.map((fq: any) => (
              <FailedCompanyLogo key={fq.id} src={BACKEND_BASE_URL + fq.company.logo} alt={`${fq.company.name} Logo`} />
            ))}
          </>
        )}

    </div>
  );
}
