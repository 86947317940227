import styled from 'styled-components';

export const FormContainer = styled.div`
    margin: auto;
    margin-top: 50px;
    max-width: 600px;

    @media (max-width: 900px) {
        margin-top: 0;
    }
`;
