import React from 'react';
import { useNavigate } from 'react-router';
import notificationIcon from '../../assets/icons/notification.svg';
import {
  HeaderTitleStyles, HeaderStyles, HeaderTitle, NotificationBell,
} from './Header.styles';

interface HeaderText {
  headerText: string
  headerTextBold: string
}

function Header({ headerText, headerTextBold }: HeaderText) {
  const navigate = useNavigate();
  return (
    <>
      <HeaderStyles>
        <HeaderTitleStyles>
          <HeaderTitle>
            {headerText}
            {' '}
            <span>{headerTextBold}</span>
          </HeaderTitle>
        </HeaderTitleStyles>
        <NotificationBell onClick={() => navigate('/notifications')}>
          <img src={notificationIcon} alt="" />
        </NotificationBell>
      </HeaderStyles>
    </>
  );
}

export default Header;
