import { FormInputPrivacyContainer } from './FormInputPrivacy.styles';

interface FormInputPricacyProps {
  required: any;
  label: string;
  name: string;
  id: string;
  key: number;
  checked: boolean;
  onChange: any;
}

export function FormInputPrivacy(props: FormInputPricacyProps) {
  const inputProps = {
    name: props.name,
    id: props.id,
    checked: props.checked,
    onChange: props.onChange,
  };

  return (
    <FormInputPrivacyContainer>
      <label className="control control-checkbox">
        <input className="checkbox-privacy" type="checkbox" {...inputProps} />
        {props.label}
        {props.required === 1 ? (
          <span className="text-danger">*</span>
        ) : (
          ''
        )}
        <div className="control_indicator" />
      </label>
    </FormInputPrivacyContainer>
  );
}
