import { ChangeEventHandler } from 'react';
import { CheckboxContainer, CheckboxLabel } from './FormInputCheckbox.styles';

interface FormInputCheckboxProps {
  label: string;
  registration?: Function;
  checked: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
}

export function FormInputCheckbox({
  label, registration, checked, onChange,
}: FormInputCheckboxProps) {
  if (registration === undefined) {
    return (
      <CheckboxContainer>
        <CheckboxLabel className="control control-checkbox">
          {label}
          <input type="checkbox" checked={checked} onChange={onChange} />
          <div className="control_indicator" />
        </CheckboxLabel>
      </CheckboxContainer>
    );
  }

  return (
    <CheckboxContainer>
      <CheckboxLabel className="control control-checkbox">
        {label}
        <input type="checkbox" {...registration} />
        <div className="control_indicator" />
      </CheckboxLabel>
    </CheckboxContainer>
  );
}
