import styled from 'styled-components';
import { themeColors } from '../../components/colors/theme';

export const Container = styled.div`
    width: calc(100% - 20px);
    padding: 10px;
    max-width: 700px;
    margin: auto;
    margin-top: 20px;
    background-color: white;
    border-radius: 10px;

    p {
        text-align: center;
    }

    .services {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
`;

export const ServiceLink = styled.a`
    margin: 5px;
    padding: 20px 10px;
    width: 100%;
    min-width: 100px;
    max-width: 200px;
    border: 2px solid rgba(0,150,0,.3);
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(0,150,0,0);
    color: black;
    text-decoration: none;
    transition: .3s;

    &:hover {
        background-color: rgba(0,150,0,.3);
    }

    .img {
        width: 80px;
        height: 80px;
        color: rgb(50,50,50)
    }

    .text {
        text-align: center;
    }
`;

export const CreateClaimButtom = styled.div`
    background-color: ${themeColors.primary};
    padding: 10px 20px;
    display: block;
    margin: 20px auto;
    max-width: 300px;
    color: white;
    text-align: center;
    border-radius: 10px;
    cursor: pointer;
`;