import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { backAxe } from '../../../services/axios-client/backAxeClient';
import { OptionalDocumentModalStyles } from './OptionalDocumentModal.styles';
import { FormInputSelect } from '../../../components/main-forms/FormInputSelect';
import { generalAlertError, generalAlertSuccessToast } from '../../../helpers/alerts';
import closeButton from '../../../assets/images/accidents/x-circle.svg';

const schema = yup.object({
  optional_document_type_id: yup
    .string()
    .required('Il campo è obbligatorio.'),
});

function OptionalDocumentModal({ quotationId, closeModal, collectData }: any) {
  const [optionalDocumentTypes, setOptionalDocumentTypes] = useState<any[]>([]);
  useEffect(() => {
    const getDocTypes = async () => {
      const response = await backAxe.get('/get-optional-document-types');

      if (response.status === 200) {
        setOptionalDocumentTypes(response.data);
      }
    };

    getDocTypes();
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({
    mode: 'all',
    resolver: yupResolver(schema),
    shouldFocusError: true,
    defaultValues: {
      optional_document_type_id: null,
    },
  });

  const sendOptionalDocumentRequest = async (data: any) => {
    const response = await backAxe.post(`/quotations/${quotationId}/optional-documents/add-type`, data);

    if (response.status === 400) {
      if (response.data.error === 'cannot_add_more_document_types') {
        generalAlertError('Non è possibile aggiungere più di 5 tipi di documento');
        closeModal();
        return;
      }
    }

    if (response.status !== 200) {
      generalAlertError('Non è possibile aggiungere il tipo di documento');
      closeModal();
      return;
    }

    generalAlertSuccessToast('Tipo di documento aggiunto');
    closeModal();
    collectData();
  };

  return (
    <OptionalDocumentModalStyles>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h2>
              Documento facoltativo
            </h2>
            {/* <button
                            id="close-modal-button"
                            type="button"
                            className="btn-close"
                            onClick={() => closeModal()}
                        ></button> */}
            <img src={closeButton} alt="" onClick={() => closeModal()} />
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit(sendOptionalDocumentRequest)}>
              {optionalDocumentTypes && (
                <FormInputSelect
                  placeholder="Seleziona tipo di documento"
                  label="Seleziona tipo di documento"
                  registration={register('optional_document_type_id')}
                  error={errors.optional_document_type_id?.message}
                  // valid={isValid('optional_document_type_id')}
                  options={optionalDocumentTypes}
                />
              )}
              <button type="submit">
                Richiedi l'aggiunta di un documento facoltativo
              </button>
            </form>
          </div>
        </div>
      </div>
    </OptionalDocumentModalStyles>
  );
}

export default OptionalDocumentModal;
