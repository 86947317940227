import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  reusableDocuments: {
    loading: false,
    error: null,
    documents: [],
  },
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    reusableDocumentsPending: (state) => {
      state.reusableDocuments.loading = true;
      state.reusableDocuments.error = null;
    },
    reusableDocumentsRejected: (state, { payload }) => {
      state.reusableDocuments.loading = false;
      state.reusableDocuments.error = payload;
    },
    reusableDocumentsFulfilled: (state, { payload }) => {
      state.reusableDocuments.loading = false;
      state.reusableDocuments.documents = payload;
    },
    reusableDocumentsReset: (state) => {
      state.reusableDocuments = { ...initialState.reusableDocuments };
    },
  },
});
