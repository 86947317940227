import { useEffect, useState } from 'react';
import { Step, Steps } from '../../Forms/common/AutoStepper.styles';

export function PurchaseAutoStepper({ quoteStatus }: any) {
  const [steppers, setSteppers] = useState<any[]>([]);

  const statuses: any = [
    {
      id: 1,
      name: 'Attesa Documenti',
      last: false,
      customId: 1,
      error: false,
    },
    {
      id: 2,
      name: 'Problemi Con i Documenti',
      last: false,
      customId: 2,
      error: true,
    },
    {
      id: 5,
      name: 'Attesa Smarcatura',
      last: false,
      customId: 3,
      error: false,
    },
    {
      id: 4,
      name: 'Attesa Pagamento',
      last: false,
      customId: 4,
      error: false,
    },
    {
      id: 6,
      name: 'In Attesa Emissione',
      last: true,
      customId: 5,
      error: false,
    },
  ];

  useEffect(() => {
    if (quoteStatus && quoteStatus === 2) {
      setSteppers(
        [
          {
            id: 2,
            name: 'Problemi Con i Documenti',
            last: false,
            customId: 2,
            error: true,
          },
          {
            id: 5,
            name: 'Attesa Smarcatura',
            last: false,
            customId: 3,
            error: false,
          },
          {
            id: 4,
            name: 'Attesa Pagamento',
            last: false,
            customId: 4,
            error: false,
          },
          {
            id: 6,
            name: 'In Attesa Emissione',
            last: true,
            customId: 5,
            error: false,
          },
        ],
      );
    }
    if (quoteStatus && quoteStatus !== 2) {
      setSteppers(
        [
          {
            id: 1,
            name: 'Attesa Documenti',
            last: false,
            customId: 1,
            error: false,
          },
          {
            id: 5,
            name: 'Attesa Smarcatura',
            last: false,
            customId: 3,
            error: false,
          },
          {
            id: 4,
            name: 'Attesa Pagamento',
            last: false,
            customId: 4,
            error: false,
          },
          {
            id: 6,
            name: 'In Attesa Emissione',
            last: true,
            customId: 5,
            error: false,
          },
        ],
      );
    }
  }, [quoteStatus]);

  // quoteStatus === step.id || (quoteStatus !== 2 && statuses.find((st: any) => st.id === quoteStatus).customId >= step.customId && step.error === false)
  //     ? 'completed' : (quoteStatus === 2 && statuses.find((st: any) => st.id === quoteStatus).error == true) ? 'not-completed' : ''

  const checkCurrentStep = (step: any) => {
    if (quoteStatus === step.id && step.error === true) {
      return 'not-completed';
    }

    if (quoteStatus === step.id || (quoteStatus !== 2 && statuses.find((st: any) => st.id === quoteStatus).customId >= step.customId && step.error === false)) {
      return 'completed';
    }

    return 'not-completed';
  };

  return (
    <div>
      <Steps>
        {steppers.map((step: any, index: number) => (
          <Step key={index} className={`${checkCurrentStep(step)}`}>
            <div className="line">
              <div className="thin-line" />
              <div className="number">{index + 1}</div>
            </div>
            <div className="text">{step.name}</div>
          </Step>
        ))}

        <Step className={`last ${quoteStatus === 6 ? 'completed' : ''}`}>
          <div className="line">
            <div className="thin-line" />
          </div>
        </Step>
      </Steps>
    </div>
  );
}
