import { useEffect, useState } from 'react';
import Select from 'react-select';
import { FormInputGroup } from './FormInputGroup';
import { FormInputLabel } from './FormInputLabel';
import { formColors } from '../colors/forms';

interface FormReactSelectBrandProps {
  label?: string;
  options: any[];
  error?: string;
  valid?: boolean;
  onChange: Function;
  values: any;
}

export function FormReactSelectBrand({
  label, options, error, valid, onChange, values,
}: FormReactSelectBrandProps) {
  const [prepOptions, setPrepOptions] = useState<any[]>([]);
  const [inputText, setInputText] = useState('');

  const defaultBorder = '#CBCBCD';

  const handleChange = (value: any) => {
    if (value.vehicle_brand_code !== undefined || value.vehicle_brand_code !== '') {
      const brand = options.find((opt) => opt.id == value.vehicle_brand_code);
      if (brand !== undefined) {
        onChange({
          vehicle_brand_code: brand.id.toString(),
        });
      }
    }

    if (value.vehicle_brand_code === null || value.vehicle_brand_code === '' || value.vehicle_brand_code === undefined) {
      onChange({
        vehicle_brand_code: '',
      });
    }
  };

  const handleInputChange = (inputValue: string) => {
    setInputText(inputValue);
  };

  const handleMenuClose = () => {
    if (!values.vehicle_brand_code && inputText) {
      const startingWithOptions = prepOptions.filter((option: any) => option.label.toLowerCase().startsWith(inputText.toLowerCase()));
      const firstStartingOption: any = startingWithOptions[0];
      const findCarPlateSymbol = options.find((opt: any) => opt.id == firstStartingOption.value);
      if (firstStartingOption && findCarPlateSymbol) {
        onChange({
          vehicle_brand_code: firstStartingOption.value.toString(),
        });
      }
    }
  };

  useEffect(() => {
    setPrepOptions(options.map((i: any) => ({ value: i.id, label: i.name })));
  }, [options]);

  return (
    <FormInputGroup>
      <FormInputLabel>{label}</FormInputLabel>
      <Select
        onMenuClose={handleMenuClose}
        onInputChange={handleInputChange}
        options={prepOptions}
        isSearchable
        placeholder=""
        value={options.filter((opt: any) => opt.id == values.vehicle_brand_code).map((o: any) => ({ value: o.id, label: o.name }))[0] ?? ''}
        onChange={(val) => {
          handleChange({
            vehicle_brand_code: val === null || val === undefined ? '' : val.value,
          });
        }}
        loadingMessage={() => 'Caricando marce...'}
        isClearable
        noOptionsMessage={() => 'Marce non trovati!'}
        styles={{
          control: (baseStyles: any, state: any) => ({
            ...baseStyles,
            borderWidth: '2px !important',
            borderColor: `${error ? formColors.error : valid ? formColors.success : state.isSeleced ? defaultBorder : state.isFocused ? defaultBorder : defaultBorder}`,
            padding: '0rem 0.75rem',
            fontWeight: 'bold',
            borderRadius: '0.375rem',
            backgroundColor: '#FBFAFA',
            '&:hover': defaultBorder,
            boxShadow: 'none',
            textAlign: 'center',
          }),
          singleValue: (provided: any) => ({
            ...provided,
            marginLeft: '15%',
          }),
        }}
      />
      <div className={`invalid-feedback ${error ? 'd-block' : ''}`}>{error}</div>
    </FormInputGroup>
  );
}
