import styled from 'styled-components';

export const ViolationsContainer = styled.div`
    background-color: rgb(240,240,240);
    padding: 10px;
    border-radius: 10px;
`;

export const YearsContainer = styled.div`
    display: flex;
    width: 100%;
    overflow-y: auto;

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
`;

export const YearButton = styled.div`
    margin: 3px;
    padding: 10px 11px;
    color: #828282;
    background-color: #F6F6F6;
    border-radius: 9px;
    cursor: pointer;
    transition: .3s;

    &:hover {
        background-color: lightgrey;
    }

    &.selected {
        background-color: black;
        color: white;
    }
`;

export const HelpersContainer = styled.div`
    display: flex;
    margin: 5px;
`;

export const Helper = styled.div`
    margin: 2px;
    padding: 8px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid black;
    border-radius: 5px;
    cursor: pointer;
    transition: .3s;

    &:hover {
        background-color: rgba(0,0,0,.1);
    }
`;

export const ResponsabilityContainer = styled.div`
    border: 2px solid black;
    border-radius: 8px;
    display: flex;
    margin: 5px;
`;

export const Responsability = styled.div`
    padding: 10px;
    width: 100%;
    z-index: 1;
    transition: .3s;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;

    &.left {
        margin-right: -3px;
    }

    &.right {
        margin-left: -3px;
    }

    &.selected {
        background-color: black;
        color: white;
        z-index: 2
    }
`;
