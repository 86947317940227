import styled from 'styled-components';
import { InputTextStyled } from '../../components/forms/InputText.styles';
import { formColors } from '../../components/colors/forms';

export const GeneralLayout = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
`;

export const Container = styled.div`
    padding: 20px;
    width: calc(100% - 40px);
    max-width: 400px;
    margin: auto;
    margin-top: 10dvh;
    border: 1px solid ${formColors.primary};
    border-radius: 20px;

    h2 {
        text-align: center;
        font-weight: 500;
        margin-bottom: 10px;
    }

    h6 {
        margin-top: 0;
        font-size: 14px;
        text-align: center;
        color: #0A0D14;
    }

    @media (max-width: 700px) {
        border: unset;
    }
`;

export const IconFormGroup = styled.div`
    position: relative;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;

    .icon {
        position: absolute;
        top: 0;
        left: 0;
        width: 50px;
        height: 58px;
        display: flex;
        justify-content: center;
        align-items: center;

        * {
            height: 20px;
            color: ${formColors.formGroupIcon};
        }
    }

    ${InputTextStyled} {
        width: calc(100% - 55px);
        padding-left: 40px;
    }
`;

export const SmallLink = styled.div`
    font-weight: 500;
    font-size: 14px;
    padding: 10px;
    text-align: center;
    cursor: pointer;
    transition: .3s;

    &:hover {
        color: ${formColors.primary};
    }
`;

export const SubmitButton = styled.button`
    margin-top: 20px;
    display: flex;
    width: 100%;
    padding: 16px;
    justify-content: center;
    align-items: center;
    border: unset;
    color: white;
    font-size: 18px;
    border-radius: 18px;
    background: ${formColors.primary};
    cursor: pointer;

    &.loading {
        background-color: ${formColors.primaryButtonDisabled};
    }
`;

export const GeneralError = styled.div`
    padding: 5px 20px;
    font-weight: 500;
    color: ${formColors.error};
    text-align: center;
`;

export const GeneralSuccess = styled.div`
    padding: 5px 20px;
    font-weight: 500;
    color: ${formColors.primary};
    text-align: center;
`;
