import styled from 'styled-components';
import { formColors } from '../colors/forms';

export const Paragraph = styled.div`
    color: rgb(100,100,100);
    font-size: 12px;
`;

export const FormInputRadioGroupStyled = styled.div`
    display: flex;
    flex-wrap: wrap;

    .radio-button{
        width: 100%;
        display:flex;
        align-items: center;
        justify-content: flex-end;
        flex-direction: row-reverse;
        margin: 5px;
        font-size: 15px;
        cursor: pointer;
    }
    .radio-button input{
        display:none;
        width: 0;
        height: 0;
    }
    .radio-button span{
        width: 20px;
        height: 20px;
        position: relative;
        margin-right: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: #bdbdbd;
    }
    .radio-button input:checked + span:after{
        content: "";
        display: block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #ffffff;
    }
    .radio-button input:checked + span {
        background-color: ${formColors.primary};
    }
    .radio-button input:disabled + span {
        background-color: #e5e5e5;
    }
    .radio-button span:before{
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        opacity: 0;
        border-radius: 50%;
        background-color: ${formColors.primary};
    }
    .radio-button input:checked + span:before {
        animation: growAndFade 0.2s ease-out;
    } 
    @keyframes growAndFade {
        0% {
            opacity: 1;
            transform: scale(1);
        }
        50% {
            opacity: 0.5;
            transform: scale(1.5);
        }
        100% {
            opacity: 0;
            transform: scale(2);
        }
    }
`;
