import { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { FormInputGroup } from './FormInputGroup';
import { FormInputLabel } from './FormInputLabel';
import { formColors } from '../colors/forms';

interface FormReactSelectResidenceInputProps {
  label?: string;
  children: any;
  options: any[];
  onChange: Function;
  values: any;
  error?: any;
  valid: boolean;
}

export function FormReactSelectResidenceInput({
  label, children, options, onChange, values, error, valid,
}: FormReactSelectResidenceInputProps) {
  const [prepOptions, setPrepOptions] = useState<any[]>([]);
  const [inputText, setInputText] = useState('');

  const defaultBorder = '#CBCBCD';

  const filterMunicipalities = (inputValue: string) => {
    if (inputValue.length >= 3) {
      return prepOptions.filter((i: any) => i.label.toLowerCase().startsWith(inputValue.toLowerCase()));
    }

    return [];
  };

  const promiseOptions = (inputValue: string) => new Promise((resolve) => {
    setTimeout(() => {
      resolve(filterMunicipalities(inputValue));
    }, 500);
  }) as Promise<any>;

  const handleChange = (value: any) => {
    if (value.residence_commune_code !== undefined || value.residence_commune_code !== '') {
      const commune = options.find((opt: any) => opt.cadastral_code === value.residence_commune_code);
      if (commune !== undefined) {
        onChange({
          residence_commune_code: commune.cadastral_code,
          residence_province_code: commune.communal_territory.car_plate_symbol,
          postal_code: commune.postal_code,
        });
      }
    }

    if (value.residence_commune_code === null || value.residence_commune_code === '' || value.residence_commune_code === undefined) {
      onChange({
        residence_commune_code: '',
        residence_province_code: '',
        postal_code: '',
      });
    }
  };

  const handleInputChange = (inputValue: string) => {
    setInputText(inputValue);
  };

  const handleMenuClose = () => {
    if (!values.residence_commune_code && inputText) {
      const startingWithOptions = prepOptions.filter((option: any) => option.label.toLowerCase().startsWith(inputText.toLowerCase()));
      const firstStartingOption: any = startingWithOptions[0];
      const findCarPlateSymbol = options.find((opt: any) => opt.cadastral_code === firstStartingOption.value);
      if (firstStartingOption && findCarPlateSymbol) {
        onChange({
          residence_commune_code: firstStartingOption.value,
          residence_province_code: findCarPlateSymbol.communal_territory.car_plate_symbol,
          postal_code: findCarPlateSymbol.postal_code,
        });
      }
    }
  };

  useEffect(() => {
    setPrepOptions(options.map((i: any) => ({ value: i.cadastral_code, label: `${i.name} (${i.communal_territory.car_plate_symbol})` })));
  }, [options]);

  return (
    <div>
      <FormInputGroup>
        <FormInputLabel>{label}</FormInputLabel>
        <AsyncSelect
          onMenuClose={handleMenuClose}
          onInputChange={handleInputChange}
          loadOptions={promiseOptions}
          defaultOptions
          isSearchable
          placeholder=""
          value={options.filter((opt: any) => opt.cadastral_code === values.residence_commune_code).map((o: any) => ({ value: o.cadastral_code, label: `${o.name} (${o.communal_territory.car_plate_symbol})` }))[0]}
          onChange={(val) => {
            handleChange({
              residence_commune_code: val === null || val === undefined ? '' : val.value,
            });
          }}
          loadingMessage={() => 'Caricando communi...'}
          isClearable
          noOptionsMessage={() => 'Digita almeno tre lettere del comune!'}
          styles={{
            control: (baseStyles: any, state: any) => ({
              ...baseStyles,
              borderWidth: '2px !important',
              borderColor: `${error ? formColors.error : valid ? formColors.success : state.isSeleced ? defaultBorder : state.isFocused ? defaultBorder : defaultBorder}`,
              padding: '0.05rem 0.75rem',
              fontWeight: 'bold',
              borderRadius: '0.375rem',
              backgroundColor: '#FBFAFA',
              '&:hover': defaultBorder,
              boxShadow: 'none',
              textAlign: 'left',
            }),
          }}
        />
        <div className={`invalid-feedback ${error ? 'd-block' : ''}`}>{error}</div>
      </FormInputGroup>
      {children !== undefined
        && (
          <div style={{ flex: 1, flexBasis: 50 }}>
            {children}
          </div>
        )}
    </div>
  );
}
