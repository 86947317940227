import { useNavigate } from 'react-router';
import { StorageService } from '../../services/storage';
import {
  ProfileAvatar, ProfileContainer, ProfileMenu, ProfileMenuItem, ProfileName, ProfileType,
} from './Profile.styles';

import DocumentsIcon from '../../assets/images/profile/documents.svg';
import SecurityIcon from '../../assets/images/profile/security.svg';
import LogoutIcon from '../../assets/images/profile/logout.svg';

import AnchorIcon from '../../assets/images/profile/anchor.svg';

export function Profile() {
  const navigate = useNavigate();
  const user = StorageService.getUser()!;

  const avatarInitials: string = user.gender === 'G'
    ? user.business_name!.substring(0, 2)
    : user.name![0] + user.surname![0];

  const profileName = user.gender === 'G'
    ? user.business_name!
    : `${user.name!} ${user.surname!}`;

  const profileType = user.gender === 'G' ? 'Compagnia' : 'Persona';

  const logout = () => {
    localStorage.clear();
    navigate('/login');
  };

  return (
    <ProfileContainer>
      <ProfileAvatar>{avatarInitials.toUpperCase()}</ProfileAvatar>
      <ProfileName>{profileName}</ProfileName>
      <ProfileType>{profileType}</ProfileType>
      <ProfileMenu>
        {/* <ProfileMenuItem>
                <div className="icon">
                    <img src={ProfileIcon} />
                </div>
                <div className="name">
                    Profilo
                </div>
                <div className="anchor">
                    <img src={AnchorIcon} />
                </div>
            </ProfileMenuItem> */}
        <ProfileMenuItem onClick={() => navigate('/profile/documents')}>
          <div className="icon">
            <img src={DocumentsIcon} />
          </div>
          <div className="name">
            I miei documenti
          </div>
          <div className="anchor">
            <img src={AnchorIcon} />
          </div>
        </ProfileMenuItem>
        <ProfileMenuItem onClick={() => navigate('/security')}>
          <div className="icon">
            <img src={SecurityIcon} />
          </div>
          <div className="name">
            Securita
          </div>
          <div className="anchor">
            <img src={AnchorIcon} />
          </div>
        </ProfileMenuItem>
        <ProfileMenuItem onClick={logout}>
          <div className="icon">
            <img src={LogoutIcon} />
          </div>
          <div className="name">
            Logout
          </div>
          <div className="anchor">
            <img src={AnchorIcon} />
          </div>
        </ProfileMenuItem>
      </ProfileMenu>
    </ProfileContainer>
  );
}
