import styled from 'styled-components';
import { formColors } from '../../../components/colors/forms';

export const FormContainer = styled.form`
    margin: auto;
    width: 100%;
    max-width: 600px;
    border: 1px solid ${formColors.primary};
    border-radius: 10px;
    overflow-x: hidden;

    .title {
        background-color: ${formColors.primary};
        color: white;
        padding: 10px 20px;
        border-top-left-radius: 7px;
        border-top-right-radius: 7px;
    }

    .form-inputs {
        margin: auto;
        padding: 0 20px;
        width: 100%;
        max-width: 550px;
    }
`;
