import styled from 'styled-components';
import { themeColors } from '../../components/colors/theme';

export const ProfileContainer = styled.div`
    margin: auto;
    margin-top: 10%;
    padding: 20px;
    background-color: white;
    width: calc(100% - 40px);
    max-width: 500px;
    border-radius: 20px;
`;

export const ProfileAvatar = styled.div`
    width: 90px;
    height: 90px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${themeColors.primary};
    border-radius: 100%;
    font-size: 35px;
    font-weight: 500;
    color: white;
`;

export const ProfileName = styled.div`
    margin-top: 10px;
    font-size: 23px;
    font-weight: 500;
    text-align: center;
`;

export const ProfileType = styled.div`
    margin-top: 5px;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    color: #BEC0C7;
`;

export const ProfileMenu = styled.div`
    display: flex;
    flex-direction: column;
`;

export const ProfileMenuItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 30px;
    cursor: pointer;
    border-radius: 10px;
    transition: .3s;

    .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
    }

    .name {
        padding: 0 10px;
        width: 100%;
    }

    &:hover {
        background-color: ${themeColors.background};
        padding-right: 20px;
    }
`;
