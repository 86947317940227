import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import Header from '../../components/utilities/Header';
import AfterSaleUploader from '../../components/uploader/AfterSaleUploader';
import { AccidentDocuments, AfterSaleButton, CancellationSection } from './AfterSaleReplacement.styles';
import InputTextarea from '../../components/forms/InputTextarea';
import { InputText } from '../../components/forms/InputText';
import { ActionIcons, AfterSaleIcon, AfterSaleText } from './ShowAccident.styles';
import xCircle from '../../assets/images/accidents/x-circle.svg';
import PageLoader from '../../components/utilities/PageLoader';
import { createNewCancellation } from '../../feature/afterSales/afterSaleActions';
import { generalAlertError } from '../../helpers/alerts';
import imageUploaded from '../../assets/icons/image-uploaded.svg';

interface FormData {
  note: string
  file: any | null,
  date: string
}

const schema = yup.object({
  file: yup.object().nullable(),
  note: yup.string().required('Il campo è obbligatorio'),
  date: yup.string().required('Il campo è obbligatorio'),
});

const asInputOptions = {
  shouldDirty: true,
  shouldTouch: true,
  shouldValidate: true,
};

function AfterSaleCancellation() {
  const { quoteId } = useParams();
  const { isSubmiting } = useSelector((store: any) => store.afterSales);
  const navigate = useNavigate();

  const {
    register,
    formState: { errors, touchedFields },
    setValue,
    watch,
    handleSubmit,
  } = useForm<any>({
    mode: 'all',
    resolver: yupResolver(schema),
    shouldFocusError: true,
    defaultValues: {
      file: null,
      note: '',
      date: '',
    },
  });

  const formData: FormData = watch();

  const fileInputHandler = (files: any) => {
    const file = files;
    let baseURL: any = '';
    const reader = new FileReader();
    reader.readAsDataURL(file);
    const fileType = file.type;
    reader.onload = () => {
      baseURL = reader.result;
      setValue('file', {
        original_filename: file.name,
        content: baseURL.replace(`data:${fileType};base64,`, ''),
      }, asInputOptions);
    };
  };

  const submitData = () => {
    if (formData.file === null) {
      generalAlertError('Carica Un Documento');
    } else {
      createNewCancellation(quoteId!, formData, navigate);
    }
  };

  const isValid = (name: string) => {
    if (errors[name]?.message !== undefined) {
      return false;
    }
    if (touchedFields[name] === undefined) {
      return false;
    }
    return true;
  };

  if (isSubmiting) {
    return <PageLoader />;
  }

  const removeUpload = () => {
    setValue('file', null);
  };

  return (
    <>
      <Header headerText="Nuova" headerTextBold="Casa" />

      <CancellationSection>
        <h2>Apri Nuovo Caso Per Cessazione</h2>
        <p>Fill or Update your information and we'll get your house covered in no time.</p>

        <form onSubmit={handleSubmit(submitData)}>
          <InputText className="colored-input" type="date" registration={register('date')} error={errors.date?.message} valid={isValid('date')} />
          <InputTextarea label="Note" error={errors.note?.message} valid={isValid('note')} className="after-sales-styles" registration={register('note')} />
          {formData.file !== null ? (
            <AccidentDocuments>
              <AfterSaleIcon>
                <img src={imageUploaded} alt="" />
              </AfterSaleIcon>
              <AfterSaleText>
                <h3>{formData.file.original_filename}</h3>
              </AfterSaleText>
              <ActionIcons onClick={() => removeUpload()}>
                <img src={xCircle} alt="" />

              </ActionIcons>
            </AccidentDocuments>
          ) : <AfterSaleUploader note="Upload Your Document" onFileUpload={(file: any) => fileInputHandler(file)} />}
          <AfterSaleButton>Continue</AfterSaleButton>
        </form>

      </CancellationSection>
    </>
  );
}

export default AfterSaleCancellation;
