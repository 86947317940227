import styled from 'styled-components';
import { formColors } from '../colors/forms';

export const FormInputNumberStyled = styled.input`
    padding: 8px 15px;
    border: unset;
    width: calc(100% - 34px);
    background-color: ${formColors.background};
    outline: none;
    border: 2px solid lightgrey;
    border-radius: 5px;

    &.is-valid {
        border-color: ${formColors.success};
    }

    &.is-invalid {
        border-color: ${formColors.error};
    }
`;
