import { backAxe } from '../../services/axios-client/backAxeClient';
import caller from './quotationsCaller';

export const getCustomerQuotes = async (
  search: string | null = null,
  selectedProduct: string | number | null = null,
) => {
  caller.customerQuotesPending();

  try {
    let response: any = null;

    if (search !== null && selectedProduct !== null) {
      response = await backAxe.get(
        `/quotations?search=${search}&product_id=${selectedProduct}`,
      );
    } else if (search !== null) {
      response = await backAxe.get(`/quotations?search=${search}`);
    } else if (selectedProduct !== null) {
      response = await backAxe.get(`/quotations?product_id=${selectedProduct}`);
    }

    if (search === null && selectedProduct === null) {
      response = await backAxe.get('/quotations');
    }

    const { data } = response.data;

    caller.customerQuotesSuccess(data.quotations);
  } catch (error) {
    caller.customerQuotesRejected('Errore interno');
  }
};

export const getCustomerInsurances = async () => {
  caller.insurancesPending();

  try {
    const response = await backAxe.get('/insurance-requests');

    const { data } = response.data;

    caller.insurancesSuccess(data.insurances);
  } catch (error) {
    caller.insurancesRejected('Errore interno');
  }
};

export const getQuotation = async (quote_id: number | string) => {
  caller.singleCustomerQuotePending();

  try {
    const response = await backAxe.get(`/quotations/${quote_id}`);

    const { data } = response.data;

    caller.singleCustomerQuoteSuccess(data);
  } catch (error) {
    caller.singleCustomerQuoteRejected('Errore interno');
  }
};
