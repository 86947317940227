import React from 'react';
import { useLocation, useNavigate } from 'react-router';
import { AccidentButtonSection, ButtonStyles } from '../../screens/AfterSales/AfterSales.styles';

function AfterSalesButtons() {
  const navigate = useNavigate();
  const location = useLocation();

  const getActiveRoute = () => {
    const route = location.pathname;

    const routesMap: any = {
      '/after-sales/categories': 'categories',
      '/after-sales': 'after-sales',
      '/after-sales/closed': 'after-sales-closed',

    };

    return routesMap[route] ?? 'not-defined';
  };

  const navigateToRoute = (routeName: string) => {
    const routesMap: any = {
      categories: '/after-sales/categories',
      'after-sales': '/after-sales',
      'after-sales-closed': '/after-sales/closed',
    };

    navigate(routesMap[routeName] ?? '/');
  };

  const routeName = getActiveRoute();

  return (
    <AccidentButtonSection>
      <ButtonStyles className={routeName === 'after-sales-closed' ? 'active' : ''} onClick={() => navigateToRoute('after-sales-closed')}>
        Chiusi
      </ButtonStyles>
      <ButtonStyles className={routeName === 'after-sales' ? 'active' : ''} onClick={() => navigateToRoute('after-sales')}>
        Attivi
      </ButtonStyles>
      <ButtonStyles className={routeName === 'categories' ? 'active' : ''} onClick={() => navigateToRoute('categories')}>
        Nuovo Caso
      </ButtonStyles>
    </AccidentButtonSection>

  );
}

export default AfterSalesButtons;
