import { FormInputGroup } from './FormInputGroup';
import { FormInputLabel } from './FormInputLabel';
import { FormInputNumberStyled } from './FormInputNumber.styles';

interface FormInputNumberProps {
  label?: string;
  registration: any;
  error?: string;
  valid?: boolean;
}

export function FormInputNumber({
  label, registration, error, valid,
}: FormInputNumberProps) {
  return (
    <FormInputGroup>
      {label !== undefined
                && <FormInputLabel>{label}</FormInputLabel>}
      <FormInputNumberStyled className={`${error ? 'is-invalid' : ''} ${valid ? 'is-valid' : ''}`} type="number" {...registration} />
      <div className="general-input-error">
        {error}
      </div>
    </FormInputGroup>
  );
}
