import { jwtDecode } from 'jwt-decode';
import { backAxe } from '../../services/axios-client/backAxeClient';
import caller from './authCaller';

export const attemptLogin = async (formData: any, navigate: Function) => {
  caller.loginPending();

  try {
    const response = await backAxe.post('/auth/login', formData);

    const { data, status } = response;

    if (status === 400) {
      caller.loginRejected('Credenziali non valide');
      return;
    }

    if (status !== 200) {
      caller.loginRejected('Errore interno');
      return;
    }

    localStorage.setItem('user_token', data.data.access_token);
    localStorage.setItem('user_refresh_token', data.data.refresh_token);
    localStorage.setItem('user', JSON.stringify(jwtDecode(data.data.access_token)));

    if (data.data.password_changed === 0) {
      navigate('/security/set-initial-password');
      return;
    }

    navigate('/');
  } catch (err) {
    caller.loginRejected('Errore interno');
  }
};

export const resetLogin = () => caller.loginReset();

export const attemptForgotPassword = async (formData: any, navigate: Function) => {
  caller.forgotPasswordPending();

  try {
    const response = await backAxe.post('/auth/forgot/password', formData);

    const { status } = response;

    if (status === 400) {
      caller.forgotPasswordRejected("L'e-mail non è stata trovata");
      return;
    }

    if (status !== 200) {
      caller.forgotPasswordRejected('Errore interno');
      return;
    }

    navigate('/auth/reset/password', {
      state: {
        email: formData.email,
      },
    });
  } catch (err) {
    caller.forgotPasswordRejected('Errore interno');
  }
};

export const resetForgotPassword = () => caller.forgotPasswordReset();

export const attemptResetPassword = async (formData: any, navigate: Function) => {
  caller.resetPasswordPending();

  try {
    const response = await backAxe.post('/auth/reset/password', formData);

    const { status, data } = response;

    if (status === 400) {
      if (data.data.error === 'incorrect_code') {
        caller.resetPasswordRejected('Il codice non e corretto');
        return;
      }

      if (data.data.error === 'code_expired') {
        caller.resetPasswordRejected('Il codice è scaduto');
        return;
      }
    }

    if (status !== 200) {
      caller.resetPasswordRejected('Errore interno');
      return;
    }

    navigate('/auth/login');
  } catch (err) {
    caller.resetPasswordRejected('Errore interno');
  }
};

export const resetResetPassword = () => caller.resetPasswordReset();

export const attemptChangePassword = async (formData: any, callback: Function) => {
  caller.changePasswordPending();

  try {
    const response = await backAxe.post('/auth/change/password', formData);

    const { status, data } = response;

    if (status === 400) {
      if (data.data.error === 'current_password_incorrect') {
        caller.changePasswordRejected('Il password non e corretto');
        return;
      }
    }

    if (status !== 200) {
      caller.changePasswordRejected('Errore interno');
      return;
    }

    caller.changePasswordFulfilled();
    callback();
  } catch (err) {
    caller.changePasswordRejected('Errore interno');
  }
};

export const resetChangePassword = () => caller.changePasswordReset();

export const attemptSetInititalPassword = async (formData: any, callback: Function) => {
  caller.setInitialPasswordPending();

  try {
    const response = await backAxe.post('/auth/change-initial-password', formData);

    const { status, data } = response;

    if (status === 400) {
      if (data.data.error === 'Initial password has already been set') {
        caller.setInitialPasswordRejected('La password iniziale è già stata impostata');
        return;
      }
    }

    if (status !== 200) {
      caller.setInitialPasswordRejected('Errore interno');
      return;
    }

    caller.setInitialPasswordFulfilled();
    callback();
  } catch (err) {
    caller.setInitialPasswordRejected('Errore interno');
  }
};

export const resetSetInitialPassword = () => caller.setInitialPasswordReset();
