import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { themeColors } from './components/colors/theme';

export const useAddToHomescreenPrompt = () => {
  const [prompt, setState] = useState<any>(null);
  const [installed, setInstalled] = useState(false);

  const promptToInstall = async () => {
    if (prompt) {
      const result = await prompt.prompt();
      setInstalled(result.outcome === 'accepted');
    }
  };

  useEffect(() => {
    const ready = (e: any) => {
      e.preventDefault();
      setState(e);
    };

    window.addEventListener('beforeinstallprompt', ready);

    return () => {
      window.removeEventListener('beforeinstallprompt', ready);
    };
  }, []);

  return { installed, promptToInstall };
};

export const AddToHomeScreenButton = styled.div`
    background-color: ${themeColors.primary};
    color: white;
    font-weight: 500;
    width: calc(100% - 40px);
    padding: 10px 20px;
    text-align: center;
    cursor: pointer;
`;
