import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { BACKEND_API_URL } from '../../config/urls';

const isTokenExpired = (exp: number) => Date.now() >= exp * 1000;

const storeUser = (token: any) => JSON.stringify(jwtDecode(token));

export const downloadFile = (fileBlob: any) => {
  const file = window.URL.createObjectURL(fileBlob);
  window.open(file, '_blank');
};

export const axeRequestInterceptor = async (config: any) => {
  if (!config.headers['Content-Type']) {
    config.headers['Content-Type'] = 'application/json';
  }

  if (!config.headers.Accept) {
    config.headers.Accept = 'application/json';
  }

  const jwtToken = localStorage.getItem('user_token');

  if (jwtToken != null) {
    try {
      const jwtTokenDecoded = jwtDecode(jwtToken);
      if (isTokenExpired(jwtTokenDecoded.exp!)) {
        // Check if refresh token is expired
        const jwtRefreshToken = localStorage.getItem('user_refresh_token');

        if (jwtRefreshToken !== null) {
          const decodedJwtRefreshToken = jwtDecode(jwtRefreshToken);

          if (isTokenExpired(decodedJwtRefreshToken.exp!)) {
            localStorage.removeItem('user_refresh_token');
            localStorage.removeItem('user_token');
            localStorage.removeItem('user');
          } else {
            const response = await axios.post(
              `${BACKEND_API_URL}/auth/refresh/token`,
              {},
              {
                headers: {
                  Refresh: localStorage.getItem('user_refresh_token'),
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                },
              },
            );

            if (response.status === 403) {
              localStorage.removeItem('user_refresh_token');
              localStorage.removeItem('user_token');
              localStorage.removeItem('user');
            } else {
              const { data } = response;

              localStorage.setItem('user_refresh_token', data.refresh_token);
              localStorage.setItem('user_token', data.access_token);
              localStorage.setItem('user', storeUser(data.access_token));
            }
          }
        } else {
          localStorage.removeItem('user_refresh_token');
          localStorage.removeItem('user_token');
          localStorage.removeItem('user');
        }
      }
    } catch (err) {
      // Do nothing
    }

    const finalJwtToken = localStorage.getItem('user_token');

    if (finalJwtToken !== null) {
      config.headers.Authorization = finalJwtToken;
    }
  }

  config.validateStatus = (status: number) => status !== 403 && status < 500;

  return config;
};

export const axeResponseInterceptor = async (error: any) => {
  if (
    error.response?.status === 403
    && localStorage.getItem('user_refresh_token') !== null
  ) {
    const response = await axios.post(
      `${BACKEND_API_URL}/auth/refresh/token`,
      {},
      {
        headers: {
          Refresh: localStorage.getItem('user_refresh_token'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    );

    if (response.status === 403) {
      localStorage.removeItem('user_refresh_token');
      localStorage.removeItem('user_token');
      localStorage.removeItem('user');
      window.location.href = `${window.location.protocol}//${window.location.host}/login`;
      return Promise.reject(error);
    }

    if (response.status !== 200) {
      return Promise.reject(error);
    }

    const { data } = response;

    localStorage.setItem('user_refresh_token', data.refresh_token);
    localStorage.setItem('user_token', data.access_token);
    localStorage.setItem('user', storeUser(data.access_token));

    error.request.headers.Authorization = localStorage.getItem('user_token');

    return axios.request(error.request);
  }

  if (error.response?.status === 403) {
    localStorage.removeItem('user_refresh_token');
    localStorage.removeItem('user_token');
    localStorage.removeItem('user');
    window.location.href = `${window.location.protocol}//${window.location.host}/login`;
    return Promise.reject(error);
  }

  return Promise.reject(error);
};
