import { NavigateFunction } from 'react-router';
import { generalAlertSuccess } from '../../helpers/alerts';
import { managementAxe } from '../../services/axios-client/managementAxeClient';
import caller from './afterSaleCaller';

export const getAfterSales = async () => {
  caller.afterSalesListPending();

  try {
    const response = await managementAxe.get('/after-sale/get-all');

    caller.afterSalesListSuccess(response.data.data);
  } catch (error) {
    caller.afterSalesListsRejected('Errore interno');
  }
};

export const createNewReplacement = async (quote_id: number | string, data: any, navigate: NavigateFunction) => {
  caller.afterSalesReplacementPending();

  try {
    const response = await managementAxe.post(`/after-sale/quotation/${quote_id}/replacement`, data);

    if (response.status !== 200) {
      caller.afterSalesReplacementRejected(response.data.message);
    }

    caller.afterSalesReplacementSuccess();
    generalAlertSuccess('Success');
    navigate('/after-sales');
  } catch (error) {
    caller.afterSalesReplacementRejected('Errore interno');
  }
};

export const createNewModification = async (quote_id: number | string, data: any, navigate: NavigateFunction) => {
  caller.afterSalesModificationPending();

  try {
    const response = await managementAxe.post(`/after-sale/quotation/${quote_id}/modification`, data);

    if (response.status !== 200) {
      caller.afterSalesModificationRejected(response.data.message);
    }

    caller.afterSalesModificationSuccess();
    generalAlertSuccess('Success');
    navigate('/after-sales');
  } catch (error) {
    caller.afterSalesModificationRejected('Errore interno');
  }
};

export const createNewCancellation = async (quote_id: number | string, data: any, navigate: NavigateFunction) => {
  caller.afterSalesCancellationPending();

  try {
    const response = await managementAxe.post(`/after-sale/quotation/${quote_id}/cancellation`, data);

    if (response.status !== 200) {
      caller.afterSalesCancellationRejected(response.data.message);
    }

    caller.afterSalesCancellationSuccess();
    generalAlertSuccess('Success');
    navigate('/after-sales');
  } catch (error) {
    caller.afterSalesCancellationRejected('Errore interno');
  }
};

export const createNewAccident = async (quote_id: number | string, data: any, navigate: NavigateFunction) => {
  caller.afterSalesAccidentPending();

  try {
    const response = await managementAxe.post(`/after-sale/quotation/${quote_id}/accidents`, data);

    if (response.status !== 200) {
      caller.afterSalesAccidentRejected(response.data.message);
    }

    if (response.status === 200) {
      caller.afterSalesAccidentSuccess();
      generalAlertSuccess('Success');
      navigate('/after-sales');
    }
  } catch (error) {
    caller.afterSalesAccidentRejected('Errore interno');
  }
};

export const getAccident = async (accident_id: number | string) => {
  caller.getAccidentPending();

  try {
    const response = await managementAxe.get(`/after-sale/accidents/${accident_id}`);

    if (response.status !== 200) {
      caller.getAccidentRejected(response.data.message);
    }

    if (response.status === 200) {
      caller.getAccidentSuccess(response.data.data);
    }
  } catch (error) {
    caller.getAccidentRejected('Errore interno');
  }
};

export const addAccidentNote = async (accident_id: number | string, data: string, fetchAccident: any) => {
  caller.afterSalesAccidentPending();

  try {
    const response = await managementAxe.post(`/claim/accident/${accident_id}/add-note`, data);

    if (response.status !== 200) {
      caller.afterSalesAccidentRejected(response.data.message);
    }

    if (response.status === 200) {
      caller.afterSalesAccidentSuccess();
      generalAlertSuccess('Success');
      fetchAccident();
    }
  } catch (error) {
    caller.afterSalesAccidentRejected('Errore interno');
  }
};

export const getReplacement = async (replacement_id: number | string) => {
  caller.getReplacementPending();

  try {
    const response = await managementAxe.get(`/after-sale/replacement/${replacement_id}`);

    if (response.status !== 200) {
      caller.getReplacementRejected(response.data.message);
    }

    if (response.status === 200) {
      caller.getReplacementSuccess(response.data.data);
    }
  } catch (error) {
    caller.getReplacementRejected('Errore interno');
  }
};

export const getModification = async (modification_id: number | string) => {
  caller.getModificationPending();

  try {
    const response = await managementAxe.get(`/after-sale/modification/${modification_id}`);

    if (response.status !== 200) {
      caller.getModificationRejected(response.data.message);
    }

    if (response.status === 200) {
      caller.getModificationSuccess(response.data.data);
    }
  } catch (error) {
    caller.getModificationRejected('Errore interno');
  }
};

export const getCancellation = async (cancellation_id: number | string) => {
  caller.getCancellationPending();

  try {
    const response = await managementAxe.get(`/after-sale/cancellation/${cancellation_id}`);

    if (response.status !== 200) {
      caller.getCancellationRejected(response.data.message);
    }

    if (response.status === 200) {
      caller.getCancellationSuccess(response.data.data);
    }
  } catch (error) {
    caller.getCancellationRejected('Errore interno');
  }
};

export const getDocumentTypes = async (type:string) => {
  caller.getDocumentTypesPending();
  try {
    const response = await managementAxe.get(`/after-sale/get-document-types/${type}`);

    if (response.status !== 200) {
      caller.getDocumentTypesRejected(response.data.message);
      return;
    }
    if (response.status === 200) {
      caller.getDocumentTypesFulfilled(response.data.data);
    }
  } catch (error) {
    caller.getDocumentTypesRejected('Errore interno');
  }
};
