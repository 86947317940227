import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useNavigate } from 'react-router';
import Header from '../../components/utilities/Header';
import {
  AccidentIcon, AccidentInfo, AccidentStyles, AfterSaleSliderItem, AfterSaleSliderSection, ClosedAccidentsSection, Status, StatusCircleClosed,
} from './AfterSales.styles';
import autoLarge from '../../assets/icons/auto-large.svg';
import infortuniLarge from '../../assets/icons/infortuni-large.svg';
import profLarge from '../../assets/icons/profession-large.svg';
import AfterSalesButtons from '../../components/utilities/AfterSalesButtons';
import { getAfterSales } from '../../feature/afterSales/afterSaleActions';
import PageLoader from '../../components/utilities/PageLoader';

function AfterSalesClosed() {
  const [selectedAfterSaleClosed, setSelectedAfterSaleClosed] = useState<string | null>('accident');
  const { loading, afterSalesList } = useSelector((store: any) => store.afterSales);
  const navigate = useNavigate();

  const afterSales = [
    {
      name: 'Denuncia Sinistro',
      slug: 'accident',
    },
    {
      name: 'Sostituzione',
      slug: 'replacement',
    },
    {
      name: 'Cessazione',
      slug: 'cancellation',
    },
    {
      name: 'Modifica dati non Tariffari',
      slug: 'modification',
    },
  ];

  const getAfterSaleRoute = (type: string, afterSaleId: number) => {
    const routesMap: any = {
      accident: 'accidents',
      replacement: 'replacements',
      cancellation: 'cancellations',
      modification: 'modifications',

    };

    navigate(`/after-sale/${routesMap[type]}/${afterSaleId}`);
  };

  useEffect(() => {
    getAfterSales();
  }, []);

  if (loading) {
    return <PageLoader />;
  }

  const selectAfterSaleClosed = (val: string) => {
    if (val === selectedAfterSaleClosed) {
      setSelectedAfterSaleClosed(selectedAfterSaleClosed);
    } else {
      setSelectedAfterSaleClosed(val);
    }
  };
  return (
    <>
      <Header headerText="Post" headerTextBold="Vendita" />
      <AfterSalesButtons />

      <AfterSaleSliderSection>
        {afterSales.map((afterSale, index) => (
          <AfterSaleSliderItem key={index} onClick={() => selectAfterSaleClosed(afterSale.slug)} className={afterSale.slug === selectedAfterSaleClosed ? 'selected' : ''}>
            <h4>{afterSale.name}</h4>
          </AfterSaleSliderItem>
        ))}
      </AfterSaleSliderSection>

      <ClosedAccidentsSection>
        {afterSalesList.filter((obj: any) => obj.type === selectedAfterSaleClosed && (obj.status === 'closed' || obj.status == 0)).map((listItem: any, index: number) => (
          <AccidentStyles key={index} onClick={() => getAfterSaleRoute(listItem.type, listItem.id)}>
            <AccidentInfo>
              <Status>
                <StatusCircleClosed />
                <p>Chiusi</p>
              </Status>
              <h3>
                Assicurazione
                <span>{listItem.product_name}</span>
              </h3>
              <h6>
                Numero Caso
                <span>
                  {' '}
                  #
                  {listItem.id}
                </span>
              </h6>
              <h4>Creata </h4>
              <span>
                {moment(listItem.created_at)
                  .toDate()
                  .toLocaleDateString('it-IT')}
              </span>
            </AccidentInfo>
            <AccidentIcon>
              {(listItem.product_name === 'Auto' || listItem.product_name === 'Autocarro') && <img src={autoLarge} alt="" />}
              {listItem.product_name === 'Rc Professionale' && <img src={infortuniLarge} alt="" />}
              {listItem.product_name === 'Infortuni' && <img src={profLarge} alt="" />}

            </AccidentIcon>
          </AccidentStyles>
        ))}

      </ClosedAccidentsSection>
    </>
  );
}

export default AfterSalesClosed;
