import styled from 'styled-components';
import { formColors } from '../colors/forms';

export const InputTextStyled = styled.input`
    padding: 15px;
    border: unset;
    width: calc(100% - 30px);
    background-color: ${formColors.background};
    outline: none;
    border-radius: 3px;

    &.colored-input{
        padding: 15px;
        border: unset;
        width: calc(100% - 30px);
        background-color: white;
        outline: none;
        border-radius: 10px;   
    }
`;
