import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import moment from 'moment';
import {
  AccidentIcon, AccidentInfo, AccidentStyles, AccidentsSection, AfterSaleSliderItem, AfterSaleSliderSection, Status, StatusCircle,
} from './AfterSales.styles';
import autoLarge from '../../assets/icons/auto-large.svg';
import infortuniLarge from '../../assets/icons/infortuni-large.svg';
import profLarge from '../../assets/icons/profession-large.svg';
import Header from '../../components/utilities/Header';
import AfterSalesButtons from '../../components/utilities/AfterSalesButtons';
import PageLoader from '../../components/utilities/PageLoader';
import { getAfterSales } from '../../feature/afterSales/afterSaleActions';

function AfterSales() {
  const [selectedAfterSale, setSelectedAfterSale] = useState<string | null>('accident');
  const { loading, afterSalesList } = useSelector((store: any) => store.afterSales);
  const navigate = useNavigate();

  const afterSales = [
    {
      name: 'Denuncia Sinistro',
      slug: 'accident',

    },
    {
      name: 'Sostituzione',
      slug: 'replacement',

    },
    {
      name: 'Cessazione',
      slug: 'cancellation',

    },

    {
      name: 'Modifica dati non Tariffari',
      slug: 'modification',
    },
  ];

  const selectAfterSale = (val: string) => {
    if (val === selectedAfterSale) {
      setSelectedAfterSale(selectedAfterSale);
    } else {
      setSelectedAfterSale(val);
    }
  };
  const getAfterSaleRoute = (type: string, afterSaleId: number) => {
    const routesMap: any = {
      accident: 'accidents',
      replacement: 'replacements',
      cancellation: 'cancellations',
      modification: 'modifications',

    };

    navigate(`/after-sale/${routesMap[type]}/${afterSaleId}`);
  };

  useEffect(() => {
    getAfterSales();
  }, []);

  if (loading) {
    return <PageLoader />;
  }

  return (

    <>
      <Header headerText="Post" headerTextBold="Vendita" />
      <AfterSalesButtons />

      <AfterSaleSliderSection>
        {afterSales.map((afterSale, index) => (
          <AfterSaleSliderItem key={index} onClick={() => selectAfterSale(afterSale.slug)} className={afterSale.slug === selectedAfterSale ? 'selected' : ''}>
            <h4>{afterSale.name}</h4>
          </AfterSaleSliderItem>
        ))}
      </AfterSaleSliderSection>

      <AccidentsSection>
        {afterSalesList.filter((obj: any) => obj.type === selectedAfterSale && (obj.status === 'active' || obj.status == 1)).map((item: any, index: number) => (
          <AccidentStyles key={index} onClick={() => getAfterSaleRoute(item.type, item.id)}>
            <AccidentInfo>
              <Status>
                <StatusCircle />
                <p>Aperto</p>
              </Status>
              <h3>
                Assicurazione
                <span>{item.product_name}</span>
              </h3>
              <h6>
                Numero Caso:
                <span>
                  #
                  {item.id}
                </span>
              </h6>
              <h4>Creata </h4>
              <span>
                {moment(item.created_at)
                  .toDate()
                  .toLocaleDateString('it-IT')}
              </span>
            </AccidentInfo>
            <AccidentIcon>
              {(item.product_name === 'Auto' || item.product_name === 'Autocarro') && <img src={autoLarge} alt="" />}
              {item.product_name === 'Rc Professionale' && <img src={infortuniLarge} alt="" />}
              {item.product_name === 'Infortuni' && <img src={profLarge} alt="" />}

            </AccidentIcon>
          </AccidentStyles>
        ))}

      </AccidentsSection>

    </>
  );
}

export default AfterSales;
