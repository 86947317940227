import React from 'react';
import {
  ModalBackdrop, ModalBody, ModalCloseButton, ModalContainer, ModalContent, ModalHeader,
} from '../../screens/Forms/common/Modal.styles';

function PurchasePaymentVerification({ paymentAuthorizationUrl, setPaymentAuthorizationUrl }: any) {
  return (
    <ModalContainer>
      <ModalBackdrop onClick={() => { }} />
      <ModalContent>
        <ModalHeader>
          <div className="title">Autorizza il pagamento</div>
        </ModalHeader>
        <ModalBody>
          <iframe
            title="Authorization"
            style={{ width: '99%', height: '70vh' }}
            src={paymentAuthorizationUrl}
          />
          <ModalCloseButton onClick={() => setPaymentAuthorizationUrl(null)}>
            Chiudi
          </ModalCloseButton>
        </ModalBody>
      </ModalContent>
    </ModalContainer>
  );
}

export default PurchasePaymentVerification;
