import React, { useRef } from 'react';
import download from '../../assets/icons/download.svg';
import circleArrow from '../../assets/images/accidents/circle-arrow.svg';
import imageUploaded from '../../assets/icons/image-uploaded.svg';
import {
  AutoDocument,
  DocumentActionIcons,
  PurchaseAutoBadge,
  PurchaseAutoDocuments,
  PurchaseAutoImg,
  PurchaseAutoText,
} from '../../screens/Purchase/PurchaseAuto.styles';
import {
  generalAlertError,
  generalAlertSuccessToast,
} from '../../helpers/alerts';
import {
  apiDownloadDocument,
  apiDownloadOptionalDocument,
  apiOptionalDocumentMethodManualUpload,
  documentMethodManualUpload,
} from '../../services/axios-client/axeCommons';

function PurchaseAutoSignableDocs({
  insuranceRequest,
  quote,
  collectData,
}: any) {
  const uploadPrivacyRef = useRef<any>(); // 12 - Privacy Greenia
  const uploadPreContractRef = useRef<any>(); // 29 - Percontrattuale Greenia
  const uploadoptionalDocRef = useRef<any>(); // 26 - Optional Docs
  const uploadDichiarazioniDocRef = useRef<any>(); // 28 - Dichiarazioni del contraente
  const uploadQuestionarioAutoDocRef = useRef<any>(); // 30 - Questionario Auto
  const uploadDocumentiDaFirmareAutoRef = useRef<any>(); // 34 - Documenti da firmare

  const fireSignedDocUpload = (typeId: any) => {
    if (typeId === 29) {
      uploadPreContractRef.current.click();
    }

    if (typeId === 12) {
      uploadPrivacyRef.current.click();
    }

    if (typeId === 26) {
      uploadoptionalDocRef.current.click();
    }

    if (typeId === 28) {
      uploadDichiarazioniDocRef.current.click();
    }

    if (typeId === 30) {
      uploadQuestionarioAutoDocRef.current.click();
    }
    if (typeId === 34) {
      uploadDocumentiDaFirmareAutoRef.current.click();
    }
  };

  const uploadSignedDocument = async (documentId: number, files: any) => {
    const uploadedFile = files[0];
    let baseURL: any = '';
    const reader = new FileReader();

    reader.readAsDataURL(uploadedFile);
    if (uploadedFile.type !== 'application/pdf') {
      generalAlertError('Formato di file errato. Il file deve essere in .pdf');
      return;
    }
    reader.onload = () => {
      baseURL = reader.result;
      documentMethodManualUpload(quote.id, documentId, {
        document: baseURL.replace('data:application/pdf;base64,', ''),
      }).then(() => {
        collectData();
        generalAlertSuccessToast('File caricato');
      });
    };
  };

  const uploadSignedOptionalDocument = async (
    documentId: number,
    file: any,
  ) => {
    const uploadedFile = file[0];
    let baseURL: any = '';
    const reader = new FileReader();
    reader.readAsDataURL(uploadedFile);
    if (uploadedFile.type !== 'application/pdf') {
      generalAlertError('Formato di file errato. Il file deve essere in .pdf');
      return;
    }
    reader.onload = () => {
      baseURL = reader.result;
      apiOptionalDocumentMethodManualUpload(quote.id, documentId, {
        document: baseURL.replace('data:application/pdf;base64,', ''),
      }).then(() => {
        collectData();
        generalAlertSuccessToast('File caricato');
      });
    };
  };

  const downloadDocument = async (documentId: number) => {
    const response = await apiDownloadDocument(documentId);
    const alink = document.createElement('a');
    alink.target = '_blank';
    alink.href = response.data.data.contents;
    alink.download = response.data.data.filename;
    alink.click();
  };

  const downloadOptionalDocument = async (documentId: number) => {
    const response = await apiDownloadOptionalDocument(documentId);
    const alink = document.createElement('a');
    alink.target = '_blank';
    alink.href = response.data.data.contents;
    alink.download = response.data.data.filename;
    alink.click();
  };

  const docs = [
    ...insuranceRequest.documents.filter(
      (filterDoc: any) => filterDoc.signable_document === 1,
    ),
    ...quote.documents.filter(
      (filterDoc: any) => filterDoc.signable_document === 1,
    ),
    ...insuranceRequest.document_requests
      .map((v: any) => v.document)
      .filter(
        (filterDoc: any) => filterDoc !== null && filterDoc.signable_document === 1,
      ),
    ...quote.document_requests
      .map((v: any) => v.document)
      .filter(
        (filterDoc: any) => filterDoc !== null && filterDoc.signable_document === 1,
      ),
  ];

  return (
    <PurchaseAutoDocuments>
      <h2>Documenti Da Firmare</h2>
      {docs.map((document: any, index: number) => (
        <AutoDocument key={index}>
          <PurchaseAutoImg>
            <img src={imageUploaded} alt="" />
          </PurchaseAutoImg>
          <PurchaseAutoText>
            <h4>{document.original_filename}</h4>
            {/* <p>img123456432.jpg</p>
                                <p>16:30 PM - 12.03.2023</p> */}
          </PurchaseAutoText>
          {document.is_signed_valid === null
          && document.signed_document_filename === null
          && document.signable_document === 1 ? (
            <PurchaseAutoBadge
              background="rgba(255, 165, 0, 0.6)"
              textColor="white"
            >
              In Attesa Firma
            </PurchaseAutoBadge>
            ) : (
              ''
            )}
          {document.is_signed_valid === null
          && document.signed_document_filename !== null
          && document.signable_document === 1 ? (
            <PurchaseAutoBadge
              background="rgba(255, 165, 0, 0.6)"
              textColor="white"
            >
              In Attesa Verifica
            </PurchaseAutoBadge>
            ) : (
              ''
            )}
          {document.is_signed_valid === 1
          && document.signed_document_filename !== null
          && document.signable_document === 1 ? (
            <PurchaseAutoBadge
              background="rgba(78, 209, 139, 0.4)"
              textColor="#00A651"
            >
              Firmato
            </PurchaseAutoBadge>
            ) : (
              ''
            )}
          {document.is_signed_valid === 0
          && document.signable_document === 1 ? (
            <PurchaseAutoBadge background="#FFC1C1" textColor="#FF5050">
              Non Valida
            </PurchaseAutoBadge>
            ) : (
              ''
            )}
          <DocumentActionIcons>
            <img
              src={download}
              alt=""
              onClick={() => (document.optional_document_request_id === undefined
                ? downloadDocument(document.id)
                : downloadOptionalDocument(document.id))}
            />
            {document.document_type.id === 12 && (
              <>
                <img
                  src={circleArrow}
                  alt=""
                  onClick={
                    document.signed_document_filename === null
                      ? () => fireSignedDocUpload(document.document_type.id)
                      : () => {}
                  }
                />
                <input
                  type="file"
                  ref={uploadPrivacyRef}
                  name=""
                  id=""
                  style={{ display: 'none' }}
                  onChange={(e: any) => uploadSignedDocument(document.id, e.target.files)}
                />
              </>
            )}
            {document.document_type.id === 29 && (
              <>
                <img
                  src={circleArrow}
                  alt=""
                  onClick={
                    document.signed_document_filename === null
                      ? () => fireSignedDocUpload(document.document_type.id)
                      : () => {}
                  }
                />
                <input
                  type="file"
                  ref={uploadPreContractRef}
                  name=""
                  id=""
                  style={{ display: 'none' }}
                  onChange={(e: any) => uploadSignedDocument(document.id, e.target.files)}
                />
              </>
            )}
            {document.document_type.id === 28 && (
              <>
                <img
                  src={circleArrow}
                  alt=""
                  onClick={
                    document.signed_document_filename === null
                      ? () => fireSignedDocUpload(document.document_type.id)
                      : () => {}
                  }
                />
                <input
                  type="file"
                  ref={uploadDichiarazioniDocRef}
                  name=""
                  id=""
                  style={{ display: 'none' }}
                  onChange={(e: any) => uploadSignedDocument(document.id, e.target.files)}
                />
              </>
            )}
            {document.document_type.id === 30 && (
              <>
                <img
                  src={circleArrow}
                  alt=""
                  onClick={
                    document.signed_document_filename === null
                      ? () => fireSignedDocUpload(document.document_type.id)
                      : () => {}
                  }
                />
                <input
                  type="file"
                  ref={uploadQuestionarioAutoDocRef}
                  name=""
                  id=""
                  style={{ display: 'none' }}
                  onChange={(e: any) => uploadSignedDocument(document.id, e.target.files)}
                />
              </>
            )}
            {document.document_type.id === 34 && (
              <>
                <img
                  src={circleArrow}
                  alt=""
                  onClick={
                    document.signed_document_filename === null
                      ? () => fireSignedDocUpload(document.document_type.id)
                      : () => {}
                  }
                />
                <input
                  type="file"
                  ref={uploadDocumentiDaFirmareAutoRef}
                  name=""
                  id=""
                  style={{ display: 'none' }}
                  onChange={(e: any) => uploadSignedDocument(document.id, e.target.files)}
                />
              </>
            )}
            {document.optional_document_request_id && (
              <>
                <img
                  src={circleArrow}
                  alt=""
                  onClick={
                    document.signed_document_filename === null
                      ? () => fireSignedDocUpload(document.document_type.id)
                      : () => {}
                  }
                />
                <input
                  type="file"
                  ref={uploadoptionalDocRef}
                  name=""
                  id=""
                  style={{ display: 'none' }}
                  onChange={(e: any) => uploadSignedOptionalDocument(document.id, e.target.files)}
                />
              </>
            )}
          </DocumentActionIcons>
        </AutoDocument>
      ))}
    </PurchaseAutoDocuments>
  );
}

export default PurchaseAutoSignableDocs;
