import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import Header from '../../components/utilities/Header';
import {
  HomeStyles, ProductSliderContainer, SearchButon, SearchContainer, SearchInput, SearchInputContainer, ProductSliderItem, QuotationsList,
} from './Home.styles';
import filterIcon from '../../assets/icons/filter-icon.svg';
import searchIcon from '../../assets/icons/search-icon.svg';
import autoIconActive from '../../assets/icons/auto.svg';
import autoIcon from '../../assets/icons/auto-active.svg';
import infortuniIconActive from '../../assets/icons/infortuni.svg';
import infortuniIcon from '../../assets/icons/infortuni-active.svg';
import professionIconActive from '../../assets/icons/profession.svg';
import professionIcon from '../../assets/icons/profession-active.svg';
import QuotationCard from '../../components/utilities/QuotationCard';
import PageLoader from '../../components/utilities/PageLoader';
import { getCustomerQuotes } from '../../feature/quotations/quotationsActions';
import { goToQuote } from '../../helpers/helper';

function Home() {
  const [selectedProduct, setSelectedProduct] = useState<number | null>(null);
  const [search, setSearch] = useState<string | null>(null);
  const navigate = useNavigate();
  const { loading, quotes } = useSelector((store: any) => store.quotations.quotations);

  const products = [
    {
      name: 'Auto',
      icon: autoIcon,
      activeIcon: autoIconActive,
      id: 1,
    },
    {
      name: 'Autocarro',
      icon: autoIcon,
      activeIcon: autoIconActive,
      id: 3,
    },
    {
      name: 'Professione',
      icon: professionIcon,
      activeIcon: professionIconActive,
      id: 4,
    },
    {
      name: 'Infortuni',
      icon: infortuniIcon,
      activeIcon: infortuniIconActive,
      id: 5,
    },
  ];

  const selectProduct = (val: number) => {
    if (val === selectedProduct) {
      setSelectedProduct(null);
      getCustomerQuotes(search, null);
    } else {
      setSelectedProduct(val);
      getCustomerQuotes(search, val);
    }
  };

  const handleSearch = (val: string) => {
    setSearch(val);
  };

  const filterQuotes = () => {
    getCustomerQuotes(search, selectedProduct);
  };

  useEffect(() => {
    getCustomerQuotes();
  }, []);

  if (loading) {
    return <PageLoader />;
  }

  return (
    <>
      <Header headerText="Benvenuto" headerTextBold="" />
      <HomeStyles>
        <SearchContainer>
          <form onSubmit={filterQuotes}>
            <SearchInputContainer>
              <img src={searchIcon} alt="Search Icon" />
              <SearchInput placeholder="Cerca per compagnia..." onChange={(e: any) => handleSearch(e.target.value)} value={search !== null ? search : ''} />
            </SearchInputContainer>
            <SearchButon type="submit">
              <img src={filterIcon} alt="Filter Icon" />
            </SearchButon>
          </form>
        </SearchContainer>
        <ProductSliderContainer>
          {products.map((product, index) => (
            <ProductSliderItem key={index} onClick={() => selectProduct(product.id)} className={product.id === selectedProduct ? 'selected' : ''}>
              <img src={product.id === selectedProduct ? product.activeIcon : product.icon} alt={product.name} />
              <h4>{product.name}</h4>
            </ProductSliderItem>
          ))}
        </ProductSliderContainer>
        <QuotationsList>
          {quotes.map((quote: any, index: number) => (
            <QuotationCard quote={quote} key={index} onClick={() => goToQuote(quote.product_id, quote.insurance_request.request_token, quote.is_aquista, navigate)} />
          ))}
          {quotes.length === 0 && <p>Nessuna quotazione Trovata!</p>}

        </QuotationsList>
      </HomeStyles>
    </>
  );
}

export default Home;
