import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router';
import Layout from './Layout';

function Base() {
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem('user_token') === null) {
      navigate('/auth/login');
    }
  }, [localStorage.getItem('user_token')]);

  if (localStorage.getItem('user_token') === null) {
    return <div />;
  }

  return (
    <Layout>
      <Outlet />
    </Layout>
  );
}

export default Base;
