import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { AutoStepper } from './common/AutoStepper';
import { FormInputText } from '../../components/main-forms/FormInputText';
import { AutoFormFieldsBySteps, AutoFormValidationsSchema } from './FormAuto.validations';
import { StorageService } from '../../services/storage';
import { FormInputCheckbox } from '../../components/main-forms/FormInputCheckbox';
import { FormInputLabel } from '../../components/main-forms/FormInputLabel';
import { FormInputSelect } from '../../components/main-forms/FormInputSelect';
import {
  flagResponse, genders, guideType, inheritMeritClass, insuranceTypes, km_during_one_year, lastSixYears, meritClass, months, vehiclesAmountInFamily, years,
} from '../../constants';
import { FormReactSelectBrand } from '../../components/main-forms/FormReactSelectBrand';
import {
  autoCancelFastQuote, autoChangeIndex, autoGetInitialData, autoGetQuickInfo, autoGetVehicleInformation, autoGetVehicleModels, autoGetVehicleVersions, autoSubmitAnswers, autoUpdateFormData, resetAutoForm,
} from '../../feature/forms/formsActions';
import { FormReactSelectModel } from '../../components/main-forms/FormReactSelectModel';
import { FormInputNumber } from '../../components/main-forms/FormInputNumber';
import getAllowedPurchaseYears from '../../helpers/getAllowedPurchaseYears';
import { FormInputSelectTable } from '../../components/main-forms/FormInputSelectTable';
import { FormContainer } from './common/FormContainer';
import { FormInputRadio } from '../../components/main-forms/FormInputRadio';
import { generalAlertError } from '../../helpers/alerts';
import { FooterButtons } from './common/FooterButtons.styles';
import { FormInputDate } from '../../components/main-forms/FormInputDate';
import getMaxAllowedBirthday from '../../helpers/getMaxAllowedBirthday';
import getAllowedLicenseYears from '../../helpers/getAllowedLicenseYears';
import { FormReactSelectInputBirthPlace } from '../../components/main-forms/FormReactSelectInputBirthPlace';
import { FormInputAddress } from '../../components/main-forms/FormInputAddress';
import { FormReactSelectResidenceInput } from '../../components/main-forms/FormReactSelectResidenceInput';
import OffcanvasForm from './common/OffcanvasForm';
import getAllowedPolicyEffectDate from '../../helpers/getAllowedPolicyEffectDate';
import { FormInputViolations, calculateEmptyViolationsData } from '../../components/main-forms/FormInputViolations';
import BersaniInfo from './common/BersaniInfo';
import { greeniaPrivacyPolicy, modifyRequestData } from '../../services/axios-client/axeCommons';
import PaymentTypePeriod from '../../components/main-forms/PaymentTypePeriod';
import { FormInputPrivacy } from '../../components/main-forms/FormInputPrivacy';
import { GreeniaPrivacyLink } from './common/GreeniaPrivacy.styles';
import PageLoader from '../../components/utilities/PageLoader';
import generateFiscalCode, { decodeFiscalCode } from '../../helpers/generateFiscalCode';
import { validateCarPlate } from '../../utils/validators';
import { FastQuoteLoading } from './common/FastQuoteLoading';
import { FastQuoteButton } from './common/FastQuote.styles';
import { FastQuoteModal } from './common/FastQuoteModal';
import Header from '../../components/utilities/Header';
import convertToItalianDate from '../../helpers/convertToItalianDate';
import QuoteGenerateLoading from '../Results/common/QuoteGenerateLoading';

interface FirstStepProps {
  answers: any;
  errors: any;
  isValid: Function;
  updateFormData: Function;
  register: Function;
  state: any;
}

function FirstStep({
  answers, errors, isValid, updateFormData, register, state,
}: FirstStepProps) {
  const {
    brands, vehicleVersions, vehicleModels,
    isLoadingVehicleModels, isLoadingVehicleVersions,
    isLoadingFastQuote, formData,
  } = state;

  const getVehicleInfo = async () => {
    autoGetVehicleInformation(answers.vehicle_plate);
  };

  return (
    <FormContainer>
      <FormInputText
        error={errors.vehicle_plate?.message}
        label="Targa dell' auto"
        onChange={(value: string) => updateFormData({ vehicle_plate: value.toUpperCase() })}
        value={answers.vehicle_plate}
        valid={isValid('vehicle_plate')}
      />

      {isValid('vehicle_plate') === true
              && (
              <div style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'start',
              }}
              >
                <FormInputCheckbox
                  label=" Compila Manualmente?"
                  checked={answers.complete_manually}
                  onChange={() => updateFormData({
                    complete_manually: !answers.complete_manually,
                    renew_check: false,
                    insurance_type: '',
                    new_risks_check: false,
                  })}
                />

                <FormInputCheckbox
                  label=" Rinnovo?"
                  checked={answers.renew_check}
                  onChange={() => updateFormData({
                    renew_check: !answers.renew_check,
                    insurance_type: !answers.renew_check ? 'B' : 'N',
                    new_risks_check: false,
                    complete_manually: false,
                  })}
                />

                <FormInputCheckbox
                  label=" Nuovo rischio?"
                  checked={answers.new_risks_check}
                  onChange={() => updateFormData({
                    new_risks_check: !answers.new_risks_check,
                    renew_check: false,
                    insurance_type: '',
                    complete_manually: false,
                  })}
                />
              </div>
              )}

      {validateCarPlate(answers.vehicle_plate)
        && (answers.renew_check === true || answers.new_risks_check === true) && (
          <>
            {isLoadingFastQuote === false
              && (
                <FastQuoteButton
                  onClick={getVehicleInfo}
                >
                  Ottieni informazioni sul veicolo
                </FastQuoteButton>
              )}

            {isLoadingFastQuote === true && (
              <FastQuoteLoading />
            )}
          </>
      )}

      {answers.vehicle_plate !== '' && (!errors.vehicle_plate?.message)
        && (answers.imm_vehicle_month !== '' || answers.complete_manually === true)
        && (
          <>
            <FormInputLabel>Data di prima immatricolazione</FormInputLabel>
            <FormInputSelect
              registration={register('imm_vehicle_month')}
              error={errors.imm_vehicle_month?.message}
              valid={isValid('imm_vehicle_month')}
              placeholder="-Mese-"
              options={months}
            />
            <FormInputSelect
              registration={register('imm_vehicle_year')}
              error={errors.imm_vehicle_year?.message}
              valid={isValid('imm_vehicle_year')}
              placeholder="-Anno-"
              options={years}
            />
            <FormReactSelectBrand
              label="Marca"
              values={{
                vehicle_brand_code: answers.vehicle_brand_code,
              }}
              error={errors.vehicle_brand_code?.message}
              valid={isValid('vehicle_brand_code')}
              onChange={(item: any) => updateFormData({
                vehicle_brand_code: item.vehicle_brand_code,
              })}
              options={brands}
            />
          </>
        )}

      {answers.imm_vehicle_year ? (
        <>
          {answers.vehicle_brand_code && (
            <div className="form-container">
              {isLoadingVehicleModels ? (
                <p className="text-center">Attendere prego...</p>
              ) : (
                <FormReactSelectModel
                  label="Modello"
                  values={{
                    vehicle_model_code: answers.vehicle_model_code,
                  }}
                  error={errors.vehicle_model_code?.message}
                  valid={isValid('vehicle_model_code')}
                  onChange={(item: any) => updateFormData({
                    vehicle_model_code: item.vehicle_model_code,
                  })}
                  options={vehicleModels}
                />
              )}
            </div>
          )}
          {answers.vehicle_brand_code && answers.vehicle_model_code ? (
            isLoadingVehicleVersions ? (
              <p className="text-center">Attendere prego...</p>
            ) : answers.vehicle_model_code
              && vehicleVersions.length > 0 ? (
                <FormInputSelectTable
                  label="Versione"
                  name="vehicle_version_code"
                  id="vehicle_version_code"
                  options={vehicleVersions}
                  selected={answers.vehicle_am_code}
                  onSelectOption={(value: any) => updateFormData({
                    vehicle_version_code: value.id,
                    vehicle_am_code: value.am_code,
                  })}
                />
              ) : (
                <div className="alert-general-error">
                  Nessuna versione trovata per questo modello!
                </div>
              )
          ) : (
            ''
          )}
          {answers.vehicle_version_code && (
            <div className="form-container">
              <FormInputNumber
                registration={register('weight')}
                error={errors.weight?.message}
                valid={isValid('weight')}
                label="Peso dell'auto"
              />
              <FormInputNumber
                registration={register('power')}
                error={errors.power?.message}
                valid={isValid('power')}
                label="Potenza in kW"
              />
              <FormInputSelect
                label="Altra alimentazione"
                registration={register('other_power_supply')}
                error={errors.other_power_supply?.message}
                valid={isValid('other_power_supply')}
                placeholder="-Seleziona-"
                options={formData.vehicle_fuels}
              />
              <FormInputSelect
                label="Parcheggio auto"
                registration={register('vehicle_parking')}
                error={errors.vehicle_parking?.message}
                valid={isValid('vehicle_parking')}
                placeholder="-Seleziona-"
                options={formData.parking_types}
              />
              <FormInputSelect
                label="Antifurto"
                registration={register('theft_protection_code')}
                error={errors.theft_protection_code?.message}
                valid={isValid('theft_protection_code')}
                placeholder="-Seleziona-"
                options={formData.theft_protections}
              />
              <FormInputSelect
                label="Utilizzo"
                registration={register('vehicle_usage')}
                error={errors.vehicle_usage?.message}
                valid={isValid('vehicle_usage')}
                placeholder="-Seleziona-"
                options={formData.vehicle_usage_types.filter((type: any) => type.vehicle_type === 'auto')}
              />
              <FormInputSelect
                label="Km percorsi in un anno"
                registration={register('predicted_km')}
                error={errors.predicted_km?.message}
                valid={isValid('predicted_km')}
                placeholder="-Seleziona-"
                options={km_during_one_year}
              />
              {answers.imm_vehicle_year !== '' && (
                <div className="row mt-2">
                  <FormInputLabel>Mese e anno in cui hai comprato l'auto</FormInputLabel>
                  <div className="col-12 col-md-8">
                    <FormInputSelect
                      registration={register('vehicle_purchased_month')}
                      error={errors.vehicle_purchased_month?.message}
                      valid={isValid('vehicle_purchased_month')}
                      placeholder="-Mese-"
                      options={months}
                    />
                  </div>

                  <div className="col-12 col-md-4">
                    <FormInputSelect
                      registration={register('vehicle_purchased_year')}
                      error={errors.vehicle_purchased_year?.message}
                      valid={isValid('vehicle_purchased_year')}
                      placeholder="-Anno-"
                      options={getAllowedPurchaseYears(
                        answers.imm_vehicle_year,
                      )}
                    />
                  </div>
                </div>
              )}

              <FormInputSelect
                label="Auto nel nucleo familiare"
                registration={register('vehicles_owned')}
                error={errors.vehicles_owned?.message}
                valid={isValid('vehicles_owned')}
                placeholder="-Seleziona-"
                options={vehiclesAmountInFamily}
              />
              <FormInputRadio
                name="tow_hook"
                label="Hai montato il gancio di traino?"
                paragraph="(iscritta a rimorchiatori, caravan, ecc.)"
                options={flagResponse}
                registration={register('tow_hook')}
                error={errors.tow_hook?.message}
              />
            </div>
          )}
        </>
      ) : (
        ''
      )}
    </FormContainer>
  );
}

interface SecondStepProps {
  register: Function;
  errors: any;
  isValid: Function;
  answers: any;
  state: any;
  updateFormData: Function;
  driverFormComponentRef: any;
  ownerFormComponentRef: any;
  driver: any;
  owner: any;
  setDriverForm: Function;
  setOwnerForm: Function;
  updateDriverFormData: Function;
  updateOwnerFormData: Function;
}

function SecondStep({
  register, errors, isValid, answers, state, updateFormData,
  driverFormComponentRef, ownerFormComponentRef,
  driver, owner, setDriverForm, setOwnerForm, updateDriverFormData, updateOwnerFormData,
}: SecondStepProps) {
  const {
    formData, states, municipalities, driverForm, ownerForm,
  } = state;

  return (
    <FormContainer>
      <FormInputRadio
        label="Il contraente è"
        options={genders}
        name="gender"
        registration={register('gender')}
        error={errors.gender?.message}
      />
      {answers.gender === 'G' && (
        <>
          <FormInputText
            registration={register('business_name')}
            error={errors.business_name?.message}
            valid={isValid('business_name')}
            label="Ragione sociale"
          />
          <FormInputText
            registration={register('vat_number')}
            error={errors.vat_number?.message}
            valid={isValid('vat_number')}
            label="Partita Iva"
          />
          <FormInputSelect
            label="Tipologia azienda"
            registration={register('company_type')}
            error={errors.company_type?.message}
            valid={isValid('company_type')}
            placeholder="-Seleziona-"
            options={formData.company_types}
          />
        </>
      )}
      {answers.gender !== 'G'
        && (
          <>
            <FormInputDate
              label="Data di nascita"
              registration={register('date_of_birth')}
              minDate="1935-01-01"
              maxDate={getMaxAllowedBirthday()}
              error={errors.date_of_birth?.message}
              valid={isValid('date_of_birth')}
            />
            {answers.date_of_birth !== '' && (
              <FormInputSelect
                label="Anno patente"
                registration={register('driving_license_year')}
                error={errors.driving_license_year?.message}
                valid={isValid('driving_license_year')}
                placeholder="-Seleziona-"
                options={getAllowedLicenseYears(answers.date_of_birth)}
              />
            )}
            <FormReactSelectInputBirthPlace
              states={states}
              options={municipalities}
              label="Luogo di nascita"
              name="commune_of_birth_code"
              values={{
                commune_of_birth_code: answers.commune_of_birth_code,
                province_of_birth_code: answers.province_of_birth_code,
                born_abroad: answers.born_abroad,
                country_of_birth_code: answers.country_of_birth_code,
              }}
              onChange={(value: any) => updateFormData({
                commune_of_birth_code: value.commune_of_birth_code,
                province_of_birth_code: value.province_of_birth_code,
                born_abroad: value.born_abroad,
                country_of_birth_code: value.country_of_birth_code,
              })}
              valid={isValid('commune_of_birth_code')}
              error={errors.commune_of_birth_code?.message}
            />
          </>
        )}
      <FormInputAddress
        valueAddress={answers.address}
        valueHouse={answers.house_number}
        label="Indirizzo"
        onAddressChange={(value: string) => updateFormData({ address: value })}
        onHouseNumberChange={(value: string) => updateFormData({ house_number: value })}
        addressError={errors.address?.message}
        houseNumberError={errors.house_number?.message}
        validAddress={isValid('address')}
        validHouseNumber={isValid('house_number')}
      />
      <FormReactSelectResidenceInput
        label="Residenza"
        options={municipalities}
        values={{
          residence_commune_code: answers.residence_commune_code,
          residence_province_code: answers.residence_province_code,
          postal_code: answers.postal_code,
        }}
        onChange={(item: any) => updateFormData({
          residence_commune_code: item.residence_commune_code,
          residence_province_code:
            item.residence_province_code,
          postal_code: item.postal_code ?? '',
        })}
        error={errors.residence_commune_code?.message}
        valid={isValid('residence_commune_code')}
      >
        <FormInputText
          placeholder="-Codice Postale-"
          label="Codice Postale"
          registration={register('postal_code')}
          error={errors.postal_code?.message}
          valid={isValid('postal_code')}
        />
      </FormReactSelectResidenceInput>

      {answers.gender !== 'G'
        && (
          <>
            <FormInputRadio
              label="Figli conviventi?"
              options={flagResponse}
              name="children"
              registration={register('children')}
              error={errors.children?.message}
            />
            <FormInputSelect
              placeholder="Stato civile"
              label="Stato civile"
              registration={register('civil_status_id')}
              error={errors.civil_status_id?.message}
              valid={isValid('civil_status_id')}
              options={formData.marital_statuses}
            />
            <FormInputSelect
              placeholder="Titolo di studio"
              label="Titolo di studio"
              registration={register('education_level_id')}
              error={errors.education_level_id?.message}
              valid={isValid('education_level_id')}
              options={formData.qualifications}
            />
            <FormInputSelect
              placeholder="Professione"
              label="Professione"
              registration={register('profession_id')}
              error={errors.profession_id?.message}
              valid={isValid('profession_id')}
              options={formData.professions}
            />
          </>
        )}

      {answers.gender !== 'G' && (
        <FormInputRadio
          label="Il contraente è anche il conducente abituale del veicolo?"
          options={flagResponse}
          name="contractor_is_driver"
          registration={register('contractor_is_driver')}
          error={errors.contractor_is_driver?.message}
        />
      )}

      {answers.contractor_is_driver === '0' ? (
        <>
          <OffcanvasForm
            ref={driverFormComponentRef}
            filled={driverForm}
            setFilled={setDriverForm}
            id="dati-driver"
            title="Dati Del Conduttore"
            data={driver}
            onChange={updateDriverFormData}
            genders={genders}
            getMaxAllowedBirthday={getMaxAllowedBirthday}
            municipalities={municipalities}
            marital_statuses={formData.marital_statuses}
            qualifications={formData.qualifications}
            professions={formData.professions}
            company_types={formData.company_types}
            isOwnerForm={false}
            states={states}
          />
        </>
      ) : (
        ''
      )}

      {answers.gender !== 'G' && (
        <FormInputRadio
          label="Il contraente è anche proprietario dell auto?"
          options={flagResponse}
          name="contractor_is_owner"
          registration={register('contractor_is_owner')}
          error={errors.contractor_is_owner?.message}
        />
      )}

      {answers.contractor_is_owner === '0' ? (
        <>
          <OffcanvasForm
            ref={ownerFormComponentRef}
            filled={ownerForm}
            states={states}
            setFilled={setOwnerForm}
            id="dati-proprietario"
            title="Dati Del Proprietario"
            data={owner}
            onChange={updateOwnerFormData}
            genders={genders}
            getMaxAllowedBirthday={getMaxAllowedBirthday}
            municipalities={municipalities}
            marital_statuses={formData.marital_statuses}
            qualifications={formData.qualifications}
            professions={formData.professions}
            company_types={formData.company_types}
            isOwnerForm
          />
        </>
      ) : (
        ''
      )}
    </FormContainer>
  );
}

interface ThirdStepProps {
  register: Function;
  errors: any;
  isValid: Function;
  answers: any;
  state: any;
  updateFormData: Function;
  bersaniComponentRef: any;
  bersani: any;
  updateBersaniData: Function;
}

function ThirdStep({
  answers, register, errors, isValid, updateFormData,
  bersaniComponentRef, bersani, updateBersaniData, state,
}: ThirdStepProps) {
  const { municipalities } = state;

  let typeGuide;

  if (answers.other_drivers === '1') {
    typeGuide = guideType.filter((guide: any) => {
      if (
        answers.youngest_age_driver < 26
        || answers.youngest_age_family_member < 26
      ) {
        return guide.id === 'free';
      }
      return guide.id !== 'exclusive';
    });
  } else {
    typeGuide = guideType.filter((guide) => {
      if (answers.youngest_age_family_member < 26) {
        return guide.id !== 'expert';
      } if (answers.youngest_age_family_member >= 26) {
        return guide;
      }

      return false;
    });
  }

  return (
    <FormContainer>
      {answers.renew_check && (
        <FormInputSelect
          label="Seleziona il tipo di assicurazione."
          registration={register('insurance_type')}
          error={errors.insurance_type?.message}
          valid={isValid('insurance_type')}
          placeholder="-Seleziona-"
          options={insuranceTypes}
          disabled
        />
      )}
      {!answers.renew_check && (
        <FormInputSelect
          label="Seleziona il tipo di assicurazione."
          registration={register('insurance_type')}
          error={errors.insurance_type?.message}
          valid={isValid('insurance_type')}
          placeholder="-Seleziona-"
          options={insuranceTypes}
        />
      )}

      <FormInputDate
        minDate={getAllowedPolicyEffectDate('min')}
        maxDate={getAllowedPolicyEffectDate('max')}
        registration={register('policy_effective_date')}
        error={errors.policy_effective_date?.message}
        valid={isValid('policy_effective_date')}
        label={
          answers.insurance_type === 'N'
            ? 'Data di inizio della copertura della polizza'
            : 'Data di scadenza della tua attuale polizza'
        }
        paragraph="Date valide: da oggi a un anno da oggi"
      />
      <FormInputRadio
        label="Ci sono altri conducenti?"
        options={flagResponse}
        name="other_drivers"
        registration={register('other_drivers')}
        error={errors.other_drivers?.message}
      />
      {answers.gender !== 'G'
        && (
          <>
            {answers.other_drivers === '1' && (
              <FormInputText
                placeholder="(18 - 100)"
                type="number"
                min={18}
                max={100}
                label="Età del conducente più giovane"
                registration={register('youngest_age_driver')}
                error={errors.youngest_age_driver?.message}
                valid={isValid('youngest_age_driver')}
              />
            )}
            <FormInputText
              placeholder="(18 - 100)"
              type="number"
              min={18}
              max={100}
              label="Età del membro più giovane della famiglia con la patente di guida"
              registration={register('youngest_age_family_member')}
              error={errors.youngest_age_family_member?.message}
              valid={isValid('youngest_age_family_member')}
            />
          </>
        )}
      {answers.insurance_type !== 'N'
        && (
          <>
            <FormInputSelect
              label="Prima assicurazione in 6 anni"
              registration={register('first_insuranced_year')}
              error={errors.first_insuranced_year?.message}
              valid={isValid('first_insuranced_year')}
              placeholder="-Seleziona-"
              options={lastSixYears}
            />
            <FormInputRadio
              label="Hai fatto sinistri negli ultimi 6 anni?"
              name="violations"
              options={flagResponse}
              onChange={(value: any) => {
                const update_data: any = {
                  violations: value,
                };

                if (value === 0) {
                  update_data.violations_number = 0;
                  update_data.violations_data = calculateEmptyViolationsData();
                }

                updateFormData(update_data);
              }}
              value={answers.violations}
            />
          </>
        )}
      {answers.violations === 1 && (
        <FormInputViolations
          onChange={(data: any) => {
            updateFormData({
              violations_data: data.data,
              violations_number: data.violations_number,
            });
          }}
          value={answers.violations_data}
        />
      )}

      {answers.insurance_type === 'N' && (
        <FormInputSelect
          label="Puoi utilizzare la classe di merito di un altro veicolo già assicurato?"
          registration={register('inherit_merit_class')}
          error={errors.inherit_merit_class?.message}
          valid={isValid('inherit_merit_class')}
          placeholder="-Seleziona-"
          options={inheritMeritClass}
        />
      )}

      {((answers.inherit_merit_class == 'A'
        && answers.insurance_type === 'N') || answers.inherit_merit_class == 'S') && (
          <BersaniInfo
            ref={bersaniComponentRef}
            inheritMeritClass={answers.inherit_merit_class}
            bersani={bersani}
            updateBersaniData={updateBersaniData}
            municipalities={municipalities}
          />
      )}

      <FormInputSelect
        label={
          answers.inherit_merit_class == 'S'
            || (answers.inherit_merit_class == 'A'
              && answers.insurance_type === 'N')
            ? 'Classe di merito del veicolo già  assicurato'
            : 'Classe di merito.'
        }
        registration={register('merit_class')}
        error={errors.merit_class?.message}
        valid={isValid('merit_class')}
        placeholder="-Seleziona-"
        options={meritClass}
      />

      <FormInputRadio
        label="Il conducente dichiara: di essere in possesso di patente italiana mai sospesa da 5 anni e con almeno 20 punti, di non aver mai ricevuto sanzioni per ubriachezza, di non aver modificato il veicolo."
        options={flagResponse}
        name="mofified_vehicle_and_valid_driving_license"
        onChange={(value: any) => updateFormData({
          mofified_vehicle: value,
          valid_driving_license: value,
        })}
        value={answers.mofified_vehicle}
        error={errors.mofified_vehicle?.message}
      />
      <FormInputSelect
        label="Tipo di guida"
        registration={register('guide_type')}
        error={errors.guide_type?.message}
        valid={isValid('guide_type')}
        placeholder="-Seleziona-"
        options={typeGuide}
      />
    </FormContainer>
  );
}

interface ForthStepProps {
  answers: any;
  register: Function;
  errors: any;
  isValid: any;
  updateAnswers: Function;
  state: any;
}

function ForthStep({
  answers, register, errors, isValid, updateAnswers, state,
}: ForthStepProps) {
  const { dataPrivacy } = state;

  const privacyItemChangeHandler = (value: any, item: any) => {
    if (!value.target.checked) {
      const newArray = [...answers.privacies];
      const index = newArray.indexOf(item.id);
      newArray.splice(index, 1);
      updateAnswers({
        privacies: newArray,
      });
      return;
    }
    const answersNewArray = [...answers.privacies];
    answersNewArray.push(item.id);
    updateAnswers({
      privacies: answersNewArray,
    });
  };

  return (
    <FormContainer>
      {answers.gender !== 'G' && (
        <>
          <FormInputText
            registration={register('name')}
            error={errors.name?.message}
            valid={isValid('name')}
            label="Nome"
          />
          <FormInputText
            registration={register('surname')}
            error={errors.surname?.message}
            valid={isValid('surname')}
            label="Cognome"
          />
        </>
      )}
      <FormInputText
        registration={register('email')}
        error={errors.email?.message}
        valid={isValid('email')}
        label="E-mail"
      />
      <FormInputText
        registration={register('phone')}
        error={errors.phone?.message}
        valid={isValid('phone')}
        label="Telefono"
        paragraph="Numeri di rete fissa non accettati dalle aziende"
      />

      <PaymentTypePeriod selectedType={answers.payment_frequency} updateAnswers={updateAnswers} />

      <FormInputLabel>Informativa Privacy e IVASS</FormInputLabel>

      <GreeniaPrivacyLink onClick={() => greeniaPrivacyPolicy()}>
        Greenia Privacy Policy
      </GreeniaPrivacyLink>

      {dataPrivacy.map((item: any, i: number) => (
        <FormInputPrivacy
          required={item.required}
          label={item.content}
          name={item.type}
          id={item.type}
          key={i}
          checked={answers.privacies.includes(item.id)}
          onChange={(value: any) => privacyItemChangeHandler(value, item)}
        />
      ))}
    </FormContainer>
  );
}

export function FormAuto() {
  const navigate = useNavigate();
  const location = useLocation();
  const state = useSelector((store: any) => store.forms.auto);

  const {
    loading, index, dataPrivacy, fastQuoteVehicleInfo, states, municipalities, loadingQuotes,
  } = state;

  const driverFormComponentRef = useRef<any>();
  const ownerFormComponentRef = useRef<any>();
  const bersaniComponentRef = useRef<any>();

  const skipCheckGenderRef = useRef(false);
  const skipCheckInsuranceTypeRef = useRef(false);
  const [skipBrandCodeEffect, setSkipBrandCodeEffect] = useState(false);
  const [skipModelCodeEffect, setSkipModelCodeEffect] = useState(false);

  const StoredAutoForm = StorageService.getAutoFormAnswers();

  const {
    register,
    formState: { errors, touchedFields },
    setValue,
    trigger,
    watch,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(AutoFormValidationsSchema),
    shouldFocusError: true,
    defaultValues: StoredAutoForm || {
      product: 'auto',
      source: 'greenia.it',
      contractor_is_owner: '1',
      contractor_is_driver: '1',
      name: '',
      surname: '',
      gender: '',
      fiscal_code: '',
      phone: '',
      email: '',
      date_of_birth: '',
      country_of_birth_code: 'Z000',
      province_of_birth_code: null,
      commune_of_birth_code: null,
      born_abroad: false,
      residence_province_code: '',
      residence_commune_code: '',
      postal_code: '',
      address: '',
      house_number: '',
      civil_status_id: '2',
      education_level_id: '3',
      profession_id: '1',
      driving_license_year: '',
      vehicle_plate: '',
      vehicle_brand_code: '',
      vehicle_model_code: '',
      vehicle_version_code: '',
      vehicle_am_code: '',
      imm_vehicle_year: '',
      imm_vehicle_month: '',
      inherit_merit_class: '',
      merit_class: '1',
      vehicle_purchased_month: '',
      vehicle_purchased_year: '',
      theft_protection_code: '4',
      tow_hook: '0',
      privacies: [],
      children: '0',
      vehicle_parking: '1',
      other_power_supply: '9',
      vehicle_usage: '1',
      predicted_km: 10000,
      vehicles_owned: 2,
      policy_effective_date: '',
      other_drivers: '1',
      youngest_age_driver: 30,
      youngest_age_family_member: 30,
      mofified_vehicle: 1,
      valid_driving_license: 1,
      first_insuranced_year: '',
      violations: 0,
      violations_data: calculateEmptyViolationsData(),
      violations_number: 0,
      business_name: '',
      vat_number: '',
      company_type: null,
      guide_type: 'free',
      insurance_type: '',
      complete_manually: false,
      renew_check: false,
      new_risks_check: false,
      power: 0,
      weight: 0,
      payment_frequency: 1, // Anuuale = 1 || Semestrale = 2
    },
  });

  const answers = watch();

  const StoredOwner = StorageService.getAutoFormOwner();

  const [owner, setOwner] = useState(
    StoredOwner || {
      isValid: false,
      name: '',
      surname: '',
      gender: '',
      fiscal_code: '',
      date_of_birth: '',
      country_of_birth_code: 'Z000',
      province_of_birth_code: null,
      commune_of_birth_code: null,
      born_abroad: false,
      residence_province_code: '',
      residence_commune_code: '',
      postal_code: '',
      address: '',
      house_number: '',
      civil_status_id: '2',
      education_level_id: '3',
      profession_id: '',
      valid_driving_license: '1',
      driving_license_year: '',
      business_name: '',
      vat_number: '',
      company_type: null,
    },
  );

  // Collect Driver Data
  const StoredDriver = StorageService.getAutoFormDriver();

  const [driver, setDriver] = useState(
    StoredDriver || {
      isValid: false,
      name: '',
      surname: '',
      gender: '',
      fiscal_code: '',
      date_of_birth: '',
      country_of_birth_code: 'Z000',
      province_of_birth_code: null,
      commune_of_birth_code: null,
      born_abroad: false,
      residence_province_code: '',
      residence_commune_code: '',
      postal_code: '',
      address: '',
      house_number: '',
      civil_status_id: '2',
      education_level_id: '3',
      profession_id: '',
      valid_driving_license: '1',
      driving_license_year: '',
    },
  );

  const StoredBersani = StorageService.getAutoFormBersani();

  const [bersani, setBersani] = useState(
    StoredBersani || {
      isValid: false,
      name: '',
      surname: '',
      date_of_birth: '',
      gender: '',
      commune_of_birth_code: '',
      vehicle_type: '',
      vehicle_plate: '',
    },
  );

  const isValid = (name: string) => {
    if (errors[name]?.message !== undefined) {
      return false;
    }

    if (touchedFields[name] === undefined) {
      return false;
    }

    return true;
  };

  const updateAnswers = (data: any) => {
    const keys = Object.keys(data);
    keys.forEach((key) => {
      setValue(key, data[key], {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    });
    trigger(keys);
  };

  const updateFormData = (answer: any, doNotRunVehicleInfoChangeChecks?: boolean) => {
    if (doNotRunVehicleInfoChangeChecks !== true) {
      if ((answer.vehicle_brand_code
        && answer.vehicle_brand_code != answers.vehicle_brand_code)) {
        answer.vehicle_model_code = '';
        answer.vehicle_version_code = null;
      } else if (
        (answer.vehicle_brand_code
          && answer.vehicle_brand_code != answers.vehicle_brand_code)
        || (answer.vehicle_model_code
          && answer.vehicle_model_code != answers.vehicle_model_code)
        || (answer.imm_vehicle_year
          && answer.imm_vehicle_year != answers.imm_vehicle_year)
      ) {
        answer.vehicle_version_code = null;
      }
    }

    updateAnswers({ ...answer });
  };

  const updateOwnerFormData = (data: any) => {
    setOwner({ ...owner, ...data });
  };

  const updateDriverFormData = (data: any) => {
    setDriver({ ...driver, ...data });
  };

  const setDriverForm = (value: any) => {
    autoUpdateFormData({
      driverForm: value,
    });
  };

  const setOwnerForm = (value: any) => {
    autoUpdateFormData({
      ownerForm: value,
    });
  };

  const updateBersaniData = (data: any) => {
    setBersani({ ...bersani, ...data });
  };

  const cancelFastquote = () => {
    autoCancelFastQuote();
  };

  const confirmFastQuote = () => {
    if (answers.renew_check === true) {
      if (fastQuoteVehicleInfo.frontend.persona_giuridica === true) {
        const commune_of_birth_code = null;
        const province_of_birth_code = null;
        const born_abroad = false;
        const country_of_birth_code = null;

        setSkipBrandCodeEffect(true);
        setSkipModelCodeEffect(true);
        skipCheckGenderRef.current = true;
        updateFormData({
          ...fastQuoteVehicleInfo.frontend,
          policy_effective_date:
            getAllowedPolicyEffectDate('min')
              <= fastQuoteVehicleInfo.frontend.policy_effective_date
              && fastQuoteVehicleInfo.frontend.policy_effective_date
              <= getAllowedPolicyEffectDate('max')
              ? fastQuoteVehicleInfo.frontend.policy_effective_date
              : '',
          date_of_birth: null,
          commune_of_birth_code,
          province_of_birth_code,
          born_abroad,
          country_of_birth_code,
          gender: 'G',

          contractor_is_driver: '0',
          civil_status_id: null,
          education_level_id: null,
          profession_id: null,
          driving_license_year: null,
          children: null,
        }, true);
      } else {
        const fiscalObj = decodeFiscalCode(fastQuoteVehicleInfo.frontend.fiscal_code);
        const commune = municipalities.find(
          (municipality: any) => municipality.name.toLowerCase() === fiscalObj.birthplace.toLowerCase(),
        );

        let commune_of_birth_code = null;
        let province_of_birth_code = null;
        let born_abroad = false;
        let country_of_birth_code = 'Z000';

        if (fiscalObj.birthplaceProvincia !== 'EE') {
          if (commune) {
            commune_of_birth_code = commune.cadastral_code;
            province_of_birth_code = fiscalObj.birthplaceProvincia;
          }
        } else {
          born_abroad = true;

          const state = states.find(
            (s: any) => s.name === fiscalObj.birthplace,
          );

          if (state !== undefined) {
            country_of_birth_code = state.state_code;
          }
        }

        setSkipBrandCodeEffect(true);
        setSkipModelCodeEffect(true);
        skipCheckGenderRef.current = true;
        updateFormData({
          ...fastQuoteVehicleInfo.frontend,
          policy_effective_date:
            getAllowedPolicyEffectDate('min')
              <= fastQuoteVehicleInfo.frontend.policy_effective_date
              && fastQuoteVehicleInfo.frontend.policy_effective_date
              <= getAllowedPolicyEffectDate('max')
              ? fastQuoteVehicleInfo.frontend.policy_effective_date
              : '',
          date_of_birth: `${fiscalObj.year}-${fiscalObj.month < 10 ? `0${fiscalObj.month}` : fiscalObj.month}-${fiscalObj.day < 10 ? `0${fiscalObj.day}` : fiscalObj.day}`,
          commune_of_birth_code,
          province_of_birth_code,
          born_abroad,
          country_of_birth_code,
          gender: fiscalObj.gender,
        }, true);
      }
    } else if (answers.new_risks_check === true) {
      setSkipBrandCodeEffect(true);
      setSkipModelCodeEffect(true);
      const {
        vehicle_model_code,
        vehicle_brand_code,
        violations,
        violations_data,
        violations_number,
        imm_vehicle_year,
        imm_vehicle_month,
        power,
        weight,
        first_insuranced_year,
      } = fastQuoteVehicleInfo.frontend;
      updateFormData({
        vehicle_model_code,
        vehicle_brand_code,
        violations,
        violations_data,
        violations_number,
        imm_vehicle_year,
        imm_vehicle_month,
        power,
        weight,
        first_insuranced_year,
      }, true);
    }

    autoCancelFastQuote();
  };

  const getFiscalCode = (birthDate: string) => {
    const d = birthDate.split('-');
    const fData: any = {
      name: answers.name,
      surname: answers.surname,
      gender: answers.gender,
      day: d[2],
      month: d[1],
      year: d[0],
    };

    if (answers.born_abroad) {
      fData.birthplace = states.filter(
        (s: any) => s.state_code === answers.country_of_birth_code,
      )[0].name;
      fData.birthplaceProvincia = 'EE';
    } else {
      fData.birthplace = municipalities.filter(
        (municipality: any) => municipality.cadastral_code === answers.commune_of_birth_code,
      )[0].name;
      fData.birthplaceProvincia = answers.province_of_birth_code;
    }

    return generateFiscalCode(fData);
  };

  const getFiscalCodeOD = (data: any) => {
    const d = convertToItalianDate(data.date_of_birth).split('-');
    const fData: any = {
      name: data.name,
      surname: data.surname,
      gender: data.gender,
      day: d[2],
      month: d[1],
      year: d[0],
    };

    if (data.born_abroad) {
      fData.birthplace = states.filter(
        (s: any) => s.state_code === data.country_of_birth_code,
      )[0].name;
      fData.birthplaceProvincia = 'EE';
    } else {
      fData.birthplace = municipalities.filter(
        (municipality: any) => municipality.cadastral_code === data.commune_of_birth_code,
      )[0].name;
      fData.birthplaceProvincia = data.province_of_birth_code;
    }
    return generateFiscalCode(fData);
  };

  const submitAnswers = () => {
    const answersData = {
      ...answers,
      owner: answers.contractor_is_owner === '0' ? owner : null,
      driver: answers.contractor_is_driver === '0' ? driver : null,
      bersani:
        answers.insurance_type === 'N' && answers.inherit_merit_class != 'N'
          ? bersani
          : null,
    };

    answersData.date_of_birth = answers.date_of_birth !== null ? convertToItalianDate(answers.date_of_birth) : null;
    answersData.policy_effective_date = convertToItalianDate(
      answers.policy_effective_date,
    );
    answersData.fiscal_code = (answers.gender !== 'G') ? getFiscalCode(answers.date_of_birth) : null;
    if (answers.contractor_is_owner === '0') {
      answersData.owner.date_of_birth = owner.gender !== 'G' ? convertToItalianDate(
        owner.date_of_birth,
      ) : null;
      answersData.owner.fiscal_code = (owner.gender !== 'G') ? getFiscalCodeOD(owner) : null;
    }
    if (answers.contractor_is_driver === '0') {
      answersData.driver.date_of_birth = convertToItalianDate(
        driver.date_of_birth,
      );
      answersData.driver.fiscal_code = getFiscalCodeOD(driver);
    }

    autoSubmitAnswers(answersData, navigate);
  };

  const prevButton = () => {
    if (index > 1) {
      autoChangeIndex(index - 1);
    }
  };
  const validateDataPrivacy = () => {
    let error = false;
    let message = '';

    dataPrivacy.forEach((privacy: any) => {
      if (privacy.required === 1) {
        const userDataArray = { ...answers }; // make a separate copy of the object
        const index = userDataArray.privacies.indexOf(privacy.id);

        if (index < 0) {
          error = true;
          message = 'Tutti i campi sono obbligatori! Tutta la privacy con la stella deve essere controllata';
        }
      }
    });
    return { error, message };
  };

  const nextButton = async () => {
    const res = await trigger(AutoFormFieldsBySteps[index - 1], { shouldFocus: true });

    if (res !== true) {
      return;
    }

    if (index === 2) {
      if (answers.contractor_is_driver === '0') {
        if (!driver.isValid) {
          driverFormComponentRef.current!.triggerErrors();
          return;
        }
      }

      if (answers.contractor_is_owner === '0') {
        if (!owner.isValid) {
          ownerFormComponentRef.current!.triggerErrors();
          return;
        }
      }
    }

    if (index === 3) {
      if (
        answers.inherit_merit_class === 'S'
        || (
          answers.inherit_merit_class == 'A'
          && answers.insurance_type === 'N'
        )
      ) {
        if (!bersani.isValid) {
          bersaniComponentRef.current.triggerErrors();
          return;
        }
      }
    }

    if (index === 4) {
      const validation = validateDataPrivacy();
      if (validation.error) {
        generalAlertError(validation.message);
        return;
      }
    }

    if (index === 4) {
      submitAnswers();
      return;
    }

    autoChangeIndex(index + 1);
    setTimeout(() => window.scrollTo(0, 0), 500);
  };

  const vehicleBrandCodeEffectDependencies = useRef<string | undefined>();
  const vehicleBrandCodeDeps = [answers.vehicle_brand_code, answers.imm_vehicle_year];
  useEffect(() => {
    if (skipBrandCodeEffect) {
      setSkipBrandCodeEffect(false);
      return;
    }

    if (vehicleBrandCodeEffectDependencies.current === undefined) {
      vehicleBrandCodeEffectDependencies.current = JSON.stringify(vehicleBrandCodeDeps);
      return;
    }

    if (vehicleBrandCodeEffectDependencies.current === JSON.stringify(vehicleBrandCodeDeps)) {
      return;
    }

    vehicleBrandCodeEffectDependencies.current = JSON.stringify(vehicleBrandCodeDeps);
    setValue('vehicle_model_code', '');
  }, vehicleBrandCodeDeps);

  const vehicleModelCodeEffectDependencies = useRef<string | undefined>();
  const vehicleModelCodeDeps = [answers.vehicle_model_code];
  useEffect(() => {
    if (skipModelCodeEffect) {
      setSkipModelCodeEffect(false);
      return;
    }

    if (vehicleModelCodeEffectDependencies.current === undefined) {
      vehicleModelCodeEffectDependencies.current = JSON.stringify(vehicleModelCodeDeps);
      return;
    }

    if (vehicleModelCodeEffectDependencies.current === JSON.stringify(vehicleModelCodeDeps)) {
      return;
    }

    vehicleModelCodeEffectDependencies.current = JSON.stringify(vehicleModelCodeDeps);

    setValue('vehicle_version_code', null);
    setValue('vehicle_am_code', '');
  }, vehicleModelCodeDeps);

  useEffect(() => {
    if (answers.vehicle_brand_code) {
      autoGetVehicleModels(answers.vehicle_brand_code, answers.imm_vehicle_year);
    }
  }, [answers.vehicle_brand_code, answers.imm_vehicle_year]);

  useEffect(() => {
    if (answers.vehicle_brand_code && answers.vehicle_model_code && answers.imm_vehicle_year) {
      autoGetVehicleVersions(answers.vehicle_model_code, answers.imm_vehicle_year);
    }
  }, [answers.vehicle_brand_code, answers.vehicle_model_code, answers.imm_vehicle_year]);

  useEffect(() => {
    StorageService.storeAutoFormAnswers(answers);
    StorageService.storeAutoFormOwner(owner);
    StorageService.storeAutoFormDriver(driver);
    StorageService.storeAutoFormBersani(bersani);
  }, [answers, owner, driver, bersani]);

  useEffect(() => {
    autoGetInitialData();

    return () => {
      resetAutoForm();
    };
  }, []);

  useEffect(() => {
    if (skipCheckInsuranceTypeRef.current === true) {
      skipCheckInsuranceTypeRef.current = false;
      return;
    }

    const triggerValidation = {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    };

    if (answers.insurance_type === 'N') {
      setValue('first_insuranced_year', new Date().getFullYear().toString());
      setValue('violations', '0');
    } else if (answers.insurance_type === 'B') {
      setValue('first_insuranced_year', answers.first_insuranced_year, triggerValidation);
      setValue('violations', '0', triggerValidation);
    }
  }, [answers.insurance_type]);

  useEffect(() => {
    if (skipCheckGenderRef.current === true) {
      skipCheckGenderRef.current = false;
      return;
    }

    const triggerValidation = {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    };

    if (answers.gender === 'G') {
      setValue('contractor_is_driver', '0');
      setValue('contractor_is_owner', '1');
      setValue('date_of_birth', null);
      setValue('country_of_birth_code', null);
      setValue('province_of_birth_code', null);
      setValue('commune_of_birth_code', null);
      setValue('born_abroad', false);
      setValue('civil_status_id', null);
      setValue('education_level_id', null);
      setValue('profession_id', null);
      setValue('driving_license_year', null);
      setValue('children', null);
      setValue('name', null);
      setValue('surname', null);
    } else if (['M', 'F'].includes(answers.gender) && answers.date_of_birth === null) {
      setValue('date_of_birth', '', triggerValidation);
      setValue('country_of_birth_code', 'Z000', triggerValidation);
      setValue('province_of_birth_code', null, triggerValidation);
      setValue('commune_of_birth_code', null, triggerValidation);
      setValue('born_abroad', false, triggerValidation);
      setValue('civil_status_id', '2', triggerValidation);
      setValue('education_level_id', '3', triggerValidation);
      setValue('profession_id', '1', triggerValidation);
      setValue('driving_license_year', '', triggerValidation);
      setValue('children', '0', triggerValidation);
      setValue('name', '', triggerValidation);
      setValue('surname', '', triggerValidation);
    }
  }, [answers.gender, answers.date_of_birth, answers.contractor_is_driver]);

  useEffect(() => {
    if (!answers.driving_license_year) {
      if (answers.date_of_birth) {
        const birthYear = answers.date_of_birth.split('-')[0];
        const regex = /^\d{4}$/;
        if (regex.test(birthYear)) {
          const drivingLicenseYear = parseInt(birthYear) + 18;
          updateAnswers({
            driving_license_year: drivingLicenseYear.toString(),
          });
        }
      }
    }
  }, [answers.date_of_birth]);

  useEffect(() => {
    const callAsync = async () => {
      if (location.state !== null && location.state.requestToken !== undefined) {
        skipCheckGenderRef.current = true;
        const {
          data: {
            data: {
              answers, owner, driver, bersani,
            },
          },
        } = await modifyRequestData(location.state.requestToken);

        setSkipBrandCodeEffect(true);
        setSkipModelCodeEffect(true);
        updateAnswers(answers);
        setOwner(owner);
        setDriver(driver);
        setBersani(bersani);
      }
    };

    callAsync();
  }, []);

  const isValidVehiclePlate = isValid('vehicle_plate') && answers.vehicle_plate.length === 7;

  useEffect(() => {
    if (isValidVehiclePlate && answers.complete_manually === true) {
      setSkipBrandCodeEffect(true);
      setSkipModelCodeEffect(true);
      autoGetQuickInfo(answers.vehicle_plate, updateFormData);
    }
  }, [isValidVehiclePlate, answers.vehicle_plate, answers.complete_manually]);

  if (loading) {
    return <PageLoader />;
  }

  if (loadingQuotes) {
    return <QuoteGenerateLoading />;
  }

  return (
    <div>
      <Header headerText="Preventivo" headerTextBold="Auto" />
      <AutoStepper currentStep={index} />

      {fastQuoteVehicleInfo !== null && (
        <FastQuoteModal
          answers={answers}
          fastQuoteVehicleInfo={fastQuoteVehicleInfo}
          cancelFastquote={cancelFastquote}
          confirmFastQuote={confirmFastQuote}
        />
      )}

      {index === 1
        && (
          <FirstStep
            answers={answers}
            errors={errors}
            isValid={isValid}
            updateFormData={updateFormData}
            register={register}
            state={state}
          />
        )}

      {index === 2
        && (
          <SecondStep
            state={state}
            answers={answers}
            driver={driver}
            owner={owner}
            updateFormData={updateFormData}
            updateDriverFormData={updateDriverFormData}
            updateOwnerFormData={updateOwnerFormData}
            driverFormComponentRef={driverFormComponentRef}
            ownerFormComponentRef={ownerFormComponentRef}
            errors={errors}
            isValid={isValid}
            register={register}
            setDriverForm={setDriverForm}
            setOwnerForm={setOwnerForm}
          />
        )}

      {index === 3
        && (
          <ThirdStep
            state={state}
            answers={answers}
            bersani={bersani}
            updateFormData={updateFormData}
            updateBersaniData={updateBersaniData}
            bersaniComponentRef={bersaniComponentRef}
            errors={errors}
            isValid={isValid}
            register={register}
          />
        )}

      {index === 4
        && (
          <ForthStep
            state={state}
            answers={answers}
            errors={errors}
            isValid={isValid}
            register={register}
            updateAnswers={updateAnswers}
          />
        )}

      {index >= 1 && index <= 4 && (
        <FooterButtons>
          <button className="btn-questionnaire" onClick={nextButton}>
            {index === 4 ? 'Vai ai preventivi' : 'Continua'}
          </button>
          {index !== 1 && (
            <button
              className="btn-questionnaire"
              onClick={prevButton}
            >
              Indietro
            </button>
          )}

        </FooterButtons>
      )}
    </div>
  );
}
