import { FormInputGroup } from './FormInputGroup';
import { FormInputLabel } from './FormInputLabel';
import { FormInputDateStyled, Paragraph } from './FormInputDate.styles';

export function FormInputDate({
  label, paragraph, registration, valid, error, minDate, maxDate, value, onChange,
}: any) {
  if (registration === undefined) {
    const handleChange = (e: any) => {
      onChange(e.target.value);
    };

    return (
      <FormInputGroup>
        {label !== undefined
                    && <FormInputLabel>{label}</FormInputLabel>}
        {paragraph !== undefined
                    && <Paragraph>{paragraph}</Paragraph>}
        <FormInputDateStyled className={`${error ? 'is-invalid' : ''} ${valid ? 'is-valid' : ''}`} type="date" lang="it-IT" min={minDate} max={maxDate} value={value} onChange={handleChange} />
        <div className="invalid-feedback">
          {error}
        </div>
      </FormInputGroup>
    );
  }
  return (
    <FormInputGroup>
      {label !== undefined
                && <FormInputLabel>{label}</FormInputLabel>}
      {paragraph !== undefined
                && <Paragraph>{paragraph}</Paragraph>}
      <FormInputDateStyled className={`${error ? 'is-invalid' : ''} ${valid ? 'is-valid' : ''}`} type="date" lang="it-IT" min={minDate} max={maxDate} {...registration} />
      <div className="invalid-feedback">
        {error}
      </div>
    </FormInputGroup>
  );
}
