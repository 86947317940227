import { useEffect, useRef } from 'react';
import { FormInputGroup } from './FormInputGroup';
import { FormInputLabel } from './FormInputLabel';
import { FormInputTable } from './FormInputSelectTable.styles';

export function FormInputSelectTable(props: any) {
  const tableRef = useRef<HTMLDivElement>(null);
  const selectedVehicleVersion = useRef(null);

  const scrollToParent = () => {
    const parent = tableRef.current! as HTMLElement;
    const parentRect = parent.getBoundingClientRect();
    const childRect = (selectedVehicleVersion.current! as HTMLElement).getBoundingClientRect();

    parent.scrollLeft += (childRect.left - parentRect.left) - (parent.clientWidth / 2);
    parent.scrollTop += (childRect.top - parentRect.top) - (parent.clientHeight / 2);
  };

  useEffect(() => {
    if (props.selected !== null && selectedVehicleVersion.current !== null) {
      scrollToParent();
    }
  }, [props.selected]);

  return (
    <FormInputGroup>
      <FormInputLabel>{props.label}</FormInputLabel>
      <FormInputTable ref={tableRef}>
        <table>
          <tbody>
            {props.options && props.options.map((opt: any, index: number) => (
              <tr key={index} ref={opt.AM_code == props.selected ? selectedVehicleVersion : undefined} className={`${opt.AM_code == props.selected ? 'selected' : ''}`} onClick={() => { props.onSelectOption({ id: opt.id, am_code: opt.AM_code }); }}>
                <td>{opt.description}</td>
                <td>{opt.cubic_capacity}</td>
                <td>
                  {opt.month}
                  /
                  {opt.year}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </FormInputTable>
    </FormInputGroup>
  );
}
