import React from 'react';
import {
  CardCompanyLogoResult, CardContainer, CardFlex, CardInformation, InformationContainer,
} from '../cards/card.styles';
import { BACKEND_BASE_URL } from '../../../../config/urls';

function PrimaCardSelected({ quotation }: any) {
  return (
    <CardContainer>
      <CardFlex>
        <CardInformation>
          <div className="price">
            <div className="title">Prezzo annuo</div>
            <div className="amount">
              &euro;
              {Number(quotation.amount).toLocaleString('it-IT', {
                minimumFractionDigits: 2,
              })}
            </div>
          </div>

        </CardInformation>
        <CardCompanyLogoResult src={BACKEND_BASE_URL + quotation.company.logo} alt={`${quotation.company.name} Logo`} />
      </CardFlex>

      <InformationContainer>
        <div className="record-result">
          <div className="record-title-result">Compagnia:</div>
          <div className="record-value-result">Ipitq ma</div>
        </div>
        <div className="record-result">
          <div className="record-title-result">Frequenza pagamento:</div>
          <div className="record-value-result">Annuo</div>
        </div>
        <div className="record-result">
          <div className="record-title-result">Massimale:</div>
          <div className="record-value-result">
            <span className="bold">6'450'000€</span>
            {' '}
            Danni a persone
            <br />
            <span className="bold">1'050'000€</span>
            {' '}
            Danni a cose
          </div>
        </div>
      </InformationContainer>
    </CardContainer>
  );
}

export default PrimaCardSelected;
