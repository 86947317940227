import React, { useEffect, useState } from 'react'
import { FastQuoteLoadingContainer, FastQuoteLoadingPopUpContainer, FastQuoteLoadingText } from './FastQuote.styles';

export const FastQuoteLoading = () => {
  const [counter, setCounter] = useState(180);

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter])


  return (
    <FastQuoteLoadingContainer>
        <FastQuoteLoadingPopUpContainer>
                <FastQuoteLoadingText>Attendi, stiamo cercando i dati...</FastQuoteLoadingText>
                <div className="counter">
                    {counter}
                </div>
        </FastQuoteLoadingPopUpContainer>
    </FastQuoteLoadingContainer>
  )
}