import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  quotations: {
    loading: true,
    quotes: [],
    error: null,
  },
  single_quote: {
    loading: true,
    quote: [],
    error: null,
  },
  insurances: {
    loading: true,
    insurances: [],
    error: null,
  },
};

export const quotationsSlice = createSlice({
  name: 'quotations',
  initialState,
  reducers: {
    customerQuotesPending: (state) => {
      state.quotations.loading = true;
      state.quotations.error = null;
    },
    customerQuotesRejected: (state, { payload }) => {
      state.quotations.loading = false;
      state.quotations.error = payload;
    },
    customerQuotesSuccess: (state, { payload }) => {
      state.quotations.loading = false;
      state.quotations.quotes = payload;
    },
    singleCustomerQuotePending: (state) => {
      state.single_quote.loading = true;
      state.single_quote.error = null;
    },
    singleCustomerQuoteRejected: (state, { payload }) => {
      state.single_quote.loading = false;
      state.single_quote.error = payload;
    },
    singleCustomerQuoteSuccess: (state, { payload }) => {
      state.single_quote.loading = false;
      state.single_quote.quote = payload;
    },

    insurancesPending: (state) => {
      state.insurances.loading = true;
      state.insurances.error = null;
    },
    insurancesRejected: (state, { payload }) => {
      state.insurances.loading = false;
      state.insurances.error = payload;
    },
    insurancesSuccess: (state, { payload }) => {
      state.insurances.loading = false;
      state.insurances.insurances = payload;
    },
  },
});
