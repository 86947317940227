import styled from 'styled-components';
import { formColors } from '../colors/forms';

export const ButtonSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  padding-top: 44px;
  padding-bottom: 25px;
`;

export const ButtonStyles = styled.button`
  border-radius: 14px;
  background: #fff;
  padding: 20px 25px;
  border: 1px solid white;
  color: #343434;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;

  &.active {
    border-radius: 14px;
    background: ${formColors.primary};
    padding: 20px 25px;
    border: 1px solid ${formColors.primary};
    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 700;
  }
`;
