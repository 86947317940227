import styled from 'styled-components';
import { formColors } from '../../../components/colors/forms';

export const Steps = styled.div`
    display: flex;
    align-items: start;
    margin-top: 10px;
    width: 100%;
    overflow-x: auto;

    &::-webkit-scrollbar {
        display: none;
    }   
`;

export const Step = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 100px;
    width: 100%;

    .line {
        display: flex;
        align-items: center;
    }

    .thin-line {
        width: calc(100% - 20px);
        height: 3px;
        background-color: ${formColors.primaryButtonDisabled};
    }

    &.last {
    }

    &.last .thin-line {
        margin-top: 8px;
        width: 100%;
    }

    .number {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 15px;
        height: 15px;
        font-size: 10px;
        border-radius: 100%;
        color: ${formColors.primaryButtonDisabled};
        background-color: white;
        border: 2px solid ${formColors.primaryButtonDisabled};
        transition: .3s;
    }

    .text {
        margin-top: 5px;
        font-size: 10px;
        text-align: center;
        transform: translateX(50%) translateX(-11px);
        color: rgb(200,200,200);
    }

    &.completed {
        .number {
            color: white;
            background-color: ${formColors.primary};
            border: 2px solid ${formColors.primary};
        }

        .thin-line {
            background-color: ${formColors.primary};
        }

        .text {
            color: rgb(100,100,100);
        }

    
    }

    &.not-completed{
        .number {
            color: white;
            background-color: ${formColors.error};
            border: 2px solid ${formColors.error};
        }

         .thin-line {
            background-color: ${formColors.error};
        }

        .text {
            color: ${formColors.error};
        }
    }

    @media (min-width: 900px) {
        .thin-line {
            width: calc(100% - 20px);
            height: 3px;
            background-color: ${formColors.primaryButtonDisabled};
        }

        &.last .thin-line {
            margin-top: 13px;
        }

        .number {
            width: 25px;
            height: 25px;
            font-size: 15px;
            transition: .3s;
        }

        .text {
            margin-top: 5px;
            font-size: 15px;
            transform: translateX(50%) translateX(-15px);
        }
    }
`;
