import styled from 'styled-components';
import { formColors } from '../../components/colors/forms';
import { themeColors } from '../../components/colors/theme';

export const ReplacementSection = styled.div`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h2{
            color: var(--Text, #201E1E);            
            font-size: 25px;            
            font-weight: 600;
            margin-top: 50px;
        }

        p{
            color: #828282;
            font-size: 15px;
            font-weight: 400;
        }
`;

export const AccidentDocuments = styled.div`
    border: 3px solid rgba(130, 130, 130, 0.5);
    border-radius: 19px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 15px;
    width: 750px;

    @media screen and (max-width: 768px) {
        width: 375px;
    }
`;

export const AfterSaleButton = styled.button`
    display: flex;
    width: 366px;
    height: 65px;
    padding: 30px 109px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 23px;
    background:${formColors.primary};
    border: 2px solid ${formColors.primary};
    margin: 20px auto;
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 23px;
    font-weight: 700;
    transition: all 300ms linear;
    cursor: pointer;
    
    &:hover{
        background: white;
        color: ${formColors.primary};
    }
`;

export const CancellationSection = styled.div`
      display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h2{
            color: var(--Text, #201E1E);            
            font-size: 25px;            
            font-weight: 600;
            margin-top: 50px;
        }

        p{
            color: #828282;
            font-size: 15px;
            font-weight: 400;
        }
        
        `;

export const AfterSaleModificaDatiSection = styled.div`
  margin-top: 20px;
         display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h2{
            color: var(--Text, #201E1E);            
            font-size: 25px;            
            font-weight: 600;
            margin-top: 50px;
        }

        p{
            color: #828282;
            font-size: 15px;
            font-weight: 400;
        }
`;

export const AfterSaleAccidentSection = styled.div`
     display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h2{
            color: var(--Text, #201E1E);            
            font-size: 25px;            
            font-weight: 600;
            margin-top: 50px;
        }

        p{
            color: #828282;
            font-size: 15px;
            font-weight: 400;
            margin-bottom: 50px;
        }
`;

export const MapContainerStyles = styled.div`
        #map {

            height: 450px;
            width: 100%;
        }

        .pac-container {
            z-index: 10000;
        }
`;

export const MapInput = styled.input`
   padding: 15px;
    border: unset;
    width: calc(100% - 30px);
    background-color: #F6F6F6;
    outline: none;
    border-radius: 3px;
    margin-bottom: 15px;

`;

export const GetCurrentLocationButton = styled.div`
    margin: 10px;
    padding: 10px 20px;
    color: white;
    background-color: ${themeColors.primary};
    border-radius: 10px;
    max-width: 230px;
    width: 100%;
    text-align: center;
    cursor: pointer;
`;
