import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { BACKEND_BASE_URL } from '../../../../config/urls';
import {
  CardButtons, CardCompanyLogo, CardContainer, CardFlex, CardInformation, CardModal, CardModalBody, CustomTable, InformationContainer,
} from './card.styles';
import InfoIcon from '../../../../assets/icons/info.svg';

const paymentFrequencyNames: {
  [key: number]: string;
} = {
  1: 'Annuo',
  2: 'Semestrale',
};

export function AdriaticCard({ quotation, selectQuotation }: any) {
  const [openInfoModal, setOpenInfoModal] = useState(false);
  // const navigate = useNavigate();

  //   navigate(`/quotes/auto/purchase/${quoteId}`);
  // };

  return (
    <CardContainer>
      {openInfoModal === true
        && (
          <CardModal>
            <CardModalBody>
              <div className="close-button" onClick={() => setOpenInfoModal(false)}>
                <FontAwesomeIcon icon={faClose} />
              </div>

              {quotation.tax_total === null && (
                <>
                  <h3>Garanzie</h3>
                  <CustomTable>
                    <thead>
                      <tr>
                        <th>Garanzia</th>
                        <th>Massimale</th>
                        <th style={{ textAlign: 'right' }}>Prezzo</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="custom-table-row-compact">
                        <td>Responsabilità civile</td>
                        <td>6.450.000€ + 1.050.000€</td>
                        <td style={{ textAlign: 'right' }}>
                          {Number(quotation.amount).toLocaleString('it-IT', {
                            minimumFractionDigits: 2,
                          })}
                          €
                        </td>
                      </tr>
                      <tr className="custom-table-row-compact">
                        <td>
                          <b>Totale</b>
                        </td>
                        <td />
                        <td style={{ textAlign: 'right' }}>
                          <b>
                            {Number(quotation.amount).toLocaleString('it-IT', {
                              minimumFractionDigits: 2,
                            })}
                            €
                          </b>
                        </td>
                      </tr>
                    </tbody>
                  </CustomTable>
                </>
              )}

              {quotation.tax_total !== null && (
                <>
                  <h3>Garanzie</h3>
                  <CustomTable>
                    <thead>
                      <tr>
                        <th>Garanzia</th>
                        <th>Massimale</th>
                        <th style={{ textAlign: 'right' }}>Prezzo</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="custom-table-row-compact">
                        <td>Responsabilità civile</td>
                        <td>6.450.000€ + 1.050.000€</td>
                        <td style={{ textAlign: 'right' }}>
                          {Number(quotation.amount).toLocaleString('it-IT', {
                            minimumFractionDigits: 2,
                          })}
                          €
                        </td>
                      </tr>
                      <tr className="custom-table-row-compact">
                        <td>
                          <b>Totale</b>
                        </td>
                        <td />
                        <td style={{ textAlign: 'right' }}>
                          <b>
                            {Number(quotation.amount).toLocaleString('it-IT', {
                              minimumFractionDigits: 2,
                            })}
                            €
                          </b>
                        </td>
                      </tr>
                    </tbody>
                  </CustomTable>
                </>
              )}

              <div className="card-extra-information">
                <h3>Informazioni</h3>
                <p>
                  <span className="bold">Cosa è assicurato:</span>
                  {' '}
                  Danni causati a Terzi dalla
                  circolazione del veicolo assicurato in aree pubbliche Danni
                  causati a Terzi dalla circolazione del veicolo assicurato in
                  aree private, purché aperte alla pubblica circolazione
                  Responsabilità Civile dei trasportati per i danni da questi
                  involontariamente causati durante la circolazione Danni
                  causati a cose e/o persone in seguito alla circolazione del
                  veicolo Danni involontariamente cagionati ai terzi
                  nell'esecuzione di salita e discesa di persone disabili
                  Danni involontariamente cagionati ai terzi nell'esecuzione
                  delle operazioni di carico e scarico da terra al veicolo e
                  viceversa Danni causati a terzi dal traino di eventuali
                  rimorchi, purché effettuato a norma.
                </p>
                <p>
                  <span className="bold">Cosa non è assicurato:</span>
                  {' '}
                  Conducente del veicolo
                  responsabile del sinstro Veicolo assicurato In caso di
                  sinistro con responsabilità, limitatamente alle cose, sono
                  esclusi i danni arrecati: Al proprietario del veicolo,
                  l'usufruttuario, l'acquirente con patto di riservato dominio
                  e il locatario nel caso di veicolo concesso in leasing Al
                  coniuge o al convivente more uxorio All'ascendente e ai
                  discendenti, parenti o affini entro il terzo grado, se
                  conviventi ed a carico del conducente A soci a
                  responsabilità illimitata e le persone che si trovano con
                  questi in rapporti di parentela, se l'assicurato sia una
                  società.
                </p>
                <p>
                  <span className="bold">Limitazioni di copertura:</span>
                  {' '}
                  La compagnia ha diritto a
                  recuperare dall'assicurato le somme pagate ai terzi
                  danneggiati (rivalsa) principalmente nei casi seguenti:
                  CCircolazione avvenuta contro la volontà del Proprietario
                  dell'usufruttuario, dell'acquirente con patto di riservato
                  dominio o del locatario in caso di locazione finanziaria
                  Conducente non abilitato alla guida Danni ai terzi
                  trasportati se il trasporto non è effettuato a norma Veicolo
                  guidato da persona in stato di ebbrezza o sotto l'influenza
                  di sostanze stupefacenti Veicolo utilizzato come scuola
                  guida, se al fianco del conducente non vi sia una persona
                  abilitata, a svolgere le funzioni di istruttore Conducente
                  in possesso di patente idonea ma scaduta Veicolo con targa
                  prova Veicolo dato a noleggio con conducente Partecipazione
                  del veicolo a gare o competizioni sportive Veicolo con
                  revisione scaduta Danni causati da figli minori Conducente
                  non previsto dalla tipologia di guida riportata in polizza
                  Dolo del conducente
                </p>
              </div>
            </CardModalBody>
          </CardModal>
        )}
      <CardFlex>
        <CardInformation>
          <div className="price">
            <div className="title">Prezzo annuo</div>
            {quotation.amount_before_discount !== null && (
              <div className="discount">
                &euro;
                {Number(quotation.amount_before_discount).toLocaleString(
                  'it-IT',
                  { minimumFractionDigits: 2 },
                )}
              </div>
            )}
            <div className="amount">
              &euro;
              {Number(quotation.amount).toLocaleString('it-IT', {
                minimumFractionDigits: 2,
              })}
            </div>
            {quotation.payment_frequency === 2
              && (
                <>
                  <div className="title">Prezzo semestrale:</div>
                  <div className="amount">
                    &euro;
                    {Number(quotation.amount / 2).toLocaleString('it-IT', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </div>
                </>
              )}
          </div>

        </CardInformation>
        <CardCompanyLogo src={BACKEND_BASE_URL + quotation.company.logo} alt={`${quotation.company.name} Logo`} />
      </CardFlex>

      <InformationContainer>
        <div className="record">
          <div className="record-title">Compagnia:</div>
          <div className="record-value">Adriatic Assicurazioni</div>
        </div>
        <div className="record">
          <div className="record-title">Frequenza pagamento:</div>
          <div className="record-value">{paymentFrequencyNames[quotation.payment_frequency]}</div>
        </div>
        <div className="record">
          <div className="record-title">Massimale:</div>
          <div className="record-value">
            <span className="bold">6'450'000€</span>
            {' '}
            Danni a persone
            <br />
            <span className="bold">1'050'000€</span>
            {' '}
            Danni a cose
          </div>
        </div>
      </InformationContainer>
      <CardButtons>
        <div
          // onClick={() => aquista(quotation.id)}
          onClick={() => selectQuotation(quotation.id)}
          className="aquista"
        >
          Seleziona
        </div>
        <div className="side-buttons">
          <div className="info-button" onClick={() => setOpenInfoModal(true)}>
            <img src={InfoIcon} />
          </div>
        </div>
      </CardButtons>
    </CardContainer>
  );
}
