import React from 'react';
import { useLocation, useNavigate } from 'react-router';
import { ButtonSection, ButtonStyles } from './InsurancesButtons.styles';

function InsurancesButtons() {
  const navigate = useNavigate();
  const location = useLocation();

  const getActiveRoute = () => {
    const route = location.pathname;

    const routesMap: any = {
      '/insurances': 'insurances',
      '/products': 'products',
    };

    return routesMap[route] ?? 'not-defined';
  };

  const navigateToRoute = (routeName: string) => {
    const routesMap: any = {
      insurances: '/insurances',
      products: '/products',
    };

    navigate(routesMap[routeName] ?? '/');
  };

  const routeName = getActiveRoute();

  return (
    <ButtonSection>
      <ButtonStyles className={routeName === 'insurances' ? 'active' : ''} onClick={() => navigateToRoute('insurances')}>
        Tutti i Preventivi
      </ButtonStyles>
      <ButtonStyles className={routeName === 'products' ? 'active' : ''} onClick={() => navigateToRoute('products')}>
        Nuovo Preventivo
      </ButtonStyles>
    </ButtonSection>
  );
}

export default InsurancesButtons;
