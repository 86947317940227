import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useNavigate, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import Header from '../../components/utilities/Header';
import { AccidentDocuments, AfterSaleButton, ReplacementSection } from './AfterSaleReplacement.styles';
import InputTextarea from '../../components/forms/InputTextarea';
import AfterSaleUploader from '../../components/uploader/AfterSaleUploader';
import { ActionIcons, AfterSaleIcon, AfterSaleText } from './ShowAccident.styles';
import xCircle from '../../assets/images/accidents/x-circle.svg';
import { createNewReplacement, getDocumentTypes } from '../../feature/afterSales/afterSaleActions';
import PageLoader from '../../components/utilities/PageLoader';
import { generalAlertError } from '../../helpers/alerts';
import imageUploaded from '../../assets/icons/image-uploaded.svg';

interface FormData {
  note: string
  files: any | null
}

const schema = yup.object({
  files: yup.object({
    1: yup.object().required('Documento è obbligatorio'),
    2: yup.object().required('Documento è obbligatorio'),
  }).required('Documenti sono obbligatori'),
  note: yup.string().required('Il campo è obbligatorio'),
});

const asInputOptions = {
  shouldDirty: true,
  shouldTouch: true,
  shouldValidate: true,
};

function AfterSaleReplacement() {
  const { quoteId } = useParams();
  const { isSubmiting, document_types } = useSelector((store: any) => store.afterSales);
  const navigate = useNavigate();
  const [files, setFiles] = React.useState<any>({});
  useEffect(() => {
    getDocumentTypes('replacement');
  }, []);

  const {
    register,
    formState: { errors, touchedFields },
    setValue,
    watch,
    handleSubmit,
  } = useForm<any>({
    mode: 'all',
    resolver: yupResolver(schema),
    shouldFocusError: true,
    defaultValues: {
      files: null,
      note: '',
    },
  });

  const formData: FormData = watch();

  const fileInputHandler = (file: any, type_id:any) => {
    let baseURL: any = '';
    const reader = new FileReader();
    reader.readAsDataURL(file);
    const fileType = file.type;
    reader.onload = () => {
      baseURL = reader.result;
      setFiles((prev:any) => {
        setValue('files', {
          ...prev,
          [type_id]: {
            original_filename: file.name,
            content: baseURL.replace(`data:${fileType};base64,`, ''),
          },
        }, asInputOptions);

        return {
          ...prev,
          [type_id]: {
            original_filename: file.name,
            content: baseURL.replace(`data:${fileType};base64,`, ''),
          },
        };
      });
    };
  };

  const submitData = async () => {
    if (formData.files === null) {
      generalAlertError('Carica Un Documento!');
    } else {
      await createNewReplacement(quoteId!, formData, navigate);
    }
  };

  const isValid = (name: string) => {
    if (errors[name]?.message !== undefined) {
      return false;
    }
    if (touchedFields[name] === undefined) {
      return false;
    }
    return true;
  };

  if (isSubmiting) {
    return <PageLoader />;
  }

  const removeUpload = (document_id:any) => {
    const tempFiles = formData.files;
    delete tempFiles[document_id];
    setValue('files', tempFiles, asInputOptions);
    setFiles(tempFiles);
  };

  return (
    <>
      <Header headerText="Nuova" headerTextBold="Casa" />

      <ReplacementSection>
        <h2>Apri Nuovo Caso Per Sostituzione</h2>
        <p>Fill or Update your information and we'll get your housee covered in no time.</p>

        <form onSubmit={handleSubmit(submitData)}>
          <InputTextarea label="Note" error={errors.note?.message} valid={isValid('note')} className="after-sales-styles" registration={register('note')} />
          {document_types.length > 0 && document_types.map((document: any, index:number) => (
            <div key={index} style={{ margin: 10 }}>
              {!files?.[document.id] ? (
                <AfterSaleUploader note={`Upload ${document.name}`} onFileUpload={(file: any) => fileInputHandler(file, document.id)} />) : (
                  <AccidentDocuments>
                    <AfterSaleIcon>
                      <img src={imageUploaded} alt="" />
                    </AfterSaleIcon>
                    <AfterSaleText>
                      <h3>{files[document.id].original_filename}</h3>
                    </AfterSaleText>
                    <ActionIcons onClick={() => removeUpload(document.id)}>
                      <img src={xCircle} alt="" />
                    </ActionIcons>
                  </AccidentDocuments>
              )}
              <div className="general-input-error">{(!Array.isArray(errors.files)) ? errors.files?.message : errors.files?.[document.id]?.message}</div>
            </div>
          ))}

          {/* {formData.file !== null ? (

            <AccidentDocuments>
              <AfterSaleIcon>
                <img src={imageUploaded} alt="" />
              </AfterSaleIcon>
              <AfterSaleText>
                <h3>{formData.file.original_filename}</h3>
              </AfterSaleText>
              <ActionIcons onClick={() => removeUpload()}>
                <img src={xCircle} alt="" />
              </ActionIcons>
            </AccidentDocuments>
          ) : <AfterSaleUploader note="Upload Your Document" onFileUpload={(file: any) => fileInputHandler(file)} />} */}
          <AfterSaleButton type="submit">Continue</AfterSaleButton>
        </form>
      </ReplacementSection>
    </>
  );
}

export default AfterSaleReplacement;
