import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  customer_quotes: {
    loading: true,
    quotes: [],
    error: null,
  },
};

export const insurancesSlice = createSlice({
  name: 'insurances',
  initialState,
  reducers: {},
});
