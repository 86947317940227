import { useEffect, useState } from 'react';
import moment from 'moment';
import {
  Helper, HelpersContainer, Responsability, ResponsabilityContainer, ViolationsContainer, YearButton, YearsContainer,
} from './FormInputViolations.styles';
import { FormInputSelect } from './FormInputSelect';
import { FormInputText } from './FormInputText';
import { FormInputLabel } from './FormInputLabel';

const getLastYears = () => {
  const currentYear = moment().year();
  const years: number[] = [];
  for (let i = currentYear - 10; i <= currentYear; i++) {
    years.push(i);
  }

  return years;
};

export const calculateEmptyViolationsData = () => {
  const years = getLastYears();

  const violationsData: any = {
    fullTotal: [],
    fullThings: [],
    fullPersons: [],
    fullMixed: [],
    partialTotal: [],
    partialThings: [],
    partialPersons: [],
    partialMixed: [],
  };

  Object.keys(violationsData).forEach((key) => {
    violationsData[key] = years.map((year) => ({
      year: year.toString(),
      value: '0',
    }));
  });

  return violationsData;
};

const calculateViolationsNumber = (violationsData: any) => {
  let violationsNumber = 0;

  violationsData.fullTotal.forEach((v: any) => {
    if (!['NA', 'NaN', '--'].includes(v.value)) {
      violationsNumber += parseInt(v.value);
    }
  });

  violationsData.partialTotal.forEach((v: any) => {
    if (!['NA', 'NaN', '--'].includes(v.value)) {
      violationsNumber += parseInt(v.value);
    }
  });

  return violationsNumber;
};

export function FormInputViolations(props: any) {
  const { value } = props;

  const [violationsData, setViolationsData] = useState(value);
  const [selectedYear, setSelectedYear] = useState(getLastYears()[0]);
  const [selectedResponsability, setSelectedResponsability] = useState<'full' | 'partial'>('full');
  const [selectedResponsabilityType, setSelectedResponsabilityType] = useState<'Things' | 'Persons' | 'Mixed'>('Things');

  useEffect(() => {
    if (JSON.stringify(violationsData) !== JSON.stringify(value)) {
      const violations_number = calculateViolationsNumber(violationsData);
      props.onChange({ data: violationsData, violations_number });
    }
  }, [violationsData]);

  useEffect(() => {
    setViolationsData(value);
  }, [value]);

  const calculateNewValue = (result: any) => result.reduce((prev: any, curr: any) => {
    if (prev === '') {
      return Number.NaN;
    }

    if (Number.isNaN(prev)) {
      return Number.NaN;
    }

    if (prev === 'NA' && curr === 'NA') {
      return 'NA';
    }

    if (curr === '--') {
      if (prev === 'NA' || prev === '--') {
        return 0;
      }

      if (Number.isInteger(prev)) {
        return prev;
      }

      const prevParsed = parseInt(prev);

      if (Number.isNaN(prevParsed) || prevParsed.toString() !== prev.toString() || prevParsed >= 100) {
        return Number.NaN;
      }

      return prevParsed;
    }

    if (curr === '') {
      return Number.NaN;
    }

    if (curr === 'NA' && prev !== '--') {
      return parseInt(prev);
    }

    if (curr === 'NA' && prev === '--') {
      return 0;
    }

    const val = parseInt(curr);

    if (Number.isNaN(val) || val.toString() !== curr || val >= 100) {
      return Number.NaN;
    }

    if (prev === 'NA' || prev === '--') {
      return val;
    }

    const prevParsed = parseInt(prev);

    if (Number.isNaN(prevParsed) || prevParsed.toString() !== prev.toString()) {
      return Number.NaN;
    }

    if (prevParsed < 0 || val < 0) {
      return Number.NaN;
    }

    if (prevParsed + val >= 100) {
      return Number.NaN;
    }

    return prevParsed + val;
  }).toString();

  const updateValidationsDataValue = (year: any, violationType: any) => (value: any) => {
    setViolationsData((prev: any) => {
      const afterChange = {
        ...prev,
        [violationType]: prev[violationType].map((v: any) => {
          if (v.year !== year) return v;

          return {
            ...v,
            value,
          };
        }),
      };

      if (['fullThings', 'fullPersons', 'fullMixed'].includes(violationType)) {
        afterChange.fullTotal = afterChange.fullTotal.map((v: any) => {
          if (v.year !== year) {
            return v;
          }

          const result = [
            afterChange.fullThings.find((s: any) => s.year === year).value,
            afterChange.fullPersons.find((s: any) => s.year === year).value,
            afterChange.fullMixed.find((s: any) => s.year === year).value,
          ];

          return {
            ...v,
            value: calculateNewValue(result),
          };
        });
      } else {
        afterChange.partialTotal = afterChange.partialTotal.map((v: any) => {
          if (v.year !== year) {
            return v;
          }

          const result = [
            afterChange.partialThings.find((s: any) => s.year === year).value,
            afterChange.partialPersons.find((s: any) => s.year === year).value,
            afterChange.partialMixed.find((s: any) => s.year === year).value,
          ];

          return {
            ...v,
            value: calculateNewValue(result),
          };
        });
      }

      return afterChange;
    });
  };

  const setValuesForYear = (year: any, value: any) => () => {
    setViolationsData((prev: any) => {
      const newVal = { ...prev };
      Object.keys(newVal).forEach((key) => {
        newVal[key] = newVal[key].map((v: any) => {
          if (v.year !== year) {
            return v;
          }

          return {
            ...v,
            value,
          };
        });
      });
      return newVal;
    });
  };

  const yearChange = (year: number) => {
    setSelectedYear(year);
    setSelectedResponsability('full');
  };

  const years = getLastYears();

  return (
    <ViolationsContainer>
      <FormInputLabel>Sinistri</FormInputLabel>
      <YearsContainer>
        {years.map((y: number) => <YearButton className={selectedYear === y ? 'selected' : ''} onClick={() => yearChange(y)}>{y}</YearButton>)}
      </YearsContainer>

      <HelpersContainer>
        <Helper onClick={setValuesForYear(selectedYear.toString(), '0')}>0</Helper>
        <Helper onClick={setValuesForYear(selectedYear.toString(), '--')}>--</Helper>
        <Helper onClick={setValuesForYear(selectedYear.toString(), 'NA')}>NA</Helper>
      </HelpersContainer>

      <ResponsabilityContainer>
        <Responsability
          className={`${selectedResponsability === 'full' ? 'selected' : ''} left`}
          onClick={() => setSelectedResponsability('full')}
        >
          Con responsabilita principale
        </Responsability>
        <Responsability
          className={`${selectedResponsability === 'partial' ? 'selected' : ''} right`}
          onClick={() => setSelectedResponsability('partial')}
        >
          Con responsabilita partiale
        </Responsability>
      </ResponsabilityContainer>

      <FormInputSelect
        label="Tipo di responsabilita"
        options={[
          {
            id: 'Things',
            name: 'Di cui con danni a: COSE',
          },
          {
            id: 'Persons',
            name: 'Di cui con danni a: PERSONE',
          },
          {
            id: 'Mixed',
            name: 'Di cui con danni a: MISTI',
          },
        ]}
        value={selectedResponsabilityType}
        onChange={(value: any) => setSelectedResponsabilityType(value)}
      />

      <FormInputText
        label="Valore"
        value={violationsData[`${selectedResponsability}${selectedResponsabilityType}`].find((v: any) => v.year == selectedYear)?.value}
        onChange={updateValidationsDataValue(selectedYear.toString(), `${selectedResponsability}${selectedResponsabilityType}`)}
        error={(violationsData[`${selectedResponsability}Total`].find((v: any) => v.year == selectedYear)?.value === 'NaN')
          ? 'Il valore non è valido' : ''}
      />
    </ViolationsContainer>
  );
}
