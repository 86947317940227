import {
  Bell, NotificationContainer, NotificationHeader, NotificationHeaderStyles, NotificationSection, NotificationTitle,
} from './Notifications.styles';
import notificationIconActive from '../../assets/icons/notification-active.svg';

function Notifications() {
  return (
    <NotificationContainer>
      <NotificationHeader>
        <NotificationHeaderStyles>
          <Bell><img src={notificationIconActive} alt="" /></Bell>
          <NotificationTitle>Notificazioni</NotificationTitle>
        </NotificationHeaderStyles>
      </NotificationHeader>

      <NotificationSection>
        {/* <NotificationSectionStyles>
            <NotificationIcon>
                <img src={activeNotification} alt="" />
            </NotificationIcon>
            <NotificationInfo>
                <h4>Account Verification</h4>
                <p>Please verify your account to gift an package to your friend.</p>
            </NotificationInfo>
            <NotificationTime>
                <p>9:41AM</p>
            </NotificationTime>
        </NotificationSectionStyles>
        <hr />
         <NotificationSectionStyles>
            <NotificationIcon>
                <img src={notification} alt="" />
            </NotificationIcon>
            <NotificationInfo>
                <h4>Get More Data, More Fun!</h4>
                <p>Congratulations, you have been activated Internet Extra Combo with 30Gb. Valid until March 29, 2023 at 8:21 Pm.</p>
            </NotificationInfo>
            <NotificationTime>
                <p>9:41AM</p>
            </NotificationTime>
        </NotificationSectionStyles>
        <hr />
         <NotificationSectionStyles>
            <NotificationIcon>
                <img src={notification} alt="" />
            </NotificationIcon>
            <NotificationInfo>
                <h4>Account Verification</h4>
                <p>Please verify your account to gift an package to your friend.</p>
            </NotificationInfo>
            <NotificationTime>
                <p>9:41AM</p>
            </NotificationTime>
        </NotificationSectionStyles>
        <hr />
         <NotificationSectionStyles>
            <NotificationIcon>
                <img src={notification} alt="" />
            </NotificationIcon>
            <NotificationInfo>
                <h4>Account Verification</h4>
                <p>Please verify your account to gift an package to your friend.</p>
            </NotificationInfo>
            <NotificationTime>
                <p>9:41AM</p>
            </NotificationTime>
        </NotificationSectionStyles>
        <hr />
         <NotificationSectionStyles>
            <NotificationIcon>
                <img src={notification} alt="" />
            </NotificationIcon>
            <NotificationInfo>
                <h4>Account Verification</h4>
                <p>Please verify your account to gift an package to your friend.</p>
            </NotificationInfo>
            <NotificationTime>
                <p>9:41AM</p>
            </NotificationTime>
        </NotificationSectionStyles>
        <hr />
         <NotificationSectionStyles>
            <NotificationIcon>
                <img src={notification} alt="" />
            </NotificationIcon>
            <NotificationInfo>
                <h4>Account Verification</h4>
                <p>Please verify your account to gift an package to your friend.</p>
            </NotificationInfo>
            <NotificationTime>
                <p>9:41AM</p>
            </NotificationTime>
        </NotificationSectionStyles>
        <hr />
         <NotificationSectionStyles>
            <NotificationIcon>
                <img src={notification} alt="" />
            </NotificationIcon>
            <NotificationInfo>
                <h4>Account Verification</h4>
                <p>Please verify your account to gift an package to your friend.</p>
            </NotificationInfo>
            <NotificationTime>
                <p>9:41AM</p>
            </NotificationTime>
        </NotificationSectionStyles>
        <hr />
         <NotificationSectionStyles>
            <NotificationIcon>
                <img src={notification} alt="" />
            </NotificationIcon>
            <NotificationInfo>
                <h4>Account Verification</h4>
                <p>Please verify your account to gift an package to your friend.</p>
            </NotificationInfo>
            <NotificationTime>
                <p>9:41AM</p>
            </NotificationTime>
        </NotificationSectionStyles>
        <hr />
         <NotificationSectionStyles>
            <NotificationIcon>
                <img src={notification} alt="" />
            </NotificationIcon>
            <NotificationInfo>
                <h4>Account Verification</h4>
                <p>Please verify your account to gift an package to your friend.</p>
            </NotificationInfo>
            <NotificationTime>
                <p>9:41AM</p>
            </NotificationTime>
        </NotificationSectionStyles>
        <hr />
         <NotificationSectionStyles>
            <NotificationIcon>
                <img src={notification} alt="" />
            </NotificationIcon>
            <NotificationInfo>
                <h4>Account Verification</h4>
                <p>Please verify your account to gift an package to your friend.</p>
            </NotificationInfo>
            <NotificationTime>
                <p>9:41AM</p>
            </NotificationTime>
        </NotificationSectionStyles> */}

      </NotificationSection>
    </NotificationContainer>
  );
}

export default Notifications;
