import styled from 'styled-components';
import { formColors } from '../../../components/colors/forms';

export const OptionalDocumentModalStyles = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1999;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0,0,0,.5);

    .modal-dialog {
        width: 100%;
        max-width: 400px;
        background-color: white;
        padding: 20px;
        border-radius: 10px;
    }
    .modal-header{
        display: flex;
        justify-content: space-between;
        align-items: center;

        img{
            cursor: pointer;
        }

      }

      .modal-body{

          button{
            display: flex;
            padding: 10px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            flex-shrink: 0;
            border-radius: 10px;
            background: ${formColors.primary};
            border: 1px solid ${formColors.primary};
            font-size: 14px;
            width: 100%;
            color: #fff;
            text-align: center;
            font-family: Poppins;
            font-weight: 600;
            line-height: 16px;
            margin-top: 10px;
            cursor: pointer;
            
             &:hover{
                color: ${formColors.primary};
                border: 1px solid ${formColors.primary};
                background-color: white;
            }
            }

           
      }
    
   
`;
