import { FormInputGroup } from './FormInputGroup';
import { FormInputLabel } from './FormInputLabel';
import { FormInputTextStyled } from './FormInputText.styles';

export function FormInputText({
  label, type, placeholder, min, max, registration, valid, error, value, onChange, inputId,
}: any) {
  if (registration === undefined) {
    const handleChange = (e: any) => {
      onChange(e.target.value.trim());
    };

    return (
      <FormInputGroup>
        {label !== undefined
                    && <FormInputLabel>{label}</FormInputLabel>}
        <FormInputTextStyled placeholder={placeholder} id={inputId} className={`${error ? 'is-invalid' : ''} ${valid ? 'is-valid' : ''}`} type={type} min={min} max={max} value={value} onChange={handleChange} />
        <div className="invalid-feedback">
          {error}
        </div>
      </FormInputGroup>
    );
  }

  return (
    <FormInputGroup>
      {label !== undefined
                && <FormInputLabel>{label}</FormInputLabel>}
      <FormInputTextStyled id={inputId} placeholder={placeholder} className={`${error ? 'is-invalid' : ''} ${valid ? 'is-valid' : ''}`} type={type} min={min} max={max} {...registration} />
      <div className="invalid-feedback">
        {error}
      </div>
    </FormInputGroup>
  );
}
