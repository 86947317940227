import React, { useEffect, useState } from 'react';
import { Dashboard } from '@uppy/react';
import Uppy from '@uppy/core';
import { UppyContainer } from './AfterSaleUploader.styles';
import uploadIcon from '../../assets/icons/upload-icon.svg';

function AfterSaleUploader(props: any) {
  const {
    note, allowedFileTypes, onFileUpload, maxFile,
  } = props;

  const [uppy, setUppy] = useState<any>();

  useEffect(() => {
    const uploadConfigurationFileUpload = {

      restrictions: {
        maxFileSize: 3000000,
        maxNumberOfFiles: maxFile,
        minNumberOfFiles: 1,
        allowedFileTypes: allowedFileTypes !== undefined ? allowedFileTypes : ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf'],
      },
    };

    const uppy = new Uppy(uploadConfigurationFileUpload);

    uppy.on('file-added', (file: any) => {
      onFileUpload(file.data);
    });

    setUppy(uppy);
  }, []);

  if (uppy === undefined) {
    return <></>;
  }

  return (
    <UppyContainer icon={uploadIcon}>
      <Dashboard uppy={uppy} note={note} proudlyDisplayPoweredByUppy={false} />
    </UppyContainer>
  );
}

export default AfterSaleUploader;
