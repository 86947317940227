import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { BACKEND_BASE_URL } from '../../../../config/urls';
import {
  CardButtons, CardCompanyLogo, CardContainer, CardFlex, CardInformation, CardModal, CardModalBody, CustomTable, ExtrasSection, InformationContainer,
} from './card.styles';
import InfoIcon from '../../../../assets/icons/info.svg';
import { updateGuaranteesAutoAction } from '../../../../feature/results/resultsActions';

const coverageName: { [key: string]: string } = {
  rca: 'Responsabilità civile',
  furto_incendio: 'Furto e Incendio',
  eventi_naturali: 'Eventi Naturali',
  assistenza_stradale: 'Assistenza stradale',
  eventi_sociopolitici: 'Atti vandalici',
  tutela_legale: 'Tutela legale',
  cristalli: 'Cristalli',
  collisione: 'Kasco collisione',
  kasko: 'Kasco completa',
  infortuni_conducente: 'Infortuni conducente',
  rivalsa: 'Zero rivalsa per ebbrezza',
};

export function PrimaCard({ quotation, requestToken, selectQuotation }: any) {
  const [coverages] = useState([]);
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const [openGuaranteesSection, setOpenGuranteesSection] = useState(false);
  const [temporaryGurantees, setTemporaryGurantees] = useState<string[]>([]);
  // const navigate = useNavigate();

  const updateCoverages = (coverageSlug: string) => {
    const cov = temporaryGurantees.find((c) => c === coverageSlug);
    if (cov === undefined) {
      setTemporaryGurantees([...temporaryGurantees, coverageSlug]);
    } else {
      setTemporaryGurantees(temporaryGurantees.filter((c) => c !== coverageSlug));
    }
  };

  const includesCoverage = (key: string) => temporaryGurantees !== null && temporaryGurantees.includes(key);

  const toggleGuaranteesSection = (value: boolean) => {
    setOpenGuranteesSection(value);
    if (value) {
      setTemporaryGurantees(quotation.guarantees);
    } else {
      setTemporaryGurantees([]);
    }
  };
  const submitGurantees = () => {
    updateGuaranteesAutoAction(requestToken, temporaryGurantees, quotation.id);
    toggleGuaranteesSection(false);
  };

  const calcAmount = (quotation_data_prima_guarantees: any[], primaCoverages: string[], key: string) => {
    const prices = quotation_data_prima_guarantees.find(
      (f: any) => f.slug === key,
    ).quotation_data_prima_guarantee_prices;
    const filterPrice = prices.filter(
      (p: any) => p.required_guarantees === ''
        || p.required_guarantees.split(',').every((e: any) => primaCoverages.includes(e)),
    );
    return filterPrice.reduce((prev: any, curr: any) => (prev.discounted < curr.discounted ? prev : curr));
  };

  const coverageAmount = (
    { quotation_data_prima: { quotation_data_prima_guarantees } }: any,
    primaCoverages: string[],
    coverageSlug: string,
  ) => calcAmount(
    quotation_data_prima_guarantees,
    primaCoverages,
    coverageSlug,
  );

  const guaranteesPrima = (quote: any) => {
    const guarantees = quote.quotation_data_prima_guarantees.map((guarantee: any) => guarantee.slug).filter((g: string) => g != 'rca' && g != 'furto_incendio');
    if (quote.quotation_data_prima_guarantees.find((qg: any) => qg.slug == 'furto_incendio') != undefined) {
      guarantees.push('incendio');
      guarantees.push('furto');
    }
    return guarantees;
  };

  const coverageLimitName = (
    { quotation_data_prima: { quotation_data_prima_guarantees } }: any,
    coverageSlug: string,
  ) => quotation_data_prima_guarantees.find((s: any) => s.slug === coverageSlug).limits_name;

  const convertToPrimaCoverages = (cvs: string[]) => {
    const cov = ['rca', ...cvs].filter(
      (c) => !['furto', 'incendio'].includes(c),
    );
    if (cvs.includes('furto') || cvs.includes('incendio')) {
      cov.push('furto_incendio');
    }
    return cov;
  };

  const primaPrices: { [key: string]: number } = {};
  let primaAllowedCoverages: string[] = []; // to know what coverages prima have

  quotation.quotation_data_prima.quotation_data_prima_guarantees.forEach((guarantee: any) => {
    primaAllowedCoverages.push(guarantee.slug);
    guarantee.quotation_data_prima_guarantee_prices.forEach((price: any) => {
      if (price.required_guarantees !== '') {
        const g = price.required_guarantees.split(',');
        primaAllowedCoverages = [...primaAllowedCoverages, ...g];
      }
    });
    primaPrices[guarantee.slug] = guarantee.quotation_data_prima_guarantee_prices;
  });

  primaAllowedCoverages = guaranteesPrima(quotation.quotation_data_prima);
  const convertedPrimaAllowedCoverages = convertToPrimaCoverages(
    primaAllowedCoverages,
  );

  const calculatedPrices = convertedPrimaAllowedCoverages
    .filter((adr) => {
      if (adr === 'rca') return true;

      for (let i = 0; i < coverages.length; i++) {
        if (adr === coverages[i]) return true;
      }
      return false;
    })
    .map((guarantee) => {
      const price = coverageAmount(quotation, coverages, guarantee);

      return {
        name: coverageName[guarantee],
        limits_name: coverageLimitName(quotation, guarantee),
        discounted: price.discounted,
        discounted_to_display: price.discounted_to_display,
      };
    });

  const totalDiscountedToDisplay = calculatedPrices.reduce(
    (prev, curr) => prev + curr.discounted_to_display,
    0,
  );

  let totalPriceWithGuarantees = 0;

  calculatedPrices.forEach((guarantee) => {
    totalPriceWithGuarantees += guarantee.discounted_to_display;
  });

  // const aquista = () => {
  //   if (quotation.product_id == 1) {
  //     navigate(`/quotes/auto/purchase/${quotation.id}`);
  //   }
  // };

  return (
    <CardContainer>
      {openInfoModal === true
        && (
          <CardModal>
            <CardModalBody>
              <div className="close-button" onClick={() => setOpenInfoModal(false)}>
                <FontAwesomeIcon icon={faClose} />
              </div>

              <h3>Garanzie</h3>
              <CustomTable>
                <thead>
                  <tr>
                    <th>Garanzia</th>
                    <th>Massimale</th>
                    <th style={{ textAlign: 'right' }}>Prezzo</th>
                  </tr>
                </thead>
                <tbody>
                  {calculatedPrices.map((guarantee, index) => (
                    <tr key={index} className="custom-table-row-compact">
                      <td>{guarantee.name}</td>
                      <td>{guarantee.limits_name}</td>
                      <td style={{ textAlign: 'right' }}>
                        {Number(
                          guarantee.discounted_to_display,
                        ).toLocaleString('it-IT', {
                          minimumFractionDigits: 2,
                        })}
                        €
                      </td>
                    </tr>
                  ))}
                  <tr className="custom-table-row-compact">
                    <td>
                      <b>Totale</b>
                    </td>
                    <td />
                    <td style={{ textAlign: 'right' }}>
                      <b>
                        {Number(totalPriceWithGuarantees).toLocaleString(
                          'it-IT',
                          { minimumFractionDigits: 2 },
                        )}
                        €
                      </b>
                    </td>
                  </tr>
                </tbody>
              </CustomTable>

              <div className="card-extra-information">
                <h3>Informazioni</h3>
                <p>
                  <span className="bold">Cosa è assicurato:</span>
                  {' '}
                  Danni causati a Terzi dalla
                  circolazione del veicolo assicurato in aree pubbliche Danni
                  causati a Terzi dalla circolazione del veicolo assicurato in
                  aree private, purché aperte alla pubblica circolazione
                  Responsabilità Civile dei trasportati per i danni da questi
                  involontariamente causati durante la circolazione Danni
                  causati a cose e/o persone in seguito alla circolazione del
                  veicolo Danni involontariamente cagionati ai terzi
                  nell'esecuzione di salita e discesa di persone disabili
                  Danni involontariamente cagionati ai terzi nell'esecuzione
                  delle operazioni di carico e scarico da terra al veicolo e
                  viceversa Danni causati a terzi dal traino di eventuali
                  rimorchi, purché effettuato a norma.
                </p>
                <p>
                  <span className="bold">Cosa non è assicurato:</span>
                  {' '}
                  Conducente del veicolo
                  responsabile del sinstro Veicolo assicurato In caso di
                  sinistro con responsabilità, limitatamente alle cose, sono
                  esclusi i danni arrecati: Al proprietario del veicolo,
                  l'usufruttuario, l'acquirente con patto di riservato dominio
                  e il locatario nel caso di veicolo concesso in leasing Al
                  coniuge o al convivente more uxorio All'ascendente e ai
                  discendenti, parenti o affini entro il terzo grado, se
                  conviventi ed a carico del conducente A soci a
                  responsabilità illimitata e le persone che si trovano con
                  questi in rapporti di parentela, se l'assicurato sia una
                  società.
                </p>
                <p>
                  <span className="bold">Limitazioni di copertura:</span>
                  {' '}
                  La compagnia ha diritto a
                  recuperare dall'assicurato le somme pagate ai terzi
                  danneggiati (rivalsa) principalmente nei casi seguenti:
                  CCircolazione avvenuta contro la volontà del Proprietario
                  dell'usufruttuario, dell'acquirente con patto di riservato
                  dominio o del locatario in caso di locazione finanziaria
                  Conducente non abilitato alla guida Danni ai terzi
                  trasportati se il trasporto non è effettuato a norma Veicolo
                  guidato da persona in stato di ebbrezza o sotto l'influenza
                  di sostanze stupefacenti Veicolo utilizzato come scuola
                  guida, se al fianco del conducente non vi sia una persona
                  abilitata, a svolgere le funzioni di istruttore Conducente
                  in possesso di patente idonea ma scaduta Veicolo con targa
                  prova Veicolo dato a noleggio con conducente Partecipazione
                  del veicolo a gare o competizioni sportive Veicolo con
                  revisione scaduta Danni causati da figli minori Conducente
                  non previsto dalla tipologia di guida riportata in polizza
                  Dolo del conducente
                </p>
              </div>
            </CardModalBody>
          </CardModal>
        )}
      <CardFlex>
        <CardInformation>
          <div className="price">
            <div className="title">Prezzo annuo</div>
            {totalDiscountedToDisplay != quotation.amount && (
              <div className="discount">
                &euro;
                {Number(totalDiscountedToDisplay).toLocaleString(
                  'it-IT',
                  { minimumFractionDigits: 2 },
                )}
              </div>
            )}
            <div className="amount">
              &euro;
              {Number(quotation.amount).toLocaleString('it-IT', {
                minimumFractionDigits: 2,
              })}
            </div>
          </div>

        </CardInformation>
        <CardCompanyLogo src={BACKEND_BASE_URL + quotation.company.logo} alt={`${quotation.company.name} Logo`} />
      </CardFlex>

      <InformationContainer>
        <div className="record">
          <div className="record-title">Compagnia:</div>
          <div className="record-value">Ipitq ma</div>
        </div>
        <div className="record">
          <div className="record-title">Frequenza pagamento:</div>
          <div className="record-value">Annuo</div>
        </div>
        <div className="record">
          <div className="record-title">Massimale:</div>
          <div className="record-value">
            <span className="bold">6'450'000€</span>
            {' '}
            Danni a persone
            <br />
            <span className="bold">1'050'000€</span>
            {' '}
            Danni a cose
          </div>
        </div>
      </InformationContainer>

      <ExtrasSection>
        <div className="header">
          <div className="title">
            Aggiungi&nbsp;
            <div className="bold">garanzie</div>
          </div>
          <div className="icon" onClick={() => toggleGuaranteesSection(!openGuaranteesSection)}>
            <FontAwesomeIcon icon={openGuaranteesSection ? faMinus : faPlus} />
          </div>
        </div>

        <div className={`extras ${openGuaranteesSection ? 'show' : ''}`}>
          {primaAllowedCoverages.map((covSlug) => (
            <div key={covSlug} className={`extra ${includesCoverage(covSlug) ? 'selected' : ''}`}>
              <div className="icon" onClick={() => updateCoverages(covSlug)}>
                <FontAwesomeIcon icon={includesCoverage(covSlug) ? faMinus : faPlus} />
              </div>
              <div className="name">{coverageName[covSlug]}</div>
            </div>
          ))}

          <div className="submit-button" onClick={() => submitGurantees()}>Aggiungi</div>
        </div>
      </ExtrasSection>

      <CardButtons>
        <div
          onClick={() => selectQuotation(quotation.id)}
          className="aquista"
        >
          Seleziona
        </div>
        <div className="side-buttons">
          <div className="info-button" onClick={() => setOpenInfoModal(true)}>
            <img src={InfoIcon} />
          </div>
        </div>
      </CardButtons>
    </CardContainer>
  );
}
