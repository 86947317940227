import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  login: {
    loading: false,
    error: null,
  },
  forgotPassword: {
    loading: false,
    error: null,
  },
  resetPassword: {
    loading: false,
    error: null,
  },
  changePassword: {
    loading: false,
    error: null,
  },
  setInitialPassword: {
    loading: false,
    error: null,
  },
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginPending: (state) => {
      state.login.loading = true;
      state.login.error = null;
    },
    loginRejected: (state, { payload }) => {
      state.login.loading = false;
      state.login.error = payload;
    },
    loginSuccess: (state) => {
      state.login.loading = false;
    },
    loginReset: (state) => {
      state.login = { ...initialState.login };
    },

    forgotPasswordPending: (state) => {
      state.forgotPassword.loading = true;
      state.forgotPassword.error = null;
    },
    forgotPasswordRejected: (state, { payload }) => {
      state.forgotPassword.loading = false;
      state.forgotPassword.error = payload;
    },
    forgotPasswordFulfilled: (state) => {
      state.forgotPassword.loading = false;
    },
    forgotPasswordReset: (state) => {
      state.forgotPassword = { ...initialState.forgotPassword };
    },

    resetPasswordPending: (state) => {
      state.resetPassword.loading = true;
      state.resetPassword.error = null;
    },
    resetPasswordRejected: (state, { payload }) => {
      state.resetPassword.loading = false;
      state.resetPassword.error = payload;
    },
    resetPasswordFulfilled: (state) => {
      state.resetPassword.loading = false;
    },
    resetPasswordReset: (state) => {
      state.resetPassword = { ...initialState.resetPassword };
    },

    changePasswordPending: (state) => {
      state.changePassword.loading = true;
      state.changePassword.error = null;
    },
    changePasswordRejected: (state, { payload }) => {
      state.changePassword.loading = false;
      state.changePassword.error = payload;
    },
    changePasswordFulfilled: (state) => {
      state.changePassword.loading = false;
    },
    changePasswordReset: (state) => {
      state.changePassword = { ...initialState.changePassword };
    },

    setInitialPasswordPending: (state) => {
      state.setInitialPassword.loading = true;
      state.setInitialPassword.error = null;
    },
    setInitialPasswordRejected: (state, { payload }) => {
      state.setInitialPassword.loading = false;
      state.setInitialPassword.error = payload;
    },
    setInitialPasswordFulfilled: (state) => {
      state.setInitialPassword.loading = false;
    },
    setInitialPasswordReset: (state) => {
      state.setInitialPassword = { ...initialState.setInitialPassword };
    },
  },
});
