import { useState, useEffect } from 'react';
import 'moment/locale/it';
import { createBrowserRouter, RouterProvider, useNavigate } from 'react-router-dom';
import { Login } from './screens/Authentication/Login';
import { GlobalStyle } from './styles/global';
import { ForgotPassword } from './screens/Authentication/ForgotPassword';
import { ResetPassword } from './screens/Authentication/ResetPassword';
import Home from './screens/Home/Home';
import Base from './layout/Base';
import { FormAuto } from './screens/Forms/FormAuto';
import Products from './screens/Products/Products';
import Notifications from './screens/Notifications/Notifications';
import ShowAccident from './screens/AfterSales/ShowAccident';
import AfterSaleCategories from './screens/AfterSales/AfterSaleCategories';
import AfterSales from './screens/AfterSales/AfterSales';
import Insurnaces from './screens/Insurances/Insurnaces';
import AfterSalesClosed from './screens/AfterSales/AfterSalesClosed';
import AfterSaleReplacement from './screens/AfterSales/AfterSaleReplacement';
import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';
import AfterSaleCancellation from './screens/AfterSales/AfterSaleCancellation';
import AfterSaleModificaDati from './screens/AfterSales/AfterSaleModificaDati';
import AfterSaleAccident from './screens/AfterSales/AfterSaleAccident';
import SelectQuotation from './screens/AfterSales/SelectQuotation';
import ShowReplacement from './screens/AfterSales/ShowReplacement';
import ShowModification from './screens/AfterSales/ShowModification';
import ShowCancellation from './screens/AfterSales/ShowCancellation';
import { Profile } from './screens/Profile/Profile';
import { ChangePasswordScreen } from './screens/Security/ChangePasswordScreen';
import { SetInitialPasswordScreen } from './screens/Security/SetInitialPasswordScreen';
import { MyDocumentsScreen } from './screens/Profile/MyDocumentsScreen';
import CompletedQuotation from './screens/Quotations/CompletedQuotation';
import { ResultsAuto } from './screens/Results/ResultsAuto';
import { AddToHomeScreenButton, useAddToHomescreenPrompt } from './AddToHomeScreen';
import { EmergencyScreen } from './screens/Emergency/EmergencyScreen';
import PurchaseAutoRequest from './screens/Purchase/PurchaseAutoRequest';

function RedirectToLogin() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/auth/login');
  }, []);

  return <div />;
}

function App() {
  const [isAppInstalled, setIsAppInstalled] = useState(false);
  const { installed, promptToInstall } = useAddToHomescreenPrompt();

  const isPWAInstalled = async () => {
    const installed = window.matchMedia('(display-mode: standalone)').matches;
    setIsAppInstalled(installed);
  };

  useEffect(() => {
    isPWAInstalled();
  }, [installed]);

  const router = createBrowserRouter([
    {
      path: '/auth/login',
      element: <Login />,
    },
    {
      path: '/auth/forgot/password',
      element: <ForgotPassword />,
    },
    {
      path: '/auth/reset/password',
      element: <ResetPassword />,
    },
    {
      path: '/',
      element: <Base />,
      children: [
        {
          path: '',
          element: <Home />,
        },
        {
          path: '/insurances',
          element: <Insurnaces />,
        },
        {
          path: 'quotations/:quoteId',
          element: <CompletedQuotation />,
        },
        {
          path: '/products',
          element: <Products />,
        },
        {
          path: '/after-sales',
          element: <AfterSales />,
        },
        {
          path: '/after-sale/accidents/:accidentId',
          element: <ShowAccident />,
        },
        {
          path: '/after-sale/replacements/:replacementId',
          element: <ShowReplacement />,
        },
        {
          path: '/after-sale/modifications/:modificationId',
          element: <ShowModification />,
        },
        {
          path: '/after-sale/cancellations/:cancellationId',
          element: <ShowCancellation />,
        },
        {
          path: '/after-sales/categories',
          element: <AfterSaleCategories />,
        },
        {
          path: '/after-sales/closed',
          element: <AfterSalesClosed />,
        },
        {
          path: '/after-sales/replacement/:quoteId',
          element: <AfterSaleReplacement />,
        },
        {
          path: '/after-sales/cancellation/:quoteId',
          element: <AfterSaleCancellation />,
        },
        {
          path: '/after-sales/modifica-dati/:quoteId',
          element: <AfterSaleModificaDati />,
        },
        {
          path: '/after-sales/accident/:quoteId',
          element: <AfterSaleAccident />,
        },
        {
          path: '/after-sales/:category',
          element: <SelectQuotation />,
        },
        {
          path: '/forms/auto',
          element: <FormAuto />,
        },
        {
          path: '/results/auto/:requestToken',
          element: <ResultsAuto />,
        },
        // {
        //   path: '/quotes/auto/purchase/:quoteId',
        //   element: <PurchaseAuto />,
        // },
        {
          path: '/quotes/auto/purchase/:requestToken',
          element: <PurchaseAutoRequest />,

        },
        {
          path: '/notifications',
          element: <Notifications />,
        },
        {
          path: '/profile',
          element: <Profile />,
        },
        {
          path: '/profile/documents',
          element: <MyDocumentsScreen />,
        },
        {
          path: '/security',
          element: <ChangePasswordScreen />,
        },
        {
          path: '/security/set-initial-password',
          element: <SetInitialPasswordScreen />,
        },
        {
          path: '/emergency',
          element: <EmergencyScreen />,
        },
      ],
    },
    {
      path: '*',
      element: <RedirectToLogin />,
    },

  ]);

  return (
    <>
      <GlobalStyle />
      {!isAppInstalled
        && <AddToHomeScreenButton onClick={() => promptToInstall()}>Add to Home Screen</AddToHomeScreenButton>}
      <RouterProvider router={router} />
    </>
  );
}

export default App;
