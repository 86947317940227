import styled from 'styled-components';
import { formColors } from '../colors/forms';

export const NavigationSection = styled.div`
  position: fixed;
  bottom: 15px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;

export const NavigationContainer = styled.div`
  width: 100%;
  max-width: 400px;
  height: 70px;
  background-color: white;
  border-radius: 30px;
  box-shadow: 0px 20px 110px 0px rgba(65, 65, 65, 0.2);
  display: flex;
  justify-content: space-evenly;
`;

export const NavigationItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  cursor: pointer;

  &.primary {
    .icon {
      margin-top: -40px;
      margin-bottom: 5px;
    }
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .text {
    font-size: 10px;
    transition: 0.3s;
  }

  &:hover .text {
    color: ${formColors.primary};
  }
`;
