interface EmergencyConfig {
  ambulance: string;
  firefighter: string;
  police: string;
}

const emergency_config: { [key: string]: EmergencyConfig } = {
  development: {
    ambulance: '+111111111',
    firefighter: '+11111112',
    police: '+111111113',
  },
  staging: {
    ambulance: '+111111111',
    firefighter: '+11111112',
    police: '+111111113',
  },
  production: {
    ambulance: '+111111111',
    firefighter: '+11111112',
    police: '+111111113',
  },
};

export const getEmergencyPhoneNumbers = () => emergency_config[process.env.REACT_APP_EMERGENCY_CONFIG!];
