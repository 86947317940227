import React from 'react';
import { useNavigate } from 'react-router';
import {
  Product, ProductContainer, ProductItems, ProductSection, ProductsGroup,
} from './Products.styles';
import Header from '../../components/utilities/Header';
import InsurancesButtons from '../../components/utilities/InsurancesButtons';
import { products } from '../../utils/constants';

function Products() {
  const navigate = useNavigate();

  return (
    <>
      <Header headerText="Scegli il " headerTextBold="Prodotto" />
      <ProductContainer>
        <InsurancesButtons />
        <ProductSection>
          <h3>
            Nuovo
            <span>Preventivo</span>
          </h3>
          <ProductsGroup>
            {products.map((product, index) => (
              <Product
                key={index}
                onClick={() => {
                  product.clear();
                  navigate(product.path);
                }}
              >
                <ProductItems>
                  <img src={product.icon} alt={product.name} />
                  <h4>{product.name}</h4>
                </ProductItems>
              </Product>
            ))}
          </ProductsGroup>
        </ProductSection>
      </ProductContainer>
    </>

  );
}

export default Products;
