import styled from 'styled-components';

export const HomeStyles = styled.div``;

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 25px;
  gap: 10px;

  form{
     display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  height: 30px;
  border-radius: 15px;
  padding: 10px;
  width: 100%;
  }
`;

export const SearchInputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  background-color: #ffffff;
  height: 30px;
  border-radius: 15px;
  padding: 10px;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  width: 100%;
`;

export const SearchInput = styled.input`
  border: none;
  margin-right: 15px;
  width: 100%;

  img {
    margin-right: 15px;
    width: 30px;
  }

  ::placeholder {
    color: #343434;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: left;
  }

  &:focus,
  &:active,
  &:focus-within {
    outline: none;
    border: none;
  }
`;

export const SearchButon = styled.button`
  background-color: #343434;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  padding: 10px;
  border: #343434;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  cursor: pointer;
`;

export const ProductSliderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  overflow-x: auto;
  margin-top: 15px;
  gap: 10px;

  &::-webkit-scrollbar {
    height: 0;
  }
`;

export const ProductSliderItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  gap: 5px;
  background-color: #ffffff;
  border-radius: 12px;
  cursor: pointer;

  h4 {
    margin-top: 0;
    margin-bottom: 0;
    color: #343434;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 135%;
  }

  img {
    width: 25px;
  }

  &.selected {
    background-color: #343434;

    h4 {
      color: #ffffff;
    }
  }
`;

export const QuotationsList = styled.div`
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  flex-direction: column;
`;
