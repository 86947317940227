import styled from 'styled-components';
import { themeColors } from './colors/theme';

export const GreeniaTable = styled.div`
    width: 100%;
    overflow-x: auto;

    table {
        border-collapse: collapse;
        overflow: hidden;
    }

    th {
        padding: 5px;
        background-color: ${themeColors.primary};
        border: 1px solid ${themeColors.primary};
        color: white;
        text-align: center;
    }

    td {
        font-size: 14px;
        padding: 5px;
        text-align: center;
        border: 1px solid ${themeColors.primary};
    }

    td.title {
        text-align: left;
    }
`;
