export const StorageService = {
  getUser: () => {
    interface User {
      id: number;
      gender: 'M' | 'F' | 'G';
      name: string | null;
      surname: string | null;
      business_name: string | null;
      email: string;
    }

    const user = localStorage.getItem('user');

    if (user === null) {
      return null;
    }

    return JSON.parse(user) as User;
  },

  storeAutoFormAnswers: (answers: any) => {
    localStorage.setItem('form-auto', JSON.stringify(answers));
  },
  getAutoFormAnswers: () => {
    const answers = localStorage.getItem('form-auto');

    if (answers === null) {
      return null;
    }

    return JSON.parse(answers);
  },
  clearAutoFormAnswers: () => {
    localStorage.removeItem('form-auto');
  },
  storeAutoFormOwner: (owner: any) => {
    localStorage.setItem('form-auto-owner', JSON.stringify(owner));
  },
  getAutoFormOwner: () => {
    const owner = localStorage.getItem('form-auto-owner');

    if (owner === null) {
      return null;
    }

    return JSON.parse(owner);
  },
  clearAutoFormOwner: () => {
    localStorage.removeItem('form-auto-owner');
  },
  storeAutoFormDriver: (driver: any) => {
    localStorage.setItem('form-auto-driver', JSON.stringify(driver));
  },
  getAutoFormDriver: () => {
    const driver = localStorage.getItem('form-auto-driver');

    if (driver === null) {
      return null;
    }

    return JSON.parse(driver);
  },
  clearAutoFormDriver: () => {
    localStorage.removeItem('form-auto-driver');
  },
  storeAutoFormBersani: (bersani: any) => {
    localStorage.setItem('form-auto-bersani', JSON.stringify(bersani));
  },
  getAutoFormBersani: () => {
    const bersani = localStorage.getItem('form-auto-bersani');

    if (bersani === null) {
      return null;
    }

    return JSON.parse(bersani);
  },
  clearAutoFormBersani: () => {
    localStorage.removeItem('form-auto-bersani');
  },
};
