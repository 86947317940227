import Swal, { SweetAlertOptions } from 'sweetalert2';

import 'sweetalert2/dist/sweetalert2.min.css';

export const generalAlertSuccessToast = (message: string) => {
  Swal.fire({
    icon: 'success',
    title: 'Successo',
    toast: true,
    position: 'bottom-right',
    timer: 6000,
    timerProgressBar: true,
    showConfirmButton: false,
    text: message,
  });
};

export const generalAlertSuccess = (message: string) => {
  Swal.fire({
    icon: 'success',
    title: 'Successo',
    text: message,
  });
};

export const generalAlertError = (message: string, errorTitle?: string) => {
  Swal.fire({
    icon: 'error',
    title: errorTitle ?? 'Errore',
    text: message,
  });
};

export const confirmAlert = (message: string, action: Function) => {
  Swal.fire({
    title: 'Sei Sicuro?',
    text: message,
    type: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#DD6B55',
    confirmButtonText: 'Si, Sono Sicuro!',
    cancelButtonText: 'No, cancela!',
    closeOnConfirm: false,
    closeOnCancel: false,
  } as SweetAlertOptions).then((isConfirm) => {
    if (isConfirm.isConfirmed) {
      action();
    }
  });
};

export const confirmAlertForBackofficeBrokerQuote = (
  message: string,
  action: Function,
  navigate: Function,
  requestToken: string | null,
) => {
  Swal.fire({
    title: 'Sei sicuro di procedere?',
    text: message,
    type: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#ff0000',
    confirmButtonText: 'Si, Sono Sicuro!',
    cancelButtonText: 'No, cancela!',
    closeOnConfirm: false,
    closeOnCancel: false,
  } as any)
    .then(
      (isConfirm) => {
        if (isConfirm.isConfirmed) {
          action();
        } else if (isConfirm.isDismissed === false || isConfirm.isConfirmed === false) {
          navigate(`/results/auto/${requestToken}`);
        }
      },
    );
};
