import styled from 'styled-components';
import { formColors } from '../../components/colors/forms';

export const SingleAfterSaleTitleSection = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 30px;
    padding-top: 50px;
    padding-bottom: 25px;
    `;

export const SingleAfterSaleIcon = styled.div`

    background-color: rgba(2, 119, 0, 0.06);
    border-radius: 50%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const SingleAfterSaleInfo = styled.div`
    h2{
        color: #343434;
        font-family: Poppins;
        font-size: 26px;
        font-weight: 400;
        margin: 0;
        display: flex;
        flex-direction: column;
        }

    span{
      color: #343434;
      font-family: Poppins;
      font-size: 26px;
      font-weight: 700;
      margin-bottom: 10px;
      margin-top: -5px;
      
    }

    h4{
      color: #343434;
      font-family: Poppins;
      font-size: 12px;
      font-weight: 500;
      margin: 2px 0;

      span{
        color: #343434;
        font-family: Poppins;
        font-size: 12px;
        font-weight: 200;
        margin: 0;
        
      }
    }
`;

export const SingleAfterSaleImageSection = styled.div`
    padding: 0 5px;

    h2{
      color: #343434;
      font-family: Poppins;
      font-size: 20px;
      font-weight: 600;
      margin-top: 30px;

    }
`;

export const AfterSaleNote = styled.div`

    h3{
      color: #343434;
      font-family: Poppins;
      font-size: 20px;
      font-weight: 600;
      margin-top: 0;
      margin-bottom: 20px;
    }

    p{
      color: #828282;
      font-family: Poppins;
      font-size: 14px;
      font-weight: 400;
      background-color: #F6F6F6;
      padding: 15px;
    }

    button{
      padding: 10px 15px;
      border-radius: 10px;
      border: 1px solid ${formColors.primary};
      color: white;
      background-color: ${formColors.primary};
      cursor: pointer;
      

      &:hover{
        color: ${formColors.primary} ;
      background-color: white;
      }
    }

    form{
      width: 100%;
    }
`;

export const SingleAfterSaleDocuments = styled.div`
    border: 3px solid rgba(130, 130, 130, 0.5);
    border-radius: 19px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    margin-top: 10px;
    
`;

export const AfterSaleIcon = styled.div`
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 70px;

    img{
      /* border-radius: 10px; */
       width: 40px;
       height: 50px;
    }

    @media screen and (max-width:768px){
      img{
         width: 35px;
      }
    }
`;

export const AfterSaleText = styled.div`
   margin-right: auto;
   
    h3{
        color: #828282;
        font-family: Poppins;
        font-size: 16px;
        font-weight: 500;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    p{
        color: #00A651;
        font-family: Poppins;
        font-size: 10px;       
        font-weight: 400; 
        margin-top: 2px;
        margin-bottom: 0;
    }

    @media screen and (max-width:768px){
      h3{
         font-size: 14px;
      }
    }
`;

export const ActionIcons = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
    cursor: pointer;
`;

export const SignableDocuments = styled.div`
  h4{
        color: #343434;
        font-size: 14px;
        font-weight: 500;
    }

    h2{
        font-size: 20px;
        font-weight: 600;
        margin-top: 30px;
        margin-bottom: 20px;
    }
`;

export const SingleSignableDocument = styled.div`
    border: 3px solid rgba(130, 130, 130, 0.5);
    border-radius: 19px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    margin-top: 10px;
    position: relative;
`;

export const SignableDocumentImg = styled.div`
 display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 70px;

    img{
      /* border-radius: 10px; */
       width: 40px;
       height: 50px;
    }

    @media screen and (max-width:768px){
      img{
         width: 35px;
      }
    }
`;
export const SignableDocumentText = styled.div`
      margin-right: auto;

    h4{
        color: #828282;
        font-family: Poppins;
        font-size: 16px;
        font-weight: 500;
        margin-top: 10px;
        margin-bottom: 10px;
    }

      @media screen and (max-width:768px){
      h4{
         font-size: 14px;
      }
    }
`;

interface DocumentiSignableProps {
  background?: string
  textColor?: string
}

export const SignableDocumentBadge = styled.span<DocumentiSignableProps>`
   background-color: ${(props) => (props.background)};
        border-radius: 7px;
        padding: 5px 20px;
        color: ${(props) => (props.textColor)};
        text-align: center;
        font-size: 10px;
        font-weight: 500;
        position: absolute;
        top: 5px;
        right: 10px;
`;

export const SignableDocumentIcons = styled.div`
     display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
    cursor: pointer;
    margin-top: 20px;
`;
