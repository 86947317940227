import React from 'react';
import { AlertInfo } from './styles';
import AdriaticCardSelected from './selectedCards/AdriaticCardSelected';
import PrimaCardSelected from './selectedCards/PrimaCardSelected';
import { PreventivassCardLinearAndDallboggResult } from './selectedCards/PreventivassCardLinearAndDallboggResult';
import { PreventivassCardResult } from './selectedCards/PreventivassCardResult';

function ProcessingQuoteAuto({ quotation }: any) {
  return (
    <>
      {quotation.company_id === 1
        && (
          <div className="col-12 mb-2">
            <AdriaticCardSelected
              quotation={quotation}
            />
          </div>
        )}
      {quotation.company_id === 2
        && (
          <div className="col-12 mb-2">
            <PrimaCardSelected
              quotation={quotation}
            />
          </div>
        )}

      {(quotation.company_id === 9 || quotation.company_id === 10)
        && (
          <div className="col-12 mb-2">
            <PreventivassCardLinearAndDallboggResult
              quotation={quotation}
            />
          </div>
        )}
      {quotation.added_manually === 1
        && (
          <div className="col-12 mb-2">
            <PreventivassCardResult quotation={quotation} />
          </div>
        )}
      {quotation === null && <AlertInfo>Seleziona Quotazione</AlertInfo>}
    </>
  );
}

export default ProcessingQuoteAuto;
