/* eslint-disable @typescript-eslint/quotes */
import styled from "styled-components";
import { themeColors } from "../../../../components/colors/theme";

export const CardContainer = styled.div`
  margin: 10px 0;
  padding: 10px;
  background-color: white;
  border-radius: 15px;
`;

export const CardFlex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CardInformation = styled.div`
  max-width: 80%;

  .price {
    padding: 10px 20px;
    font-weight: 700;

    .title {
      color: black;
    }

    .discount {
      font-size: 16px;
      color: ${themeColors.error};
      text-decoration: line-through;
    }

    .amount {
      font-size: 20px;
      font-weight: 500;
      color: ${themeColors.primary};
    }
  }

  .price-result {
    padding: 5px 10px;
    font-weight: 700;

    .title-result {
      color: black;
    }

    .discount-result {
      font-size: 13px;
      color: ${themeColors.error};
      text-decoration: line-through;
    }

    .amount-result {
      font-size: 15px;
      font-weight: 500;
      color: ${themeColors.primary};
    }
  }
`;

export const InformationContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  .record {
    padding: 10px 20px;
    font-size: 14px;

    .record-title {
      color: ${themeColors.primary};
    }
  }

  .record-result {
    padding: 5px 8px;
    font-size: 12px;

    .record-title-result {
      color: ${themeColors.primary};
    }
  }
`;

export const CardCompanyLogo = styled.img`
  margin: 10px 20px;
  width: 100px;
  height: 100px;
  object-fit: contain;
`;

export const CardCompanyLogoResult = styled.img`
  margin: 10px 20px;
  width: 80px;
  height: 80px;
  object-fit: contain;
`;

export const CardButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 5px 20px;

  .aquista {
    padding: 12px 30px;
    background-color: ${themeColors.primary};
    color: white;
    border-radius: 15px;
    cursor: pointer;
  }

  .side-buttons {
    .info-button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      background-color: ${themeColors.primary};
      color: white;
      border-radius: 15px;
      cursor: pointer;
    }
  }
`;

export const CardModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 30000;
`;

export const CardModalBody = styled.div`
  position: relative;
  width: calc(100% - 40px);
  max-width: 800px;
  min-height: 300px;
  max-height: 90%;
  background-color: white;
  border-radius: 20px;
  overflow-y: auto;
  padding: 10px 20px;

  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 10px;
    font-size: 20px;
    cursor: pointer;
  }

  .card-extra-information {
    font-size: 15px;
    .bold {
      font-weight: 700;
    }
  }
`;

export const CustomTable = styled.table`
  position: relative;
  width: 100%;
  overflow: auto;
  border-collapse: collapse;

  & > thead {
    text-align: left;
    font-size: 14px;
    color: grey;
    border-bottom: 2px solid rgb(227, 235, 239);
  }

  .custom-table-row-compact {
    font-size: 14px;
    color: rgb(90, 90, 90);
    border-bottom: 1px solid rgb(220, 220, 220);
    background-color: white;
    transition: 0.3s;
  }

  .custom-table-row-compact:hover {
    background-color: rgb(240, 240, 240);
  }

  .custom-table-row-compact > td {
    padding: 5px 5px;
  }
`;

export const ExtrasSection = styled.div`
  padding: 10px;
  background-color: white;
  border-radius: 20px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .title {
    padding: 10px 20px;
    display: flex;
  }

  .bold {
    font-weight: 700;
  }
  .icon {
    padding: 10px 15px;
    cursor: pointer;
  }

  .extras {
    display: none;

    &.show {
      display: block;
    }

    .extra {
      display: flex;
      padding: 5px 20px;

      .icon {
        width: 20px;
        height: 20px;
        padding: unset;
        color: ${themeColors.primary};
        border: 2px solid ${themeColors.primary};
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
      }

      &.selected {
        .icon {
          color: ${themeColors.error};
          border: 2px solid ${themeColors.error};
        }
      }
    }

    .submit-button {
      display: inline-block;
      margin: 10px 20px;
      padding: 10px 30px;
      background-color: ${themeColors.primary};
      border-radius: 15px;
      color: white;
      font-weight: 500;
      cursor: pointer;
    }
  }
`;
