import styled from 'styled-components';
import { formColors } from '../colors/forms';

export const FormInputGroup = styled.div`
    padding: 5px;

    .invalid-feedback {
        padding: 5px;
        color: ${formColors.error};
        font-size: 13px;
    }
`;
