import styled from 'styled-components';

export const InsuranceCardStyles = styled.div`
  border-radius: 20px;
  padding: 10px 20px;
  background: #fff;
  width: calc(100% - 40px);
  cursor: pointer;
`;

export const InsuranceInfo = styled.div`
  h2 {
    margin-top: 0;
    margin-bottom: 15px;
    color: #343434;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;

    span {
      font-weight: bold;
    }
  }

  p {
    color: #343434;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 20px;
    margin-top: 0;
    margin-bottom: 0;

    span {
      font-weight: 500;
    }
  }

`;

export const InsuranceInfoContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 15px;
  padding-bottom: 10px;
`;
