import { BACKEND_BASE_URL } from '../../../../config/urls';
import {
  CardCompanyLogoResult, CardContainer, CardFlex, CardInformation, InformationContainer,
} from '../cards/card.styles';

export function PreventivassCardLinearAndDallboggResult({ quotation }: any) {
  return (
    <CardContainer>
      <CardFlex>
        <CardInformation>
          <div className="price-result">
            <div className="title-result">Prezzo annuo</div>
            <div className="amount-result">
              &euro;
              {Number(quotation.amount).toLocaleString('it-IT', {
                minimumFractionDigits: 2,
              })}
            </div>
          </div>

        </CardInformation>
        <CardCompanyLogoResult src={`${BACKEND_BASE_URL + quotation.company.logo}`} />
      </CardFlex>

      <InformationContainer>
        <div className="record-result">
          <div className="record-title-result">Compagnia:</div>
          <div className="record-value-result">{quotation.company.name}</div>
        </div>
        <div className="record-result">
          <div className="record-title-result">Frequenza pagamento:</div>
          <div className="record-value-result">Annuo</div>
        </div>
      </InformationContainer>
    </CardContainer>
  );
}
