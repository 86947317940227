import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { useNavigate } from 'react-router';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { attemptForgotPassword, resetForgotPassword } from '../../feature/auth/authActions';
import {
  Container, GeneralError, GeneralLayout, GeneralSuccess, IconFormGroup, SmallLink, SubmitButton,
} from './Authentication.styles';
import { InputText } from '../../components/forms/InputText';

const schema = yup.object({
  email: yup
    .string()
    .required('Il campo è obbligatorio')
    .email('Il campo deve essere un email'),
});

interface FormData {
  email: string;
}

export function ForgotPassword() {
  const navigate = useNavigate();
  const state = useSelector((store: any) => store.auth.forgotPassword);
  const { loading, error, success } = state;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    shouldFocusError: true,
  });

  const submitData = (data: FormData) => {
    attemptForgotPassword(data, navigate);
  };

  useEffect(() => {
    if (localStorage.getItem('user_token') !== null) {
      navigate('/');
    }
  }, [localStorage.getItem('user_token')]);

  useEffect(() => () => {
    resetForgotPassword();
  }, []);

  return (
    <GeneralLayout>
      <Container>
        <h2>Ho dimenticato la password</h2>

        <form onSubmit={handleSubmit(submitData)}>
          {error !== null
                    && <GeneralError>{error}</GeneralError>}

          {success === true
                    && <GeneralSuccess>Ti abbiamo inviato un'e-mail con il codice per reimpostare la password</GeneralSuccess>}

          <IconFormGroup>
            <div className="icon">
              <FontAwesomeIcon icon={faEnvelope} />
            </div>
            <InputText
              placeholder="Email"
              registration={register('email')}
              error={errors.email?.message}
            />
          </IconFormGroup>

          <SmallLink onClick={() => navigate('/auth/login')}>Accedi?</SmallLink>

          <SubmitButton type="submit" className={loading ? 'loading' : ''} disabled={loading}>Richiedi reimpostazione</SubmitButton>
        </form>
      </Container>
    </GeneralLayout>
  );
}
