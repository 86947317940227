import { BACKEND_BASE_URL } from '../../../../config/urls';
import {
  CardCompanyLogoResult, CardContainer, CardFlex, CardInformation, InformationContainer,
} from '../cards/card.styles';

export function PreventivassCardResult({ quotation }: any) {
  const paymentFrequencyNames: {
    [key: number]: string;
  } = {
    1: 'Annuo',
    2: 'Semestrale',
  };

  return (
    <CardContainer>
      <CardFlex>
        <CardInformation>
          <div className="price-result">
            <div className="title-result">Prezzo annuo</div>
            <div className="amount-result">
              &euro;
              {Number(quotation.amount).toLocaleString('it-IT', {
                minimumFractionDigits: 2,
              })}
            </div>
            {quotation.payment_frequency === 2
              && (
                <>
                  <div className="title-result">Prezzo semestrale:</div>
                  <div className="amount-result">
                    &euro;
                    {Number(quotation.amount / 2).toLocaleString('it-IT', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </div>
                </>
              )}
          </div>

        </CardInformation>
        <CardCompanyLogoResult src={`${BACKEND_BASE_URL}/preventivass/companies/${quotation.preventivas_company.logo_filename}`} />
      </CardFlex>

      <InformationContainer>
        <div className="record-result">
          <div className="record-title-result">Compagnia:</div>
          <div className="record-value-result">{quotation.preventivas_company.company_name}</div>
        </div>
        <div className="record-result">
          <div className="record-title-result">Frequenza pagamento:</div>
          <div className="record-value-result">{paymentFrequencyNames[quotation.payment_frequency]}</div>
        </div>
      </InformationContainer>
    </CardContainer>
  );
}
