/* eslint-disable @typescript-eslint/comma-dangle */
/* eslint-disable @typescript-eslint/quotes */
import { generalAlertError } from "../../helpers/alerts";
import { backAxe } from "../../services/axios-client/backAxeClient";
import caller from "./purchaseCaller";

export const autoUpdateState = (data: any) => {
  caller.autoUpdateState(data);
};

export const resetAutoPurchase = () => {
  caller.autoResetState();
};

export const autoGetData = async (quote_id: number | string) => {
  try {
    const response = await backAxe.get(
      `/vehicle/quotation/${quote_id}/purchase`
    );
    const {
      data: { data },
    } = response;

    caller.autoUpdateState({
      loading: false,
      insuranceRequest: data.insurance_request,
      uploadedFirstDocs: {
        id_card: data.insurance_request.documents.find(
          (d: any) => d.document_type_id === 1
        )
          ? 1
          : 0,
        vehicle_card: data.insurance_request.documents.find(
          (d: any) => d.document_type_id === 2
        )
          ? 1
          : 0,
        libretto_veicolo_bersani: data.insurance_request.documents.find(
          (d: any) => d.document_type_id === 20
        )
          ? 1
          : 0,
        stato_di_famiglia: data.insurance_request.documents.find(
          (d: any) => d.document_type_id === 21
        )
          ? 1
          : 0,
        id_card_famigliare: data.insurance_request.documents.find(
          (d: any) => d.document_type_id === 22
        )
          ? 1
          : 0,
        visura_camerale: data.insurance_request.documents.find(
          (d: any) => d.document_type_id === 24
        )
          ? 1
          : 0,
      },
      quoteStatus: data.status,
      quote: data,
    });
  } catch (error) {
    // return caller.singleCustomerQuoteRejected("Errore interno");
  }
};

export const autoSelectSignMethod = async (
  signMethod: any,
  quotationId: number | string,
  collectData: any,
  signature_mail = null
) => {
  caller.autoUpdateState({
    loading: true,
  });

  if (signMethod === 0) {
    await backAxe.post(`/quotations/${quotationId}/manually/sign/documents`);
  } else {
    await backAxe.post(`/quotations/${quotationId}/digitally/sign/documents`, {
      signature_mail,
    });
  }

  collectData();
};

export const autoResendInvalidSignedDocs = async (
  quotationId: number | string,
  collectData: any
) => {
  caller.autoUpdateState({
    loading: true,
  });
  await backAxe.post(
    `/quotations/${quotationId}/digitally/sign/documents/resend`
  );
  collectData();
};

export const confirmSelectedForBackofficeQuote = async (
  quotationId: number,
  collectData: any
) => {
  caller.autoUpdateState({
    loading: true,
  });
  const res = await backAxe.post(
    `/quotations/${quotationId}/backoffice/selected`
  );

  if (res.status === 200) {
    collectData();
  }
};

const handleInsuranceRequestsUpdateByProduct = (
  store_key: string,
  payload: any
) => {
  switch (store_key) {
    case "auto":
      caller.autoUpdateState({
        ...payload,
      });
      break;
    // case 'autocarro':
    //   caller.autocarroUpdateState({
    //     ...payload,
    //   });
    //   break;
    // case 'moto':
    //   caller.motoUpdateState({
    //     ...payload,
    //   });
    //   break;
    // case 'profession':
    //   caller.professionUpdateState({
    //     ...payload,
    //   });
    //   break;
    // case 'infortuni':
    //   caller.infodriveUpdateState({
    //     ...payload,
    //   });
    //   break;
    // case 'infodrive':
    //   caller.infodriveUpdateState({
    //     ...payload,
    //   });
    //   break;
    // case 'travel':
    //   caller.travelUpdateState({
    //     ...payload,
    //   });
    //   break;
    // case 'commercial':
    //   caller.commercialUpdateState({
    //     ...payload,
    //   });
    //   break;
    default:
      break;
  }
};

// ? TODO
const checkIfDocumentsAreUploaded = (productType: any, response: any) => {
  let uploadedDocs = {};
  if (productType === "auto") {
    uploadedDocs = {
      id_card: response.data.data.insurance_request.documents.find(
        (d: any) => d.document_type_id === 1
      )
        ? 1
        : 0,
      vehicle_card: response.data.data.insurance_request.documents.find(
        (d: any) => d.document_type_id === 2
      )
        ? 1
        : 0,
      libretto_veicolo_bersani:
        response.data.data.insurance_request.documents.find(
          (d: any) => d.document_type_id === 20
        )
          ? 1
          : 0,
      stato_di_famiglia: response.data.data.insurance_request.documents.find(
        (d: any) => d.document_type_id === 21
      )
        ? 1
        : 0,
      id_card_famigliare: response.data.data.insurance_request.documents.find(
        (d: any) => d.document_type_id === 22
      )
        ? 1
        : 0,
      visura_camerale: response.data.data.insurance_request.documents.find(
        (d: any) => d.document_type_id === 24
      )
        ? 1
        : 0,
    };
  }

  return uploadedDocs;
};

export const purchaseInsuranceRequest = async (
  request_token: string,
  store_key: string
) => {
  handleInsuranceRequestsUpdateByProduct(store_key, {
    loading: true,
  });

  try {
    const response = await backAxe.get(
      `/insurance-request/save/${request_token}`
    );

    handleInsuranceRequestsUpdateByProduct(store_key, {
      loading: false,
      rc_quotation: response.data.data.rc_quotation,
      tutela_quotation: response.data.data.tutela_quotation,
      quote: response.data.data.rc_quotation,
      insuranceRequest: response.data.data.insurance_request,
      uploadedFirstDocs: checkIfDocumentsAreUploaded(store_key, response),
      quoteStatus: response.data.data.rc_quotation.status,
      assistenzaQuote: response.data.data.assistenza_quotation,
      infortuniQuote: response.data.data.infortuni_conducente_quotation,
      rivalsaQuote: response.data.data.rivalsa_quotation,
      cristalliQuote: response.data.data.cristalli_quotation,
    });
  } catch (error) {
    generalAlertError("Errore Interno.");

    handleInsuranceRequestsUpdateByProduct(store_key, {
      loading: false,
    });
  }
};
