import { BACKEND_BASE_URL } from '../../../../config/urls';
import {
  CardButtons, CardCompanyLogo, CardContainer, CardFlex, CardInformation, InformationContainer,
} from './card.styles';

export function PreventivassCard({ quotation, selectQuotation }: any) {
  // const navigate = useNavigate();
  // const aquista = () => {
  //   navigate(`/quotes/auto/purchase/${quotation.id}`);
  // };

  const paymentFrequencyNames: {
    [key: number]: string;
  } = {
    1: 'Annuo',
    2: 'Semestrale',
  };

  return (
    <CardContainer>
      <CardFlex>
        <CardInformation>
          <div className="price">
            <div className="title">Prezzo annuo</div>
            <div className="amount">
              &euro;
              {Number(quotation.amount).toLocaleString('it-IT', {
                minimumFractionDigits: 2,
              })}
            </div>
            {quotation.payment_frequency === 2
              && (
                <>
                  <div className="title">Prezzo semestrale:</div>
                  <div className="amount">
                    &euro;
                    {Number(quotation.amount / 2).toLocaleString('it-IT', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </div>
                </>
              )}
          </div>

        </CardInformation>
        <CardCompanyLogo src={`${BACKEND_BASE_URL}/preventivass/companies/${quotation.preventivas_company.logo_filename}`} />
      </CardFlex>

      <InformationContainer>
        <div className="record">
          <div className="record-title">Compagnia:</div>
          <div className="record-value">{quotation.preventivas_company.company_name}</div>
        </div>
        <div className="record">
          <div className="record-title">Frequenza pagamento:</div>
          <div className="record-value">{paymentFrequencyNames[quotation.payment_frequency]}</div>
        </div>
      </InformationContainer>

      <CardButtons>
        <div
          onClick={() => selectQuotation(quotation.id)}
          className="aquista"
        >
          Seleziona
        </div>
      </CardButtons>
    </CardContainer>
  );
}
