/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable @typescript-eslint/indent */
/* eslint-disable @typescript-eslint/quotes */
import styled from "styled-components";
import { formColors } from "../../components/colors/forms";

export const SignMethodContainer = styled.div`
  margin-top: 50px;
  h3 {
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 0;
  }
  p {
    color: #828282;
    font-size: 15px;
    font-weight: 400;
    text-align: center;
  }
  h2 {
    font-size: 19px;
    font-weight: 400;
    margin-top: 30px;
    margin-bottom: 20px;
  }
`;

export const PurchaseAutoDocuments = styled.div`
  h4 {
    color: #343434;
    font-size: 14px;
    font-weight: 500;
  }

  h2 {
    font-size: 19px;
    font-weight: 400;
    margin-top: 30px;
    margin-bottom: 20px;
  }
`;
export const AutoDocument = styled.div`
  border: 3px solid rgba(130, 130, 130, 0.5);
  border-radius: 19px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin-top: 10px;
  position: relative;
`;

export const PurchaseAutoImg = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-left: 10px;
  align-items: center;
  width: 50px;
  height: 70px;

  img {
    border-radius: 10px;
    width: 40px;
    height: 70px;
  }
`;

export const DocumentActionIcons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  cursor: pointer;
  margin-top: 20px;
`;

export const PurchaseAutoText = styled.div`
  margin-right: auto;

  h4 {
    color: #828282;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

export const PurchaseAutoIcon = styled.div``;
interface DocumentiAutoPurchaseProps {
  background?: string;
  textColor?: string;
}

export const PurchaseAutoBadge = styled.span<DocumentiAutoPurchaseProps>`
  background-color: ${(props) => props.background};
  border-radius: 7px;
  padding: 5px 20px;
  color: ${(props) => props.textColor};
  text-align: center;
  font-size: 10px;
  font-weight: 500;
  position: absolute;
  top: 5px;
  right: 10px;
`;

export const DocumentsToUpload = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;

  @media screen and (min-width: 1023px) {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
`;

export const DocumentUploader = styled.div`
  margin-bottom: 10px;
  margin-top: 10px;
`;

export const AfterDocumentUploader = styled.div`
  border: 2px solid rgba(130, 130, 130, 0.5);
  border-radius: 19px;
  padding: 10px;
  position: relative;
  max-height: 200px;
  background-color: white;
  width: 360px;
  margin: 10px 0;

  @media (min-width: 600px) and (max-width: 1199px) {
    width: 300px;
  }
`;

export const DocumentBadge = styled.span<DocumentiAutoPurchaseProps>`
  background-color: ${(props) => props.background};
  border-radius: 7px;
  padding: 5px 20px;
  margin-bottom: 10px;
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 10px;
  color: ${(props) => props.textColor};
`;

export const DocumentUploaderText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 35px;

  p {
    margin-top: 10px;
    margin-bottom: 0;
    font-size: 13px;
    font-weight: 400;
    color: #828282;
  }

  h5 {
    margin-top: 10px;
    margin-bottom: 0;
    color: #99dbb9;
    font-size: 16px;
    font-weight: 700;
  }

  h6 {
    margin-top: 10px;
    margin-bottom: 0;
    font-size: 13px;
    font-weight: 400;
    color: red;
  }

  img {
    width: 35px;
  }
`;

export const PurchaseMethodContainer = styled.div``;
export const QuoteInfoStyles = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 22px;
  background-color: white;
  padding: 20px;
  margin-top: 20px;
`;
export const QuotationInfo = styled.div`
  h3 {
    color: #343434;
    font-size: 15px;
    line-height: 30px;
    font-weight: 700;
    margin: 0;
  }
  h4 {
    color: #00a651;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    margin: 0;
    margin-bottom: 15px;
  }
  h6 {
    margin: 5px 0;
    font-size: 14px;
    color: #343434;
    font-weight: 700;
    line-height: 20px;
  }
  h5 {
    margin-bottom: 0;
    margin-top: 15px;
  }
`;

export const QuotationImg = styled.div`
  background-color: rgba(2, 119, 0, 0.06);
  border-radius: 50%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
`;

export const PaymentMethods = styled.div`
  @media screen and (min-width: 768px) {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    gap: 20px;
  }
`;

export const CardPayment = styled.div`
  border-radius: 22px;
  background-color: white;
  padding: 20px;
  margin-top: 20px;

  h2 {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 30px;

    span {
      font-weight: 700;
    }
  }

  @media screen and (min-width: 850px) {
    width: 50%;
  }
`;

export const PaymentProofStyled = styled.div`
  h6 {
    margin-top: 0;
    margin-bottom: 5px;
  }

  div.alert {
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 5px;
    font-size: 12px;

    &.alert-error {
      background-color: ${formColors.error};
      color: ${formColors.background};
    }

    &.alert-success {
      background-color: ${formColors.success};
      color: ${formColors.background};
    }
  }
`;

export const BonificoPayment = styled.div`
  border-radius: 22px;
  background-color: white;
  padding: 20px;
  margin-top: 20px;
  gap: 20px;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  justify-content: space-between;

  h2 {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 0;

    span {
      font-weight: 700;
    }
  }
  h3 {
    margin: 5px 0;
    font-size: 16px;
    color: #343434;
    font-weight: 700;
    line-height: 20px;
  }

  @media screen and (min-width: 850px) {
    width: 50%;
  }
`;

interface CI {
  isInvalid?: any;
}

export const ErrorMessage = styled.p`
  color: rgba(240, 0, 0, 0.4);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 5px;
  margin-left: 5px;
`;

export const CardExpiry = styled.div<CI>`
  label {
    color: grey;
    font-family: Poppins;
    font-style: normal;
    line-height: normal;
    margin-bottom: 5px;
    margin-left: 5px;
  }

  input {
    padding: 12px;
    width: calc(100% - 30px);
    background-color: white;
    border: 1px solid rgb(219, 221, 230);
    border-radius: 10px;

    &:active {
      border: 2px solid rgb(153, 219, 185);
      outline: none;
      box-shadow: rgb(153, 219, 185) 1px 1px 10px;
    }

    &:focus-visible {
      border: 2px solid rgb(153, 219, 185);
      outline: none;
      box-shadow: rgb(153, 219, 185) 1px 1px 10px;
    }
    // eslint-disable-next-line no-confusing-arrow
    ${(props) =>
      (props.isInvalid === true ? "rgba(240, 0, 0, 0.4)" : "#dbdde6")};

    &:focus,
    &:active,
    &:focus-visible {
      border: 2px solid #99dbb9;
      outline: none;
      box-shadow: 1px 1px 10px #99dbb9;
    }

    ::placeholder {
      color: #dbdde6;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
    }
  }
`;

export const CardInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: stretch;
`;

export const ExpiryDate = styled.div``;

export const CvvNumber = styled.div``;

export const PaymentButton = styled.button`
  padding: 10px 20px;
  border: ${formColors.primary};
  border: 1px solid ${formColors.primary};
  border-radius: 10px;
  background: ${formColors.primary};
  color: white;
  transition: all 300ms linear;
  margin: 20px 6px;
  cursor: pointer;

  &:hover {
    color: ${formColors.primary};
    background: white;
  }
`;

export const PaymentWarning = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 20px 0;

  img {
    margin: 0 7px;
    width: 30px;
  }

  p {
    font-size: 14px;
    font-weight: 200;
    color: rgba(52, 52, 52, 0.6);
    margin: 0;
  }

  @media screen and (max-width: 768px) {
    p {
      font-size: 11px;
    }
  }
`;

export const BonificoInfo = styled.div``;

export const AttesaEmissioneStatus = styled.div`
  border-radius: 22px;
  background-color: white;
  margin-top: 20px;
  padding: 60px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 15px;

  h2 {
    color: #00a651;
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    line-height: 30px; /* 125% */
  }
  p {
    color: #343434;
    text-align: center;
    font-size: 13px;
    font-weight: 500;
    width: 80%;
    margin: 0 auto;
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    width: 50%;
    margin: 30px auto;

    p {
      width: 70%;
      margin: 0 auto;
    }
  }
  @media screen and (min-width: 1025px) {
    width: 45%;
    margin: 30px auto;

    p {
      width: 60%;
      margin: 0 auto;
    }
  }
`;

export const DigitalSignMethod = styled.div`
  /* display: flex;
    justify-content: space-between;
    align-items: center; */
  border-radius: 22px;
  background-color: white;
  padding: 30px;
  margin-top: 20px;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 10px;
    background: ${formColors.primary};
    border: 2px solid ${formColors.primary};
    margin: 0px auto;
    margin-top: 30px;
    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-size: 15px;
    font-weight: 700;
    transition: all 300ms linear;
    padding: 10px 20px;

    &:hover {
      background: white;
      color: ${formColors.primary};
    }
  }

  h3 {
    text-align: center;
    font-size: 17px;

    font-weight: 200;
    margin-bottom: 0;
  }

  @media (max-width: 768px) {
    padding: 25px;

    h3 {
      font-size: 15px;
    }
  }
`;

export const SelectEmailContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 15px;
`;

export const InputContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  p {
    color: #343434;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
  }

  @media (max-width: 768px) {
    p {
      font-size: 14px;
    }
  }
`;

export const InputCircle = styled.div`
  fill: #fff;
  border: 2px solid #99dbb9;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  position: relative;

  &.selected-input {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.selected-input::before {
    content: "";
    position: absolute;
    width: 15px;
    height: 15px;
    background-color: #99dbb9;
    border-radius: 50%;
  }

  @media (max-width: 1200px) {
    width: 20px;
    height: 20px;

    &.selected-input::before {
      width: 12px;
      height: 12px;
    }
  }
`;

export const PayByCardContainer = styled.div`
  border-radius: 30px;
  padding: 25px 20px;
  border: 2px solid #dbdde6;
  background: #fff;
  height: 100%;

  h3 {
    color: var(--common-black, #1a1921);
    font-feature-settings: "clig" off, "liga" off;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    margin-bottom: 30px;
  }

  .field-row {
    display: flex;
    flex-wrap: wrap;
    padding: 5px;
    border-bottom: 1px solid grey;
  }

  .field-row:last-child {
    border-bottom: none;
  }

  .field-name {
    flex: 1;
  }

  .invoice-totals .field-name {
    flex: 1;
    min-width: unset;
  }

  .field-value {
    flex: 1;
  }

  @media (max-width: 600px) {
    .field-row {
      flex-direction: column;
    }
  }

  .field-value.pull-right {
    text-align: right;
  }

  .payment-record {
    margin-bottom: 10px;
    padding: 10px;
    max-width: 600px;
    border: 1px solid grey;
  }
`;

export const SignatureDigitalSentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 22px;
  background-color: white;
  padding: 30px;
  margin-top: 20px;

  button {
    margin-top: 35px;
    border-radius: 15px;
    background: #ffc907;
    width: 70%;
    border: 2px solid #ffc907;
    padding: 12px 15px;
    color: #343434;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    transition: all 250ms linear;

    &:hover {
      border: 2px solid #ffc907;
      background-color: transparent;
    }
  }

  @media (max-width: 1200px) {
    button {
      width: 100%;
    }
  }
`;

export const WarningContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 20px 0;

  img {
    margin: 0 7px;
    width: 30px;
  }

  p {
    font-size: 16px;
    font-weight: 200;
    color: rgba(52, 52, 52, 0.6);
    margin: 0;
  }
`;

export const AccordionContainer = styled.div`
  margin-top: 20px;
`;

export const SingleAccordion = styled.div`
  border-radius: 10px;
  background-color: white;
  cursor: pointer;
`;

export const AccordionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  h3 {
    margin: 0;
  }
`;

export const AccordionBody = styled.div`
  border-top: 1px solid rgba(130, 130, 130, 0.5);
  padding: 30px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 30px;

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const AccordionInfo = styled.div`
  padding: 25px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  border: 1px solid rgba(130, 130, 130, 0.5);
  border-radius: 10px;

  hr {
    width: 100%;
  }

  &.first-item {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  &.second-item {
    grid-column-start: 3;
    grid-column-end: 6;
  }

  p {
    color: #343434;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 5px;
    margin-top: 5px;

    span {
      color: #343434;
      font-size: 18px;
      font-weight: 300;
    }
  }

  h3 {
    color: ${formColors.primary};
    font-size: 19px;
    font-weight: 500;
    margin-bottom: 5px;
    margin-top: 5px;

    span {
      color: #343434;
      font-size: 18px;
      font-weight: 300;
    }
  }
`;

export const OptionalDocument = styled.div`
  width: 360px;
  background-color: #fff;
  border: 2px dashed rgba(130, 130, 130, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 19px;
  padding: 10px;
  position: relative;
  height: 180px;
  margin: 10px 0;

  img {
    transform: scale(1);
  }

  button {
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 10px;
    background: ${formColors.primary};
    border: 1px solid ${formColors.primary};
    outline: none;
    box-shadow: none;
    font-size: 14px;
    width: 70%;
    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    margin-top: 10px;
    cursor: pointer;
  }
  @media (min-width: 600px) and (max-width: 1199px) {
    width: 300px;
  }

  @media (max-width: 1200px) {
    img {
      transform: scale(1);
    }

    button {
      font-size: 12px;
      line-height: 14px;
      width: 85%;
    }
  }
`;
export const BadgeOptional = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 10px;
  background: #dadada;
  color: #1a1921;
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: inline-block;
  padding: 6px 12px;

  @media (max-width: 1200px) {
    padding: 4px 8px;
    font-size: 10px;
    border-radius: 8px;
    top: 5px;
    right: 5px;
  }
`;

export const RemoveDocument = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 26px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  cursor: pointer;

  img {
    width: 25px;
  }
`;

export const PrimaRedirectAlert = styled.h3`
  background-color: ${formColors.primary};
  border: 1px solid ${formColors.primary};
  padding: 10px;
  color: white;
  border-radius: 10px;
`;

export const GoBackButton = styled.button`
  border-radius: 14px;
  background: ${formColors.primary};
  padding: 15px 20px;
  border: 1px solid ${formColors.primary};
  color: white;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
  margin-top: 40px;
  transition: all 4ms linear;

  &.active {
    border-radius: 14px;
    background: ${formColors.primary};
    padding: 20px 25px;
    border: 1px solid ${formColors.primary};
    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 700;
  }
  &:hover {
    background: white;
    color: ${formColors.primary};
  }
`;

export const ChatSupport = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const TableContainer = styled.div``;

export const Table = styled.table`
  width: auto;
  border-collapse: collapse;
`;

export const TableHeader = styled.th`
  background-color: ${formColors.primary};
  color: white;
  padding: 10px;
  text-align: left;
`;

export const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
`;

export const TableCell = styled.td`
  padding: 10px;
  border: 1px solid #dddddd;
  text-align: left;
  white-space: nowrap;
`;

export const TotalRow = styled.tr`
  background-color: ${formColors.primary};
  font-weight: bold;
`;

export const TotalCell = styled.td`
  padding: 10px;
  color: white;
  text-align: right;
  white-space: nowrap;
`;
