import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import Header from '../../components/utilities/Header';
import { QuotationsList } from '../Home/Home.styles';
import QuotationCard from '../../components/utilities/QuotationCard';
import PageLoader from '../../components/utilities/PageLoader';
import { getCustomerQuotes } from '../../feature/quotations/quotationsActions';
import { AfterSaleButton } from './AfterSaleReplacement.styles';
import { generalAlertError } from '../../helpers/alerts';

function SelectQuotation() {
  const [selectedQuote, setSelectedQuote] = useState<number | null>(null);
  const { loading, quotes } = useSelector((store: any) => store.quotations.quotations);
  const { category } = useParams();
  const navigate = useNavigate();

  const selectQuote = (quoteId: number) => {
    if (quoteId === selectedQuote) {
      setSelectedQuote(null);
    } else {
      setSelectedQuote(quoteId);
    }
  };

  const navigateToForm = () => {
    if (selectedQuote === null) {
      generalAlertError('Per Favore Seleziona Una Quotazione!');
    } else {
      navigate(`/after-sales/${category}/${selectedQuote}`);
    }
  };

  useEffect(() => {
    getCustomerQuotes();
  }, []);

  if (loading) {
    return <PageLoader />;
  }

  return (
    <>
      <Header headerText="Scegli la tua" headerTextBold="Quotazione" />
      <QuotationsList>
        {quotes.filter((obj: any) => obj.status === 7).map((quote: any, index: number) => (
          <QuotationCard quote={quote} key={index} customClass={quote.id === selectedQuote ? 'selected' : ''} onClick={() => selectQuote(quote.id)} />

        ))}
      </QuotationsList>
      <AfterSaleButton onClick={() => navigateToForm()}>Continua</AfterSaleButton>
    </>
  );
}

export default SelectQuotation;
