import { FormInputGroup } from './FormInputGroup';
import { FormInputLabel } from './FormInputLabel';
import { FormInputSelectStyled } from './FormInputSelect.styles';

interface FormInputSelectProps {
  label?: string;
  placeholder?: string;
  options: any[];
  registration?: any;
  error?: any;
  valid?: boolean;
  disabled?: boolean;
  value?: string;
  onChange?: Function;
}

export function FormInputSelect({
  label, placeholder, options, registration, error, valid, disabled, value, onChange,
}: FormInputSelectProps) {
  const handleChange = (e: any) => {
    onChange!(e.target.value);
  };

  if (registration === undefined) {
    return (
      <FormInputGroup>
        <FormInputLabel>{label}</FormInputLabel>
        <FormInputSelectStyled className={`form-control text-center fw-bold ${error ? 'is-invalid' : ''} ${valid ? 'is-valid' : ''}`} disabled={disabled ?? false} onChange={handleChange} value={value}>
          <option value="" disabled className="fw-normal">{placeholder}</option>
          {options.map((opt) => <option key={opt.id ? opt.id.toString() : opt} value={opt.id ? opt.id : opt}>{opt.id ? opt.name : opt}</option>)}
        </FormInputSelectStyled>
        <div className="invalid-feedback">
          {error}
        </div>
      </FormInputGroup>
    );
  }

  return (
    <FormInputGroup>
      <FormInputLabel>{label}</FormInputLabel>
      <FormInputSelectStyled className={`form-control text-center fw-bold ${error ? 'is-invalid' : ''} ${valid ? 'is-valid' : ''}`} disabled={disabled ?? false} {...registration}>
        <option value="" disabled className="fw-normal">{placeholder}</option>
        {options.map((opt) => <option key={opt.id ? opt.id.toString() : opt} value={opt.id ? opt.id : opt}>{opt.id ? opt.name : opt}</option>)}
      </FormInputSelectStyled>
      <div className="invalid-feedback">
        {error}
      </div>
    </FormInputGroup>
  );
}
