import React, { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useNavigate } from 'react-router';
import { Controller, useForm } from 'react-hook-form';
import moment from 'moment';
import {
  BonificoInfo, BonificoPayment, CardExpiry, CardInfo, CardPayment, ErrorMessage, PayByCardContainer, PaymentButton, PaymentMethods, PaymentProofStyled, PaymentWarning, PurchaseMethodContainer,
} from '../../screens/Purchase/PurchaseAuto.styles';
import PurchaseUploader from '../uploader/PurchaseUploader';
import warningIcon from '../../assets/icons/warning-icon.svg';
import { backAxe } from '../../services/axios-client/backAxeClient';
import PageLoader from '../utilities/PageLoader';
import PurchasePaymentVerification from './PurchasePaymentVerification';
import { generalAlertSuccess } from '../../helpers/alerts';

const schema = yup.object({
  card_number: yup
    .string()
    .required('Il campo è obbligatorio')
    .matches(/^[0-9]+$/, 'Dovrebbe contenere solo numeri')
    .min(16, 'Il campo dovrebbe contenere 16 numeri'),
  card_expiry: yup
    .string()
    .required('Il campo è obbligatorio')
    .matches(/^(0[1-9]|1[0-2])\/?([0-9]{2})$/, 'Il formato non è valido')
    .min(5, 'Deve contenere una scadenza valida nel formato specificato')
    .max(5, 'Deve contenere una scadenza valida nel formato specificato'),
  card_cvv: yup
    .string()
    .required('Il campo è obbligatorio')
    .matches(/^[0-9]+$/, 'Dovrebbe contenere solo numeri')
    .min(3, 'Dovrebbe contenere almeno 3 numeri')
    .max(4, 'Dovrebbe contenere meno di 5 numeri'),
});

// const translateError = (error: string) => {
//   const translations: { [key: string]: string } = {
//     'Invalid expiry month': 'Mese di scadenza non valido',
//     'Invalid expiry year': 'Anno di scadenza non valido',
//     'Credit card with wrong check-digit': 'Carta di credito con cvv errata',
//     'Not numeric characters in credit card number':
//       'Caratteri non numerici nel numero della carta di credito',
//   };

//   if (translations[error] !== undefined) {
//     return translations[error];
//   }

//   return error;
// };

function PurchasePaymentStatus({
  quote, rc_quotation, insuranceRequest,
}: any) {
  const navigate = useNavigate();
  const [quoteData, setQuoteData] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [paymentAuthorizationUrl, setPaymentAuthorizationUrl] = useState(null);

  const {
    register, handleSubmit, control, setValue, reset: resetCardForm, formState: { errors },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    shouldFocusError: true,
  });

  const uploadPaymentProf = (file: any) => {
    const uploadedFile = file;
    const reader = new FileReader();
    reader.readAsDataURL(uploadedFile);
    reader.onload = () => {
      const fileType = uploadedFile.type;
      backAxe.post(`/insurance-request/${insuranceRequest.id}/upload-payment-proof`, {
        document: (reader.result as string).replace(`data:${fileType};base64,`, ''),
        file_type: fileType,
      }).then(() => {
        navigate('/');
        generalAlertSuccess('Stiamo verificando la prova del pagamento, attendi nostro feedback!');
      });
    };
  };

  const collectQuoteInformation = async () => {
    setLoading(true);

    try {
      const response = await backAxe.get(`/quotations/${rc_quotation.id}/for-payment`);
      if (response.status === 200) {
        setQuoteData(response.data.data);
      }

      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const submitData = async (data: any) => {
    setLoading(true);

    try {
      const response = await backAxe.post(`/payments/insurance-request/${insuranceRequest.id}/pay`, {
        card: {
          number: data.card_number,
          exp_month: data.card_expiry.split('/')[0],
          exp_year: data.card_expiry.split('/')[1],
          cvv: data.card_cvv,
        },
      });

      if (response.status === 400) {
        setLoading(false);
      } else if (response.status === 500) {
        setLoading(false);
      } else if (response.status === 200) {
        setPaymentAuthorizationUrl(response.data.data.user_redirect);
        resetCardForm();
        collectQuoteInformation();
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const formatInput = (value: string) => value.replace(/(\d{2})(\d{0,3})/, '$1/$2');

  const handleInputChange = (e: any) => {
    const inputValue = e.target.value.replace(/\//g, '');
    const formattedValue = formatInput(inputValue);

    e.target.value = formattedValue;

    setValue('card_expiry', formattedValue);
  };

  useEffect(() => {
    collectQuoteInformation();
  }, [quote.id]);

  if (loading) {
    return <PageLoader />;
  }

  const paymentsMadeWithCard = quoteData.quotation_payments.filter((p: any) => p.status === 4).length;
  const paymentsMadeWithBonifico = rc_quotation.payment_proofs.filter((p: any) => p.payment_proof_valid === 1).length;
  const paymentMadeCount = paymentsMadeWithBonifico + paymentsMadeWithCard;

  const totalPaymentsRequired = rc_quotation.payment_frequency;

  return (
    <PurchaseMethodContainer>
      {/* <QuoteInfoStyles>
        <QuotationInfo>
          <h3>
            Prezzo annuo:
            {' '}
          </h3>
          <h4>
            &euro;
            {Number(quoteData.quotation.amount).toLocaleString('it-IT', {
              minimumFractionDigits: 2,
            })}
          </h4>
          {rc_quotation.company !== null ? (
            <h6>
              Compagnia:
              {' '}
              <span>{rc_quotation.company.name}</span>
            </h6>
          ) : (
            <h6>
              Compagnia:
              {' '}
              <span>{rc_quotation.preventivas_company.company_name}</span>
            </h6>
          )}
          <h6>
            Guida:
            {' '}
            <span>{guideType.find((v: any) => v.id === quote.insurance_request.guide_type)?.name}</span>
          </h6>
          <h6>
            Frequenza pagamento:
            {' '}
            <span>{quoteData.quotation.payment_frequency === 1 ? 'Annuo' : 'Semestrale'}</span>
          </h6>
          <h6>
            Prodotto:
            {' '}
            <span>Auto</span>
          </h6>
          <h6>
            Pagato:
            {' '}
            <span>
              &euro;
              {quoteData.quotation.amount_paid !== null ? Number(quoteData.quotation.amount_paid).toLocaleString('it-IT', {
                minimumFractionDigits: 2,
              }) : 0}
            </span>
          </h6>
          <h6>
            {quoteData.quotation.amount_paid === null ? 'Primo Pagamento' : 'Secondo Pagamento'}
            {' '}
            <span>
              &euro;
              {quoteData.quotation.amount_paid
                ? quoteData.quotation.amount.toFixed(2)
                - quoteData.quotation.amount_paid.toFixed(2)
                : quoteData.quotation.amount.toFixed(2)
                / quoteData.quotation.payment_frequency}
            </span>
            {' '}

          </h6>
        </QuotationInfo>
        <QuotationImg>
          <img src={carAccident} alt="" />
        </QuotationImg>
      </QuoteInfoStyles> */}
      <PaymentMethods>
        <CardPayment>
          <h2>
            Paga con
            {' '}
            <span>carta</span>
          </h2>
          <form onSubmit={handleSubmit(submitData)}>
            <CardExpiry isInvalid={errors.card_cvv?.message === undefined}>
              <label htmlFor="">Carta di credito o di debito</label>
              <input type="text" {...register('card_number')} placeholder="Inserisci il numero della carta" />
              <ErrorMessage>
                {errors.card_number?.message}
              </ErrorMessage>
            </CardExpiry>
            <CardInfo>
              <CardExpiry>
                <label htmlFor="card_expiry">Data di scadenza</label>
                <Controller
                  name="card_expiry"
                  control={control}
                  render={({ field }: any) => (
                    <input
                      type="text"
                      {...field}
                      placeholder="MM/AA"
                      maxLength="5"
                      onChange={handleInputChange}
                    />
                  )}
                />
                <ErrorMessage className="invalid-feedback">
                  {errors.card_expiry?.message}
                </ErrorMessage>
              </CardExpiry>
              <CardExpiry isInvalid={errors.card_cvv?.message === undefined}>
                <label htmlFor="">Codice di sicurezza (CVV)</label>
                <input type="text" {...register('card_cvv')} placeholder="CVV" />
                <ErrorMessage>
                  {errors.card_cvv?.message}
                </ErrorMessage>
              </CardExpiry>
            </CardInfo>
            <PaymentWarning>
              <img src={warningIcon} alt="" />
              <p>Se si fa clic su Invia verrà visualizzata una modalità di conferma, dopo la conferma attendere un paio di minuti per l'elaborazione. Grazie!</p>
            </PaymentWarning>
            <PaymentButton type="submit">Procedi Con Il Pagamento</PaymentButton>
          </form>
          {quoteData.quotation_payments.length !== 0
            && (
              <div className="row mt-3">
                <div className="col-12 col-xxl-12">
                  <PayByCardContainer>
                    {quoteData.quotation_payments.length !== 0 && (
                      <div>
                        <h3>Stato del pagamento</h3>
                        {quoteData.quotation_payments.map((payment: any) => (
                          <div className="payment-record" key={payment.id}>
                            <div className="field-row">
                              <div className="field-name">
                                <b>Carta</b>
                              </div>
                              <div className="field-value">
                                ************
                                {payment.last_four}
                              </div>
                            </div>
                            <div className="field-row">
                              <div className="field-name">
                                <b>Stato</b>
                              </div>
                              <div className="field-value">
                                {payment.status_name}
                              </div>
                            </div>
                            <div className="field-row">
                              <div className="field-name">
                                <b>Pagato</b>
                              </div>
                              <div className="field-value">
                                {payment.amount.toLocaleString()}
                                {' '}
                                &euro;
                              </div>
                            </div>
                            <div className="field-row">
                              <div className="field-name">
                                <b>Ora</b>
                              </div>
                              <div className="field-value">
                                {moment(payment.created_at).format(
                                  'DD.MM.YYYY hh:mm',
                                )}
                              </div>
                            </div>
                            {/* {payment.status === 1 &&
                         payment.user_redirect !== null && (
                           <div className="field-row">
                             <a target="_blank" href={payment.user_redirect}>
                               <div className="btn btn-success">
                                 Autorizza il pagamento
                               </div>
                             </a>
                           </div>
                         )} */}
                          </div>
                        ))}
                      </div>
                    )}
                  </PayByCardContainer>
                </div>
              </div>
            )}
        </CardPayment>
        <BonificoPayment>
          <h2>
            Paga con
            {' '}
            <span>bonifico</span>
          </h2>
          {rc_quotation.payment_proofs.map((pp: any, index: number) => (
            <PaymentProofStyled key={pp.id}>
              <h6>
                Pagamento:
                {' '}
                {index + 1}
                {' '}
                | Pagato:
                {' '}
                {pp.amount}
                {' '}
                &euro;
              </h6>

              {(totalPaymentsRequired === 1 || (totalPaymentsRequired === 2 && (paymentsMadeWithBonifico - paymentMadeCount + 1) === index) || (totalPaymentsRequired === 2 && paymentMadeCount === 0))
                && (
                  <>
                    {pp.payment_proof_valid === 0 && (
                      <div className="alert alert-error">
                        La prova del pagamento non e valida
                      </div>
                    )}
                    {pp.payment_proof_valid === 1 && (
                      <div className="alert alert-success">
                        La prova del pagamento e valida
                      </div>
                    )}

                    {pp.payment_proof_file !== null
                      && pp.payment_proof_valid === null && (
                        <div className="alert alert-warning">
                          Stiamo verificando la prova del pagemento
                        </div>
                    )}

                    <embed
                      type={pp.file_type}
                      style={{ marginTop: '10px', width: '100%', minHeight: '250px' }}
                      src={`data:${pp.file_type};base64,${pp.payment_proof_file}`}
                    />
                  </>
                )}

            </PaymentProofStyled>
          ))}
          {rc_quotation.payment_proofs.find((pp: any) => pp.payment_proof_valid === null) === undefined && (
            <>
              <BonificoInfo>
                <h3>
                  Iban:
                  {' '}
                  <span>IT60B0326803210052864099270</span>
                </h3>
                <h3>
                  Titolare:
                  {' '}
                  <span>Greenia srl</span>
                </h3>
                <h3>
                  Causale:
                  {' '}
                  <span>Pagamento polizza [nome + cognome]</span>
                </h3>
              </BonificoInfo>
              <PurchaseUploader note="Carica Prova Del Pagamento" onFileUpload={(file: any) => uploadPaymentProf(file)} />
            </>
          )}

        </BonificoPayment>
      </PaymentMethods>
      {paymentAuthorizationUrl && (
        <PurchasePaymentVerification paymentAuthorizationUrl={paymentAuthorizationUrl} setPaymentAuthorizationUrl={setPaymentAuthorizationUrl} />
      )}
    </PurchaseMethodContainer>
  );
}

export default PurchasePaymentStatus;
