import styled from 'styled-components';

export const ShowQuotationStyles = styled.div``;

export const LogoInfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const LogoContainer = styled.div`
  width: 100px;
  height: 100px;
  background-color: #fbfbf9;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 80px;
  }
`;

export const InfoContainer = styled.div``;

export const QuoteStatus = styled.div``;

export const Circle = styled.div``;

export const InfoList = styled.ul``;

export const InfoListItem = styled.li``;
