import React, { useEffect, useState } from 'react'
import { QuoteGenerateLoadingStyles } from './QuoteGenerateLoading.styles'

function QuoteGenerateLoading() {
  const [counter, setCounter] = useState(60)

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter])


  return (
    <QuoteGenerateLoadingStyles>
      <div className="card">
        <div>
          <h5>Greenia sta elaborando i tuoi preventivi...</h5>
          <p>
            {counter}
          </p>
        </div>
      </div>
    </QuoteGenerateLoadingStyles>
  )
}

export default QuoteGenerateLoading