import React from 'react';
import { LayoutStyles } from './Layout.styles';
import { Navigation } from '../components/Navigation/Navigation';

function Layout({ children }: any) {
  return (
    <LayoutStyles>
      {children}
      <Navigation />
    </LayoutStyles>
  );
}

export default Layout;
