import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  IconDefinition, faBan, faCarBurst, faPenToSquare, faRotateRight,
} from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router';
import Header from '../../components/utilities/Header';
import {
  AfterSaleCategoriesGroup, AfterSaleCategoriesSection, AfterSaleCategory, AfterSaleCategoryItem,
} from './AfterSaleCategories.styles';
import AfterSalesButtons from '../../components/utilities/AfterSalesButtons';

interface Categories {
  name: string
  icon: IconDefinition,
  path: string

}

function AfterSaleCategories() {
  const navigate = useNavigate();
  const categories: Categories[] = [
    {
      name: 'Denuncia Sinistro',
      icon: faCarBurst,
      path: '/after-sales/accident',
    },

    {
      name: 'Sostituzione',
      icon: faRotateRight,
      path: '/after-sales/replacement',
    },

    {
      name: 'Cessazione',
      icon: faBan,
      path: '/after-sales/cancellation',
    },
    {
      name: 'Modifica Dati',
      icon: faPenToSquare,
      path: '/after-sales/modifica-dati',
    },
  ];

  return (
    <>
      <Header headerText="Post" headerTextBold="Vendita" />

      <AfterSalesButtons />
      <AfterSaleCategoriesSection>
        <p>Please select the kind of case that you want to open based on what happened to you</p>

        <AfterSaleCategoriesGroup>
          {categories.map((category, index) => (
            <AfterSaleCategory key={index} onClick={() => navigate(category.path)}>
              <AfterSaleCategoryItem>
                <FontAwesomeIcon icon={category.icon} className="afterSaleIcon" />
                <h4>{category.name}</h4>
              </AfterSaleCategoryItem>

            </AfterSaleCategory>
          ))}

        </AfterSaleCategoriesGroup>
      </AfterSaleCategoriesSection>
    </>
  );
}

export default AfterSaleCategories;
