import styled from 'styled-components';

export const PreventiviSection = styled.div``;

export const PreventiviStyles = styled.div`
    border-radius: 22px;
    background-color: white;
    padding: 30px;
    margin-top: 30px;

    h2{
        color: #343434;
        font-size: 20px;
        font-weight: 700;
        margin-top: 0;
        margin-bottom: 25px;
    }

    h3{
        color: #343434;
        font-size: 12px;
        font-weight: 500;
        margin-bottom: 5px;
        margin-top: 5px;
        
        span{
            color: #343434;
            font-size: 12px;
            font-weight: 300;
           
        }
    }
`;

export const DocumentiPolizza = styled.div`
    border: 3px solid rgba(130, 130, 130, 0.5);
    border-radius: 19px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
  margin-top: 10px;
    position: relative;


`;

export const DocumentiPolizzaIcon = styled.div`
     
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 25px;
    height:25px;
    margin-right: 10px;
    margin-top: 5px;

    img{
    
       width:40px;
       height: 40px;
    }
      @media screen and (min-width: 500px) {
        margin-right:10px;
    }

  
`;
export const DocumentiPolizzaText = styled.div`

   margin-right: auto;
   margin-top: 5px;

  h3 {
    color: #828282;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

interface DocumentiPolizzaBadgeProps {
  background?: string
}

export const DocumentiPolizzaBadge = styled.div<DocumentiPolizzaBadgeProps>`
        background-color: ${(props) => (props.background)};
        border-radius: 7px;
        padding:3px 15px;
        position: absolute;
        top: 5px;
        right: 10px;
    
    h6{
        color: white;
        text-align: center;
        font-family: Poppins;
        font-size: 10px;
        font-weight: 500;
        margin: 0;
    }
`;

export const DownloadIcon = styled.div`
 display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  cursor: pointer;
  margin-top: 20px;

  img{
    margin-top: 5px ;
  }
`;
