import styled from 'styled-components';

export const NotificationContainer = styled.div`
   
        
    `;

export const NotificationHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
`;

export const NotificationHeaderStyles = styled.div`
    display: flex;
    justify-content: flex-start;
    gap: 15px;
    align-items: center;
   
`;

export const Bell = styled.div`
    width: 47px;
`;

export const NotificationTitle = styled.div`

    color: #343434;
       font-family: Poppins;
    font-size: 25px;
        font-weight: 400;
        
    
`;

export const ClearButton = styled.div`
    
    button{
        border: none;
        background-color: transparent;
        color: var(--Red-500---Main, #FF3939);
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        /* line-height: 120%; 20.869px */
    }
`;

export const NotificationSection = styled.div`
    padding: 40px 0;
   

    hr{
       
        border: 0.5px solid rgba(243, 243, 250, 1);
       
    }
`;

export const NotificationSectionStyles = styled.div`
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 13px;
        padding: 10px 0;
`;

export const NotificationIcon = styled.div`
    padding: 10px;
    background-color: rgba(243, 243, 250, 1);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const NotificationInfo = styled.div`
        h4{
            margin-top: 0;
            margin-bottom: 0;
            color: #343434;
            font-family: Poppins;
            font-size: 16px;
            font-weight: 600;
            line-height: 130%; /* 20.869px */
        }
        p{
            margin-top: 5px;
            margin-bottom: 0;
            color: var(--Grey-500---Main, #67697A);
            font-family: Poppins;
            font-size: 14px;
            font-weight: 400;
            line-height: 160%; /* 22.016px */
        }

        @media screen and (min-width: 768px) {
            margin-right: auto;
        }
`;

export const NotificationTime = styled.div`
        p{
             margin-top: 0;
            margin-bottom: 0;
            color: var(--Grey-500---Main, #67697A);
            text-align: right;
            font-family: Poppins;
            font-size: 14px;
            font-weight: 500;
            line-height: 130%; /* 17.888px */
        }
`;
