import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { attemptSetInititalPassword, resetSetInitialPassword } from '../../feature/auth/authActions';
import { generalAlertSuccessToast } from '../../helpers/alerts';
import { InputText } from '../../components/forms/InputText';
import {
  Container, GeneralError, GeneralLayout, IconFormGroup, SubmitButton,
} from '../Authentication/Authentication.styles';

const schema = yup.object({
  password: yup.string()
    .min(6, 'La password deve contenere almeno 6 caratteri')
    .required('Il campo è obbligatorio'),
  password_confirmation: yup.string()
    .required('Il campo è obbligatorio')
    .test({
      name: 'equalTo',
      exclusive: false,
      message: 'Le password devono essere uguali',
      params: {
        reference: yup.ref('password').path,
      },
      test(value) {
        return value === this.resolve(yup.ref('password'));
      },
    }),
});

interface FormData {
  password: string;
}

export function SetInitialPasswordScreen() {
  const navigate = useNavigate();

  const state = useSelector((store: any) => store.auth.setInitialPassword);
  const { loading, error } = state;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    shouldFocusError: true,
  });

  const submitData = (data: FormData) => {
    attemptSetInititalPassword({
      password: data.password,
    }, () => {
      generalAlertSuccessToast('La password è stata impostata');
      navigate('/');
    });
  };

  useEffect(() => () => {
    resetSetInitialPassword();
  }, []);

  return (
    <GeneralLayout>
      <Container>
        <h2>Imposta un'altra password</h2>

        <form onSubmit={handleSubmit(submitData)}>
          {error !== null
                    && <GeneralError>{error}</GeneralError>}

          <IconFormGroup>
            <div className="icon">
              <FontAwesomeIcon icon={faLock} />
            </div>
            <InputText
              type="password"
              placeholder="Password"
              registration={register('password')}
              error={errors.password?.message}
            />
          </IconFormGroup>

          <IconFormGroup>
            <div className="icon">
              <FontAwesomeIcon icon={faLock} />
            </div>
            <InputText
              type="password"
              placeholder="Confirm Password"
              registration={register('password_confirmation')}
              error={errors.password_confirmation?.message}
            />
          </IconFormGroup>

          <SubmitButton type="submit" className={loading ? 'loading' : ''} disabled={loading}>Imposta la password</SubmitButton>
        </form>
      </Container>
    </GeneralLayout>
  );
}
