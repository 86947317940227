import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { getReusableDocuments, resetReusableDocuments } from '../../feature/profile/profileActions';
import Header from '../../components/utilities/Header';
import { DocumentItem, DocumentsContainer } from './MyDocumentsScreen.styles';
import PageLoader from '../../components/utilities/PageLoader';
import PDFIcon from '../../assets/images/common/pdf.svg';
import DownloadButtonIcon from '../../assets/images/common/download-button.svg';
import { apiDownloadDocument } from '../../services/axios-client/axeCommons';

export function MyDocumentsScreen() {
  const state = useSelector((store: any) => store.profile.reusableDocuments);

  const { loading, documents } = state;

  const downloadDocument = async (documentId: number) => {
    const response = await apiDownloadDocument(documentId);
    const alink = document.createElement('a');
    alink.target = '_blank';
    alink.href = response.data.data.contents;
    alink.download = response.data.data.filename;
    alink.click();
  };

  useEffect(() => {
    getReusableDocuments();

    return () => {
      resetReusableDocuments();
    };
  }, []);

  if (loading) {
    return <PageLoader />;
  }

  return (
    <div>
      <Header headerText="I miei" headerTextBold="Documenti" />
      <DocumentsContainer>
        {documents.map((doc: any) => {
          const statuses: { [key: number]: string } = {
            0: 'Con errore',
            1: 'Valido',
          };

          const status = statuses[doc.is_uploaded_valid] ?? 'In verifica';

          const statusLabels: { [key: string]: string } = {
            'Con errore': 'danger',
            Valido: 'success',
            'In verifica': 'warning',
          };

          return (
            <DocumentItem key={doc.id}>
              <div className="icon">
                <img src={PDFIcon} />
              </div>
              <div className="info">
                <div className="document-type">{doc.document_type_name}</div>
                <div className="created-at">
                  <b>Caricato:</b>
                  <br />
                  {moment(doc.created_at).format('LLL')}
                </div>
              </div>
              <div className="left-section">
                <div className={`status ${statusLabels[status]}`}>
                  {status}
                </div>
                <div className="actions">
                  <div className="action" onClick={() => downloadDocument(doc.id)}>
                    <img src={DownloadButtonIcon} />
                  </div>
                </div>
              </div>
            </DocumentItem>
          );
        })}
      </DocumentsContainer>
    </div>
  );
}
