import React from 'react';
import {
  CardCompanyLogoResult, CardContainer, CardFlex, CardInformation, InformationContainer,
} from '../cards/card.styles';
import { BACKEND_BASE_URL } from '../../../../config/urls';

const paymentFrequencyNames: {
  [key: number]: string;
} = {
  1: 'Annuo',
  2: 'Semestrale',
};

function AdriaticCardSelected({ quotation }: any) {
  return (
    <CardContainer>
      <CardFlex>
        <CardInformation>
          <div className="price-result">
            <div className="title-result">Prezzo annuo</div>
            {quotation.amount_before_discount !== null && (
              <div className="discount-result">
                &euro;
                {Number(quotation.amount_before_discount).toLocaleString(
                  'it-IT',
                  { minimumFractionDigits: 2 },
                )}
              </div>
            )}
            <div className="amount-result">
              &euro;
              {Number(quotation.amount).toLocaleString('it-IT', {
                minimumFractionDigits: 2,
              })}
            </div>
            {quotation.payment_frequency === 2
              && (
                <>
                  <div className="title-result">Prezzo semestrale:</div>
                  <div className="amount-result">
                    &euro;
                    {Number(quotation.amount / 2).toLocaleString('it-IT', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </div>
                </>
              )}
          </div>

        </CardInformation>
        <CardCompanyLogoResult src={BACKEND_BASE_URL + quotation.company.logo} alt={`${quotation.company.name} Logo`} />
      </CardFlex>

      <InformationContainer>
        <div className="record-result">
          <div className="record-title-result">Compagnia:</div>
          <div className="record-value-result">Adriatic Assicurazioni</div>
        </div>
        <div className="record-result">
          <div className="record-title-result">Frequenza pagamento:</div>
          <div className="record-value-result">{paymentFrequencyNames[quotation.payment_frequency]}</div>
        </div>
        <div className="record-result">
          <div className="record-title-result">Massimale:</div>
          <div className="record-value-result">
            <span className="bold">6'450'000€</span>
            {' '}
            Danni a persone
            <br />
            <span className="bold">1'050'000€</span>
            {' '}
            Danni a cose
          </div>
        </div>
      </InformationContainer>
    </CardContainer>
  );
}

export default AdriaticCardSelected;
