import styled from 'styled-components';
import { formColors } from '../colors/forms';

export const HeaderStyles = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
`;

export const HeaderTitleStyles = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
`;

export const ProfileBadge = styled.div`
  width: 60px;
  height: 60px;
  background-color: ${formColors.primary};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${formColors.background};
  font-weight: bold;
  font-size: 20px;
`;

export const HeaderTitle = styled.h2`
  font-size: 25px;
  margin: 0;
  font-weight: 400;

  span {
    font-weight: 700;
  }

  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

export const NotificationBell = styled.div`
  position: abosolute;
  width: 30px;
  height: 30px;
  cursor: pointer;
  right: calc(1200px - 25px);
  top: 30px;
  z-index: 1058;

  img {
    width: 30px;
  }
`;
