import React from 'react';
import { Loader, PageLoaderStyles } from './PageLoader.styles';

function PageLoader() {
  return (
    <PageLoaderStyles>
      <Loader />
    </PageLoaderStyles>
  );
}

export default PageLoader;
