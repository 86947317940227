import { GreeniaTable } from '../../../components/GreeniaTable.styles';
import { FormInputText } from '../../../components/main-forms/FormInputText';
import {
  ModalActionButton, ModalBackdrop, ModalBody, ModalContainer, ModalContent, ModalHeader,
} from './Modal.styles';

interface FastQuoteModalProps {
  cancelFastquote: any;
  confirmFastQuote: any;
  answers: any;
  fastQuoteVehicleInfo: any;
}

export function FastQuoteModal({
  cancelFastquote, confirmFastQuote, answers, fastQuoteVehicleInfo,
}: FastQuoteModalProps) {
  const doNothing = () => {};

  return (
    <ModalContainer>
      <ModalBackdrop onClick={cancelFastquote} />
      <ModalContent>

        <ModalHeader>
          <div className="title">Informazioni sul veicolo da Ania</div>
          <div className="actions">
            <ModalActionButton
              className="primary"
              onClick={confirmFastQuote}
            >
              Conferma
            </ModalActionButton>
            <ModalActionButton
              className="secondary"
              onClick={cancelFastquote}
            >
              Chiudi
            </ModalActionButton>
          </div>
        </ModalHeader>
        <ModalBody>
          {answers.renew_check === true
          && (
          <>
            {fastQuoteVehicleInfo.info.persona_giuridica === true
              && (
              <div className="row">
                <FormInputText
                  label="Nome dell'azienda"
                  value={fastQuoteVehicleInfo.info.nome_commerciale}
                  onChange={doNothing}
                />
                <FormInputText
                  label="Partita Iva"
                  value={fastQuoteVehicleInfo.info.partita_iva}
                  onChange={doNothing}
                />
              </div>
              )}

            {fastQuoteVehicleInfo.info.persona_giuridica !== true
              && (
              <div className="row">
                <FormInputText
                  label="Nome"
                  value={fastQuoteVehicleInfo.info.nome}
                  onChange={doNothing}
                />

                <FormInputText
                  label="Congnome"
                  value={fastQuoteVehicleInfo.info.cognome}
                  onChange={doNothing}
                />
              </div>
              )}
            <div className="row">
              <FormInputText
                label="Data di decorrenza"
                value={fastQuoteVehicleInfo.info.data_di_decorrenza}
                onChange={doNothing}
              />

              <FormInputText
                label="Classe di merito"
                value={fastQuoteVehicleInfo.info.classe_di_merito}
                onChange={doNothing}
              />
            </div>
            <div className="row">
              <FormInputText
                label="Comune di Residenza"
                value={fastQuoteVehicleInfo.info.comune_di_residenza}
                onChange={doNothing}
              />
              <FormInputText
                label="Provincia Residenza"
                value={fastQuoteVehicleInfo.info.provincia_residenza}
                onChange={doNothing}
              />
            </div>
          </>
          )}

          <div className="row">
            <FormInputText
              label="Marca"
              value={fastQuoteVehicleInfo.info.modello}
              onChange={doNothing}
            />
            <FormInputText
              label="Modello"
              value={fastQuoteVehicleInfo.info.marcha}
              onChange={doNothing}
            />
          </div>
          <div className="row">
            <FormInputText
              label="kW"
              value={fastQuoteVehicleInfo.info.power}
              onChange={doNothing}
            />
            <FormInputText
              label="Massa"
              value={fastQuoteVehicleInfo.info.weight}
              onChange={doNothing}
            />
          </div>
          <GreeniaTable>
            <table>
              <thead>
                <th>Tipo Sinistro</th>
                {Object.keys(fastQuoteVehicleInfo.info.sinistri[0]).filter((v) => v !== 'TIPO SINISTRO').sort().map((v) => <th>{v}</th>)}
              </thead>
              <tbody>
                {fastQuoteVehicleInfo.info.sinistri.map((s: any) => (
                  <tr className="custom-table-row">
                    <td className="title">{s['TIPO SINISTRO']}</td>
                    {Object.keys(fastQuoteVehicleInfo.info.sinistri[0]).filter((v) => v !== 'TIPO SINISTRO').sort().map((v) => <td>{s[v]}</td>)}
                  </tr>
                ))}
              </tbody>
            </table>
          </GreeniaTable>
        </ModalBody>
      </ModalContent>
    </ModalContainer>
  );
}
