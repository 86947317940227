import styled from 'styled-components';
import { formColors } from '../../../components/colors/forms';

export const FooterButtons = styled.div`
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    width: calc(100% - 20px);
    margin: auto;
    padding: 10px;
    max-width: 800px;

    .btn-questionnaire {
        outline: none;
        border: none;
        background-color: ${formColors.primary};
        color: white;
        margin: 10px;
        padding: 10px 20px;
        font-size: 20px;
        border-radius: 15px;
        cursor: pointer;
    }
`;
