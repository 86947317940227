import CodiceFiscale from 'codice-fiscale-js';

const birthplaceMapping: { [key: string]: string } = {
  'CONGO EX ZAIRE': 'CONGO LEOPOLDVILLE',
};

export default function generateFiscalCode({
  name, surname, gender, day, month, year, birthplace, birthplaceProvincia,
}: any) {
  const finalBirthPlace = birthplaceMapping[birthplace] ?? birthplace;

  const cf = new CodiceFiscale({
    name, surname, gender, day, month, year, birthplace: finalBirthPlace, birthplaceProvincia,
  });
  return cf.cf;
}

export const decodeFiscalCode = (fiscalCode: string) => (new CodiceFiscale(fiscalCode)).toJSON();
