import styled from 'styled-components';
import { formColors } from '../../components/colors/forms';

export const AccidentButtonSection = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    padding-top: 44px;
    padding-bottom: 25px;
`;

export const ButtonStyles = styled.button`
       border-radius: 14px;
        background: #FFF;
        padding: 20px 25px;
        border: 1px solid white;
        color: #343434;
        text-align: center;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        cursor: pointer;

       &.active{
        border-radius: 14px;
        background: ${formColors.primary};
        padding: 20px 25px;
        border: 1px solid ${formColors.primary};
        color: #FFF;
        text-align: center;
        font-family: Poppins;
        font-size: 14px;
        font-weight: 700;
    }
`;

export const AccidentsSection = styled.div`
    margin: 30px 10px;
    
`;

export const Status = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
    padding: 10px 5px;

    p{
        color: #343434;
        font-family: Poppins;
        font-size: 12px;
        font-weight: 600;
        margin: 0;
       
    }
  `;

export const StatusCircle = styled.div`
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${formColors.primary};
`;

export const AccidentStyles = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
     border-radius: 22px;
    background-color: white;
    padding: 20px;
    margin-top: 20px;
    cursor: pointer;
`;

export const AccidentInfo = styled.div`
        h3{
            color: #343434;
            font-family: Poppins;
            font-size: 20px;
            font-weight: 400;
            margin: 0;
           
            span{
                color: #343434;
                font-family: Poppins;
                font-size: 20px;
                font-weight: 700;
                
            }
        }

        h6{
          color: #343434;
          margin-top: 5px;
          margin-bottom: 0;
          font-family: Poppins;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 30px; /* 250% */


          span{
            color: #343434;
            font-family: Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 200;
            line-height: 30px;
          }
        }

        h4{
          color: #343434;
          font-family: Poppins;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          margin-bottom: 0;
          
        }

        span{
          color: #343434;
          
          font-family: Poppins;
          font-size: 12px;
          font-style: normal;
          font-weight: 300;
          line-height: normal;
        }
`;

export const AccidentIcon = styled.div`
    background-color: rgba(2, 119, 0, 0.06);
    border-radius: 50%;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: 70px;

    img{
      width: 50px;
    }
`;

export const AfterSaleSliderSection = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    overflow-x: auto;
    gap: 10px;

    &::-webkit-scrollbar {
        height: 0;
  }

`;

export const AfterSaleSliderItem = styled.div`
 
    padding: 15px 20px;
    gap: 5px;
    background-color: #ffffff;
    border-radius: 12px;
    cursor: pointer;

  h4 {
    margin-top: 0;
    margin-bottom: 0;
    color: #343434;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
 
    
  }

  &.selected {
    background-color: #343434;

    h4 {
      color: #ffffff;
    }
  }
`;

export const ClosedAccidentsSection = styled.div`
    margin: 30px 10px;

`;

export const StatusCircleClosed = styled.div`
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #F00;
`;
