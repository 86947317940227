import moment from 'moment';
import { InsuranceCardStyles, InsuranceInfo, InsuranceInfoContainer } from './InsuranceCard.styles';

export function InsuranceCard({ insurance, onClick }: any) {
  return (
    <InsuranceCardStyles onClick={onClick}>
      <InsuranceInfoContainer>
        <InsuranceInfo>
          <h2>
            Assicurazione
            <span>{insurance.product.name}</span>
          </h2>
          {insurance.vehicle !== null
            && (
              <p>
                Targa:
                {insurance.vehicle.vehicle_plate}
              </p>
            )}
          {insurance.policy_effective_date !== null && (
            <>
              <p>
                Inizio della copertura:
                {moment(insurance.policy_effective_date)
                  .toDate()
                  .toLocaleDateString('it-IT')}
              </p>
              <p>
                Scadenza:
                {moment(insurance.policy_effective_date)
                  .add(
                    365,
                    'days',
                  )
                  .toDate()
                  .toLocaleDateString('it-IT')}
              </p>
            </>
          )}
          <p>
            Creato a:
            {moment(insurance.created_at)
              .toDate()
              .toLocaleDateString('it-IT')}
          </p>

        </InsuranceInfo>
      </InsuranceInfoContainer>
    </InsuranceCardStyles>
  );
}
