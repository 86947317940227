import styled from 'styled-components';
import { formColors } from '../colors/forms';

export const FormGroup = styled.div`
    padding: 5px;

    .invalid-feedback {
        padding: 5px;
        color: ${formColors.error};
        font-size: 13px;
    }

    &.after-sales-styles{
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        margin-top: 10px;
    }
`;
