export const goToQuote = (
  productId: number,
  requestToken: string | null = null,
  isAquista: number | null,
  navigate: any,
) => {
  if (productId === 1) {
    if (isAquista === 0) {
      navigate(`/results/auto/${requestToken}`);
    } else if (isAquista === 1) {
      navigate(`/quotes/auto/purchase/${requestToken}`);
    }
  }
};
