import styled from 'styled-components';
import { themeColors } from '../../components/colors/theme';

export const DocumentsContainer = styled.div`
    margin-top: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
`;

export const DocumentItem = styled.div`
    margin: 5px;
    padding: 10px 30px;
    width: calc(100% - 60px);
    max-width: 400px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 2px solid lightgrey;
    border-radius: 19px;

    .info {
        padding: 10px 20px;
        width: 100%;

        .document-type {
            font-weight: 500;
        }

        .created-at {
            color: ${themeColors.primary};
            font-size: 12px;

            b {
                font-weight: 500;
            }
        }
    }

    .left-section {
        width: 150px;

        .status {
            padding: 5px 10px;
            border-radius: 8px;
            font-size: 12px;
            text-align: center;
        }

        .status.warning {
            color: rgb(255, 184, 0);
            background-color: rgba(255, 184, 0, 0.3);
        }

        .status.success {
            color: rgb(78, 209, 139);
            background-color: rgba(78, 209, 139, 0.4);
        }

        .status.danger {
            color: rgb(255, 123, 123);
            background-color: rgba(255, 123, 123, 0.4);
        }

        .actions {
            width: 100%;
            margin-top: 15px;
            display: flex;
            align-items: center;
            justify-content: center;

            .action {
                cursor: pointer;
            }
        }
    }
`;
