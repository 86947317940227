import React from 'react';
import Header from '../../components/utilities/Header';
import {
  LogoContainer, LogoInfoContainer, ShowQuotationStyles, InfoContainer, QuoteStatus, Circle, InfoList, InfoListItem,
} from './CompletedQuotation.styles';
import adriaticLogo from '../../assets/adriatic.svg';

function CompletedQuotation() {
  return (
    <>
      <Header headerText="Assicurazione" headerTextBold="Auto" />
      <ShowQuotationStyles>
        <LogoInfoContainer>
          <LogoContainer>
            <img src={adriaticLogo} alt="" />
          </LogoContainer>
          <InfoContainer>
            <QuoteStatus>
              <Circle />
              <p>Expired</p>
            </QuoteStatus>
            <InfoList>
              <InfoListItem>InsuranceId: 1232142</InfoListItem>
              <InfoListItem>InsuranceId: 1232142</InfoListItem>
              <InfoListItem>InsuranceId: 1232142</InfoListItem>
            </InfoList>
          </InfoContainer>
        </LogoInfoContainer>
        <div>
          <div>
            price
          </div>
          <div>
            Frequency
          </div>
        </div>
        <div>
          more info
        </div>
      </ShowQuotationStyles>
    </>
  );
}

export default CompletedQuotation;
