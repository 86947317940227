import styled from 'styled-components';
import { formColors } from '../colors/forms';

export const FormInputTable = styled.div`
    display: block;
    overflow-y: auto;
    height: 200px;

    table {
        border-collapse: separate;
        border-spacing: 0;
        width: 100%;
    }

    tr {
        color: grey;
        cursor: pointer;
    }

    tr:nth-child(2n) {
        background-color: rgb(250,250,250);
    }

    tr:nth-child(2n - 1) {
        background-color: rgb(240,240,240);
    }

    tr.selected {
        background-color: ${formColors.primary};
        color: white;
    }

    td {
        padding: 10px;
        font-size: 12px;
        border: 1px solid transparent;
        border-style: none solid solid none;
    }

    tr td:first-child { border-top-left-radius: 10px; }
    tr td:last-child { border-top-right-radius: 10px; }

    tr td:first-child { border-bottom-left-radius: 10px; }
    tr td:last-child { border-bottom-right-radius: 10px; }

    tr td:first-child { border-top-style: solid; }
    tr td:first-child { border-left-style: solid; }
`;
