/* eslint-disable @typescript-eslint/quotes */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  auto: {
    loading: true,
    quote: null,
    insuranceRequest: null,
    quoteStatus: 1,
    uploadingIdCard: false,
    uploadingVehicleCard: false,
    uploadingLibrettoVeicoloBersani: false,
    uploadingStatoDiFamiglia: false,
    uploadingIdCardFamigliare: false,
    uploadingVisuraCamerale: false,
    signMethod: null,
    uploadedFirstDocs: {
      id_card: 0,
      vehicle_card: 0,
      libretto_veicolo_bersani: 0,
      stato_di_famiglia: 0,
      id_card_famigliare: 0,
      visura_camerale: 0,
    },
    uploadingOptionalDocumentRequestId: null,
    rc_quotation: null,
    tutela_quotation: null,
    assistenzaQuote: null,
    infortuniQuote: null,
    rivalsaQuote: null,
    cristalliQuote: null,
  },
};

export const purchaseSlice = createSlice({
  name: "purchases",
  initialState,
  reducers: {
    autoUpdateState: (state, { payload }) => {
      state.auto = { ...state.auto, ...payload };
    },
    autoResetState: (state) => {
      state.auto = { ...initialState.auto };
    },
  },
});
