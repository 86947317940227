import styled from 'styled-components';

export const InsurancesStyles = styled.div``;

export const QuotationsList = styled.div`
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  flex-direction: column;
`;
