import { createGlobalStyle } from 'styled-components';
import { formColors } from '../components/colors/forms';

export const GlobalStyle = createGlobalStyle`
  * {
    font-family: Poppins;
    font-weight: 400;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 8px;
    border-radius: 3px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 3px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 3px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
    border-radius: 3px;
  }

  .invalid-feedback,.general-input-error {
    font-size: 15px;
    color: ${formColors.error};
  }
  
  html, body {
    background-color: #f9f9f9;
    margin: 0;
  }
`;
