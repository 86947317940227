import axios from 'axios';
import { QUOTATION_SERVICE_API_URL } from '../../config/urls';
import { axeRequestInterceptor, axeResponseInterceptor } from './axiosHelpers';

export const quotationsAxe = axios.create();

quotationsAxe.defaults.validateStatus = (status) => status < 500;

quotationsAxe.interceptors.request.use(axeRequestInterceptor);

quotationsAxe.interceptors.response.use((response) => response, axeResponseInterceptor);

quotationsAxe.defaults.baseURL = QUOTATION_SERVICE_API_URL;
