import styled from 'styled-components';
import { formColors } from '../colors/forms';

export const QuotationCardStyles = styled.div`
  border-radius: 20px;
  background: #fff;
  width: 100%;
  cursor: pointer;

  &.selected{
    border: 2px solid ${formColors.primary};
  }
`;

export const Status = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  padding: 15px;

  p {
    margin-top: 0;
    margin-bottom: 0;
    color: #343434;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 135%;
  }
`;

export const StatusCircle = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;

  &.invalid {
    background-color: #f00;
  }

  &.new-quote {
    background-color: #60c000;
  }

  &.completed {
    background-color: #60c000;
  }

  &.progress {
    background-color: #ffc700;
  }
`;

export const QuoteInfo = styled.div`
  h2 {
    margin-top: 0;
    margin-bottom: 15px;
    color: #343434;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;

    span {
      font-weight: bold;
    }
  }

  p {
    color: #343434;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 20px;
    margin-top: 0;
    margin-bottom: 0;

    span {
      font-weight: 500;
    }
  }

`;

export const QuoteInfoContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 15px;
  padding-bottom: 10px;
`;

export const LogoContainer = styled.div`
  width: 100px;
  height: 100px;
  background-color: #fbfbf9;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  img {
    max-width: 80px;
  }
`;
