import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: true,
  afterSalesList: [],
  error: null,
  document_types: [],
  isSubmiting: false,
  accident: {
    loading: true,
    accident: null,
    error: null,
    accident_files: [],
    accident_notes: [],
    signable_documents: [],
  },
  replacement: {
    loading: true,
    replacement: null,
    error: null,
    replacement_files: [],
    signable_documents: [],
  },
  modification: {
    loading: true,
    modification: null,
    error: null,
    modification_files: [],
    signable_documents: [],

  },
  cancellation: {
    loading: true,
    cancellation: null,
    error: null,
    cancellation_files: [],
    signable_documents: [],
  },
};

export const afterSaleSlice = createSlice({
  name: 'afterSales',
  initialState,
  reducers: {
    afterSalesListPending: (state) => {
      state.loading = true;
      state.error = null;
    },
    afterSalesListRejected: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    afterSalesListSuccess: (state, { payload }) => {
      state.loading = false;
      state.afterSalesList = payload;
    },
    afterSalesReplacementPending: (state) => {
      state.isSubmiting = true;
      state.error = null;
    },
    afterSalesReplacementRejected: (state, { payload }) => {
      state.isSubmiting = false;
      state.error = payload;
    },
    afterSalesReplacementSuccess: (state) => {
      state.isSubmiting = false;
    },
    afterSalesModificationPending: (state) => {
      state.isSubmiting = true;
      state.error = null;
    },
    afterSalesModificationRejected: (state, { payload }) => {
      state.isSubmiting = false;
      state.error = payload;
    },
    afterSalesModificationSuccess: (state) => {
      state.isSubmiting = false;
    },
    afterSalesCancellationPending: (state) => {
      state.isSubmiting = true;
      state.error = null;
    },
    afterSalesCancellationRejected: (state, { payload }) => {
      state.isSubmiting = false;
      state.error = payload;
    },
    afterSalesCancellationSuccess: (state) => {
      state.isSubmiting = false;
    },
    afterSalesAccidentPending: (state) => {
      state.isSubmiting = true;
      state.error = null;
    },
    afterSalesAccidentRejected: (state, { payload }) => {
      state.isSubmiting = false;
      state.error = payload;
    },
    afterSalesAccidentSuccess: (state) => {
      state.isSubmiting = false;
    },
    getAccidentPending: (state) => {
      state.accident.loading = true;
      state.accident.error = null;
    },
    getAccidentRejected: (state, { payload }) => {
      state.accident.loading = false;
      state.accident.error = payload;
    },
    getAccidentSuccess: (state, { payload }) => {
      state.accident.loading = false;
      state.accident.accident = payload.accident;
      state.accident.accident_files = payload.documents;
      state.accident.accident_notes = payload.notes;
      state.accident.signable_documents = payload.signable_documents;
    },
    addAccidentNotePending: (state) => {
      state.accident.loading = true;
      state.accident.error = null;
    },
    addAccidentNoteRejected: (state, { payload }) => {
      state.accident.loading = false;
      state.accident.error = payload;
    },
    addAccidentNoteSuccess: (state) => {
      state.accident.loading = false;
    },
    getReplacementPending: (state) => {
      state.replacement.loading = true;
      state.replacement.error = null;
    },
    getReplacementRejected: (state, { payload }) => {
      state.replacement.loading = false;
      state.replacement.error = payload;
    },
    getReplacementSuccess: (state, { payload }) => {
      state.replacement.loading = false;
      state.replacement.replacement = payload.replacement;
      state.replacement.replacement_files = payload.documents;
      state.replacement.signable_documents = payload.signable_documents;
    },
    getModificationPending: (state) => {
      state.modification.loading = true;
      state.modification.error = null;
    },
    getModificationRejected: (state, { payload }) => {
      state.modification.loading = false;
      state.modification.error = payload;
    },
    getModificationSuccess: (state, { payload }) => {
      state.modification.loading = false;
      state.modification.modification = payload.modification;
      state.modification.modification_files = payload.documents;
      state.modification.signable_documents = payload.signable_documents;
    },
    getCancellationPending: (state) => {
      state.cancellation.loading = true;
      state.cancellation.error = null;
    },
    getCancellationRejected: (state, { payload }) => {
      state.cancellation.loading = false;
      state.cancellation.error = payload;
    },
    getCancellationSuccess: (state, { payload }) => {
      state.cancellation.loading = false;
      state.cancellation.cancellation = payload.cancellation;
      state.cancellation.cancellation_files = payload.documents;
      state.cancellation.signable_documents = payload.signable_documents;
    },

    getDocumentTypesPending: (state) => {
      state.loading = true;
      state.error = null;
    },
    getDocumentTypesRejected: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    getDocumentTypesFulfilled: (state, { payload }) => {
      state.loading = false;
      state.document_types = payload.documents;
    },
  },
});
