/* eslint-disable operator-linebreak */
/* eslint-disable @typescript-eslint/quotes */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  auto: {
    loading: true,
    requestInfo: null,
    quotations: null,
    addingQuote: false,
    processingQuote: null,
    tutelaLegaleQuote: null,
    assistenzaQuote: null,
    infortuniQuote: null,
    rivalsaQuote: null,
    cristalliQuote: null,
  },
};

export const resultsSlice = createSlice({
  name: "results",
  initialState,
  reducers: {
    autoRequestInfoUpdated: (state, { payload }) => {
      state.auto.requestInfo = {
        ...payload.insurance_request,
        coverages: payload.insurance_request.coverages
          .split(",")
          .filter((v: string) => v !== ""),
      };
      state.auto.quotations = payload.insurance_request.quotes;
      state.auto.processingQuote = payload.processingQuote;
      state.auto.assistenzaQuote = payload.assistenzaStradaleQuote;
      state.auto.infortuniQuote = payload.infortuniConducenteQuote;
      state.auto.rivalsaQuote = payload.rivalsaQuote;
      state.auto.cristalliQuote = payload.cristalliQuote;
      state.auto.tutelaLegaleQuote = payload.tutelaLegaleQuote;
      state.auto.loading = false;
    },
    autoDataUpdated: (state, { payload }) => {
      state.auto = { ...state.auto, ...payload };
    },
    autoReset: (state) => {
      state.auto = { ...initialState.auto };
    },
  },
});
