import React from 'react';
import { FormGroup } from './FormGroup';
import { Label } from './Label';
import { InputTextAreaStyles } from './InputTextarea.styles';

function InputTextarea({
  registration, label, placeholder, error, valid, value, onChange, inputId, className, textareaClass,
}: any) {
  if (registration === undefined) {
    const handleChange = (e: any) => {
      onChange(e.target.value.trim());
    };

    return (
      <FormGroup className={className}>
        {label !== undefined
                    && <Label>{label}</Label>}
        <InputTextAreaStyles placeholder={placeholder} id={inputId} className={`${error ? 'is-invalid' : ''} ${valid ? 'is-valid' : ''} ${textareaClass}`} onChange={handleChange}>{value}</InputTextAreaStyles>
        <div className="invalid-feedback">
          {error}
        </div>
      </FormGroup>
    );
  }

  return (
    <FormGroup className={className}>
      {label !== undefined
                && <Label>{label}</Label>}
      <InputTextAreaStyles id={inputId} placeholder={placeholder} className={`${error ? 'is-invalid' : ''} ${valid ? 'is-valid' : ''} ${textareaClass}`} {...registration} />
      <div className="invalid-feedback">
        {error}
      </div>
    </FormGroup>
  );
}

export default InputTextarea;
