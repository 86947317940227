import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { useSelector } from 'react-redux';
import {
  autoResendInvalidSignedDocs, autoSelectSignMethod, autoUpdateState, confirmSelectedForBackofficeQuote, purchaseInsuranceRequest,
  resetAutoPurchase,
} from '../../feature/purchase/purchaseActions';
import {
  apiDeleteOptionalDocument, apiUploadOptionalDocument, deleteDocument, uploadPurchaseDocument,
} from '../../services/axios-client/axeCommons';
import {
  confirmAlertForBackofficeBrokerQuote, generalAlertError, generalAlertSuccess, generalAlertSuccessToast,
} from '../../helpers/alerts';
import PageLoader from '../../components/utilities/PageLoader';
import CompletedQuoteScreen from '../Quotations/CompletedQuoteScreen';
import Header from '../../components/utilities/Header';
import { PurchaseAutoStepper } from './common/PurchaseAutoStepper';
import {
  AccordionBody, AccordionContainer, AccordionHeader, AccordionInfo, AfterDocumentUploader, AttesaEmissioneStatus, BadgeOptional, ChatSupport, DigitalSignMethod, DocumentBadge, DocumentsToUpload, DocumentUploader, DocumentUploaderText, GoBackButton, InputCircle, InputContainer, OptionalDocument, PaymentWarning, PrimaRedirectAlert, RemoveDocument, SelectEmailContainer, SignatureDigitalSentContainer, SignMethodContainer, SingleAccordion,
  Table,
  TableCell,
  TableContainer,
  TableHeader,
  TableRow,
  TotalCell,
  TotalRow,
  WarningContainer,
} from './PurchaseAuto.styles';
import { guideType } from '../../constants';
import { ButtonSection } from '../../components/utilities/InsurancesButtons.styles';
import { ButtonStyles } from '../AfterSales/AfterSales.styles';
import warningIcon from '../../assets/icons/warning-icon.svg';
import uploadIcon from '../../assets/icons/upload-icon.svg';
import attesaEmissione from '../../assets/icons/attesa-emissione.svg';
import { InputGroup } from '../../components/forms/InputGroup';
import PurchaseAutoSignableDocs from '../../components/purchaseAuto/PurchaseAutoSignableDocs';
import PurchaseUploader from '../../components/uploader/PurchaseUploader';
import imageUploaded from '../../assets/icons/image-uploaded.svg';
import closeButton from '../../assets/images/accidents/x-circle.svg';
import chatSupport from '../../assets/icons/chat-support.svg';
import PurchasePaymentStatus from '../../components/purchaseCommon/PurchasePaymentStatus';
import OptionalDocumentModal from './common/OptionalDocumentModal';

function PurchaseAutoRequest() {
  const [signatureType, setSignatureType] = useState<any>(false);
  const [selectedMail, setSelectedMail] = useState<any>(null);
  const [signatureMail, setSignatureMail] = useState<any>('');
  const { requestToken } = useParams();
  const navigate = useNavigate();
  const {
    loading, quote, quoteStatus, insuranceRequest, uploadedFirstDocs, rc_quotation, tutela_quotation, assistenzaQuote, infortuniQuote, rivalsaQuote, cristalliQuote,
  } = useSelector((store: any) => store.purchase.auto);
  const [showOptionalDocumentModal, setShowOptionalDocumentModal] = useState<any>(false);
  const [showAccordion, setShowAccordion] = useState<boolean>(false);

  // 1 digital - digital signature`
  // 0 paper - cartacea

  const collectQuoteData = async () => {
    purchaseInsuranceRequest(requestToken!, 'auto');
  };

  const toggleButton = () => {
    setShowAccordion(!showAccordion);
  };

  const selectSignatureMethod = (method: any) => {
    if (method === 0) {
      if (quote.product_id == 1) {
        autoUpdateState({
          signMethod: method,
        });
        autoSelectSignMethod(method, quote.id, collectQuoteData);
      }
    }

    if (method === 1) {
      if (signatureType === method) {
        setSignatureType(null);
      } else {
        setSignatureType(method);
      }
    }
  };

  const handleSelectedMail = (val: any) => {
    if (val === 1) {
      setSelectedMail(val);
      setSignatureMail(insuranceRequest.customer.email);
    } else {
      setSignatureMail('');
      setSelectedMail(2);
    }
  };

  const selectedMethod = () => {
    if (quote.product_id == 1) {
      autoSelectSignMethod(signatureType, quote.id, collectQuoteData, signatureMail);
    }
  };

  const uploadIDCard = (file: any) => {
    let fileInfo;
    let baseURL: any = '';
    const uploadedFile = file;
    // Make new FileReader
    const reader = new FileReader();
    // Convert the file to base64 text
    reader.readAsDataURL(uploadedFile);
    // on reader load pdf...
    reader.onload = async () => {
      fileInfo = uploadedFile.name;

      const fileType = uploadedFile.type;
      baseURL = reader.result;
      const data = {
        document_type_id: 1,
        request_id: quote.insurance_request.id,
        quotation_id: rc_quotation.id,
        original_filename: fileInfo,
        unsigned_document: baseURL.replace(`data:${fileType};base64,`, ''),
      };
      autoUpdateState({
        uploadingIdCard: true,
      });
      const response = await uploadPurchaseDocument(data);
      autoUpdateState({
        uploadingIdCard: false,
      });
      if (response.status !== 200) {
        return;
      }
      collectQuoteData();
      generalAlertSuccessToast('File caricato');
    };
  };

  const uploadVehicleCard = (file: any) => {
    let fileInfo;
    let baseURL: any = '';
    const uploadedFile = file;
    // Make new FileReader
    const reader = new FileReader();
    // Convert the file to base64 text
    reader.readAsDataURL(uploadedFile);
    // on reader load pdf...
    reader.onload = async () => {
      fileInfo = uploadedFile.name;
      const fileType = uploadedFile.type;
      baseURL = reader.result;
      const data = {
        document_type_id: 2,
        request_id: quote.insurance_request.id,
        quotation_id: rc_quotation.id,
        original_filename: fileInfo,
        unsigned_document: baseURL.replace(`data:${fileType};base64,`, ''),
      };
      autoUpdateState({
        uploadingVehicleCard: true,
      });
      const response = await uploadPurchaseDocument(data);
      autoUpdateState({
        uploadingVehicleCard: false,
      });
      if (response.status !== 200) {
        return;
      }
      collectQuoteData();
      generalAlertSuccessToast('File caricato');
    };
  };

  const uploadLibrettoVeicoloBersani = (file: any) => {
    let fileInfo;
    let baseURL: any = '';
    const uploadedFile = file;
    // Make new FileReader
    const reader = new FileReader();
    // Convert the file to base64 text
    reader.readAsDataURL(uploadedFile);
    // on reader load pdf...
    reader.onload = async () => {
      fileInfo = uploadedFile.name;
      const fileType = uploadedFile.type;
      baseURL = reader.result;
      const data = {
        document_type_id: 20,
        request_id: quote.insurance_request.id,
        quotation_id: rc_quotation.id,
        original_filename: fileInfo,
        unsigned_document: baseURL.replace(`data:${fileType};base64,`, ''),
      };
      autoUpdateState({
        uploadingLibrettoVeicoloBersani: true,
      });
      const response = await uploadPurchaseDocument(data);
      autoUpdateState({
        uploadingLibrettoVeicoloBersani: false,
      });
      if (response.status !== 200) {
        return;
      }
      collectQuoteData();
      generalAlertSuccessToast('File caricato');
    };
  };

  const uploadStatoDiFamiglia = (file: any) => {
    let fileInfo;
    let baseURL: any = '';
    const uploadedFile = file;
    // Make new FileReader
    const reader = new FileReader();
    // Convert the file to base64 text
    reader.readAsDataURL(uploadedFile);
    // on reader load pdf...
    reader.onload = async () => {
      fileInfo = uploadedFile.name;
      const fileType = uploadedFile.type;
      baseURL = reader.result;
      const data = {
        document_type_id: 21,
        request_id: quote.insurance_request.id,
        quotation_id: rc_quotation.id,
        original_filename: fileInfo,
        unsigned_document: baseURL.replace(`data:${fileType};base64,`, ''),
      };
      autoUpdateState({
        uploadingStatoDiFamiglia: true,
      });
      const response = await uploadPurchaseDocument(data);
      autoUpdateState({
        uploadingStatoDiFamiglia: false,
      });
      if (response.status !== 200) {
        return;
      }
      collectQuoteData();
      generalAlertSuccessToast('File caricato');
    };
  };

  const uploadIdCardFamigliare = (file: any) => {
    let fileInfo;
    let baseURL: any = '';
    const uploadedFile = file;
    // Make new FileReader
    const reader = new FileReader();
    // Convert the file to base64 text
    reader.readAsDataURL(uploadedFile);
    // on reader load pdf...
    reader.onload = async () => {
      fileInfo = uploadedFile.name;
      const fileType = uploadedFile.type;
      baseURL = reader.result;
      const data = {
        document_type_id: 22,
        request_id: quote.insurance_request.id,
        quotation_id: rc_quotation.id,
        original_filename: fileInfo,
        unsigned_document: baseURL.replace(`data:${fileType};base64,`, ''),
      };
      autoUpdateState({
        uploadingIdCardFamigliare: true,
      });
      const response = await uploadPurchaseDocument(data);
      autoUpdateState({
        uploadingIdCardFamigliare: false,
      });
      if (response.status !== 200) {
        return;
      }
      collectQuoteData();
      generalAlertSuccessToast('File caricato');
    };
  };

  const uploadVisuraCamerale = (file: any) => {
    let fileInfo;
    let baseURL: any = '';
    const uploadedFile = file;
    // Make new FileReader
    const reader = new FileReader();
    // Convert the file to base64 text
    reader.readAsDataURL(uploadedFile);
    // on reader load pdf...
    reader.onload = async () => {
      fileInfo = uploadedFile.name;
      const fileType = uploadedFile.type;
      baseURL = reader.result;
      const data = {
        document_type_id: 24,
        request_id: quote.insurance_request.id,
        quotation_id: rc_quotation.id,
        original_filename: fileInfo,
        unsigned_document: baseURL.replace(`data:${fileType};base64,`, ''),
      };
      autoUpdateState({
        uploadingVisuraCamerale: true,
      });
      const response = await uploadPurchaseDocument(data);
      autoUpdateState({
        uploadingVisuraCamerale: false,
      });
      if (response.status !== 200) {
        return;
      }
      collectQuoteData();
      generalAlertSuccessToast('File caricato');
    };
  };

  const uploadOptionalDocument = (file: any, optionalDocumentRequestId: number) => {
    let fileInfo;
    let baseURL: any = '';
    const uploadedFile = file;
    // Make new FileReader
    const reader = new FileReader();
    // Convert the file to base64 text
    reader.readAsDataURL(uploadedFile);
    // on reader load pdf...
    reader.onload = async () => {
      fileInfo = uploadedFile.name;
      const fileType = uploadedFile.type;
      baseURL = reader.result;
      const data = {
        optional_document_request_id: optionalDocumentRequestId,
        original_filename: fileInfo,
        unsigned_document: baseURL.replace(`data:${fileType};base64,`, ''),
      };
      autoUpdateState({
        uploadingOptionalDocumentRequestId: optionalDocumentRequestId,
      });
      const response = await apiUploadOptionalDocument(data);
      autoUpdateState({
        uploadingOptionalDocumentRequestId: null,
      });
      if (response.status !== 200) {
        return;
      }
      collectQuoteData();
      generalAlertSuccessToast('File caricato');
    };
  };

  const resendInvalidSignedDocs = () => {
    if (quote.product_id == 1) {
      autoResendInvalidSignedDocs(quote.id, collectQuoteData);
    }
  };

  const openOptionalDocumentModal = () => {
    setShowOptionalDocumentModal(true);
  };

  const removeDocument = async (documentId: number) => {
    try {
      const response = await deleteDocument(documentId);

      if (response.status === 200) {
        collectQuoteData();
      }
    } catch (err) {
      // console.error(err);
    }
  };

  const removeOptionalDocument = async (documentId: number) => {
    try {
      const response = await apiDeleteOptionalDocument(documentId);

      if (response.status === 200) {
        collectQuoteData();
      }
    } catch (err) {
      // console.error(err);
    }
  };

  const navigateBack = () => {
    navigate(`/results/auto/${insuranceRequest.request_token}`);
  };

  useEffect(() => {
    if (quote && quote.initial_signature_type !== null) {
      setSignatureType(quote.initial_signature_type);
    }
  }, [quote]);

  useEffect(() => {
    if (loading === false && quote.prima_expired === true) {
      navigate(`/results/auto/${insuranceRequest.request_token}`);
      generalAlertError('È necessario compilare nuovamente il preventivo con Modifica Dati', 'Link e scaduto!');
    } else if (loading === false && quote.prima_link !== undefined && quote.prima_link !== null) {
      window.open(quote.prima_link, '_self');
    }

    if (loading === false && quote.adriatic_expired === true) {
      navigate(`/results/auto/${insuranceRequest.request_token}`);
      generalAlertError('È necessario compilare nuovamente il preventivo con Modifica Dati', 'Link e scaduto!');
    }
  }, [loading, quote]);

  useEffect(() => {
    if (quote && quote.redirect_user === true) {
      navigate('/');
      generalAlertSuccess('La pratica è completa, attendi nostro feedback!');
    }
  }, [quote]);

  const acceptSelectedForBackoffice = () => {
    confirmSelectedForBackofficeQuote(quote.id, collectQuoteData);
  };

  useEffect(() => {
    if (quote !== null) {
      if (quote.preventivas_company_id !== null && quote.added_manually !== 1 && quote.selected_for_backoffice === null) {
        confirmAlertForBackofficeBrokerQuote('Se confermi di procedere con questa quota e cambi idea devi fare modifica dati!', () => acceptSelectedForBackoffice(), navigate, insuranceRequest.request_token);
        return;
      }

      if (quote.should_redirect === 1) {
        navigate(`/results/auto/${insuranceRequest.request_token}`);
        generalAlertError('Se vuoi selezionare un altro preventivo devi fare modifica dati!');
      }
    }
  }, [quote]);

  const getTotal = () => {
    let rcTotalAmount: any = 0;
    let tutelaTotalAmount: any = 0;
    let assistenzaInProccessAmount: any = 0;
    let rivalsaInProccessAmount: any = 0;
    let cristalliInProccessAmount: any = 0;
    let infortuniConducenteInProccessAmount: any = 0;

    rcTotalAmount = rc_quotation.amount;
    tutelaTotalAmount = tutela_quotation !== null ? tutela_quotation.amount : 0;
    assistenzaInProccessAmount = assistenzaQuote !== null ? assistenzaQuote.amount : 0;
    rivalsaInProccessAmount = rivalsaQuote !== null ? rivalsaQuote.amount : 0;
    cristalliInProccessAmount = cristalliQuote !== null ? cristalliQuote.amount : 0;
    infortuniConducenteInProccessAmount = infortuniQuote !== null ? infortuniQuote.amount : 0;

    if (rc_quotation.amount_paid === null) {
      return parseFloat(rcTotalAmount + tutelaTotalAmount + assistenzaInProccessAmount + rivalsaInProccessAmount + cristalliInProccessAmount + infortuniConducenteInProccessAmount).toFixed(2);
    }
    // ? To Check on Auto
    return parseFloat(rcTotalAmount).toFixed(2);
  };

  useEffect(() => {
    collectQuoteData();

    return () => {
      resetAutoPurchase();
    };
  }, []);

  if (loading) {
    return <PageLoader />;
  }

  const documentsRequestsThatNeedUpload = [
    ...insuranceRequest.document_requests.filter((dr: any) => dr.document === null || dr.document.signable_document === 0),
    ...quote.document_requests.filter((dr: any) => dr.document === null || dr.document.signable_document === 0),
  ];

  return (
    <>
      {(quoteStatus !== 7 && quoteStatus !== 8)
        ? (
          <>
            <Header headerTextBold="Insurance" headerText="Purchase" />
            <PurchaseAutoStepper quoteStatus={quoteStatus} />
            <GoBackButton onClick={navigateBack}>
              <FontAwesomeIcon icon={faArrowLeftLong} size="xl" />
            </GoBackButton>
            <AccordionContainer>
              <SingleAccordion>
                <AccordionHeader onClick={() => toggleButton()}>
                  <h3>Informazione</h3>
                  {!showAccordion
                    && <FontAwesomeIcon icon={faChevronDown} />}
                  {showAccordion
                    && <FontAwesomeIcon icon={faChevronUp} />}
                </AccordionHeader>

                {showAccordion
                  && (
                    <AccordionBody>
                      <AccordionInfo className="first-item">
                        <h3>{insuranceRequest.vehicle.version.full_description}</h3>
                        <p>
                          Targa:
                          <span>
                            {' '}
                            {insuranceRequest.vehicle.vehicle_plate}
                          </span>
                        </p>
                        <p>
                          Immatricolazione:
                          <span>
                            {' '}
                            {moment(quote.insurance_request.vehicle.vehicle_month).format('MM')}
                            .
                            {moment(quote.insurance_request.vehicle.vehicle_year).format('YYYY')}
                          </span>
                        </p>
                        <p>
                          Tipo Di Guida:
                          <span>
                            {' '}
                            {guideType.find((v: any) => v.id === quote.insurance_request.guide_type)?.name}
                          </span>
                        </p>
                        <p>
                          Decorrenza:
                          {' '}
                          <span>
                            {moment(rc_quotation.insurance_request.policy_effective_date).format('DD.MM.YYYY')}
                          </span>
                        </p>
                        <p>
                          Status:
                          {' '}
                          <span>{rc_quotation.quotation_status.name}</span>
                        </p>
                      </AccordionInfo>
                      <AccordionInfo className="second-item">
                        <h3>Informazioni Prodotti</h3>
                        <hr />
                        <TableContainer>
                          <Table>
                            <thead>
                              <TableRow>
                                <TableHeader>Prodotto</TableHeader>
                                <TableHeader>Compagnia</TableHeader>
                                <TableHeader>Frequenza Pagamento</TableHeader>
                                <TableHeader>Premio</TableHeader>
                              </TableRow>
                            </thead>
                            <tbody>
                              <TableRow>
                                <TableCell>{rc_quotation.product.name}</TableCell>
                                <TableCell>
                                  {rc_quotation.company_id !== null ? rc_quotation.company.name : rc_quotation.preventivas_company.company_name}
                                </TableCell>
                                <TableCell>
                                  {rc_quotation.payment_frequency === 1 ? 'Annuale' : 'Semestrale'}
                                </TableCell>
                                <TableCell>
                                  {parseFloat(rc_quotation.amount)}
                                  {' '}
                                  &euro;
                                </TableCell>
                              </TableRow>
                              {tutela_quotation !== null
                                && (
                                  <TableRow>
                                    <TableCell>Tutela Legale</TableCell>
                                    <TableCell>
                                      {tutela_quotation.company.name}
                                    </TableCell>
                                    <TableCell>
                                      {tutela_quotation.payment_frequency === 1 ? 'Annuale' : 'Semestrale'}
                                    </TableCell>
                                    <TableCell>
                                      {parseFloat(tutela_quotation.amount)}
                                      {' '}
                                      &euro;
                                    </TableCell>
                                  </TableRow>
                                )}
                              {assistenzaQuote !== null
                                && (
                                  <TableRow>
                                    <TableCell>
                                      Assistenza Stradale
                                    </TableCell>
                                    <TableCell>{assistenzaQuote && assistenzaQuote.company.name}</TableCell>
                                    <TableCell>
                                      {assistenzaQuote.payment_frequency === 1 ? 'Annuale' : 'Semestrale'}
                                    </TableCell>
                                    <TableCell>
                                      {' '}
                                      {parseFloat(assistenzaQuote.amount)}
                                      {' '}
                                      &euro;
                                    </TableCell>
                                  </TableRow>
                                )}
                              {infortuniQuote && (
                                <TableRow>
                                  <TableCell>Infortuni Conducente</TableCell>
                                  <TableCell>
                                    {infortuniQuote.company.name}
                                  </TableCell>
                                  <TableCell>
                                    {infortuniQuote.payment_frequency === 1 ? 'Annuale' : 'Semestrale'}
                                  </TableCell>
                                  <TableCell>
                                    {parseFloat(infortuniQuote.amount)}
                                    {' '}
                                    &euro;
                                  </TableCell>
                                </TableRow>
                              )}
                              {cristalliQuote && (
                                <TableRow>
                                  <TableCell>Cristalli</TableCell>
                                  <TableCell>
                                    {cristalliQuote.company.name}
                                  </TableCell>
                                  <TableCell>
                                    {cristalliQuote.payment_frequency === 1 ? 'Annuale' : 'Semestrale'}
                                  </TableCell>
                                  <TableCell>
                                    {parseFloat(cristalliQuote.amount)}
                                    {' '}
                                    &euro;
                                  </TableCell>
                                </TableRow>
                              )}
                              {rivalsaQuote && (
                                <TableRow>
                                  <TableCell>Rivalsa</TableCell>
                                  <TableCell>
                                    {rivalsaQuote.company.name}
                                  </TableCell>
                                  <TableCell>
                                    {rivalsaQuote.payment_frequency === 1 ? 'Annuale' : 'Semestrale'}
                                  </TableCell>
                                  <TableCell>
                                    {parseFloat(rivalsaQuote.amount)}
                                    {' '}
                                    &euro;
                                  </TableCell>
                                </TableRow>
                              )}
                              <TotalRow>
                                <TotalCell style={{ textAlign: 'left' }}>Totale</TotalCell>
                                <TotalCell />
                                <TotalCell />
                                <TotalCell>
                                  {' '}
                                  &euro;
                                  {' '}
                                  {getTotal()}
                                </TotalCell>
                              </TotalRow>
                            </tbody>
                          </Table>
                        </TableContainer>
                      </AccordionInfo>
                    </AccordionBody>
                  )}
              </SingleAccordion>
            </AccordionContainer>
            {quote.company_id === 2
              ? (
                <PrimaRedirectAlert>
                  Verrai reindirizzato alla piattaforma Prima.it
                </PrimaRedirectAlert>
              )
              : (
                <>
                  {quoteStatus === 1 || quoteStatus === 2 || quoteStatus === 5
                    ? (
                      <SignMethodContainer>
                        <h3>Scegli il tuo metodo di firma</h3>
                        <ButtonSection>
                          {/* <ButtonStyles className={signatureType === 1 ? 'active' : ''} onClick={quote.initial_signature_type === 0 || quote.initial_signature_type === 1 ? () => { } : () => selectSignatureMethod(1)}>
                          Firma Digitale
                        </ButtonStyles> */}
                          <ButtonStyles className={signatureType === 0 ? 'active' : ''} onClick={rc_quotation.initial_signature_type === 1 || rc_quotation.initial_signature_type === 0 ? () => { } : () => selectSignatureMethod(0)}>
                            Cartacea
                          </ButtonStyles>
                        </ButtonSection>

                        {
                          signatureType === 1
                          && (
                            <>
                              {insuranceRequest.initial_signature_type === 1 && (insuranceRequest.documents.find((doc: any) => doc.is_signed_valid === 0) || insuranceRequest.documents.find((doc: any) => doc.is_signed_valid === 0))
                                ? (
                                  <SignatureDigitalSentContainer>
                                    <WarningContainer>
                                      <img src={warningIcon} />
                                      {' '}
                                      <p>In uno o più documenti la firma non è valida.</p>
                                    </WarningContainer>
                                    <button onClick={() => resendInvalidSignedDocs()}>Inviare per rifirmare</button>

                                  </SignatureDigitalSentContainer>
                                )
                                : quote.initial_signature_type === 1 ? (
                                  <SignatureDigitalSentContainer>
                                    <WarningContainer>
                                      <img src={warningIcon} />
                                      {' '}
                                      <p>E stata inviata un'e-mail al cliente!</p>
                                    </WarningContainer>
                                  </SignatureDigitalSentContainer>
                                ) : ''}
                              {insuranceRequest.initial_signature_type === null
                                && (
                                  <DigitalSignMethod>
                                    <h3>Con la Firma digitale firmerai i tuoi documenti tramite email. Inserisci una nuova email o usa quella che hai</h3>
                                    <SelectEmailContainer>
                                      <InputContainer onClick={() => handleSelectedMail(1)}>
                                        <InputCircle className={selectedMail === 1 ? 'selected-input' : ''} />
                                        <p>
                                          {' '}
                                          Usa
                                          {insuranceRequest.customer.email}
                                        </p>
                                      </InputContainer>
                                      <InputContainer onClick={() => handleSelectedMail(2)}>
                                        <InputCircle className={selectedMail === 2 ? 'selected-input' : ''} />
                                        <p>Usa altro email</p>
                                      </InputContainer>
                                    </SelectEmailContainer>

                                    {selectedMail === 2 && (
                                      <InputGroup
                                        placeholder="Scrivi qui la tua email..."
                                        value={signatureMail}
                                        onChange={(val: any) => setSignatureMail(val)}
                                      />
                                    )}
                                    <PaymentWarning>
                                      <img src={warningIcon} alt="" />
                                      <p>Se si fa clic su Invia verrà visualizzata una modalità di conferma, dopo la conferma attendere un paio di minuti per l'elaborazione. Grazie!</p>
                                    </PaymentWarning>
                                    <button onClick={() => selectedMethod()}>Procedi con la Firma Digitale</button>
                                  </DigitalSignMethod>
                                )}
                            </>
                          )
                        }
                        {
                          signatureType === 0
                          && <PurchaseAutoSignableDocs insuranceRequest={insuranceRequest} quote={quote} collectData={collectQuoteData} />
                        }
                        <h2>Carica Documenti</h2>
                        <DocumentsToUpload>
                          <DocumentUploader>
                            {uploadedFirstDocs.id_card === 1 ? (
                              <AfterDocumentUploader>
                                {(insuranceRequest.documents.find((doc: any) => doc.document_type_id === 1) && insuranceRequest.documents.find((doc: any) => doc.document_type_id === 1).is_uploaded_valid === 1)
                                  && (
                                    <DocumentBadge background="rgba(78, 209, 139, 0.4)" textColor="#00A651">
                                      Valido
                                    </DocumentBadge>
                                  )}
                                {(insuranceRequest.documents.find((doc: any) => doc.document_type_id === 1) && insuranceRequest.documents.find((doc: any) => doc.document_type_id === 1).is_uploaded_valid === 0)
                                  && (
                                    <>
                                      <RemoveDocument onClick={() => removeDocument(insuranceRequest.documents.find((doc: any) => doc.document_type_id === 1).id)}>
                                        <img src={closeButton} alt="" />
                                      </RemoveDocument>
                                      <DocumentBadge background="#FFC1C1" textColor="#FF5050">
                                        Non Valido
                                      </DocumentBadge>
                                    </>
                                  )}
                                {(insuranceRequest.documents.find((doc: any) => doc.document_type_id === 1) && insuranceRequest.documents.find((doc: any) => doc.document_type_id === 1).is_uploaded_valid === null) && (
                                  <DocumentBadge background="rgba(255, 165, 0, 0.6)" textColor="white">
                                    In Attesa Verifica
                                  </DocumentBadge>
                                )}
                                <DocumentUploaderText>
                                  <img src={imageUploaded} alt="" />
                                  {(insuranceRequest.documents.find((doc: any) => doc.document_type_id === 1) && insuranceRequest.documents.find((doc: any) => doc.document_type_id === 1).motivation !== null)
                                    && (
                                      <h6>
                                        {insuranceRequest.documents.find((doc: any) => doc.document_type_id === 1).motivation}
                                      </h6>
                                    )}
                                  <p>Tocca qui per scaricare</p>
                                  <h5> Carta D'Identita</h5>
                                </DocumentUploaderText>
                              </AfterDocumentUploader>
                            ) : <PurchaseUploader note="Carica Carta D'Identita" onFileUpload={(file: any) => uploadIDCard(file)} />}
                          </DocumentUploader>

                          <DocumentUploader>
                            {uploadedFirstDocs.vehicle_card === 1 ? (
                              <AfterDocumentUploader>
                                {(insuranceRequest.documents.find((doc: any) => doc.document_type_id === 2) && insuranceRequest.documents.find((doc: any) => doc.document_type_id === 2).is_uploaded_valid === 1) && (
                                  <DocumentBadge background="rgba(78, 209, 139, 0.4)" textColor="#00A651">
                                    Valido
                                  </DocumentBadge>
                                )}
                                {(insuranceRequest.documents.find((doc: any) => doc.document_type_id === 2) && insuranceRequest.documents.find((doc: any) => doc.document_type_id === 2).is_uploaded_valid === 0)
                                  && (
                                    <>
                                      <RemoveDocument onClick={() => removeDocument(insuranceRequest.documents.find((doc: any) => doc.document_type_id === 2).id)}>
                                        <img src={closeButton} alt="" />
                                      </RemoveDocument>
                                      <DocumentBadge background="#FFC1C1" textColor="#FF5050">
                                        Non Valido
                                      </DocumentBadge>
                                    </>
                                  )}
                                {(insuranceRequest.documents.find((doc: any) => doc.document_type_id === 2) && insuranceRequest.documents.find((doc: any) => doc.document_type_id === 2).is_uploaded_valid === null) && (
                                  <DocumentBadge background="rgba(255, 165, 0, 0.6)" textColor="white">
                                    In Attesa Verifica
                                  </DocumentBadge>
                                )}
                                <DocumentUploaderText>
                                  <img src={imageUploaded} alt="" />
                                  {(insuranceRequest.documents.find((doc: any) => doc.document_type_id === 2) && insuranceRequest.documents.find((doc: any) => doc.document_type_id === 2).motivation !== null)
                                    && (
                                      <h6>
                                        {insuranceRequest.documents.find((doc: any) => doc.document_type_id === 2).motivation}
                                      </h6>
                                    )}
                                  <p>Tocca qui per scaricare</p>
                                  <h5>Libretto Del Veicolo</h5>
                                </DocumentUploaderText>
                              </AfterDocumentUploader>
                            ) : <PurchaseUploader note="Carica Libretto Dei Veicolo" onFileUpload={(file: any) => uploadVehicleCard(file)} />}
                          </DocumentUploader>

                          {['S', 'A'].includes(insuranceRequest.vehicle.inherit_merit_class) === true && (
                            <DocumentUploader>
                              {uploadedFirstDocs.libretto_veicolo_bersani === 1 ? (
                                <AfterDocumentUploader>
                                  {(insuranceRequest.documents.find((doc: any) => doc.document_type_id === 20) && insuranceRequest.documents.find((doc: any) => doc.document_type_id === 20).is_uploaded_valid === 1) && (
                                    <DocumentBadge background="rgba(78, 209, 139, 0.4)" textColor="#00A651">
                                      Valido
                                    </DocumentBadge>
                                  )}
                                  {(insuranceRequest.documents.find((doc: any) => doc.document_type_id === 20) && insuranceRequest.documents.find((doc: any) => doc.document_type_id === 20).is_uploaded_valid === 0) && (
                                    <>
                                      <RemoveDocument onClick={() => removeDocument(insuranceRequest.documents.find((doc: any) => doc.document_type_id === 20).id)}>
                                        <img src={closeButton} alt="" />
                                      </RemoveDocument>
                                      <DocumentBadge background="#FFC1C1" textColor="#FF5050">
                                        Non Valido
                                      </DocumentBadge>
                                    </>
                                  )}
                                  {(insuranceRequest.documents.find((doc: any) => doc.document_type_id === 20) && insuranceRequest.documents.find((doc: any) => doc.document_type_id === 20).is_uploaded_valid === null) && (
                                    <DocumentBadge background="rgba(255, 165, 0, 0.6)" textColor="white">
                                      In Attesa Verifica
                                    </DocumentBadge>
                                  )}
                                  <DocumentUploaderText>
                                    <img src={imageUploaded} alt="" />
                                    {(insuranceRequest.documents.find((doc: any) => doc.document_type_id === 20) && insuranceRequest.documents.find((doc: any) => doc.document_type_id === 20).motivation !== null)
                                      && (
                                        <h6>
                                          {insuranceRequest.documents.find((doc: any) => doc.document_type_id === 20).motivation}
                                        </h6>
                                      )}
                                    <p>Tocca qui per scaricare</p>
                                    <h5>Libretto Veicolo Per Bersani</h5>
                                  </DocumentUploaderText>
                                </AfterDocumentUploader>
                              ) : <PurchaseUploader note="Carica Libretto Veicolo Per Bersani" onFileUpload={(file: any) => uploadLibrettoVeicoloBersani(file)} />}
                            </DocumentUploader>
                          )}

                          {insuranceRequest.vehicle.inherit_merit_class === 'A'
                            && (
                              <>
                                <DocumentUploader>
                                  {uploadedFirstDocs.stato_di_famiglia === 1 ? (
                                    <AfterDocumentUploader>
                                      {(insuranceRequest.documents.find((doc: any) => doc.document_type_id === 21) && insuranceRequest.documents.find((doc: any) => doc.document_type_id === 21).is_uploaded_valid === 1) && (
                                        <DocumentBadge background="rgba(78, 209, 139, 0.4)" textColor="#00A651">
                                          Valido
                                        </DocumentBadge>
                                      )}
                                      {(insuranceRequest.documents.find((doc: any) => doc.document_type_id === 21) && insuranceRequest.documents.find((doc: any) => doc.document_type_id === 21).is_uploaded_valid === 0) && (
                                        <>
                                          <RemoveDocument onClick={() => removeDocument(insuranceRequest.documents.find((doc: any) => doc.document_type_id === 21).id)}>
                                            <img src={closeButton} alt="" />
                                          </RemoveDocument>
                                          <DocumentBadge background="#FFC1C1" textColor="#FF5050">
                                            Non Valido
                                          </DocumentBadge>
                                        </>
                                      )}
                                      {(insuranceRequest.documents.find((doc: any) => doc.document_type_id === 21) && insuranceRequest.documents.find((doc: any) => doc.document_type_id === 21).is_uploaded_valid === null) && (
                                        <DocumentBadge background="rgba(255, 165, 0, 0.6)" textColor="white">
                                          In Attesa Verifica
                                        </DocumentBadge>
                                      )}
                                      <DocumentUploaderText>
                                        <img src={imageUploaded} alt="" />
                                        {(insuranceRequest.documents.find((doc: any) => doc.document_type_id === 21) && insuranceRequest.documents.find((doc: any) => doc.document_type_id === 21).motivation !== null)
                                          && (
                                            <h6>
                                              {insuranceRequest.documents.find((doc: any) => doc.document_type_id === 21).motivation}
                                            </h6>
                                          )}
                                        <p>Tocca qui per scaricare</p>
                                        <h5>Stato Di Famiglia</h5>
                                      </DocumentUploaderText>
                                    </AfterDocumentUploader>
                                  ) : <PurchaseUploader note="Carica Stato Di Famiglia" onFileUpload={(file: any) => uploadStatoDiFamiglia(file)} />}
                                </DocumentUploader>
                                <DocumentUploader>
                                  {uploadedFirstDocs.id_card_famigliare === 1 ? (
                                    <AfterDocumentUploader>
                                      {(insuranceRequest.documents.find((doc: any) => doc.document_type_id === 22) && insuranceRequest.documents.find((doc: any) => doc.document_type_id === 22).is_uploaded_valid === 1) && (
                                        <DocumentBadge background="rgba(78, 209, 139, 0.4)" textColor="#00A651">
                                          Valido
                                        </DocumentBadge>
                                      )}
                                      {(insuranceRequest.documents.find((doc: any) => doc.document_type_id === 22) && insuranceRequest.documents.find((doc: any) => doc.document_type_id === 22).is_uploaded_valid === 0) && (
                                        <>
                                          <RemoveDocument onClick={() => removeDocument(insuranceRequest.documents.find((doc: any) => doc.document_type_id === 22).id)}>
                                            <img src={closeButton} alt="" />
                                          </RemoveDocument>
                                          <DocumentBadge background="#FFC1C1" textColor="#FF5050">
                                            Non Valido
                                          </DocumentBadge>
                                        </>
                                      )}
                                      {(insuranceRequest.documents.find((doc: any) => doc.document_type_id === 22) && insuranceRequest.documents.find((doc: any) => doc.document_type_id === 22).is_uploaded_valid === null) && (
                                        <DocumentBadge background="rgba(255, 165, 0, 0.6)" textColor="white">
                                          In Attesa Verifica
                                        </DocumentBadge>
                                      )}
                                      <DocumentUploaderText>
                                        <img src={imageUploaded} alt="" />
                                        {(insuranceRequest.documents.find((doc: any) => doc.document_type_id === 22) && insuranceRequest.documents.find((doc: any) => doc.document_type_id === 22).motivation !== null)
                                          && (
                                            <h6>
                                              {insuranceRequest.documents.find((doc: any) => doc.document_type_id === 22).motivation}
                                            </h6>
                                          )}
                                        <p>Tocca qui per scaricare</p>
                                        <h5>Carta D'Identita Famigliare</h5>
                                      </DocumentUploaderText>
                                    </AfterDocumentUploader>
                                  ) : <PurchaseUploader note="Carica Carta D'Identita Famigliare" onFileUpload={(file: any) => uploadIdCardFamigliare(file)} />}
                                </DocumentUploader>
                              </>
                            )}

                          {insuranceRequest.customer.gender === 'G' && (
                            <DocumentUploader>
                              {uploadedFirstDocs.visura_camerale === 1 ? (
                                <AfterDocumentUploader>
                                  {(insuranceRequest.documents.find((doc: any) => doc.document_type_id === 24) && insuranceRequest.documents.find((doc: any) => doc.document_type_id === 24).is_uploaded_valid === 1) && (
                                    <DocumentBadge background="rgba(78, 209, 139, 0.4)" textColor="#00A651">
                                      Valido
                                    </DocumentBadge>
                                  )}
                                  {(insuranceRequest.documents.find((doc: any) => doc.document_type_id === 24) && insuranceRequest.documents.find((doc: any) => doc.document_type_id === 24).is_uploaded_valid === 0) && (
                                    <>
                                      <RemoveDocument onClick={() => removeDocument(insuranceRequest.documents.find((doc: any) => doc.document_type_id === 24).id)}>
                                        <img src={closeButton} alt="" />
                                      </RemoveDocument>
                                      <DocumentBadge background="#FFC1C1" textColor="#FF5050">
                                        Non Valido
                                      </DocumentBadge>
                                    </>
                                  )}
                                  {(insuranceRequest.documents.find((doc: any) => doc.document_type_id === 24) && insuranceRequest.documents.find((doc: any) => doc.document_type_id === 24).is_uploaded_valid === null) && (
                                    <DocumentBadge background="rgba(255, 165, 0, 0.6)" textColor="white">
                                      In Attesa Verifica
                                    </DocumentBadge>
                                  )}
                                  <DocumentUploaderText>
                                    <img src={imageUploaded} alt="" />
                                    {(insuranceRequest.documents.find((doc: any) => doc.document_type_id === 24) && insuranceRequest.documents.find((doc: any) => doc.document_type_id === 24).motivation !== null)
                                      && (
                                        <h6>
                                          {insuranceRequest.documents.find((doc: any) => doc.document_type_id === 24).motivation}
                                        </h6>
                                      )}
                                    <p>Tocca qui per scaricare</p>
                                    <h5>Visura Camerale</h5>
                                  </DocumentUploaderText>
                                </AfterDocumentUploader>
                              ) : <PurchaseUploader note="Carica Visura Camerale" onFileUpload={(file: any) => uploadVisuraCamerale(file)} />}
                            </DocumentUploader>
                          )}
                          {documentsRequestsThatNeedUpload.map((documentRequest: any) => (

                            documentRequest.document !== null ? (
                              <AfterDocumentUploader>

                                {(documentRequest.document.document_type_id === 26 && documentRequest.document.is_uploaded_valid === 1) && (
                                  <DocumentBadge background="rgba(78, 209, 139, 0.4)" textColor="#00A651">
                                    Valido
                                  </DocumentBadge>
                                )}
                                {(documentRequest.document.document_type_id === 26 && documentRequest.document.is_uploaded_valid === 0)
                                  && (
                                    <>
                                      <RemoveDocument onClick={() => removeOptionalDocument(documentRequest.document.id)}>
                                        <img src={closeButton} alt="" />
                                      </RemoveDocument>
                                      <DocumentBadge background="#FFC1C1" textColor="#FF5050">
                                        Non Valido
                                      </DocumentBadge>
                                    </>
                                  )}
                                {documentRequest.document.document_type_id === 26 && documentRequest.document.is_uploaded_valid === null && (
                                  <DocumentBadge background="rgba(255, 165, 0, 0.6)" textColor="white">
                                    In Attesa Verifica
                                  </DocumentBadge>
                                )}
                                <DocumentUploaderText>
                                  <img src={imageUploaded} alt="" />
                                  {(insuranceRequest.documents.find((doc: any) => doc.document_type_id === 26) && insuranceRequest.documents.find((doc: any) => doc.document_type_id === 26).motivation !== null)
                                    && (
                                      <h6>
                                        {insuranceRequest.documents.find((doc: any) => doc.document_type_id === 26).motivation}
                                      </h6>
                                    )}
                                  <p>Tocca qui per scaricare</p>
                                  <h5>{documentRequest.optional_document_type.name}</h5>
                                </DocumentUploaderText>
                              </AfterDocumentUploader>
                            ) : <PurchaseUploader note={`Carica ${documentRequest.optional_document_type.name}`} onFileUpload={(file: any) => uploadOptionalDocument(file, documentRequest.id)} />
                          ))}
                          <OptionalDocument>
                            <BadgeOptional>
                              Facoltativo
                            </BadgeOptional>
                            <img src={uploadIcon} alt="" />
                            <button onClick={() => openOptionalDocumentModal()}>
                              Richiedi di aggiungere un documento
                            </button>
                          </OptionalDocument>
                        </DocumentsToUpload>
                        <p>Grazie per aver caricato i tuoi documenti. Verificheremo i tuoi documenti entro 12 ore e cambieremo lo stato</p>
                        <ChatSupport>
                          <img src={chatSupport} alt="" />
                        </ChatSupport>
                      </SignMethodContainer>
                    )
                    : ''}

                  {quoteStatus === 4
                    ? (
                      <PurchasePaymentStatus
                        quote={quote}
                        rc_quotation={rc_quotation}
                        tutela_quotation={tutela_quotation}
                        insuranceRequest={insuranceRequest}
                      />
                    )
                    : ''}
                  {quoteStatus === 6
                    ? (
                      <AttesaEmissioneStatus>
                        <img src={attesaEmissione} alt="" />
                        <h2>In Attesa Emissione</h2>
                        <p>Quando lagente backoffice carica la polizza, riceveri una notifica e il contratto porta essere scaricato</p>
                      </AttesaEmissioneStatus>
                    )
                    : ''}
                </>
              )}

          </>
        )
        : ''}
      {
        showOptionalDocumentModal === true
        && (
          <OptionalDocumentModal
            quotationId={rc_quotation.id}
            closeModal={() => setShowOptionalDocumentModal(false)}
            collectData={collectQuoteData}
          />
        )
      }
      {
        (quoteStatus === 7 || quoteStatus === 8)
          ? (
            <CompletedQuoteScreen
              quote={quote}
              rc_quotation={rc_quotation}
              insuranceRequest={insuranceRequest}
            />
          )
          : ''
      }
    </>
  );
}

export default PurchaseAutoRequest;
