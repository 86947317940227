import { BACKEND_BASE_URL } from '../../../../config/urls';
import {
  CardButtons, CardCompanyLogo, CardContainer, CardFlex, CardInformation, InformationContainer,
} from './card.styles';

const paymentFrequencyNames: {
  [key: number]: string;
} = {
  1: 'Annuo',
  2: 'Semestrale',
};

export function AragCard({
  quotation, selectQuotation, type,
}: any) {
  return (
    <CardContainer>
      <CardFlex>
        <CardInformation>
          <div className="price">
            <div className="title">Prezzo annuo</div>
            <div className="amount">
              &euro;
              {Number(quotation.amount).toLocaleString('it-IT', {
                minimumFractionDigits: 2,
              })}
            </div>
          </div>

        </CardInformation>
        <CardCompanyLogo src={BACKEND_BASE_URL + quotation.company.logo} alt={`${quotation.company.name} Logo`} />
      </CardFlex>

      <InformationContainer>
        <div className="record">
          <div className="record-title">Compagnia:</div>
          <div className="record-value">{quotation.company.name}</div>
        </div>
        <div className="record">
          <div className="record-title">Frequenza pagamento:</div>
          <div className="record-value">{paymentFrequencyNames[quotation.payment_frequency]}</div>
        </div>
        <div className="record">
          <div className="record-title">Prodotto:</div>
          <div className="record-value">{type.name}</div>
        </div>
        <div className="record">
          <div className="record-title">Emissione:</div>
          <div className="record-value">Tradizionale</div>
        </div>
      </InformationContainer>

      <CardButtons>
        <div
          // onClick={() => aquista(quotation.id)}
          onClick={() => selectQuotation(quotation.id)}
          className="aquista"
        >
          Seleziona
        </div>
      </CardButtons>
    </CardContainer>
  );
}
