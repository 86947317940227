import styled from 'styled-components';

export const ProductContainer = styled.div``;

export const ProductSection = styled.div`
  h3 {
    color: #343434;
    text-align: center;
    font-family: Poppins;
    font-size: 24px;
    font-weight: 700;

    span {
      color: #343434;
      font-family: Poppins;
      font-size: 24px;
      font-weight: 500;
    }
  }
`;

export const ProductsGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 15px;
  margin-top: 40px;
`;

export const Product = styled.div`
  border-radius: 22px;
  border: 3px solid rgba(0, 166, 81, 0.2);
  padding: 20px;
  width: 130px;
  cursor: pointer;
`;

export const ProductItems = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  h4 {
    margin-top: 5px;
    margin-bottom: 0;
    color: #343434;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
  }
`;
