import { FormGroup } from './FormGroup';
import { Label } from './Label';
import { InputTextStyled } from './InputText.styles';

export function InputText({
  label, type, placeholder, min, max, registration, valid, error, value, onChange, inputId, className,
}: any) {
  if (registration === undefined) {
    const handleChange = (e: any) => {
      onChange(e.target.value.trim());
    };

    return (
      <FormGroup>
        {label !== undefined
                    && <Label>{label}</Label>}
        <InputTextStyled placeholder={placeholder} id={inputId} className={`${error ? 'is-invalid' : ''} ${valid ? 'is-valid' : ''} ${className}`} type={type} min={min} max={max} value={value} onChange={handleChange} />
        <div className="invalid-feedback">
          {error}
        </div>
      </FormGroup>
    );
  }

  return (
    <FormGroup>
      {label !== undefined
                && <Label>{label}</Label>}
      <InputTextStyled id={inputId} placeholder={placeholder} className={`${error ? 'is-invalid' : ''} ${valid ? 'is-valid' : ''} ${className}`} type={type} min={min} max={max} {...registration} />
      <div className="invalid-feedback">
        {error}
      </div>
    </FormGroup>
  );
}
