/* eslint-disable @typescript-eslint/quotes */
import styled from "styled-components";
import { themeColors } from "../../../components/colors/theme";

export const InformationCard = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 20px;
  background-color: white;
  border-radius: 20px;

  .title {
    font-size: 18px;
    display: flex;
    margin-bottom: 20px;
  }

  .bold {
    font-weight: 700;
  }

  .row {
    margin-bottom: 5px;
    display: flex;
  }
`;

export const ModifyDateButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  cursor: pointer;
  margin-top: 20px;

  p {
    margin: 0;
    color: #00a651;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  img {
    max-width: 25px;
  }

  @media only screen and (max-width: 900px) {
    p {
      font-size: 16px;
    }

    img {
      max-width: 16px;
    }
  }
`;

export const GeneralExtrasCard = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  background-color: white;
  border-radius: 20px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .title {
    padding: 10px 20px;
    display: flex;
  }

  .bold {
    font-weight: 700;
  }
  .icon {
    padding: 10px 15px;
    cursor: pointer;
  }

  .extras {
    display: none;

    &.show {
      display: block;
    }

    .extra {
      display: flex;
      padding: 5px 20px;

      .icon {
        width: 20px;
        height: 20px;
        padding: unset;
        color: ${themeColors.primary};
        border: 2px solid ${themeColors.primary};
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
      }

      &.selected {
        .icon {
          color: ${themeColors.error};
          border: 2px solid ${themeColors.error};
        }
      }
    }

    .submit-button {
      display: inline-block;
      margin: 10px 20px;
      padding: 10px 30px;
      background-color: ${themeColors.primary};
      border-radius: 15px;
      color: white;
      font-weight: 500;
      cursor: pointer;
    }
  }
`;

export const FailedCompanyLogo = styled.img`
  width: 100px;
  height: 100px;
  object-fit: contain;
`;

export const QuotationsTypeButtons = styled.div`
  width: 100%;
  border: 2px solid ${themeColors.primary};
  display: flex;
  border-radius: 15px;
  overflow: hidden;

  .button {
    padding: 10px 20px;
    width: 100%;
    text-align: center;
    cursor: pointer;
    color: ${themeColors.primary};

    &.selected {
      background-color: ${themeColors.primary};
      color: white;
    }
  }
`;

export const QuoteLoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
`;

export const QuoteLoader = styled.span`
  width: 50px;
  height: 50px;
  background: rgb(0, 166, 81);
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 600;
`;

export const ModifyDataButton = styled.div`
  margin-top: 10px;
  background-color: ${themeColors.warning};
  padding: 15px 30px;
  display: inline-block;
  border-radius: 10px;
  color: white;
  font-weight: 500;
  cursor: pointer;
`;

export const RiskContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;

  @media screen and (max-width: 800px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const RiskCard = styled.div`
  margin-bottom: 10px;
  padding: 20px;
  background-color: white;
  border-radius: 20px;

  &.bg-success {
    background-color: rgba(25, 135, 84, 0.25);
  }
  &.bg-secondary {
    background-color: rgba(108, 117, 125, 0.25);
  }

  h5 {
    font-weight: 500;
    font-size: 16px;
  }
`;

export const RiskTitle = styled.div`
  h4 {
    margin: 0;
    padding: 5px 10px;
    font-weight: 500;
    font-size: 17px;
  }
`;

export const SimpleButton = styled.button`
  padding: 12px 30px;
  background-color: ${themeColors.primary};
  color: white;
  border-radius: 15px;
  cursor: pointer;
  display: flex;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
  cursor: pointer;
  border: none;
  font-size: 16px;
  transition: background-color 0.3s ease;
`;

export const ButtonDiv = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  margin-top: 40px;
`;

export const SimpleModal = styled.div`
  &.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1059;
  }

  .modal-content {
    background: #f9f9f9;
    padding: 20px;
    border-radius: 5px;
    width: 90%;
    max-width: 900px;
    max-height: 80vh; /* Set maximum height for modal */
    overflow-y: auto; /* Enable vertical scrolling */
    position: relative;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
  }

  /* Hide scrollbars */
  .modal-content::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  .modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }

  .small-button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: ${themeColors.primary};
    border: none;
    color: white; /* White text */
    text-align: center;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    border-radius: 15px;
    transition: background-color 0.3s ease;
  }

  .button-div {
    display: flex;
    justify-content: end;
    align-items: center;
  }

  @media (max-width: 600px) {
    .modal-content {
      padding: 15px;
    }

    .small-button {
      width: 100%; /* Make buttons full-width on small screens */
    }
  }
`;

export const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
`;

export const UnstyledListAquista = styled.div`
  list-style: none;
`;

export const ListItemsAquista = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  h5 {
    font-weight: 500;
    font-size: 16px;
    margin: 0;
  }
`;

export const AlertInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 15px;
  width: 80%;
  margin: 0 auto;
  background-color: gray;
  color: white;
  border-radius: 10px;
  margin-top: 15px;
`;
