import styled from 'styled-components';

export const LayoutStyles = styled.div`
  max-width: 1200px;
  width: calc(100% - 40px);
  padding: 20px;
  margin: 0 auto;
  margin-bottom: 120px;
  position: relative;
`;
